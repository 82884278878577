import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { v4 as uuidv4 } from 'uuid';

// Components
import GptUI from './gpt-ui';

// Actions


// Helpers
import { fetchGptVisionPatientDemographicsPrompt, fetchAutoGptVisionPatientDemographicsResponse,
    fetchGptVisionPatientDemographicsImages, setGptVisionPatientDemographicsData } from '../../../reducers/gpt-patient-demographics-slice';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';

import '../../../../static/css/components/admin-gpt.scss';
import '../../../../static/css/pages/admin-patient-exam-page.scss';

interface Message {
    input: string;
    output: string;
    meta: string;
}

const GptPatientDemographicsVision = () => {
    const dispatch = useCare1AppDispatch();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const examId = useCare1AppSelector(store => store.examData.id);
    const patientDemographicsImageUrls = useCare1AppSelector(store => store.gptPatientDemographics.gpt_vision_patient_demographics_images);

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            metaStr = `${obj['message']['meta']}`;

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);

            dispatch(setGptVisionPatientDemographicsData({dataString: outputStr}));
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT output',
            });
        };
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/patient_demographics/vision/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // Get Gpt response
        getAutoGptResponse();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {
        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'input': inputMessage,
                    'image_urls': patientDemographicsImageUrls.map(imageUrl => `${getFilepathFromBackendMediaUrl(imageUrl)}`),
                    'history': []
                }
            }));
            setMessageHistory(() => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
        }
    }

    const getAutoGptResponse = async () => {

        if (examId === null) {
            return
        }
        
        try {
            const result = await dispatch(fetchAutoGptVisionPatientDemographicsResponse()).unwrap();
            if (result && result.success) {
                if (result.gpt_vision_patient_demographics_response !== '') {
                    handleSocketOnMessage(result.gpt_vision_patient_demographics_response);
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {

        }
    }

    const handleReset = async () => {
        if (examId === null) {
            return
        }

        try {
            const result = await dispatch(fetchGptVisionPatientDemographicsPrompt()).unwrap();
            if (result && result.success) {
                setInputMessage(result.gpt_patient_demographics_chat_prompts);

                // Also get the image URLs that go with the prompt.
                const imagesResult = await dispatch(fetchGptVisionPatientDemographicsImages()).unwrap();
                if (!imagesResult || !imagesResult.success) {
                    throw new Error(imagesResult?.error);
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT vision patient demographics prompts',
            });
        }
    }

    return (
        <div className={`gpt-patient-demographics-chat gpt-vision`}>
            <GptUI
                messageHistory={messageHistory}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                sendMessage={sendMessage}
                formatMessageContent={formatMessageContent}
                isAssistantTyping={isAssistantTyping}
                messagesEndRef={messagesEndRef}
            />
            <Row className='gpt-chat-reset-button-row'>
                <Col>
                    <Button
                        className={''}
                        onClick={handleReset}
                        type="primary"
                        size="large"
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
        </div>)
}

export default GptPatientDemographicsVision;
