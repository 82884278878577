import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Flex, Input, Tag, theme, Tooltip } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { setShareEmails, resetShareEmailSendingStatus } from '../../reducers/disease-resource-slice';

const tagInputStyle: React.CSSProperties = {
  width: 128,
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: 'top',
};

const DiseaseResourceEmailTags: React.FC = () => {

    const shareEmails = useCare1AppSelector(store => store.diseaseResource.shareEmails);

    const dispatch = useCare1AppDispatch();

    const { token } = theme.useToken();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef<InputRef>(null);
    const editInputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [editInputValue]);

    const handleClose = (removedEmail: string) => {
        const newEmails = shareEmails.filter((tag) => tag !== removedEmail);
        // console.log(newTags);
        dispatch(setShareEmails(newEmails as string[]));
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && !shareEmails.includes(inputValue)) {
            dispatch(setShareEmails([...shareEmails, inputValue]));
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newEmails = [...shareEmails];
        newEmails[editInputIndex] = editInputValue;
        dispatch(setShareEmails(newEmails));
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    const tagPlusStyle: React.CSSProperties = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: 'dashed',
    };

    return (
        <Flex gap="4px 0" wrap="wrap">
        {shareEmails.map<React.ReactNode>((email, index) => {
            if (editInputIndex === index) {
                return (
                    <Input
                        ref={editInputRef}
                        key={email}
                        size="small"
                        style={tagInputStyle}
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                        />
                );
            }
            const isLongTag = email.length > 25;
            const tagElem = (
            <Tag
                key={email}
                closable
                style={{ userSelect: 'none' }}
                onClose={() => handleClose(email)}
            >
                <span
                    onDoubleClick={(e) => {
                        setEditInputIndex(index);
                        setEditInputValue(email);
                        e.preventDefault();
                    }}
                >
                {isLongTag ? `${email.slice(0, 25)}...` : email}
                </span>
            </Tag>
            );
            return isLongTag ? (
                <Tooltip title={email} key={email}>
                    {tagElem}
                </Tooltip>
                ) : (
                <Tooltip title={'Double click to edit'} key={email}>
                    {tagElem}
                </Tooltip>
                );
        })}
        {inputVisible ? (
            <Input
                ref={inputRef}
                type="text"
                size="small"
                style={tagInputStyle}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
                />
            ) : (
            <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                New Email
            </Tag>
        )}
        </Flex>
    );
};

export default DiseaseResourceEmailTags;
