import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import '../../../../static/css/components/admin-gpt.scss';
import '../../../../static/css/components/admin-gpt-image-identifier.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import GptImageIdentifierChat from "./gpt-image-identifier-chat";
import GptImageIdentifierImages from "./gpt-image-identifier-images";
import GptImageIdentifierDNDImages from "./gpt-image-identifier-dnd-images";
import GptImageIdentifierDNDExtraImages from './gpt-image-identifier-dnd-extra-images';
import * as Constants from '../../../constants';
import { lengthEqual } from '../../../reducers/patient-exam-slice';
import GptImageIdentifierExtraImages from './gpt-image-identifier-extra-images';
import { GptResultType } from './gpt-image-identifier-itemtypes';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


const GptImageIdentifier = () => {

    const rightFundus = useCare1AppSelector(store => store.examData.photoUrls.right_fundus_photo);
    const leftFundus = useCare1AppSelector(store => store.examData.photoUrls.left_fundus_photo);

    const rightStereoFundus = useCare1AppSelector(store => store.examData.photoUrls.right_stereo_photo);
    const leftStereoFundus = useCare1AppSelector(store => store.examData.photoUrls.left_stereo_photo);

    const rightVfPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_vf_photo);
    const leftVfPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_vf_photo);

    const rightOctRnflPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_oct_rnfl_photo);
    const leftOctRnflPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_oct_rnfl_photo);

    const rightOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.right_oct_photo);
    const leftOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.left_oct_photo);

    const extraImages = useCare1AppSelector(store => store.examData[Constants.EXTRA_IMAGES], lengthEqual);

    const [gptRightFundus, setGptRightFundus] = useState('');
    const [gptLeftFundus, setGptLeftFundus] = useState('');

    const [gptRightStereoFundus, setGptRightStereoFundus] = useState('');
    const [gptLeftStereoFundus, setGptLeftStereoFundus] = useState('');

    const [gptRightVfPhoto, setGptRightVfPhoto] = useState('');
    const [gptLeftVfPhoto, setGptLeftVfPhoto] = useState('');

    const [gptRightOctRnflPhoto, setGptRightOctRnflPhoto] = useState('');
    const [gptLeftOctRnflPhoto, setGptLeftOctRnflPhoto] = useState('');

    const [gptRightOCTMacula, setGptRightOCTMacula] = useState('');
    const [gptLeftOCTMacula, setGptLeftOCTMacula] = useState('');

    const [gptExtraImages, setGptExtraImages] = useState<string[]>([]);

    const handleReset = () => {
        setGptRightFundus('');
        setGptLeftFundus('');
        setGptRightStereoFundus('');
        setGptLeftStereoFundus('');
        setGptRightVfPhoto('');
        setGptLeftVfPhoto('');
        setGptRightOctRnflPhoto('');
        setGptLeftOctRnflPhoto('');
        setGptRightOCTMacula('');
        setGptLeftOCTMacula('');
        setGptExtraImages([]);
    }

    const handleChatResult = async (image_urls: string[], output: GptResultType) => {
        let idxBased = 1;
        if (output['right_fundus_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_fundus_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_stereo_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_stereo_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_vf_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_vf_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_oct_rnfl_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_oct_rnfl_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_oct_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_oct_photo'] === 0) {
            idxBased = 0;
        }

        try {
            if (output['right_fundus_photo'] !== null) {
                const bi: string = image_urls[output['right_fundus_photo'] - idxBased];
                setGptRightFundus(bi);
            }
        }
        catch { };

        try {
            if (output['left_fundus_photo'] !== null) {
                const bi: string = image_urls[output['left_fundus_photo'] - idxBased];
                setGptLeftFundus(bi);
            }
        }
        catch { };

        try {
            if (output['right_stereo_photo'] !== null) {
                const bi: string = image_urls[output['right_stereo_photo'] - idxBased];
                setGptRightStereoFundus(bi);
            }
        }
        catch { };

        try {
            if (output['left_stereo_photo'] !== null) {
                const bi: string = image_urls[output['left_stereo_photo'] - idxBased];
                setGptLeftStereoFundus(bi);
            }
        }
        catch { };

        try {
            if (output['right_vf_photo'] !== null) {
                const bi: string = image_urls[output['right_vf_photo'] - idxBased];
                setGptRightVfPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['left_vf_photo'] !== null) {
                const bi: string = image_urls[output['left_vf_photo'] - idxBased];
                setGptLeftVfPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['right_oct_rnfl_photo'] !== null) {
                const bi: string = image_urls[output['right_oct_rnfl_photo'] - idxBased];
                setGptRightOctRnflPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['left_oct_rnfl_photo'] !== null) {
                const bi: string = image_urls[output['left_oct_rnfl_photo'] - idxBased];
                setGptLeftOctRnflPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['right_oct_photo'] !== null) {
                const bi: string = image_urls[output['right_oct_photo'] - idxBased];
                setGptRightOCTMacula(bi);
            }
        }
        catch { };

        try {
            if (output['left_oct_photo'] !== null) {
                const bi: string = image_urls[output['left_oct_photo'] - idxBased];
                setGptLeftOCTMacula(bi);
            }
        }
        catch { };

        try {
            if ('unclassified' in output) {
                const unclassified = output['unclassified'];
                if (unclassified) {
                    const res : string[] = [];
                    for (let i=0; i<unclassified.length; i++) {
                        const bi: string = image_urls[unclassified[i] - idxBased];
                        res.push(bi);
                    }
                    setGptExtraImages(res);
                }
            }
        }
        catch { };
    }

    const setGptExtraImagesAtIndex = (base64Image: string, idx: number) => {
        if (base64Image !== null && base64Image !== undefined && base64Image !== '') {

            if (idx === -1) {
                setGptExtraImages([base64Image, ...gptExtraImages]);
            }
            else {
                const res: string[] = [...gptExtraImages];
                res[idx] = base64Image;
                setGptExtraImages(res);
            }
        }
    }

    const deleteBase64Image = (field: string) => {
        console.log(`please delete ${field} base64 image`);

        if (field.indexOf('extra-image') !== -1) {
            const regexpIdx = /[(\d+]/;
            const match = field.match(regexpIdx);

            if (match) {
                console.log(`removing extra-image[${match[0]}]`);

                const res: string[] = [...gptExtraImages];
                res.splice(Number(match[0]), 1);
                setGptExtraImages(res);
            }
        }
        else if (field === 'right-fundus') {
            setGptExtraImagesAtIndex(gptRightFundus, -1);
            setGptRightFundus('');
        }
        else if (field === 'right-stereo') {
            setGptExtraImagesAtIndex(gptRightStereoFundus, -1);
            setGptRightStereoFundus('');
        }
        else if (field === 'right-oct-macula') {
            setGptExtraImagesAtIndex(gptRightOCTMacula, -1);
            setGptRightOCTMacula('');
        }
        else if (field === 'right-oct-rnfl') {
            setGptExtraImagesAtIndex(gptRightOctRnflPhoto, -1);
            setGptRightOctRnflPhoto('');
        }
        else if (field === 'right-vf') {
            setGptExtraImagesAtIndex(gptRightVfPhoto, -1);
            setGptRightVfPhoto('');
        }
        else if (field === 'left-fundus') {
            setGptExtraImagesAtIndex(gptLeftFundus, -1);
            setGptLeftFundus('');
        }
        else if (field === 'left-stereo') {
            setGptExtraImagesAtIndex(gptLeftStereoFundus, -1);
            setGptLeftStereoFundus('');
        }
        else if (field === 'left-oct-macula') {
            setGptExtraImagesAtIndex(gptLeftOCTMacula, -1);
            setGptLeftOCTMacula('');
        }
        else if (field === 'left-oct-rnfl') {
            setGptExtraImagesAtIndex(gptLeftOctRnflPhoto, -1);
            setGptLeftOctRnflPhoto('');
        }
        else if (field === 'left-vf') {
            setGptExtraImagesAtIndex(gptLeftVfPhoto, -1);
            setGptLeftVfPhoto('');
        }
    }

    const moveBase64Images = (fromField: string, toField: string) => {
        console.log(`${fromField} should be moved to ${toField}`)

        if (toField.indexOf('extra-image') !== -1) {
            return;
        }

        const imageMap: { [k: string]: string } = {};
        imageMap['right-fundus'] = gptRightFundus;
        imageMap['right-stereo'] = gptRightStereoFundus;
        imageMap['right-oct-macula'] = gptRightOCTMacula;
        imageMap['right-oct-rnfl'] = gptRightOctRnflPhoto;
        imageMap['right-vf'] = gptRightVfPhoto;
        imageMap['left-fundus'] = gptLeftFundus;
        imageMap['left-stereo'] = gptLeftStereoFundus;
        imageMap['left-oct-macula'] = gptLeftOCTMacula;
        imageMap['left-oct-rnfl'] = gptLeftOctRnflPhoto;
        imageMap['left-vf'] = gptLeftVfPhoto;

        for (let i=0; i<gptExtraImages.length; i++) {
            imageMap[`extra-image[${i}]`] = gptExtraImages[i];
        }

        const fromImg = imageMap[fromField];

        if (toField === 'right-fundus') {
            deleteBase64Image('right-fundus');
            setGptRightFundus(fromImg);
        }
        else if (toField === 'right-stereo') {
            deleteBase64Image('right-stereo');
            setGptRightStereoFundus(fromImg);
        }
        else if (toField === 'right-oct-macula') {
            deleteBase64Image('right-oct-macula');
            setGptRightOCTMacula(fromImg);
        }
        else if (toField === 'right-oct-rnfl') {
            deleteBase64Image('right-oct-rnfl');
            setGptRightOctRnflPhoto(fromImg);
        }
        else if (toField === 'right-vf') {
            deleteBase64Image('right-vf');
            setGptRightVfPhoto(fromImg);
        }
        else if (toField === 'left-fundus') {
            deleteBase64Image('left-fundus');
            setGptLeftFundus(fromImg);
        }
        else if (toField === 'left-stereo') {
            deleteBase64Image('left-stereo');
            setGptLeftStereoFundus(fromImg);
        }
        else if (toField === 'left-oct-macula') {
            deleteBase64Image('left-oct-macula');
            setGptLeftOCTMacula(fromImg);
        }
        else if (toField === 'left-oct-rnfl') {
            deleteBase64Image('left-oct-rnfl');
            setGptLeftOctRnflPhoto(fromImg);
        }
        else if (toField === 'left-vf') {
            deleteBase64Image('left-vf');
            setGptLeftVfPhoto(fromImg);
        }

        if (fromField === 'right-fundus') {
            setGptRightFundus('');
        }
        else if (fromField === 'right-stereo') {
            setGptRightStereoFundus('');
        }
        else if (fromField === 'right-oct-macula') {
            setGptRightOCTMacula('');
        }
        else if (fromField === 'right-oct-rnfl') {
            setGptRightOctRnflPhoto('');
        }
        else if (fromField === 'right-vf') {
            setGptRightVfPhoto('');
        }
        else if (fromField === 'left-fundus') {
            setGptLeftFundus('');
        }
        else if (fromField === 'left-stereo') {
            setGptLeftStereoFundus('');
        }
        else if (fromField === 'left-oct-macula') {
            setGptLeftOCTMacula('');
        }
        else if (fromField === 'left-oct-rnfl') {
            setGptLeftOctRnflPhoto('');
        }
        else if (fromField === 'left-vf') {
            setGptLeftVfPhoto('');
        }
    }

    return (
        <div className="admin-gpt-image-identifier">
            <Row>
                <Col span={24}>
                    <GptImageIdentifierImages
                        rightFundus={rightFundus}
                        leftFundus={leftFundus}
                        rightStereoFundus={rightStereoFundus}
                        leftStereoFundus={leftStereoFundus}
                        rightVfPhoto={rightVfPhoto}
                        leftVfPhoto={leftVfPhoto}
                        rightOctRnflPhoto={rightOctRnflPhoto}
                        leftOctRnflPhoto={leftOctRnflPhoto}
                        rightOCTMacula={rightOCTMacula}
                        leftOCTMacula={leftOCTMacula}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <GptImageIdentifierExtraImages
                        extraImages={[...extraImages].sort((a, b) => b.date - a.date)}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <GptImageIdentifierChat
                        rightFundus={rightFundus}
                        leftFundus={leftFundus}
                        rightStereoFundus={rightStereoFundus}
                        leftStereoFundus={leftStereoFundus}
                        rightVfPhoto={rightVfPhoto}
                        leftVfPhoto={leftVfPhoto}
                        rightOctRnflPhoto={rightOctRnflPhoto}
                        leftOctRnflPhoto={leftOctRnflPhoto}
                        rightOCTMacula={rightOCTMacula}
                        leftOCTMacula={leftOCTMacula}
                        onChatResult={handleChatResult}
                        onReset={handleReset}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DndProvider backend={HTML5Backend} context={window}>
                        <Row>
                            <Col span={24}>
                                <GptImageIdentifierDNDImages
                                    rightFundus={gptRightFundus}
                                    leftFundus={gptLeftFundus}
                                    rightStereoFundus={gptRightStereoFundus}
                                    leftStereoFundus={gptLeftStereoFundus}
                                    rightVfPhoto={gptRightVfPhoto}
                                    leftVfPhoto={gptLeftVfPhoto}
                                    rightOctRnflPhoto={gptRightOctRnflPhoto}
                                    leftOctRnflPhoto={gptLeftOctRnflPhoto}
                                    rightOCTMacula={gptRightOCTMacula}
                                    leftOCTMacula={gptLeftOCTMacula}
                                    setRightFundus={setGptRightFundus}
                                    setLeftFundus={setGptLeftFundus}
                                    setRightStereoFundus={setGptRightStereoFundus}
                                    setLeftStereoFundus={setGptLeftStereoFundus}
                                    setRightVfPhoto={setGptRightVfPhoto}
                                    setLeftVfPhoto={setGptLeftVfPhoto}
                                    setRightOctRnflPhoto={setGptRightOctRnflPhoto}
                                    setLeftOctRnflPhoto={setGptLeftOctRnflPhoto}
                                    setRightOCTMacula={setGptRightOCTMacula}
                                    setLeftOCTMacula={setGptLeftOCTMacula}
                                    moveBase64Images={moveBase64Images}
                                    deleteBase64Image={deleteBase64Image}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <GptImageIdentifierDNDExtraImages
                                    extraImages={gptExtraImages}
                                    setGptExtraImagesAtIndex={setGptExtraImagesAtIndex}
                                    moveBase64Images={moveBase64Images}
                                    deleteBase64Image={deleteBase64Image}
                                />
                            </Col>
                        </Row>
                    </DndProvider>
                </Col>
            </Row>
        </div>)
}

export default GptImageIdentifier;
