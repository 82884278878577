import { Col, Row, Divider, Checkbox, Select, Tag } from 'antd';
import PatientExamHeaderODSelect from './patient-exam-header-od-select';
import { useNavigate } from 'react-router-dom';
import { EXAM_HIGH_PRIORITY, EXAM_ID, EXAM_DATE, REACT_DROPDOWN_LABEL,
    VISIT_NUMBER, TOTAL_VISIT_NUMBER,
    } from '../../../constants';
import { lengthEqual } from '../../../reducers/patient-exam-slice';
import { convertTimeToLongFormatLabel } from '../../../helpers/utilities';
import PatientExamHeaderExamStatus from './patient-exam-header-exam-status';
import PatientExamHeaderOMDCStatus from './patient-exam-header-omdc-status';
import PatientExamHeaderExamDatePicker from './patient-exam-header-exam-date-picker';

import { localizedText } from '../../../localizedText';
import { goToExam, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
const { CheckableTag } = Tag;

type ComponentProps = {
    onClick?: () => void,
}

const PatientExamHeaderExamDateStatus = ({onClick}: ComponentProps) => {
    const { OD_LOCATION_TEXT, CLOSEST_OMD_TEXT, OMDC_STATUS_TEXT, CHART_STATUS_TEXT, 
        PATIENT_VISIT_NUMBER_TEXT, OF_TEXT, EXAM_DATE_TEXT, HIGH_PRIORITY_TEXT, 
        OD_TEXT } = localizedText;

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const userIsOD = useCare1AppSelector(store => store.user.isOD)
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const odCity = useCare1AppSelector(store => store.examData.optometrist_city);
    const odProvince = useCare1AppSelector(store => store.examData.optometrist_province);
    const odClosestOmdHours = useCare1AppSelector(store => store.examData.optometrist_closest_omd_hours);
    const odClosestOmdName = useCare1AppSelector(store => store.examData.optometrist_closest_omd_name);
    const isExamHighPriority = useCare1AppSelector(store => store.examData[EXAM_HIGH_PRIORITY]);
    const examDate = useCare1AppSelector(store => store.examData[EXAM_DATE]);
    const examId = useCare1AppSelector(store => store.examData[EXAM_ID]);
    const exams = useCare1AppSelector(store => store.examData.exams, lengthEqual);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);
    const visitNumber = useCare1AppSelector(store => store.examData[VISIT_NUMBER]);
    const totalVisit = useCare1AppSelector(store => store.examData[TOTAL_VISIT_NUMBER]);
    const odUsesRluPei = useCare1AppSelector(store => store.examData.od_uses_rlu_pei);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const omdr = useCare1AppSelector(store => store.examData.omdr);
    const omdrDisplay = omdr && omdr !== 'None' ? omdr : null;

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const formattedDate = convertTimeToLongFormatLabel(examDate);

    // As per RQs the component should be disabled if the previous status is 'ready' or 'reviewed'
    // EH-EXAM-DATE-STATUS 019, 020, 021, 022
    const isDisabled = examStatusPrevious && (examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed');

    const odLocation = (odCity && odProvince) ? `${odCity}, ${odProvince}`
        : odCity && !odProvince ? `${odCity}`
        : !odCity && odProvince ? `${odProvince}`
        : 'N/A';

    const closestOMD = (odClosestOmdHours && odClosestOmdName) ? `${odClosestOmdHours} hrs to Dr. ${odClosestOmdName}`
        : odClosestOmdHours && !odClosestOmdName ? `${odClosestOmdHours} hrs`
        : !odClosestOmdHours && odClosestOmdName ? `Dr. ${odClosestOmdName}`
        : 'N/A';

    return (
        <>
            { ( userIsOMDC || userIsOMDR ) &&
                <Col span={6} className={'omd-pei-col-three'}>
                    {/* Column Three OMDC Labels */}
                    <Row className={'row-omdc'}>
                        <Col span={8} className={'omd-pei-col-three-labels'}
                            onClick={onClick}
                        >
                            <Row className="header-label">
                                <div className="omd-pei-od-label">
                                    <label className="patient-exam-header-label od">{OD_TEXT}</label>
                                </div>
                            </Row>
                            <Row className="header-label">
                                <div className="omd-pei-od-location-label">
                                    <label
                                        data-testid='od-city-label'
                                        className="patient-exam-header-label od"
                                    >
                                        {OD_LOCATION_TEXT}:
                                    </label>
                                </div>
                            </Row>
                            <Row className="header-label">
                                <div className="omd-pei-closest-omd-label">
                                    <label
                                        data-testid='closest-omd-label'
                                        className="patient-exam-header-label od"
                                    >
                                        {CLOSEST_OMD_TEXT}:
                                    </label>
                                </div>
                            </Row>
                        </Col>
                        {/* Column Three OMDC Data */}
                        <Col span={13}>
                            <Row>
                                <Col span={24}>
                                    <div className="omd-pei-exam-od">
                                        <PatientExamHeaderODSelect />
                                    </div>
                                    {/* EH-EXAM-DATE-STATUS 010, 011, 012, 013 */}
                                    <div className={'omd-pei-exam-od-location-content'}>
                                        <label
                                            data-testid='od-city-value'
                                            className="patient-exam-header-content omd-location"
                                        >
                                            {`${odLocation}`}
                                        </label>
                                    </div>
                                    <div className={'omd-pei-exam-od-closest-omdc-content'}>
                                        <label
                                            data-testid='closest-omd-value'
                                            className="patient-exam-header-content omd-hours-name"
                                        >
                                            {`${closestOMD}`}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={3}>
                            <Divider className={'divider-three-omd'} type="vertical" />
                        </Col>
                    </Row>
                </Col>
            }

            {/* Start Column Three !OMDC or Column Four OMDC/OMDR */}
            <Col span={6} className={'exam-date-column'}>
                {/* EH-EXAM-DATE-STATUS 001, 002, 003, 004 */}
                <Row className={'exam-row'}>
                {/* Column Three Labels */}
                <Col span={7} className={'exam-date-labels'}
                    onClick={onClick}
                >
                    <Row className="header-label exam-date-label">
                        <label className="patient-exam-header-label">{EXAM_DATE_TEXT}</label>
                    </Row>
                    <Row className="header-label chart-status-label">
                        <label className="patient-exam-header-label" htmlFor={"Chart Status:"}>
                            {userIsOMDC ? OMDC_STATUS_TEXT + ':' : CHART_STATUS_TEXT}
                        </label>
                    </Row>
                    <Row className="header-label exam-omdr-label">
                        { (userIsOD && odUsesRluPei && !rluExamPremium) &&
                            <label className="patient-exam-header-label omdr">
                                {`OMDR:`}
                            </label>
                        }
                    </Row>
                </Col>

                {/* Column Three Data */}
                <Col span={15} className={'exam-date-content'}>
                    <Row className="exam-date">
                        {/* EH 008 */}
                        <Col className={'exam-date-content-col'} span={24}>
                            {!userIsOMDC && !userIsOMDR &&
                                <PatientExamHeaderExamDatePicker />
                            }
                            { ( userIsOMDC || (userIsOMDR && userIsRE)) &&
                                <div className={'patient-exam-date-input'}>
                                    <Select
                                        size="small"
                                        data-testid='exam-date-picker'
                                        className="patient-exam-visits-selection"
                                        onChange={(value) => {
                                            goToExam(value, history);
                                        }}
                                        value={examId}
                                    >
                                        {
                                            exams && exams.map((exam) => (
                                                <Select.Option key={exam.id} value={exam.id}>
                                                    {exam[REACT_DROPDOWN_LABEL]}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                    <div data-testid='visit-count' className={`patient-visit-of-count`} >
                                        {`${PATIENT_VISIT_NUMBER_TEXT} ${visitNumber} ${OF_TEXT} ${totalVisit}`}
                                    </div>
                                </div>
                            }
                            { ( userIsOMDR && !userIsRE) &&
                                <div className={'patient-exam-date-input'}>
                                    <label
                                        className="patient-exam-header-content"
                                        data-testid='exam-date-picker'
                                    >
                                        {formattedDate}
                                    </label>
                                </div>
                            }
                        </Col>
                        </Row>
                        <Row className="chart-status">
                            {/* EH-EXAM-DATE-STATUS 014, 015, 016, 017, 018 */}
                            { userIsOMDC ?
                                <PatientExamHeaderOMDCStatus />
                                :
                                <PatientExamHeaderExamStatus disabled={Boolean(isDisabled || odUsesRluPei)} />
                            }
                        </Row>
                        <Row className='exam-high-priority'>
                            {/* EH-EXAM-DATE-STATUS 005, 006, 007, 008, 009 */}
                            { ((userIsOD && !odUsesRluPei)|| userIsADMIN) &&
                                <Checkbox
                                    className={'high-priority'}
                                    data-testid='high-priority-checkbox'
                                    disabled={Boolean(isDisabled)}
                                    checked={isExamHighPriority}
                                    onChange={(e) => dispatch(setExamDataValue(EXAM_HIGH_PRIORITY, e.target.checked))}
                                >
                                    <span className='exam-high-priority-label'>{HIGH_PRIORITY_TEXT}</span>
                                </Checkbox>
                            }
                            { userIsOMDR && !userIsRE &&
                                <CheckableTag
                                    className='exam-high-priority-label'
                                    data-testid='high-priority-checkbox'
                                    checked={isExamHighPriority}
                                >
                                    {HIGH_PRIORITY_TEXT}
                                </CheckableTag>
                            }
                        </Row>
                        <Row className="exam-omdr">
                            { (userIsOD && odUsesRluPei && !rluExamPremium) &&
                                <label className="patient-exam-header-content omdr">
                                    {omdrDisplay && `Dr. ${omdrDisplay}`}
                                </label>
                            }
                        </Row>
                        </Col>
                    {!userIsOMDC && !userIsOMDR && (
                        <Col span={2}>
                            <Divider className={'divider-three'} type="vertical" />
                        </Col>
                    )}
                </Row>
            </Col>
        </>
    )
}

export default PatientExamHeaderExamDateStatus;