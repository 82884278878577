import { useState } from 'react';
import { Modal, Button, DatePicker, Divider, Col, Row, Checkbox } from 'antd';
import '../../../static/css/components/admin-reports-modal.scss';
import { useCreateBillingReportMutation, useCreateExamReportMutation, useCreatePreReviewsReportMutation, 
    useCreatePreReviewsTimeReportMutation, useCreateIntegrationListReportMutation, useCreateOmdReviewReportMutation, useCreatePatientHandoutReportMutation } from '../../services/admin-reports-api';
import { localizedText } from '../../localizedText';

type AppProps = {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
}

const AdminReportsGenerateModal = ({isVisible, setIsVisible}: AppProps) => {
    const { START_TEXT, END_TEXT, ADMIN_REPORTS_MODAL_TEXT, EXAM_VISIT_DATE, 
        EXAM_CREATED_DATE, GET_REPORT_TEXT, LAST_OMDR_REVIEW_DATE, OMD_REVIEWS_TEXT, 
        INTEGRATION_LIST_UPLOADS, BILLING_REPORT_EXAM_TEXT } = localizedText;
    const [createExamReport] = useCreateExamReportMutation();
    const [createOmdReviewReport] = useCreateOmdReviewReportMutation();
    const [createIntegrationListReport] = useCreateIntegrationListReportMutation();
    const [createBillingReport] = useCreateBillingReportMutation();
    const [createPreReviewsReport] = useCreatePreReviewsReportMutation();
    const [createPreReviewsTimeReport] = useCreatePreReviewsTimeReportMutation();
    const [createPatientHandoutReport] = useCreatePatientHandoutReportMutation();

    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [reportType, setReportType] = useState<string>('');

    const handleModalClose = () => {
        setIsVisible(false);
    }

    const handleExamReport = async () => {
        try {
            const result = await createExamReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: String(message),
                title: 'Error',
            });
        }
    }

    const handleOmdReviewReport = async () => {
        try {
            const result = await createOmdReviewReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handleIntegrationList = async () => {
        try {
            const result = await createIntegrationListReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handleBillingReport = async () => {
        try {
            const result = await createBillingReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handlePreReviewsReport = async () => {
        try {
            const result = await createPreReviewsReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }


    const handlePreReviewsTimeReport = async () => {
        try {
            const result = await createPreReviewsTimeReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handlePatientHandoutReport = async () => {
        try {
            const result = await createPatientHandoutReport({
                start_date: startDate,
                end_date: endDate,
                type: reportType,
             }
            ).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Report is successfully requested.',
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handleSubmit = () => {
        if (startDate === '' || endDate === '') {
            Modal.error({
                className: 'info-modal',
                content: 'Please select start date and end date',
                title: 'Error',
            });

            return;
        }
        
        if (['by_visit', 'by_create', 'by_review'].includes(reportType)) {
            handleExamReport();
        }
        else if (reportType === 'omd_reviews') {
            handleOmdReviewReport();
        }
        else if (reportType === 'integration_list_report') {
            handleIntegrationList();
        }
        else if (reportType === 'billing_report') {
            handleBillingReport();
        }
        else if (reportType === 'pre_reviews_report') {
            handlePreReviewsReport();
        }
        else if (reportType === 'pre_reviews_time_report') {
            handlePreReviewsTimeReport();
        }
        else if (reportType === 'patient_handout_report') {
            handlePatientHandoutReport();
        }
        else {
            Modal.error({
                className: 'info-modal',
                content: 'Please select a report type',
                title: 'Error',
            });

            return;
        }

        setIsVisible(false);
    }

    return (
        <Modal
            className="admin-reports-modal"
            open={isVisible}
            closable={true}
            onCancel={(e) => handleModalClose() }
        >
            <Row>
                <Col span={24}>
                    <div className="modal-title">
                        {ADMIN_REPORTS_MODAL_TEXT}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div className='date-label'>
                        {START_TEXT}:
                    </div>
                </Col>
                <Col span={10}>
                    <DatePicker
                        className='start-date'
                        onChange={((date, dateString) => { setStartDate(`${dateString}`); })}
                    />
                </Col>
                <Col span={2}>
                    <div className='date-label'>
                        {END_TEXT}:
                    </div>
                </Col>
                <Col span={10}>
                    <DatePicker
                        className='end-date'
                        onChange={((date, dateString) => { setEndDate(`${dateString}`); })}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={7}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "by_visit"}
                        onChange={ (e) => { setReportType("by_visit"); }}
                    >
                    {EXAM_VISIT_DATE}
                    </Checkbox>
                </Col>
                <Col span={8}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "by_create"}
                        onChange={ (e) => { setReportType("by_create"); }}
                    >
                    {EXAM_CREATED_DATE}
                    </Checkbox>
                </Col>
                <Col span={9}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "by_review"}
                        onChange={ (e) => { setReportType("by_review"); }}
                    >
                   {LAST_OMDR_REVIEW_DATE}
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col span={7}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "omd_reviews"}
                        onChange={ (e) => { setReportType("omd_reviews"); }}
                    >
                    {OMD_REVIEWS_TEXT}
                    </Checkbox>
                </Col>
                <Col span={8}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "integration_list_report"}
                        onChange={ (e) => { setReportType("integration_list_report"); }}
                    >
                   {INTEGRATION_LIST_UPLOADS}
                    </Checkbox>
                </Col>
                <Col span={9}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "pre_reviews_report"}
                        onChange={ (e) => { setReportType("pre_reviews_report"); }}
                    >
                    Pre-reviews
                    </Checkbox>
                </Col>
                </Row>
                <Row>
                <Col span={15}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "billing_report"}
                        onChange={ (e) => { setReportType("billing_report"); }}
                    >
                    {BILLING_REPORT_EXAM_TEXT}
                    </Checkbox>
                </Col>
                <Col span={9}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "pre_reviews_time_report"}
                        onChange={ (e) => { setReportType("pre_reviews_time_report"); }}
                    >
                    Pre-reviews Time
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col span={15}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "patient_handout_report"}
                        onChange={ (e) => { setReportType("patient_handout_report"); }}
                    >
                        Patient Handout Report
                    </Checkbox>
                </Col>
            </Row>
            <div className='footer'>
                < Divider />
                <Button
                    className="get-report-button"
                    onClick={handleSubmit}
                >
                    {GET_REPORT_TEXT}
                </Button>
            </div>
        </Modal>
    );
}

export default AdminReportsGenerateModal;