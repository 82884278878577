import React, { Fragment, useState, useRef, useEffect, RefObject } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Col, Row, Button, Tooltip, Switch } from 'antd';
import { SwapOutlined, FileSyncOutlined, ReloadOutlined, InfoCircleOutlined, QuestionCircleOutlined, 
         CloseCircleOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import StereoViewerImage from './patient-exam-stereo-viewer-image';
import '../../../static/css/shared/images-history-card.scss';
import '../../../static/css/components/patient-exam-csv-stereo-viewer.scss';
import { CSV_STEREO_VIEWER_WIDTH } from '../../constants';
import { localizedText } from '../../localizedText';
import { useSetExamStereoviewerImagesPositionMutation } from '../../services/exam-api';
import { setExamDataValues, ICSVPhoto } from '../../reducers/patient-exam-slice';
import { IStereoViewerImagesPosition } from '../../services/exam-api';

interface StereoViewerImageObject {
    getImageZoomData: () => IStereoViewerImagesPosition,
    loadSavedTransform: () => void,
    resetDefaultSize: () => void,
}

type ComponentProps = {
    inGlaucomaWorksheet: boolean,
}

const PatientExamStereoViewer = ({ inGlaucomaWorksheet } : ComponentProps) => {
    const { RESET_TEXT, PRESAVED_VIEW_TEXT, SAVE_VIEW_TEXT, DEFAULT_VIEW_TEXT,
        SWITCH_OS_PHOTOS, SWITCH_OD_PHOTOS, STEREO_VIEWER_TEXT,
        PATIENT_EXAM_ZOOM_CLICK } = localizedText;
    const dispatch = useCare1AppDispatch();
    const csvLeftFundus = useCare1AppSelector<ICSVPhoto>(store => store.examData.csv_left_fundus);
    const csvRightFundus = useCare1AppSelector<ICSVPhoto>(store => store.examData.csv_right_fundus);
    const csvLeftStereo = useCare1AppSelector<ICSVPhoto>(store => store.examData.csv_left_stereo);
    const csvRightStereo = useCare1AppSelector<ICSVPhoto>(store => store.examData.csv_right_stereo);
    const csvSwitchLeftPhoto = useCare1AppSelector(store => store.examData.csv_left_switched);
    const csvSwitchRightPhoto = useCare1AppSelector(store => store.examData.csv_right_switched);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const examId = useCare1AppSelector(store => store.examData.id) as number;
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);

    const [setExamStereoviewerImagesPosition] = useSetExamStereoviewerImagesPositionMutation();

    // Display stereo viewer if both side fundus and stereo images are loaded
    // { STEREO-VIEWER 006, 007, 008, 009 }
    const displayStereoViewer = csvLeftFundus || csvLeftStereo || csvRightFundus || csvRightStereo;

    const [switchRightPhoto, switchRightPhotos] = useState(csvSwitchRightPhoto);
    const [switchLeftPhoto, switchLeftPhotos] = useState(csvSwitchLeftPhoto);

    const [wheelEnabled, setWheelEnabled] = useState(false);

    const emptyStereoViewerInfo = {
        left: '',
        top: '',
        width: '',
        height: '',
    }

    const defaultStereoViewerInfo = {
        left: 0,
        top: 0,
        width: CSV_STEREO_VIEWER_WIDTH,
        height: CSV_STEREO_VIEWER_WIDTH,
    }

    const headingText = `${STEREO_VIEWER_TEXT}`
    const tooltipText = `New images are available after validation. ${userIsAdmin ? `${PATIENT_EXAM_ZOOM_CLICK}` : ''}`
    const tooltipTextOD = `${PATIENT_EXAM_ZOOM_CLICK}`


    // use these states to store the image zoom and position info before switch photo is clicked
    const [leftFundusInfo, setLeftFundusInfo] = useState<IStereoViewerImagesPosition>(emptyStereoViewerInfo);
    const [rightFundusInfo, setRightFundusInfo] = useState<IStereoViewerImagesPosition>(emptyStereoViewerInfo);
    const [leftStereoInfo, setLeftStereoInfo] = useState<IStereoViewerImagesPosition>(emptyStereoViewerInfo);
    const [rightStereoInfo, setRightStereoInfo] = useState<IStereoViewerImagesPosition>(emptyStereoViewerInfo);

    const handleRightSwitch = () => {

        const rightFundusZoomData = rightFundusRef!.current!.getImageZoomData();
        const rightStereoZoomData = rightStereoRef!.current!.getImageZoomData();
        setRightFundusInfo(rightFundusZoomData);
        setRightStereoInfo(rightStereoZoomData);
        switchRightPhotos(!switchRightPhoto);
    }

    const handleLeftSwitch = () => {
        const leftStereoZoomData = leftStereoRef!.current!.getImageZoomData();
        const leftFundusZoomData = leftFundusRef!.current!.getImageZoomData();
        setLeftFundusInfo(leftFundusZoomData);
        setLeftStereoInfo(leftStereoZoomData);
        switchLeftPhotos(!switchLeftPhoto);
    }

    const resetLocalStereoViewerInfo = () => {
        setRightFundusInfo(emptyStereoViewerInfo);
        setLeftFundusInfo(emptyStereoViewerInfo);
        setRightStereoInfo(emptyStereoViewerInfo);
        setLeftStereoInfo(emptyStereoViewerInfo);
    }

    useEffect(() => {
        switchRightPhotos(csvSwitchRightPhoto);
        switchLeftPhotos(csvSwitchLeftPhoto);
    }, [csvSwitchLeftPhoto, csvSwitchRightPhoto])

    const rightStereoRef = useRef<StereoViewerImageObject>(null);
    const rightFundusRef = useRef<StereoViewerImageObject>(null);
    const leftStereoRef = useRef<StereoViewerImageObject>(null);
    const leftFundusRef = useRef<StereoViewerImageObject>(null);

    const handleSave = () => {
        // Get the image location and zoom data from each image and save them to the exam data, and the backend.
        const rightStereoZoomData = rightStereoRef!.current!.getImageZoomData();
        const rightFundusZoomData = rightFundusRef!.current!.getImageZoomData();
        const leftStereoZoomData = leftStereoRef!.current!.getImageZoomData();
        const leftFundusZoomData = leftFundusRef!.current!.getImageZoomData();

        resetLocalStereoViewerInfo();

        const csv_right_stereo = {
            ...csvRightStereo,
            left: rightStereoZoomData.left,
            top: rightStereoZoomData.top,
            width: rightStereoZoomData.width,
            height: rightStereoZoomData.height,
        };

        const csv_right_fundus = {
            ...csvRightFundus,
            left: rightFundusZoomData.left,
            top: rightFundusZoomData.top,
            width: rightFundusZoomData.width,
            height: rightFundusZoomData.height,
        };

        const csv_left_stereo = {
            ...csvLeftStereo,
            left: leftStereoZoomData.left,
            top: leftStereoZoomData.top,
            width: leftStereoZoomData.width,
            height: leftStereoZoomData.height,
        };

        const csv_left_fundus =  {
            ...csvLeftFundus,
            left: leftFundusZoomData.left,
            top: leftFundusZoomData.top,
            width: leftFundusZoomData.width,
            height: leftFundusZoomData.height,
        };

        dispatch(setExamDataValues({
            csv_right_stereo,
            csv_right_fundus,
            csv_left_stereo,
            csv_left_fundus,
            csv_left_switched: switchLeftPhoto,
            csv_right_switched: switchRightPhoto,
        }));

        setExamStereoviewerImagesPosition({
            csvLeftFundus: csv_left_fundus,
            csvLeftStereo: csv_left_stereo,
            csvRightFundus: csv_right_fundus,
            csvRightStereo: csv_right_stereo,
            examId,
            leftSwitched: switchLeftPhoto,
            rightSwitched: switchRightPhoto,
            saveEmptyValues: true,
        })
        .then(() => {
            // Also set the stereoviewer image position values of the exams where the images come from, if needed.
            // It's possible that each image can come from a different exam, so they are set individually.
            if (csvLeftFundus.exam && csvLeftFundus.exam !== examId) {
                return setExamStereoviewerImagesPosition({
                    csvLeftFundus: csv_left_fundus,
                    csvLeftStereo: emptyStereoViewerInfo,
                    csvRightFundus: emptyStereoViewerInfo,
                    csvRightStereo: emptyStereoViewerInfo,
                    examId: csvLeftFundus.exam,
                    leftSwitched: switchLeftPhoto,
                    rightSwitched: switchRightPhoto,
                    saveEmptyValues: false,
                });
            }
        })
        .then(() => {
            if (csvLeftStereo.exam && csvLeftStereo.exam !== examId) {
                return setExamStereoviewerImagesPosition({
                    csvLeftFundus: emptyStereoViewerInfo,
                    csvLeftStereo: csv_left_stereo,
                    csvRightFundus: emptyStereoViewerInfo,
                    csvRightStereo: emptyStereoViewerInfo,
                    examId: csvLeftStereo.exam,
                    leftSwitched: switchLeftPhoto,
                    rightSwitched: switchRightPhoto,
                    saveEmptyValues: false,
                });
            }
        })
        .then(() => {
            if (csvRightFundus.exam && csvRightFundus.exam !== examId) {
                return setExamStereoviewerImagesPosition({
                    csvLeftFundus: emptyStereoViewerInfo,
                    csvLeftStereo: emptyStereoViewerInfo,
                    csvRightFundus: csv_right_fundus,
                    csvRightStereo: emptyStereoViewerInfo,
                    examId: csvRightFundus.exam,
                    leftSwitched: switchLeftPhoto,
                    rightSwitched: switchRightPhoto,
                    saveEmptyValues: false,
                });
            }
        })
        .then(() => {
            if (csvRightStereo.exam && csvRightStereo.exam !== examId) {
                return setExamStereoviewerImagesPosition({
                    csvLeftFundus: emptyStereoViewerInfo,
                    csvLeftStereo: emptyStereoViewerInfo,
                    csvRightFundus: emptyStereoViewerInfo,
                    csvRightStereo: csv_right_stereo,
                    examId: csvRightStereo.exam,
                    leftSwitched: switchLeftPhoto,
                    rightSwitched: switchRightPhoto,
                    saveEmptyValues: false,
                });
            }
        });
    }

    const handleViewerImageReset = (imageRef: RefObject<StereoViewerImageObject>) => {
        imageRef!.current!.resetDefaultSize();
    }

    const handlePresavedView = () => {
        
        resetLocalStereoViewerInfo();

        rightStereoRef!.current!.loadSavedTransform();
        rightFundusRef!.current!.loadSavedTransform();
        leftStereoRef!.current!.loadSavedTransform();
        leftFundusRef!.current!.loadSavedTransform();

        switchRightPhotos(csvSwitchRightPhoto);
        switchLeftPhotos(csvSwitchLeftPhoto);
    }

    const handleDefaultView = () => {
        rightStereoRef!.current!.resetDefaultSize();
        rightFundusRef!.current!.resetDefaultSize();
        leftStereoRef!.current!.resetDefaultSize();
        leftFundusRef!.current!.resetDefaultSize();

        setRightFundusInfo(defaultStereoViewerInfo);
        setRightStereoInfo(defaultStereoViewerInfo);
        setLeftFundusInfo(defaultStereoViewerInfo);
        setLeftStereoInfo(defaultStereoViewerInfo);

        // Also clear any image switching.
        switchRightPhotos(false);
        switchLeftPhotos(false);
    }

    const rightFundusStatus =
        <div>
            {csvRightFundus.status === 'did_not_run' && <div className={csvRightFundus.status}><CloseCircleOutlined /> Disc Hemorrhage model did not run</div>}
            {csvRightFundus.status === 'not_detected' && <div className={csvRightFundus.status}><CheckCircleOutlined /> No disc hemorrhage detected</div>}
            {csvRightFundus.status === 'detected' && <div className={csvRightFundus.status}><WarningOutlined /> Potential disc hemorrhage detected</div>}
        </div>

    const rightStereoStatus =
        <div>
            {csvRightStereo.status === 'did_not_run' && <div className={csvRightStereo.status}><CloseCircleOutlined /> Disc Hemorrhage model did not run</div>}
            {csvRightStereo.status === 'not_detected' && <div className={csvRightStereo.status}><CheckCircleOutlined /> No disc hemorrhage detected</div>}
            {csvRightStereo.status === 'detected' && <div className={csvRightStereo.status}><WarningOutlined /> Potential disc hemorrhage detected</div>}
        </div>

    const leftFundusStatus =
        <div>
            {csvLeftFundus.status === 'did_not_run' && <div className={csvLeftFundus.status}><CloseCircleOutlined /> Disc Hemorrhage model did not run</div>}
            {csvLeftFundus.status === 'not_detected' && <div className={csvLeftFundus.status}><CheckCircleOutlined /> No disc hemorrhage detected</div>}
            {csvLeftFundus.status === 'detected' && <div className={csvLeftFundus.status}><WarningOutlined /> Potential disc hemorrhage detected</div>}
        </div>

    const leftStereoStatus =
        <div>
            {csvLeftStereo.status === 'did_not_run' && <div className={csvLeftStereo.status}><CloseCircleOutlined /> Disc Hemorrhage model did not run</div>}
            {csvLeftStereo.status === 'not_detected' && <div className={csvLeftStereo.status}><CheckCircleOutlined /> No disc hemorrhage detected</div>}
            {csvLeftStereo.status === 'detected' && <div className={csvLeftStereo.status}><WarningOutlined /> Potential disc hemorrhage detected</div>}
        </div>

    const rightFundus =
        <StereoViewerImage
            photoUrl={csvRightFundus.url}
            overlayUrl={inGlaucomaWorksheet ? csvRightFundus.overlay_url : ''}
            idx={'1'}
            left={rightFundusInfo.left !== '' ? rightFundusInfo.left: csvRightFundus.left}
            top={rightFundusInfo.top !== '' ?  rightFundusInfo.top : csvRightFundus.top}
            width={rightFundusInfo.width || csvRightFundus.width}
            height={rightFundusInfo.height || csvRightFundus.height}
            ref={rightFundusRef}
            isAdmin={userIsAdmin}
            wheelEnabled={wheelEnabled}
            aiButtonEnabled={inGlaucomaWorksheet}
        />

    const rightStereo =
        <StereoViewerImage
            photoUrl={csvRightStereo.url}
            overlayUrl={inGlaucomaWorksheet ? csvRightStereo.overlay_url : ''}
            idx={'2'}
            left={rightStereoInfo.left !== '' ? rightStereoInfo.left : csvRightStereo.left}
            top={rightStereoInfo.top !== '' ? rightStereoInfo.top : csvRightStereo.top}
            width={rightStereoInfo.width || csvRightStereo.width}
            height={rightStereoInfo.height || csvRightStereo.height}
            ref={rightStereoRef}
            isAdmin={userIsAdmin}
            wheelEnabled={wheelEnabled}
            aiButtonEnabled={inGlaucomaWorksheet}
        />

    const leftFundus =
        <StereoViewerImage
            photoUrl={csvLeftFundus.url}
            overlayUrl={inGlaucomaWorksheet ? csvLeftFundus.overlay_url : ''}
            idx={'3'}
            left={leftFundusInfo.left !== '' ? leftFundusInfo.left :csvLeftFundus.left}
            top={leftFundusInfo.top !== '' ? leftFundusInfo.top :csvLeftFundus.top}
            width={leftFundusInfo.width ||csvLeftFundus.width}
            height={leftFundusInfo.height ||csvLeftFundus.height}
            ref={leftFundusRef}
            isAdmin={userIsAdmin}
            wheelEnabled={wheelEnabled}
            aiButtonEnabled={inGlaucomaWorksheet}
        />

    const leftStereo =
        <StereoViewerImage
            photoUrl={csvLeftStereo.url}
            overlayUrl={inGlaucomaWorksheet ? csvLeftStereo.overlay_url : ''}
            idx={'4'}
            left={leftStereoInfo.left !== '' ? leftStereoInfo.left : csvLeftStereo.left}
            top={leftStereoInfo.top !== '' ? leftStereoInfo.top : csvLeftStereo.top}
            width={leftStereoInfo.width || csvLeftStereo.width}
            height={leftStereoInfo.height || csvLeftStereo.height}
            ref={leftStereoRef}
            isAdmin={userIsAdmin}
            wheelEnabled={wheelEnabled}
            aiButtonEnabled={inGlaucomaWorksheet}
        />

    return (
        <Fragment>
        { displayStereoViewer &&
            <Card className={`${inGlaucomaWorksheet ? 'patient-exam-csv-stereo-viewer-glaucoma-worksheet' : 'patient-exam-csv-stereo-viewer'} images-history-card `} bordered={false}>
                {/* STEREO-VIEWER 001, 002, 003, 004, 005 */}
                <Row>
                <div className="heading">
                    {headingText}
                    {/* { (userIsAdmin || userIsOMDR) && */}
                    <Tooltip
                        className="popup-tooltip"
                        placement='right'
                        title={tooltipText}>
                            <InfoCircleOutlined />
                    </Tooltip>
                    {/* } */}
                </div>
                <div>
                    { (!userIsAdmin) &&
                    <Tooltip
                    className="popup-tooltip-2"
                    placement='right'
                    title={tooltipTextOD}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                    }
                </div>
                </Row>
                {inGlaucomaWorksheet && <Row className='status'>
                    <Col span={4}>
                        {!switchRightPhoto ? rightStereoStatus : rightFundusStatus}
                    </Col>
                    <Col span={2}>
                        <div className='status-side'>OD (Right Eye)</div>
                    </Col>
                    <Col span={4}>
                        {switchRightPhoto ? rightStereoStatus : rightFundusStatus}
                    </Col>
                    <Col span={2}>
                        <div className='status-side'>OD (Right Eye)</div>
                    </Col>
                    <Col span={4}>
                        {switchLeftPhoto ? leftStereoStatus : leftFundusStatus}
                    </Col>
                    <Col span={2}>
                        <div className='status-side'>OS (Left Eye)</div>
                    </Col>
                    <Col span={4}>
                        {!switchLeftPhoto ? leftStereoStatus : leftFundusStatus}
                    </Col>
                    <Col span={2}>
                        <div className='status-side'>OS (Left Eye)</div>
                    </Col>
                </Row>}
                <Row>
                    <Col className='image two' data-testid="right-stereo" key={1} span={6}>
                        {!switchRightPhoto ? rightStereo : rightFundus}
                    </Col>
                    <Col className='image one' data-testid="right-fundus" key={2} span={6}>
                        {switchRightPhoto ? rightStereo : rightFundus}
                    </Col>
                    <Col className='image three' data-testid="left-fundus" key={3} span={6}>
                        {switchLeftPhoto ? leftStereo : leftFundus}
                    </Col>
                    <Col className='image four' data-testid="left-stereo" key={4} span={6}>
                        {!switchLeftPhoto ? leftStereo : leftFundus}
                    </Col>
                </Row>
                { userIsOMDC ?
                    <Row className='photo-controls-row centered'>
                        <Col span={12} className='od-panel'>
                            <Button
                                className='swap-button'
                                icon={<SwapOutlined />}
                                type='primary'
                                onClick={handleRightSwitch}
                            >
                                {SWITCH_OD_PHOTOS}
                            </Button>
                        </Col>
                        <Col span={12} className='os-panel'>
                            <Button
                                className='swap-button'
                                icon={<SwapOutlined />}
                                type='primary'
                                onClick={handleLeftSwitch}
                            >
                                {SWITCH_OS_PHOTOS}
                            </Button>
                        </Col>
                    </Row>
                    :
                    <Row className='photo-controls-row' gutter={[16, 0]}>
                        <Col span={12} className='od-panel'>
                            <Row justify={'space-between'}>
                                <Col span={4}>
                                    {!userIsOD && 
                                    <Button className='save-button' type='primary' icon={<FileSyncOutlined />} onClick={handleSave}>
                                        {SAVE_VIEW_TEXT}
                                    </Button>
                                    }
                                </Col>
                                <Col>
                                    <Button
                                        className='reset-zoom-button'
                                        icon={<ReloadOutlined />}
                                        type='primary'
                                        onClick={() => {
                                            handleViewerImageReset(switchRightPhoto ? rightFundusRef : rightStereoRef);
                                        }}
                                    >
                                        {RESET_TEXT}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='swap-button'
                                        icon={<SwapOutlined />}
                                        type='primary'
                                        onClick={handleRightSwitch}
                                    >
                                        {SWITCH_OD_PHOTOS}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='reset-zoom-button'
                                        icon={<ReloadOutlined />}
                                        type='primary'
                                        onClick={() => {
                                            handleViewerImageReset(switchRightPhoto ? rightStereoRef : rightFundusRef);
                                        }}
                                    >
                                        {RESET_TEXT}
                                    </Button>
                                </Col>
                                <Col span={4}>
                                    {!userIsOD &&
                                    <Button size="small" className='presaved-button' type='primary' onClick={handlePresavedView}>
                                    {PRESAVED_VIEW_TEXT}
                                    </Button>
                                    }
                                </Col>
                            </Row>
                            
                            {/*
                            <img className="reset-zoom-button" alt="reset" width="32" height="32" src={ResetZoomButton}
                                onClick={() => {
                                    handleViewerImageReset(switchRightPhoto ? rightStereoRef : rightFundusRef);
                                }}
                            />
                            */}
                        </Col>
                        <Col span={12} className='od-panel'>
                            <Row justify={'space-between'} align={'middle'}>
                                <Col span={4}>
                                    {!userIsOD && 
                                    <Button className='default-view-button' type='primary' onClick={handleDefaultView}>
                                        {DEFAULT_VIEW_TEXT}
                                    </Button>
                                    }
                                </Col>
                                <Col>
                                    <Button
                                        className='reset-zoom-button third'
                                        icon={<ReloadOutlined />}
                                        type='primary'
                                        onClick={() => {
                                            handleViewerImageReset(switchLeftPhoto ? leftStereoRef : leftFundusRef);
                                        }}
                                    >
                                        {RESET_TEXT}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='swap-button'
                                        icon={<SwapOutlined />}
                                        type="primary"
                                        onClick={handleLeftSwitch}
                                    >
                                        {SWITCH_OS_PHOTOS}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='reset-zoom-button fourth'
                                        icon={<ReloadOutlined />}
                                        type='primary'
                                        onClick={() => {
                                            handleViewerImageReset(switchLeftPhoto ? leftFundusRef : leftStereoRef);
                                        }}
                                    >
                                        {RESET_TEXT}
                                    </Button>
                                </Col>
                                <Col className='mouse-wheel-switch' span={4}>
                                    { userIsAdmin && 
                                    <>
                                        <span className='mouse-wheel-text'>
                                            Wheel
                                        </span>
                                        <Switch
                                            className='mouse-wheel-button'
                                            checked={wheelEnabled}
                                            checkedChildren="On"
                                            unCheckedChildren="Off"
                                            onChange={(checked, e) => setWheelEnabled(checked)}
                                        />
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
            </Card>
        }
        </Fragment>
    );
}

export default PatientExamStereoViewer;