import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Layout, Divider, Row, Col, Select, Input, Spin, Button } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router';
import { IUploadReconcilerPatientMatchingTableItem } from '../../../services/upload-reconciler-api';
import PatientMatchingListTable from './patient-matching-list-table';
import PatientSearchBar from '../../integrated/patient-search-bar';
import { getEditPatientData } from '../../../reducers/patient-edit-slice';
import { useGetGpsByProvinceIdQuery, useGetAllGpsQuery } from '../../../services/doctor-api';
import {
    OPERATING_TEXT, PHONE_MASK_FORMAT
} from '../../../constants';
import InputMask from 'react-input-mask';

import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet


type ComponentProps = {
    patientId: number | null,
    patientMatchingList: IUploadReconcilerPatientMatchingTableItem[],
    isFetching: boolean,
    gptProvince: number | null,
    gp: number | null,
    setGp: (v: number | null) => void,
    gpFax: string | null,
    setGpFax: (v: string | null) => void,
    odNotes: string,
    setOdNotes: (v: string) => void,
    reMatch: () => void;
    createNewPatient: () => void;
    patientMatchingOps: { [k: number]: string },
    setPatientMatchingOps: (v: { [k: number]: string }) => void,
    mergePatients: () => void;
}

const PatientReconciliation = ({
    patientId, patientMatchingList, isFetching, gptProvince,
    gp, setGp, gpFax, setGpFax, odNotes, setOdNotes,
    reMatch, createNewPatient, patientMatchingOps, setPatientMatchingOps, mergePatients
}: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const [patientSearchValue, setPatientSearchValue] = useState('');

    const patientEditDetails = useCare1AppSelector(store => store.patientEdit.details);
    const { data: gpByProvinceList = [], isFetching: isGpListByProvinceFetching } = useGetGpsByProvinceIdQuery(gptProvince === null ? -1 : gptProvince, { skip: !gptProvince });
    const { data: gpAllList = [], isFetching: isGpAllListFetching } = useGetAllGpsQuery();

    useEffect(() => {
        if (patientId !== null) {
            dispatch(getEditPatientData(patientId));
        }
    }, [dispatch, patientId]);

    const getGpList = () => {
        if (gptProvince === null) {
            return gpAllList;
        }
        else {
            return gpByProvinceList;
        }
    }

    const setGpAndFax = (v: number) => {
        setGp(v);

        const selectedGp = getGpList().find(gp => gp.id === v);
        const gpFax = selectedGp ? selectedGp.fax_number : '';
        setGpFax(gpFax);
    }

    const shouldDisableMergingButton = () => {

        let targetCount = 0;
        let deleteCount = 0;
        for (const [key, value] of Object.entries(patientMatchingOps)) {
            if (value === 'target') {
                targetCount++;
            }
            if (value === 'delete') {
                deleteCount++;
            }
        }

        if (targetCount === 1 && deleteCount >= 1) {
            return false;
        }
        return true;
    }

    return (
        <Spin
            className="loading-spinner"
            spinning={isFetching}
            size="large"
            tip={OPERATING_TEXT}
        ><div className="patient-reconciliation">
                <Row gutter={16}>
                    <Col span={6}>
                        <div className='title'>Patient Reconciliation</div>
                    </Col>
                    <Col span={18}>
                        <div className='button-container'>
                            <Button
                                disabled={shouldDisableMergingButton()}
                                className='merge-patients-button'
                                onClick={mergePatients}
                            >Merge Patients</Button>

                            <Button
                                className='create-new-patient-button'
                                onClick={createNewPatient}
                            >Create a new Patient</Button>

                            <Button
                                className='re-match-button'
                                onClick={reMatch}
                            >Re-match</Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='sub-title'>Existing Patient(s) Matched: {patientMatchingList.length}</div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <PatientMatchingListTable
                            tableList={patientMatchingList}
                            patientMatchingOps={patientMatchingOps}
                            setPatientMatchingOps={setPatientMatchingOps}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='sub-title'>Search Patient</div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='patient-search-bar'>
                            <PatientSearchBar
                                value={patientSearchValue}
                                setValue={setPatientSearchValue}
                            />
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="divider">
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <label className="ant-form-item-no-colon" title="OD Notes">OD Notes</label>
                            </Col>
                            <Col span={24}>
                                <Input.TextArea
                                    rows={6}
                                    className={'od-notes-input'}
                                    value={odNotes}
                                    onChange={(e) => setOdNotes(e.currentTarget.value)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <label className="ant-form-item-no-colon" title="GP">GP</label>
                            </Col>
                            <Col span={24}>
                                <Select
                                    className="patient-exam-gp-select"
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="label"
                                    placeholder={'Select Gp'}
                                    options={getGpList().map((gp, index) => { return { value: gp.id, label: `${gp.first_name} ${gp.last_name}` } })}
                                    value={gp}
                                    onChange={(v) => setGpAndFax(Number(v))}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <label className="ant-form-item-no-colon" title="GP Fax No">GP Fax No</label>
                            </Col>
                            <Col span={24}>
                                <InputMask
                                    className={'gp-fax-number-input'}
                                    mask={PHONE_MASK_FORMAT}
                                    placeholder={PHONE_MASK_FORMAT}
                                    value={gpFax !== null ? `${gpFax}` : ''}
                                    onChange={(e) => setGpFax(e.currentTarget.value)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>);
}

export default PatientReconciliation;