// Libraries
import { Fragment } from 'react';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { Layout, Spin, Row, Col } from 'antd';

// Components
import ExamDateSelectModal from '../components/retina/exam-date-select-modal';
import HelpDeskModal from '../components/retina/help-desk-modal';
import IGPStatusTable from '../components/admin/igp-status-table';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import OMDCStatusTable from '../components/admin/omdc-status-table';
import PatientAddModal from '../components/retina/patient-add-modal';
import PatientEditModal from '../components/retina/patient-edit-modal';
import PatientInsuranceModal from '../components/integrated/us/patient-insurance-modal';
import PatientListControlBar from '../components/retina/patient-list-control-bar';
import PatientListControlBarADMIN from '../components/admin/patient-list-control-bar-admin';
import PatientListControlBarOMDC from '../components/consultant/patient-list-control-bar-omdc';
import PatientListControlBarOMDR from '../components/reviewer/patient-list-control-bar-omdr';
import PatientListMenuAdmin from '../components/admin/patient-list-menu-admin';
import PatientListTable from '../components/retina/patient-list-table';
import PatientListTableADMIN from '../components/admin/patient-list-table-admin';
import ODReviewReminderEmailModal from '../components/admin/od-review-reminder-email-modal';
import PatientListTableOMDC from '../components/consultant/patient-list-table-omdc';
import PatientListTableOMDRIC from '../components/reviewer/patient-list-table-omdr-ic';
import PatientListTableOMDRRE from '../components/reviewer/patient-list-table-omdr-re';
import PatientsToReviewTable from '../components/reviewer/patients-to-review-table';
import SRPTable from '../components/admin/srp-table';
import IgpPatientListPage from './igp-patient-list-page';

// Helpers
import '../../static/css/pages/_patient-list-page.scss';
import { OPERATING_TEXT } from '../constants';
import PatientSelectModal from '../components/integrated/patient-select-modal';
import SelectNewExamDateModal from '../components/integrated/select-new-exam-date-modal';
import ReferralLetterPatientAddModal from '../components/integrated/referral-letter-pei-new-patient-modal';
import PatientsToReviewTableAdmin from '../components/admin/patients-to-review-table-admin';
import ExamsSelected from '../components/admin/exams-selected';

const { Header, Content } = Layout;

const PatientsListPage = () => {
    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);
    const patientEditOperating = useCare1AppSelector(store => store.patientEdit.operating);
    const patientListOperating = useCare1AppSelector(store => store.patientList.operating);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsIgp = useCare1AppSelector(store => store.user.isIGP);
    const igpPatientListIsLoading = useCare1AppSelector(store => store.igpPatientList.isLoading);
    const isReferralLetterNewPatientModalVisible = useCare1AppSelector(store => store.patientEdit.referralLetterNewPatientModalOpen);



    const className = userIsADMIN ? ' user-is-admin' : '';

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={ examOperating || patientDetailsOperating
                    || patientEditOperating || patientListOperating
                    || igpPatientListIsLoading }
                size="large"
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar className="patient-list-navigation-control-bar"/>
                </Header>
                <Content>
                    <div className={`patient-list-page${className}`}>

                        {/* ONE: Patient List Control Bar */}
                        {/* For RE and IC */}
                        {userIsOD &&
                            <PatientListControlBar />
                        }
                        {/* For OMDC */}
                        {userIsOMDC &&
                            <PatientListControlBarOMDC />
                        }
                        {/* For OMDCR and OMDRP */}
                        {userIsOMDR && !userIsRE &&
                            <PatientsToReviewTable />
                        }
                        { userIsOMDR &&
                            <PatientListControlBarOMDR />
                        }

                        {/* TWO: Patient List Table */}
                        {/* For RE and IC */}
                        {userIsOD &&
                            <PatientListTable />
                        }
                        {/* For OMDC */}
                        {userIsOMDC &&
                            <PatientListTableOMDC />
                        }
                        {/* For OMDR RE */}
                        {userIsOMDR && userIsRE &&
                            <PatientListTableOMDRRE />
                        }

                        {/* For OMDR IC */}
                        {userIsOMDR && !userIsRE &&
                            <PatientListTableOMDRIC />
                        }

                        {/* For ADMIN */}
                        {userIsADMIN &&
                            <Fragment>
                                <PatientListMenuAdmin />
                                <Row>
                                    <OMDCStatusTable />
                                    <IGPStatusTable />
                                </Row>
                                <SRPTable />
                                <Row>
                                    <Col span={9}>
                                        <PatientsToReviewTableAdmin />
                                        <ExamsSelected />
                                    </Col>
                                    <Col span={15}>
                                        <PatientListControlBarADMIN />
                                    </Col>
                                </Row>
                                <PatientListTableADMIN />
                                <ODReviewReminderEmailModal />
                            </Fragment>
                        }

                        {userIsIgp && <IgpPatientListPage />}

                        {/* THREE: Modals */}
                        <PatientAddModal />
                        <PatientEditModal />
                        <HelpDeskModal />
                        <ExamDateSelectModal />
                        <PatientInsuranceModal />
                        <PatientSelectModal />
                        <SelectNewExamDateModal />
                        {isReferralLetterNewPatientModalVisible && <ReferralLetterPatientAddModal />}
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default PatientsListPage;
