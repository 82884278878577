import React, { Fragment, useEffect } from 'react';
import { Card, Checkbox, Row, Form, Modal } from 'antd';
import PatientExamUntilYesterdayEntry from './patient-exam-until-yesterday-entry';
import { getUntilYesterdayValues, getUntilYesterdayValuesRlu, isUntilYesterdayValueEmpty } from '../../helpers/patient-exam-convert';
import DivTagInput from './div-tag-input';
import * as Constants from '../../constants';
import { useFormValidation } from '../../context/useFormValidationContext';

import '../../../static/css/components/patient-exam-until-yesterday.scss';
import { localizedText } from '../../localizedText';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IUntilYesterdayItem, addUntilYesterdayEntry, deleteUntilYesterdayEntry, setUntilYesterdayNoGlc, toggleUntilYesterdayEntries } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import PatientExamUntilYesterdayEntryReferralLetter from './patient-exam-until-yesterday-entry-referral-letter';

const FormItem = Form.Item;

type ComponentProps = {
    placeholder?: string,
    disabled: boolean,
}

const PatientExamUntilYesterday = ({ placeholder, disabled }: ComponentProps) => {
    const { PATIENT_EXAM_UNTIL_YESTERDAY, UNTIL_YESTERDAY_TITLE, NO_GLC_MEDS, ENTRY_REQUIRED } = localizedText;
    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    // OMD users should not see the checkbox,
    // If 'No Glc Meds' checkbox is selected then display an entry of "No Medications"
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOMD = userIsOMDC || userIsOMDR ;

    // useFormValidation hook
    const { setUntilYesterdayForm } = useFormValidation();

    const until_yesterday = useCare1AppSelector(store => store.examData.until_yesterday);
    const glcDropsList = useCare1AppSelector(store => store.options.glcDropsList);
    const glcComplianceList = useCare1AppSelector(store => store.options.glcComplianceList);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const inReferralLetterPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    const values = inReferralLetterPei ? getUntilYesterdayValuesRlu(until_yesterday.values, glcDropsList) 
        : getUntilYesterdayValues(until_yesterday.values, glcDropsList, glcComplianceList);
        
    const noGlc = until_yesterday.values[0] && until_yesterday.values[0].disabled;

    const closeUntilYesterdayEntries = () =>{
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const untilYesterdayValues = until_yesterday.values ? until_yesterday.values : [];

        let isRowCompleted = true;
        let selectedRow: null | number = null;

        if (inReferralLetterPei) {
            untilYesterdayValues.forEach((entry, index) => {
                if (entry.glc_past_drops_select === '' && entry.glc_past_drops_eye_select !== '') {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });
        }
        else {
            // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
            // a row are empty, it is considered a complete row
            untilYesterdayValues.forEach((entry, index) => {
                if (entry.glc_past_drops_select === '' &&
                    (entry.glc_past_drops_eye_select !== '' || entry.glc_past_drops_compliance_select !=='')) {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });

        }

        if (!isRowCompleted) {
            Modal.confirm({
                className: 'div-tag-row-incomplete-modal',
                title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                content: INCOMPLETE_ROW_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: INCOMPLETE_ROW_OK_TEXT,
                cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                cancelButtonProps: { className: 'continue-editing' },
                onOk: () => {
                    dispatch(deleteUntilYesterdayEntry(selectedRow!));
                    dispatch(toggleUntilYesterdayEntries());
                },
            });
        } else {
            dispatch(toggleUntilYesterdayEntries());
        }
    }

    useEffect(() => {
        if (values && values.length === 0) {
            dispatch(addUntilYesterdayEntry());
        }
        else {
            if (!noGlc) {
                const entry = until_yesterday.values[until_yesterday.values.length - 1];
                if (inReferralLetterPei) {
                    if (entry.glc_past_drops_select !== '') {
                        dispatch(addUntilYesterdayEntry());
                    }
                } else {
                    if (!isUntilYesterdayValueEmpty(entry)) {
                        dispatch(addUntilYesterdayEntry());
                    }
                }
            }
        }

    }, [until_yesterday, dispatch, noGlc, values, inReferralLetterPei])

    useEffect(() => {
        form.setFieldsValue({
            until_yesterday: noGlc
        });
        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setUntilYesterdayForm(form);
        // clean up function when setUntilYesterday component unmounts
        return () => {
            setUntilYesterdayForm(null);
        }
    }, [form, until_yesterday, noGlc, setUntilYesterdayForm])

    const onValuesChange = (field: {until_yesterday: boolean}) => {
        dispatch(setUntilYesterdayNoGlc(field['until_yesterday']));
    }

    // This check is required in order to display the checkbox validation error message.
    const checkCheckBox = (value: boolean | undefined) => {
        if (!value && isUntilYesterdayRequired(until_yesterday)) {
            return Promise.reject(new Error(ENTRY_REQUIRED));
        } else {
            return Promise.resolve();
        }
    }

    const isUntilYesterdayRequired = (untilYesterday: {show: boolean; values: IUntilYesterdayItem[]}) => {
        // At least one entry is required if same no GLC meds is not selected.
        // Having only 1 Entry and that entry is empty means there is not one complete entry selected..
        return untilYesterday.values.length === 1 &&
            (Object.values(untilYesterday.values[0]).some(str => str === ''));
    }

    const isNoEntries = values.length === 1;
    const entries = until_yesterday.values.map((entry, index) => {

        return  inReferralLetterPei? (
            <PatientExamUntilYesterdayEntryReferralLetter
                eyeTypes={eyeTypes}
                index={index}
                data={entry}
                glcDropsList={glcDropsList}
                size={"small"}
                key={index}
            />
        ) : (
            <PatientExamUntilYesterdayEntry
                eyeTypes={eyeTypes}
                index={index}
                data={entry}
                glcDropsList={glcDropsList}
                glcComplianceList={glcComplianceList}
                size={"small"}
                key={index}
            />
        );
    });

    // At least one entry is required if same no GLC meds is not selected.
    // 1 Entry means there is not one complete entry selected.
    const required = isUntilYesterdayRequired(until_yesterday);

    // Do not display the subtitle if there is a validation error. This provides space for the error message.
    const label =
        <span data-testid='heading' className={'heading'}>
            {UNTIL_YESTERDAY_TITLE}
            {!required && <span className='sub-title'>Pt Was Taking</span>}
        </span>

    return (
        <Card bordered={false} className={'patient-exam-until-yesterday'}>
            {/* UNTIL-YEST 001 */}
            <Form form={form} onValuesChange={onValuesChange} >
                { !userIsOMD &&
                <Fragment>
                    <Row className={'heading-row'}>
                        {/* UNTIL-YEST 005*/}
                        <FormItem
                            className={'until-yesterday-checkbox'}
                            label={label}
                            colon={false}
                            name='until_yesterday'
                            valuePropName='checked'
                            rules={[{
                                required: required,
                                validator: (_,value) => checkCheckBox(value),
                            }]}
                        >
                            <Checkbox
                                disabled={disabled}
                                data-testid='no-glc-meds'
                            >
                                {NO_GLC_MEDS}
                            </Checkbox>
                        </FormItem>
                    </Row>
                    <Row className={disabled ? 'div-tag-disabled' : ''}>
                        {/* UNTIL-YEST 002, UNTIL-YEST 003, UNTIL-YEST 004 */}
                        <DivTagInput
                            disabled={noGlc || disabled}
                            placeholder={placeholder}
                            onClick={() => {
                                dispatch(toggleUntilYesterdayEntries());
                            } }
                            values={values}
                            drawer={!noGlc && until_yesterday.show}
                            onClose={() => {
                                closeUntilYesterdayEntries();
                            }}
                            entries={entries}
                            emptyValue={Constants.UNTIL_YESTERDAY_EMPTY_VALUE}
                            required={required}
                        />
                    </Row>
                </Fragment>
                }
                { userIsOMD &&
                <Fragment>
                    <Row className={'heading-row'}>
                        <FormItem
                            label={(<span className='heading'>{PATIENT_EXAM_UNTIL_YESTERDAY}</span>)}
                        ></FormItem>
                    </Row>
                    <Row>
                        { !required && !noGlc &&
                            <DivTagInput
                                disabled={noGlc || disabled}
                                values={values}
                                emptyValue={Constants.UNTIL_YESTERDAY_EMPTY_VALUE}
                            />
                        }
                        { ( required || noGlc || isNoEntries ) &&
                            <DivTagInput
                                disabled={true}
                                values={['']}
                            />
                        }
                    </Row>
                </Fragment>
                }
            </Form>
        </Card>
    );
}

export default PatientExamUntilYesterday;