import { Fragment, useEffect } from 'react';
import { Card, Checkbox, Row, Form, Modal } from 'antd';
import PatientExamOngoingEntry from './patient-exam-ongoing-entry';
import { getOngoingValues, isOngoingValueEmpty, isRluOngoingValueEmpty } from '../../helpers/patient-exam-convert';
import DivTagInput from './div-tag-input';
import * as Constants from '../../constants';
import { useFormValidation } from '../../context/useFormValidationContext';

import '../../../static/css/components/patient-exam-ongoing.scss';
import { localizedText } from '../../localizedText';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IOngoingItem, addOngoingEntry, deleteOngoingEntry, setOngoingSameMeds, toggleOngoingEntries } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

const FormItem = Form.Item

type ComponentProps = {
    placeholder?: string,
    disabled: boolean,
}

const PatientExamOngoing = ({ placeholder, disabled }: ComponentProps) => {
    const { ASKED_PATIENT_TEXT, PATIENT_EXAM_DROPS_ONGOING, SAME_MEDS, EXAM_ONGOING_TITLE, ENTRY_REQUIRED } = localizedText;
    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();
    const { setOngoingForm } = useFormValidation();

    const ongoing = useCare1AppSelector(store => store.examData.ongoing);
    const untilYesterday = useCare1AppSelector(store => store.examData.until_yesterday);
    const glcDropsList = useCare1AppSelector(store => store.options.glcDropsList);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    const glcDropOptions = isReferralLetterUploadPEI ? glcDropsList.concat([['letter_is_not_clear','Letter is not clear']]) : glcDropsList;


    // OMD users should not see the checkbox,
    // If same meds checkbox is true then display an entry of "Same Medications"
    // If Until Yesterday has no medications then display an entry of "No Medications"
    // If same meds checkbox is not true and there are entries, display the entries
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOMD = userIsOMDC || userIsOMDR ;

    const sameMeds = ongoing.values[0] && ongoing.values[0].disabled;
    const selectedVals = sameMeds ? untilYesterday.values : ongoing.values;
    const values = getOngoingValues(selectedVals, glcDropOptions, sameMeds);
    const isUntilYesterdayEmpty = untilYesterday.values[0] && untilYesterday.values[0].disabled;

    const closeOngoingEntries = () => {
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const ongoingValues = ongoing.values;

        let isRowCompleted = true;
        let selectedRow: null | number = null;
        // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
        // a row are empty, it is considered a complete row
        if (isReferralLetterUploadPEI) {
            ongoingValues.forEach((entry, index) => {
                if (entry.glc_current_drops_eye_select && !entry.glc_current_drops_select) {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });
        } else {
            ongoingValues.forEach((entry, index) => {
                const valuesArray = Object.values(entry);
                if (valuesArray.some((value) => value === '') &&
                    !valuesArray.every((value) => value === '')) {
                    isRowCompleted = false;
                    selectedRow = index;
                }
            });
        }
        

        if (!isRowCompleted) {
            Modal.confirm({
                className: 'div-tag-row-incomplete-modal',
                title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                content: INCOMPLETE_ROW_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: INCOMPLETE_ROW_OK_TEXT,
                cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                cancelButtonProps: { className: 'continue-editing' },
                onOk: () => {
                    dispatch(deleteOngoingEntry(selectedRow!));
                    dispatch(toggleOngoingEntries());
                },
            });
        } else {
            dispatch(toggleOngoingEntries());
        }
    }

    useEffect(() => {
        if (values && values.length === 0) {
            dispatch(addOngoingEntry());
        }
        else {
            if (!sameMeds) {
                const entry = ongoing.values[ongoing.values.length - 1];
                if (isReferralLetterUploadPEI) {
                    if (!isRluOngoingValueEmpty(entry)) {
                        dispatch(addOngoingEntry());
                    }
                } else {
                    if (!isOngoingValueEmpty(entry)) {
                        dispatch(addOngoingEntry());
                    }
                }
            }
        }

    }, [values, dispatch, ongoing.values, untilYesterday.values, sameMeds, isReferralLetterUploadPEI]);

    useEffect(() => {
        form.setFieldsValue({
            ongoing: sameMeds
        });

        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setOngoingForm(form);
        // clean up function when patient-exam-ongoing component unmounts
        return () => {
            setOngoingForm(null);
        }
    }, [form, setOngoingForm, sameMeds, ongoing.values, untilYesterday.values])

    const onValuesChange = (field: {ongoing: boolean}) => {
        dispatch(setOngoingSameMeds(field['ongoing']));
    }

    // This check is required in order to display the checkbox validation error message.
    const checkCheckBox = (value: boolean | undefined) => {
        if (!value && isOngoingRequired(ongoing)) {
            return Promise.reject(new Error(ENTRY_REQUIRED));
        } else {
            return Promise.resolve();
        }
    }

    const isOngoingRequired = (ongoing: {show: boolean, values: IOngoingItem[]}) => {
        // At least one entry is required if same meds is not selected.
        // Having only 1 Entry and that entry is empty means there is not one complete entry selected..
        return ongoing.values.length === 1 &&
            (Object.values(ongoing.values[0]).some(str => str === ''));
    }

    const entries = ongoing.values.map((entry, index) => {
        return (
            <PatientExamOngoingEntry
                eyeTypes={eyeTypes}
                key={index}
                index={index}
                data={entry}
                glcDropsList={glcDropOptions}
                size={"small"}
            />
        );
    });

    const required = isOngoingRequired(ongoing);

    // Do not display the subtitle if there is a validation error. This provides space for the error message.
    const label =
        <span data-testid='heading' className={'heading'}>
            {EXAM_ONGOING_TITLE}
            {!required && <span className='sub-title'>{ASKED_PATIENT_TEXT}</span>}
        </span>

    return (
        <Card bordered={false} className={'patient-exam-ongoing'}>
            {/* ONGOING 001, ONGOING 002 */}
            <Form form={form} onValuesChange={onValuesChange} >
                { !userIsOMD &&
                <Fragment>
                    <Row className={'heading-row'}>
                        {/* ONGOING 006 */}
                        <FormItem
                            className={'ongoing-checkbox'}
                            label={label}
                            colon={false}
                            name='ongoing'
                            valuePropName='checked'
                            rules={[{
                                required: required,
                                validator: (_,value) => checkCheckBox(value),
                            }]}
                        >
                            <Checkbox
                                disabled={disabled}
                            >
                                {SAME_MEDS}
                            </Checkbox>
                        </FormItem>
                    </Row>
                    <Row className={disabled ? 'div-tag-disabled' : ''}>
                        {/* ONGOING 003, ONGOING 004, ONGOING 005 */}
                        <DivTagInput
                            disabled={sameMeds || disabled}
                            placeholder={placeholder}
                            onClick={() => {
                                dispatch(toggleOngoingEntries());
                            }}
                            values={values}
                            drawer={!sameMeds && ongoing.show && ongoing.values.length > 0}
                            onClose={() => {
                                // Update ant form fields
                                if (!required) {
                                    // This function is not defined as it is not passed in to the component
                                    // onChange({ ongoing: { errors: undefined } });
                                }

                                closeOngoingEntries();
                            }}
                            entries={entries}
                            emptyValue={Constants.ONGOING_EMPTY_VALUE}
                            required={required}
                        />
                    </Row>
                </Fragment>
                }
                { userIsOMD &&
                <Fragment>
                    <Row className={'heading-row'}>
                        <FormItem
                            label={(<span className='heading'>{PATIENT_EXAM_DROPS_ONGOING}</span>)}
                        ></FormItem>
                    </Row>
                    <Row>
                        { !sameMeds && !required &&
                           <DivTagInput
                                disabled={sameMeds || disabled}
                                values={values}
                                emptyValue={Constants.ONGOING_EMPTY_VALUE}
                            />
                        }
                        { (required || (sameMeds && isUntilYesterdayEmpty)) &&
                            <DivTagInput
                                disabled={true}
                                values={['']}
                            />
                        }
                        { sameMeds && !isUntilYesterdayEmpty &&
                            <DivTagInput
                                disabled={true}
                                values={['Same medications']}
                            />
                        }
                    </Row>
                </Fragment>
                }
            </Form>
        </Card>
    );
}

export default PatientExamOngoing;
