import { Row, Col, Tabs, Card } from 'antd';
import GptImageIdentifierDNDImg from './gpt-image-identifier-dnd-img';


const { TabPane } = Tabs;

type ComponentProps = {
    rightFundus: string,
    leftFundus: string,
    rightStereoFundus: string,
    leftStereoFundus: string,
    rightVfPhoto: string,
    leftVfPhoto: string,
    rightOctRnflPhoto: string,
    leftOctRnflPhoto: string,
    rightOCTMacula: string,
    leftOCTMacula: string,
    setRightFundus: (img: string) => void,
    setLeftFundus: (img: string) => void,
    setRightStereoFundus: (img: string) => void,
    setLeftStereoFundus: (img: string) => void,
    setRightVfPhoto: (img: string) => void,
    setLeftVfPhoto: (img: string) => void,
    setRightOctRnflPhoto: (img: string) => void,
    setLeftOctRnflPhoto: (img: string) => void,
    setRightOCTMacula: (img: string) => void,
    setLeftOCTMacula: (img: string) => void,
    moveBase64Images: (fromField: string, toField: string) => void,
    deleteBase64Image: (img: string) => void,
}


const GptImageIdentifierDNDImages = ({ rightFundus, leftFundus, rightStereoFundus, leftStereoFundus,
    rightVfPhoto, leftVfPhoto, rightOctRnflPhoto, leftOctRnflPhoto,
    rightOCTMacula, leftOCTMacula,
    setRightFundus, setLeftFundus, setRightStereoFundus, setLeftStereoFundus,
    setRightVfPhoto, setLeftVfPhoto, setRightOctRnflPhoto, setLeftOctRnflPhoto,
    setRightOCTMacula, setLeftOCTMacula,
    moveBase64Images, deleteBase64Image }: ComponentProps) => {

    return (
        <div className="gpt-image-identifier-images">
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <Card className='patient-exam-fundus' bordered={false}>
                                <Tabs type="card">
                                    <TabPane tab='Fundus Photo' forceRender key="1">
                                        <GptImageIdentifierDNDImg
                                            field='right-fundus'
                                            className='dropzone-image'
                                            base64Image={rightFundus}
                                            setBase64Image={setRightFundus}
                                            moveBase64Images={moveBase64Images}
                                            deleteBase64Image={deleteBase64Image}
                                        />
                                    </TabPane>
                                    <TabPane tab='Second Fundus Photo' forceRender key="2">
                                        <GptImageIdentifierDNDImg
                                            field='right-stereo'
                                            className='dropzone-image'
                                            base64Image={rightStereoFundus}
                                            setBase64Image={setRightStereoFundus}
                                            moveBase64Images={moveBase64Images}
                                            deleteBase64Image={deleteBase64Image}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-macula' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT Macula' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='right-oct-macula'
                                                    className='dropzone-image'
                                                    base64Image={rightOCTMacula}
                                                    setBase64Image={setRightOCTMacula}
                                                    moveBase64Images={moveBase64Images}
                                                    deleteBase64Image={deleteBase64Image}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-rnfl' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT RNFL' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='right-oct-rnfl'
                                                    className='dropzone-image'
                                                    base64Image={rightOctRnflPhoto}
                                                    setBase64Image={setRightOctRnflPhoto}
                                                    moveBase64Images={moveBase64Images}
                                                    deleteBase64Image={deleteBase64Image}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-vf' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='VF' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='right-vf'
                                                    className='dropzone-image'
                                                    base64Image={rightVfPhoto}
                                                    setBase64Image={setRightVfPhoto}
                                                    moveBase64Images={moveBase64Images}
                                                    deleteBase64Image={deleteBase64Image}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-vf' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='VF' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='left-vf'
                                                    className='dropzone-image'
                                                    base64Image={leftVfPhoto}
                                                    setBase64Image={setLeftVfPhoto}
                                                    moveBase64Images={moveBase64Images}
                                                    deleteBase64Image={deleteBase64Image}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-macula' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT Macula' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='left-oct-macula'
                                                    className='dropzone-image'
                                                    base64Image={leftOCTMacula}
                                                    setBase64Image={setLeftOCTMacula}
                                                    moveBase64Images={moveBase64Images}
                                                    deleteBase64Image={deleteBase64Image}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-rnfl' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT RNFL' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='left-oct-rnfl'
                                                    className='dropzone-image'
                                                    base64Image={leftOctRnflPhoto}
                                                    setBase64Image={setLeftOctRnflPhoto}
                                                    moveBase64Images={moveBase64Images}
                                                    deleteBase64Image={deleteBase64Image}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Card className='patient-exam-fundus' bordered={false}>
                                <Tabs type="card">
                                    <TabPane tab='Fundus Photo' forceRender key="1">
                                        <GptImageIdentifierDNDImg
                                            field='left-fundus'
                                            className='dropzone-image'
                                            base64Image={leftFundus}
                                            setBase64Image={setLeftFundus}
                                            moveBase64Images={moveBase64Images}
                                            deleteBase64Image={deleteBase64Image}
                                        />
                                    </TabPane>
                                    <TabPane tab='Second Fundus Photo' forceRender key="2">
                                        <GptImageIdentifierDNDImg
                                            field='left-stereo'
                                            className='dropzone-image'
                                            base64Image={leftStereoFundus}
                                            setBase64Image={setLeftStereoFundus}
                                            moveBase64Images={moveBase64Images}
                                            deleteBase64Image={deleteBase64Image}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);
}

export default GptImageIdentifierDNDImages;
