import { useEffect, useState } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-reports-table.scss';
import { DeleteOutlined,DownloadOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { IAdminReport, IReportsFilter, useDeleteAdminReportMutation, useGetAdminReportsQuery } from '../../services/admin-reports-api';
import {convertLocalToPST} from '../../helpers/utilities';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';

export const ReportsNameLookup: Record<string, string> = {
    'by_visit': 'Exam Visit Date',
    'by_create': 'Exam Create Date',
    'by_review': 'Last OMDR Review Date',
    'omd_reviews': 'OMD Reviews',
    'integration_list_report': 'Integration List Uploads',
    'billing_report': 'Billing Report (Based on Exam Visit Date)',
    'pre_reviews_report': 'Pre-Reviews Report',
    'pre_reviews_time_report': 'Pre-Reviews Time Report',
    'patient_handout_report': 'Patient Handout Report',
    'billing_report_submission_date': 'Billing Report (Based on Submission Date)',
}

const AdminReportsTable = ({filter}: {filter: IReportsFilter}) => {
   
    const {data, isFetching } = useGetAdminReportsQuery(filter, {pollingInterval: 30000, refetchOnFocus: true});
    const [deleteAdminReport] = useDeleteAdminReportMutation();

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const downloadReport = (filePath : string) => {
        const url = `${getBackendMediaUrl()}${filePath}`;
        window.open(url, '_reports')?.focus();
    }

    const deleteReport = (report: IAdminReport) => {
        Modal.confirm({
            className: 'info-modal',
            title: `Are you sure to delete report ${report.report_name} from ${report.start_date} to ${report.end_date}?`,
            onOk: () => deleteAdminReport(report.key),
        })
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const columns : ColumnsType<IAdminReport> = [{
        className: 'report-name-column',
        title: 'Report Name',
        dataIndex: 'report_name',
        sorter: (a, b) => compareStrings(a['report_name'], b['report_name']),
        render: (value, _) => <span>{ReportsNameLookup[value]}</span>
    }, {
        className: 'start-date-column',
        title: 'Start Date',
        dataIndex: 'start_date',
        sorter: (a, b) => compareStrings(a['start_date'], b['start_date']),
    }, {
        className: 'end-date-column',
        title: 'End Date',
        dataIndex: 'end_date',
        sorter: (a, b) => compareStrings(a['end_date'], b['end_date']),
    }, {
        className: 'created-date-column',
        title: 'Report Created Date',
        dataIndex: 'created_date',
        sorter: (a, b) => compareStrings(a['created_date'], b['created_date']),
        render: (value, _) => <span>{convertLocalToPST(value)}</span>
    }, {
        className: 'user-column',
        title: 'User',
        dataIndex: 'user',
        sorter: (a, b) => compareStrings(a['user'], b['user']),
    }, {
        className: 'status-column',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => compareStrings(a['status'], b['status']),
    }, {
        className: 'action-column',
        title: 'Actions',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button
                    className="download-report-button"
                    onClick={(e) => downloadReport(record.file_path)}
                    disabled={record.status !== 'succeeded'}
                >
                    <DownloadOutlined />
                </Button>
                <Button
                    className='delete-report-button'
                    onClick={(e) => deleteReport(record)}
                    disabled={record.status !== 'succeeded' && record.status !== 'failed' &&
                        record.status !== 'processing'}
                >
                    <DeleteOutlined />
                </Button>
            </Space>
        ),
    },];

    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    dataSource={data}
                    loading={isFetching}
                />
            </div>
        </div>
    );
}

export default AdminReportsTable;