import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import Care1LightBox from '../../../components/retina/care1-light-box';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './gpt-image-identifier-itemtypes';
import TrashButton from '../../../../static/images/trash_button.svg';


const getWindowDimensions = () => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
        windowWidth,
        windowHeight
    };
}

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

interface EncodedFile {
    name: string;
    size: number;
    encoded: string;
}

interface ErrorFile {
    name: string;
    size: number;
    error: string;
}

interface DropResult {
    name: string
}

type ComponentProps = {
    isModal?: boolean,
    field: string,
    base64Image: string,
    className: string,
    setBase64Image: (img: string) => void,
    moveBase64Images: (fromField: string, toField: string) => void,
    deleteBase64Image: (img: string) => void,
}

const GptImageIdentifierDNDImg = ({ isModal = false, field, base64Image, className,
    setBase64Image, moveBase64Images, deleteBase64Image }: ComponentProps) => {

    const [lightboxOn, setLightboxOn] = useState(false);

    const sendToggleLightbox = () => {
        setLightboxOn(!lightboxOn);
    }

    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const { windowWidth, windowHeight } = useWindowDimensions();

    const [error, setError] = useState<ErrorFile | null>(null);


    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: ItemTypes.IMAGE,
            item: { field },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult() as DropResult;
                if (item && dropResult) {
                    moveBase64Images(field, dropResult.name);
                }
            },
            canDrag: () => {
                return base64Image !== null && base64Image !== undefined && base64Image !== '';
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [base64Image, moveBase64Images],
    )

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: ItemTypes.IMAGE,
            canDrop: () => {
                return true
            },
            drop: () => {
                return {
                    name: field,
                }
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }),
        [],
    )

    const deleteImage = () => {
        deleteBase64Image(field);
    }

    // determine whether the height is bigger or width is bigger, only scale images with small width and height
    const SCALE_RATIO = 1.5
    const dropzoneWrapperClassName = useMemo(() => {
        return imageHeight && imageWidth
            ? (imageHeight > imageWidth) && (windowHeight / imageHeight >= SCALE_RATIO)
                ? 'height-constraint-image'
                : (imageHeight < imageWidth) && (windowWidth / imageWidth >= SCALE_RATIO)
                    ? 'width-constraint-image'
                    : ''
            : '';
    }, [imageHeight, imageWidth, windowHeight, windowWidth])

    const download = (source: string) => {

        const regexType = /data:image\/(.*);base64/;
        const match = source.match(regexType);

        const a = document.createElement('a');
        a.href = source
        a.download = `${field}.${match ? match[1] : '.png'}`

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const loadFile = (file: File): Promise<EncodedFile> => new Promise((res, rej) => {
        var reader = new FileReader();
        let base = {
            name: file.name,
            size: file.size,
        }
        reader.addEventListener("abort", e => rej(`File upload aborted:${e}`));
        reader.addEventListener("error", e => rej(`File upload error: ${e}`));
        reader.addEventListener("load", () => res({
            ...base,
            encoded: reader.result as string
        }), false);
        reader.readAsDataURL(file);
    })

    const rejectFile = (fr: FileRejection): ErrorFile => {
        const f = fr.file;
        return {
            name: f.name,
            size: f.size,
            error: 'File rejected'
        }
    }

    const onDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
        if (rejectedFiles.length > 0) {
            setError(rejectFile(rejectedFiles[0])); // set/reset error
        }
        setBase64Image('');
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            try {
                const f = await loadFile(file);
                setBase64Image(f.encoded);
            }
            catch (e) {
                setError({
                    name: file.name,
                    size: file.size,
                    error: `${e}`
                })
            }
        }
    }, [setBase64Image, setError, loadFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/gif': ['.gif'],
        },
        maxSize: 100000000,
        multiple: false,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop
    });


    return (<>
        <div
            className="drag-area"
            ref={drag}
            onClick={sendToggleLightbox}
        >
            {base64Image &&
                <>
                    <div
                        className="dragging"
                        style={{
                            opacity: isDragging ? 0.5 : 1,
                        }}
                    >
                        <img
                            className={`${className}`}
                            src={base64Image}
                            alt=''
                        />
                    </div>
                </>
            }
            {!isModal && <div {...getRootProps({
                className: 'dnd-area',
                ref: drop
            })}>
                <input {...getInputProps()} />
                {
                    (base64Image === null || base64Image === undefined || base64Image === '') && <div className={'dz-message'}>
                        <div className="dropzone-empty-image"></div>
                        <div className="dropzone-empty-text">{'Drag & Drop to upload.'}</div>
                    </div>
                }
            </div>}
        </div>
        {(base64Image && !isModal) && <div className='dropzone-details'>
            <img
                className='dropzone-delete'
                onClick={deleteImage}
                src={TrashButton}
                alt="Delete"
            />
        </div>}
        {base64Image && <Care1LightBox
            field={'photoUrl'}
            isOpen={lightboxOn}
            mainSrc={base64Image}
            mainSrcThumbnail={base64Image}
            onCloseRequest={sendToggleLightbox}
            wrapperClassName={dropzoneWrapperClassName}
            /* @ts-ignore:next-line */
            onImageLoad={(src, imageType, image) => {
                setImageHeight(image.naturalHeight);
                setImageWidth(image.naturalWidth);
            }}
            toolbarButtons={[<CloudDownloadOutlined className="care1-lightbox-download-icon" onClick={() => download(base64Image)} />]}
        />}
    </>);
}

export default GptImageIdentifierDNDImg;
