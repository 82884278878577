import { Modal, Button,  Row, Typography, Col, Card, Flex } from 'antd';

// Action imports
import { MailOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { toggleEmailConfirmationModal, togglePreviewModal } from '../../../reducers/review-processing-slice';
import { useBulkSetExamReviewProcessedMutation, useSendReviewEmailMutation } from '../../../services/review-processing-api';
import EmailTags from './email-tags';

const EmailConfirmModal = () => {

    const emailConfirmationModalOpen = useCare1AppSelector(store => store.reviewProcessing.emailConfirmationModalOpen);
    const selectedRowKeys = useCare1AppSelector(store => store.reviewProcessing.selectedRowKeys);
    const selectedOdName = useCare1AppSelector(store => store.reviewProcessing.selectedOdName);
    const patientReviewInfoText = useCare1AppSelector(store => store.reviewProcessing.patientReviewInfoText);
    const taskItems = useCare1AppSelector(store => store.reviewProcessing.taskItems);
    const emailSubject = useCare1AppSelector(store => store.reviewProcessing.emailSubject);
    const odEmails = useCare1AppSelector(store => store.reviewProcessing.odEmails);
    const odId = useCare1AppSelector(store => store.reviewProcessing.odId);
    const templateText = useCare1AppSelector(store => store.reviewProcessing.templateText);


    const [sendReviewEmail] = useSendReviewEmailMutation();
    const [bulkSetExamReviewProcessed] = useBulkSetExamReviewProcessedMutation();


    const dispatch = useCare1AppDispatch();

    const handleSendReviewEmail = async () => {
        try {
            const res = await sendReviewEmail({
                subject: emailSubject,
                patient_review_info: patientReviewInfoText,
                recipients: odEmails,
                od_id: odId!,
                template_text: templateText,
                task_items: taskItems,
            }).unwrap();

            if (res.success) {
                dispatch(toggleEmailConfirmationModal());
                dispatch(togglePreviewModal());
                Modal.info({
                    className: 'info-modal',
                    title: 'Email sent successfully.',
                    zIndex: 2001,
                });

                const response = await bulkSetExamReviewProcessed({exam_ids: selectedRowKeys}).unwrap();
                if (response.success) {
                    Modal.info({
                        className: 'info-modal',
                        title: 'Bulk set exam review processed successfully.',
                        zIndex: 2000,
                    });
                } else {
                    throw new Error(res?.error);
                }
            } else {
                throw new Error(res?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                zIndex: 2001,
                title: 'Error',
            });
        }
    }

    const closeButton = (
        <Button
            key='close-button'
            onClick={() => dispatch(toggleEmailConfirmationModal())}
        >
            No
        </Button>
    );
    const sendEmailButton = (
        <Button
            key='save-button'
            icon={<MailOutlined />}
            type='primary'
            style={{height: '2rem'}}
            onClick={handleSendReviewEmail}
        >
            Yes, Send Email
        </Button>
    );
    
    const [labelCol, contentCol] = [5, 19];
      
    return (
        <Modal
            open={emailConfirmationModalOpen}
            closable={false}
            footer={[closeButton, sendEmailButton]}
            keyboard={true}
            width={720}
            title='Are you sure you want to send the Reviews Email'
            zIndex={999}
            styles={{
                body:{
                    height: '240px',
                    padding: '0',
                }
            }}
        >
            <Card bordered={false} style={{padding: '12px 0', height: '100%'}}>
                <Row gutter={[12, 24]}>
                    <Col span={labelCol}>
                        <Flex justify='right'><Typography.Text>No. of patients:</Typography.Text></Flex>
                    </Col>
                    <Col span={contentCol}>{selectedRowKeys.length}</Col>

                    <Col span={labelCol}>
                        <Flex justify='right'><Typography.Text>OD Name:</Typography.Text></Flex>
                    </Col>
                    <Col span={contentCol}>Dr. {selectedOdName}</Col>

                    <Col span={labelCol}>
                        <Flex justify='right'><Typography.Text>Recipients:</Typography.Text></Flex>
                    </Col>
                    <Col span={contentCol}><EmailTags /></Col>
                </Row>
            </Card>
        </Modal>
    );
}

export default EmailConfirmModal;
