import LocalizedStrings from 'react-localization';
import globalColors from '../static/css/shared/global-colors.scss';

export const localizedText = new LocalizedStrings({
    // When unspecified, the language defaults to the first entry, which is English.
    en:{
        // Help desk modal
        HELP_DESK_REQUEST: 'Help Desk Request',
        YOUR_NAME_TEXT: 'Your Name',
        YOUR_EMAIL_TEXT: 'Your Email (to which we will reply)',
        YOUR_NAME_PLACEHOLDER: 'Name of person making request',
        CONTACT_EMAIL_TEXT: 'Contact you at this email',
        HELP_DESK_DETAILS: 'Help Request Details',
        PROVINCE_TEXT: 'Province',
        CLINIC_TEXT: 'Clinic',
        DOCTOR_TEXT: 'Doctor',
        DATE_TEXT: 'Date',

        // Generate Letter Modal
        GENERATE_TEXT: 'Generate',
        CONSULTATION_LETTER_TEXT: 'Consultation Letter',
        DOCTORS_NAME_TEXT: 'Doctor\'s Name',
        TO_OTHER_TEXT: 'To Other',
        TO_GP_TEXT: 'To GP',
        TO_MD2_TEXT: 'To MD#2',

        // Admin Reports Modal
        ADMIN_REPORTS_MODAL_TEXT: 'Admin Reports',
        LAST_OMDR_REVIEW_DATE: 'Last OMDR Review Date',
        OMD_REVIEWS_TEXT: 'OMD Reviews',
        INTEGRATION_LIST_UPLOADS: 'Integration List Uploads',
        BILLING_REPORT_EXAM_TEXT: 'Billing Report (Based on Submission Date)',
        GET_REPORT_TEXT: 'Get Report',

        // Navigation bar
        HELP_DESK_TEXT: 'Help Desk',
        PATIENT_LIST_TEXT: 'Patient List',
        PLATFORM_RETINA_ENABLED_TITLE: 'Retina Enabled',
        PLATFORM_INTEGRATED_TITLE: 'Integrated Clinic',
        PLATFORM_OMDC_TITLE: 'OMD Consultant',
        PLATFORM_OMDR_RE_TITLE: 'OMD Retina Enabled',
        PLATFORM_OMDR_IC_TITLE: 'OMD Integrated Clinic',
        PLATFORM_ADMIN_TITLE: 'Administrator',
        LOG_OUT_TEXT: 'Log Out',

        // Patient exam GP referred
        SUCCESFULLY_UPDATED_TEXT: 'is successfully uploaded',

        // Patient Exam Chief Complaint
        CHIEF_COMPLAINT_GLC_LABEL: 'Glc',
        CHIEF_COMPLAINT_OPTIC_NERVE_LABEL: 'Optic Nerve',
        CHIEF_COMPLAINT_GLCS_LABEL: 'GlcS',
        CHIEF_COMPLAINT_DM_LABEL: 'DM',
        CHIEF_COMPLAINT_AMD_LABEL: 'AMD',
        CHIEF_COMPLAINT_ERM_LABEL: 'ERM',
        CHIEF_COMPLAINT_CAT_REF_LABEL: 'Cat Ref',
        CHIEF_COMPLAINT_POST_OP_LABEL: 'Cat Post-Op',
        CHIEF_COMPLAINT_NAR_ANG_LABEL: 'Nar Ang',
        CHIEF_COMPLAINT_TEXT: 'Chief Complaint',

        // Exam Status text
        EXAM_STATUS_TITLE_TEXT: 'CHART STATUS IS:',
        OCT_HISTORY_TEXT: 'OCT Macula',
        OCT_RNFL_TEXT: 'OCT RNFL',
        VF_TEXT: 'VF',
        FUNDUS_HISTORY_TEXT: 'Fundus Photo',
        CONVERSATION_HISTORY_TEXT: 'OMD & OD Conversation',
        OD_NOTES_TEXT: 'Show OD Notes',
        EXTRA_IMAGES_TEXT: 'Extra Images',
        IOP_GRAPH_TEXT: 'IOP (vs. age)',
        BCVA_GRAPH_TEXT: 'BCVA (vs. age)',
        NKDA_TEXT: 'NKDA',
        SULFA_TEXT: 'Sulfa',
        NONE_TEXT: 'None',
        NO_GLC_MEDS: 'No Glc Meds',
        SAME_MEDS: 'The Same Meds',
        SAVE_BUTTON_TEXT: 'Save',
        SAVE_AND_CLOSE_BUTTON_TEXT: 'Save & Close',
        SAVE_BUTTON_FIELD_REQUIRED: 'The recommended fields are not required, but completing the recommended fields improves the ability of Care1 doctors to provide care.',
        SAVE_BUTTON_ANYWAY: 'Save Anyway',
        SAVE_BUTTON_EDITING: 'Continue Editing',
        SAVE_BUTTON_EMPTY_FIELD_WARNING: 'Warning: Incomplete recommended fields (yellow) are found on the chart',
        GP_CONSULTATION_LETTER_TEXT: 'GP LETTER',
        CHIEF_COMPLAINT_PLACEHOLDER_TEXT: 'Search or enter other complaints',
        EXAM_HISTORY_PLACEHOLDER: 'Something short, even only 1-3 sentences long, is perfectly fine.',
        CD_VALUES_BAND_TOOLTIP_TEXT: 'You can select a different CD value for the OD and/or OS Fundus photo',
        YES_TEXT: 'Yes',
        YES_TEXT_CAPITALIZED: 'YES',
        NO_TEXT: 'No',
        NO_TEXT_CAPITALIZED: 'NO',
        GP_REFERRED_TEXT: 'This visit was GP referred',
        UPLOAD_PROGRESS_ABORTED: 'Upload Progress is Aborted.',
        PHN_TEXT: 'PHN',

        // Patient Visits Text
        PATIENT_VISIT_NUMBER_TEXT: 'Patient Visit',
        OF_TEXT: 'of',
        ITEMS_TEXT: 'items',
        DOB_TEXT: 'DOB:',
        TELEPHONE_TEXT: 'TEL:',
        GP_TEXT: 'GP:',
        GP2_TEXT: 'MD#2:',
        OMD_TEXT: 'OMD:',
        OD_TEXT: 'OD:',
        EXAM_DATE_TEXT: 'Exam Date:',
        HIGH_PRIORITY_TEXT: 'High Priority',
        CHART_STATUS_TEXT: 'Chart Status:',
        OD_SELECT_PLACEHOLDER_TEXT: 'Select OD',
        NO_HISTORY_TEXT: 'No history',
        GENERATE_LETTERS: 'Generate Letters',
        WEEKS_TEXT: 'Week(s)',
        MONTHS_TEXT: 'Month(s)',
        MONTHS: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

        // Patient Visit Exit Confirmation Modal
        EXIT_CONFIRMATION_TITLE: 'Are you sure you want to leave this visit?',
        EXIT_CONTENT_TEXT: 'You have unsaved data.',
        CONFIRMATION_OK_TEXT: 'Yes',
        CONFIRMATION_CANCEL_TEXT: 'No',

        // IC OD Patient Exam
        RIGHT_SIDE_TEXT: 'right',
        LEFT_SIDE_TEXT: 'left',
        DRAG_DROP_TEXT: 'Drag & Drop to upload.',
        EXTRA_DRAG_DROP_TEXT: 'Drag & Drop\r\nto upload.',
        LEFT_FUNDUS_PHOTO_TITLE_TEXT: 'Left Fundus Photo',
        RIGHT_FUNDUS_PHOTO_TITLE_TEXT: 'Right Fundus Photo',
        LEFT_STEREOPAIR_TITLE_TEXT: 'Left Second Fundus Photo',
        RIGHT_STEREOPAIR_TITLE_TEXT: 'Right Second Fundus Photo',
        LEFT_STEREOPAIR_TITLE_TEXT_RE_OD: 'Left Stereopair Photo',
        RIGHT_STEREOPAIR_TITLE_TEXT_RE_OD: 'Right Stereopair Photo',
        OCT_MACULA_PHOTO_DROP_TEXT: 'Drag & Drop to upload.\r\nUpload a new OCT Macula at every visit.\r\nIf you have multiple OCT slices, please upload\r\n the single file that looks the most pathological.',
        PHOTO_DROP_TEXT_SHORT: 'Drag & Drop to upload.',
        SUPPORTED_FORMAT_TEXT: 'Supported Formats: jpg, png, tiff, pdf',
        LEFT_OCT_MACULA_PHOTO_TITLE_TEXT: 'Left OCT Macula',
        LEFT_OCT_RNFL_PHOTO_TITLE_TEXT: 'Left OCT RNFL',
        LEFT_VF_PHOTO_TITLE_TEXT: 'Left VF',
        RIGHT_OCT_MACULA_PHOTO_TITLE_TEXT: 'Right OCT Macula',
        RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT: 'Right OCT RNFL',
        RIGHT_VF_PHOTO_TITLE_TEXT: 'Right VF',
        FILE_UPLOAD_FAILED_TEXT: 'Files must\r\n be smaller\r\nthan 1MB.',
        EXAM_PHOTO_FUNDUS: 'Fundus Photo',
        EXAM_PHOTO_STEREOPAIR: 'Second Fundus Photo',
        EXAM_PHOTO_STEREOPAIR_RE_OD: 'Stereopair',
        OD_LOCATION_TEXT: 'OD Location',
        CLOSEST_OMD_TEXT: 'Closest OMD',
        OMDC_STATUS_TEXT: 'OMDC Status',
        HISTORY_SYMPTOMS_TITLE: 'History',
        EXAM_NOTES_TITLE: 'Exam',
        EXAM_ONGOING_TITLE: 'Ongoing',
        ENTRY_REQUIRED: 'Entry required!',
        EXAM_NOTE_DEFAULT_TEXT: 'clear corneas OU, open angles OU, clear lens OU, and normal retinas.',
        EXAM_OMD_HISTORY_TITLE: 'OMD History',
        ALLERGIES_PLACEHOLDER: 'Medication allergies',
        ALLERGIES_TITLE: 'Allergies',
        OCT_MACULA_TEXT: 'OCT Macula',
        RIGHT_OCT_MACULA_TEXT: 'Right OCT Macula',
        LEFT_OCT_MACULA_TEXT: 'Left OCT Macula',
        IOP_TEXT: 'IOP',
        VERT_CD_TEXT: 'Vert CD',
        ISHIHARA_TEXT: 'Ishihara',
        INSTRUMENT_TEXT: 'Instrument',
        PACHS_TEXT: 'Pachs',
        RX_TEXT: 'Rx',
        BCVA_TEXT: 'BCVA',
        AUTOFRACTION_SUFFICIENT_TEXT: 'autorefraction sufficient',
        EXAM_IMPRESSION_TITLE: 'Impression',
        OD_NOTES_PLAN_TITLE: 'OD Notes + Proposed Plan',
        GOVT_BILL_TEXT: 'We could not bill the govt for',
        GOVT_BILL_EXPLANATION_TEXT: 'There is additional study funding available if you were not able to bill the government for certain diagnostic testing. Please indicate here if you were not able to bill the government for this patient.',
        FU_LETTER_TEXT: 'Pt is returning for follow-up in',
        OTHER_OMD_TEXT: 'Pt currently also sees an OMD',
        OMD_APT_TEXT: 'Care1 to refer pt to in-person OMD',
        UPLOAD_TEXT: 'Upload',
        UPLOADED_TEXT: 'Uploaded',
        VIEW_ALL_TEXT: 'VIEW ALL',

        // DeepMD
        DEEPMD_SUBHEADING_TEXT: 'Glaucoma Band',
        DEEPMD_INACCURACY_TOOLTIP: 'Please help me learn to be more accurate by submitting an Outlier Report if you feel my recommendation is not accurate.',
        DEEPMD_HELP_TRAIN: 'Help Train Me: Report AI Inaccuracy',
        DEEPMD_SUSPICIOUS_FEATURES_GLC: 'Suspicious Features of GLC',
        DEEPMD_REASSURING_FEATURES_GLC: 'Reassuring Features of GLC',
        DEEPMD_ANALYZED_DATA: 'I\'ve analyzed the uploaded patient data',
        DEEPMD_ALERTS: 'Alerts',
        DEEPMD_COMFORT_ZONES: 'DeepMD Comfort Zones',
        DEEPMD_CURRENT_COMFORT: 'Current Comfort Zones',
        DEEPMD_COUNSELLING_RECOMMENDATIONS: 'Counseling Recommendations',

        // IC OMDR Patient Exam
        REVIEWER_SELECTORS_TEXT: "Reviewer Selectors",
        OMDR_REVIEWER_NOTES_HEADING: 'Private Notes to OD',
        OMD_REVIEW_NOTES_PLACEHOLDER: 'Enter full sentences using your keyboard or dictation software. Spell carefully! Your notes will be copied directly into your consult letter.',
        OMDR_NOTES_TO_OD_AND_GP_HEADING: 'Notes to OD and GP',
        PATIENT_EXAM_NOTES_PROVIDED: 'These notes will be provided to the patient’s GP in addition to being visible to the OD and OMD. These notes are NOT carried over each visit.',
        EXAM_NOTES_CLICK: 'Click the buttons to insert pre-generated sentences into your consult, which you can modify or add to if needed',
        PATIENT_EXAM_NOTES_TOOLTIP: 'These notes are visible to both OD and OMD, and are carried over each visit',
        EXAM_NOTES_HISTORY_TITLE: 'Running Notes about Patient',
        IOP_HISTORY_TITLE: 'IOP History',
        PATIENT_EXAM_HISTORY_MEDS_EXAMS_TITLE: 'History, Exam and Medications',
        PATIENT_EXAM_UNTIL_YESTERDAY: 'Drops (until yesterday)',
        PATIENT_EXAM_DROPS_ONGOING: 'Drops (ongoing)',
        PATIENT_EXAM_ZOOM_CLICK: 'Single click to zoom, right click to zoom 5x.',
        STEREO_VIEWER_TEXT: 'Stereo Viewer',
        VERTICAL_CD_VALUES: 'Vertical C/D Values',
        IOP_FOLLOWUP_TEXT: 'IOP Final Recommendations and Follow Up',
        SAVE_VIEW_TEXT: 'Save View',
        RESET_TEXT: 'Reset',
        PRESAVED_VIEW_TEXT: 'Presaved View',
        DEFAULT_VIEW_TEXT: 'Default View',
        SWITCH_OS_PHOTOS: 'Switch OS Photos',
        SWITCH_OD_PHOTOS: 'Switch OD Photos',
        BASELINE_TESTING_IMAGE_LABEL: '[Baseline testing images are displayed in the first row]',
        SHOW_ADMIN_VIEW: 'Show Admin View',
        SECOND_OPINION_OPTION: 'Enter full sentences using your keyboard or dictation software. Spell carefully! Your notes will be copied directly into your consult letter.',
        IOP_AIM_TOOLTIP_TEXT: "When iop aim is 'XXX', press any number or BACKSPACE to remove all 'X' in the iop aim input box",
        SECOND_OPINIONS_TEXT: 'Second Opinions',
        SELECT_REVIEWER_TEXT: 'Select Reviewer',
        OMD_SUBMIT_TOOLTIP: 'Press Ctrl + Shift + X to submit the exam, and open a new patient in the background. Press Ctrl + Tab to advance to the next patient.',
        LAST_LETTER_TEXT: 'Last letter to GP',
        GP_LETTER_TEXT: 'GP Letter',
        GP_LETTER_TOOLTIP: 'GP letters are sent out by the software automatically, approximately once per year, but based on a variety of protocols such as how often the GP wants to hear from Care1.',
        OMD_FOLLOWUP_OD_WILL_SEE: 'OD will see in',
        OMD_FOLLOWUP_APPT: 'In-Person OMD Appt',
        FOLLOWUP_TEXT: 'Follow Up',
        VERBALIZE_TEXT: 'Verbalize (Beta)',
        INITIATE_TREATMENT_TEXT: 'Initiate Treatment',
        NOTES_MOA_TEXT: 'Notes to MOA',
        NOTES_MOA_TOOLTIP: 'These notes are for internal use only. They may contain additional information from the MOA, or can be used to send questions or instructions to the MOA.',
        START_TEXT: 'Start',
        END_TEXT: 'End',
        PG_TEST: 'PG',
        TREATMENT_DROP_OPTIONS: [
            {
                value: 'prostaglandin',
                label: 'PG',
                is_default: true,
            },
            {
                value: 'betablocker',
                label: 'BB',
            },
            {
                value: 'pg_bb',
                label: 'PG-BB',
            },
        ],
        IOP_ZONE_TX: 'IOP Zone on Tx',
        TREATMENT_IOP_OPTIONS: [
            {
                value: '14',
                label: '14',
            },
            {
                value: '17',
                label: '17',
            },
            {
                value: '21',
                label: '21',
            },
            {
                value: '24',
                label: '24',
            },
            {
                value: 'custom',
                label: 'Custom',
            },
        ],
        SIDE_EFFECT_DROP_OPTIONS: [
            {
                value: 'different_pg',
                label: 'Different PG',
                required_drops: ['prostaglandin', 'betablocker'],
                is_default: true,
            },
            {
                value: 'pg_bb',
                label: 'PG-BB',
                required_drops: ['prostaglandin', 'betablocker'],
            },
            {
                value: 'different_pg_bb',
                label: 'Different PG-BB',
                required_drops: ['pg_bb'],
                is_default: true,
            },
            {
                value: 'pg_slt',
                label: 'PG + SLT',
                required_drops: ['pg_bb'],
            },
            {
                value: 'bb_cai',
                label: 'BB-CAI',
                required_drops: ['pg_bb'],
            },
        ],
        CANCEL_TEXT: 'Cancel',
        FOLLOW_UP_TEXT: 'Follow Up',
        TREATMENT_INITIATION_TEXT: 'Treatment Initiation',
        IF_SIDE_EFFECTS: 'If side effects',
        IF_EFFECTIVE_TEXT: 'If effective',
        SWITCH_TO_TEXT: 'Switch to',
        DISCUSS_ARTIFICIAL_TEARS: 'Discuss Artificial Tears',
        IF_NOT_ENOUGH_EFFECT: 'If not enough effect',
        DISCUSS_REFRIDGERATION: 'Discuss refrigeration ',
        FELLOW_EYE_START: 'Fellow Eye: Start',
        FOLLOW_UP_EVERY: 'Follow up Every:',
        DARK_BLUE_DTC_RANGE: 'Dark Blue = DTC Range',

        // Admin patient exam
        FAX_TEXT: 'Fax',
        IN_PERSON_CONSULT: 'In-Person Consultation',
        EMAIL_NOT_VALID: 'Email is not valid',
        EMAIL_REQUIRED: 'Email is required',
        FAX_NUMBER_NOT_COMPLETE: 'Fax number is not complete',
        FAX_REQUIRED: 'Fax is required',
        FAX_EMAIL_MESSAGE: 'Fax/Email message',
        IA_TEXT: 'I/A',
        G_TEXT: 'G',
        Y_TEXT: 'Y',
        R_TEXT: 'R',
        NA_TEXT: 'N/A',
        AUTOMATIC_CODE_TEXT: 'Automatic Code',
        INVALID_TEXT: 'Invalid',
        TELE_FU_TEXT: 'TELE F/U',
        GP_LETTER_TEXT_ADMIN: 'GP LETTER',
        ISSUES_TEXT: 'ISSUES',
        OD_REPLY_TEXT: 'OD REPLY',
        OD_HAS_QUEST_ISSUE: 'OD HAS QUEST/ISSUE',
        OD_RQST_OMD_APPT: 'OD RQST OMD APPT',
        OD_DROPS: 'DROPS',
        CURT_UPCOMING_OMD: 'CURT/UPCOMING OMD',
        GP_REF_DATES: 'GP Referral Dates',
        SELECTED_GP_REF_DATE: 'Selected GP Referral Date',
        MANUAL_GP_REF_DATE: 'Manual GP Referral Date',
        OCT_VF_TEXT: 'OCT/VF',
        OMDC_ONTARIO_BILLING_CODE: 'OMDC Ontario Billing Code',
        OMDR_ONTARIO_BILLING_CODE: 'OMDR Ontario Billing Code',
        IGP_STATUS_LABEL: 'IGP St:',
        IGP_LABEL: 'IGP:',
        SELECT_IGP_TEXT: 'Select IGP',
        SELECT_IGP_STATUS: 'Select IGP Status',
        WNL_TEXT: 'WNL',
        GRS_TEXT: 'GRS',
        BGRS_TEXT: 'B-GRS',
        B_TEXT: 'B',
        ONL_TEXT: 'ONL',
        AHS_TEXT: 'AHS',
        MANUAL_CODE_OVERRIDE: 'Manual Code Override:',
        OCT_RNFL_AVERAGE: 'OCT RNFL Average',
        VF_GHT_TEXT: 'VF GHT',
        ADMIN_SELECTORS_TEXT: 'Admin Selectors',
        GP_INFO_TEXT: 'GP INFO',
        NO_SURG_TEXT: 'NO SURG',
        OCT_TEXT: 'OCT',
        CATTM_TEXT: 'CatTM',
        PAST_OMD_TEXT: 'PAST OMD',
        PREREVIEW_INITIALS: 'Pre-Review & Initials',
        PREREVIEWED_TEXT: 'Pre-Reviewed',
        PREREVIEW_TEXT: 'Pre-Review',
        SECOND_REVIEW: '2nd Review',
        UNTIL_YESTERDAY_TITLE: 'Until Yesterday',
        OD_BASELINE_TEXT: 'OD Baseline',
        HXDHX_OD_TEXT: 'HxDHx OD',
        HXDHX_OS_TEXT: 'HxDHx OS',
        PATIENT_HAS_MULTIPLE_EXAMS: 'Patient has multiple Ready for OMD exams',
        PATIENT_HAS_NO_MULTIPLE_EXAMS: 'Patient does not have multiple Ready for OMD exams',

        // Patient exam comorbitities
        FHX_GLC_TEXT: 'FHX Glc',
        HEART_BLOCK_TEXT: 'Heart Block',
        STROKE_TEXT: 'Stroke',
        UVEITIS_OD_TEXT: 'Uveitis OD',
        UVEITIS_OS_TEXT: 'Uveitis OS',
        PXF_OD_TEXT: 'PXF OD',
        PXF_OS_TEXT: 'PXF OS',
        PDS_OD_TEXT: 'PDS OD',
        PDS_OS_TEXT: 'PDS OS',
        ASTHMA_TEXT: 'Asthma',
        COMORBIDITIES_TITLE: 'Comorbidities',

        // OMDR Patient review table
        REVIEW_TABLE_CATEGORY: 'Category',
        PATIENTS_TO_REVIEW: 'Patients to review',
        TOTAL_PATIENTS: 'Total Patients',
        REVIEW_TABLE_PATIENTS: 'Patients',
        PRE_REVIEW_ALERTS: 'Pre-review Alerts',
        OMD_REVIEWER_NOTES: 'OMD Reviewer Notes',
        OMDC_NOTES_HEADING: 'Consult',
        FOLLOW_UP_OPTIONS_HEADING: 'OMDC Follow-Up Options',

        // OMDC Patient List
        OMDC_STATUS_TYPES : [
            { key: 'all_statuses', value: 'All Statuses' },
            { key: 'ready', value: 'Ready for Consultant' },
            { key: 'reviewed', value: 'Consultant Reviewed' },
            { key: 'processed', value: 'Consultation Processed' },
        ],

        // OMDR Patient List
        EXAM_ROOMS_FUNCTIONALITY: 'To support the 5 exam rooms functionality in your Chrome browser:',
        INSTALL_EXTENSION: 'Install extension.',
        FOLLOW_STEPS: 'Follow these steps',
        NEW_TABS: 'New Tabs at End 3000',
        FORCE_BACKGROUND: 'Force Background Tab',
        OPEN_ALL_PATIENTS: 'Open All My Pts',
        DISABLE_POPUP_BLOCKER: 'Disable the popup blocker',
        UNAUTHORIZED_MODAL_TITLE: 'The Requested resource is not available',
        PROVINCE_HEADING: 'PRV',
        OD_HEADING: 'OD',
        GLC_CLASS_HEADING: 'GLC Class',
        IOP_HEADING: 'IOP',
        BIOP_HEADING: 'Recent IOP Hx',
        BDISC_HEADING: 'Recent Disc Hx',
        BFIELD_HEADING: 'Recent VF Hx',
        LATEST_VISIT_HEADING: 'Latest Visit',
        COMMENTS_HEADING: 'Comments',
        NUMBER_OF_VISITS_HEADING: '# Visits',
        EXAM_HEADING: 'Exam',
        UNAUTHORIZED_MODAL_CONTENT: 'Please contact hello@care1.ca if you continue to have issues.',
        OMDR_IC_STATUS_TYPES: [
            { key: 'none', value: 'No Filter Selected', label: 'No Filter Selected'},
            { key: 'ready', value: 'Ready for OMD', label: 'Ready for OMD' },
            { key: 'special_attention', value: 'Special Attention', label: 'Special Attention' },
            { key: 'retina1', value: 'Retina (DM, AMD, ERM)', label: 'Retina (DM, AMD, ERM)' },
            { key: 'retina2', value: 'Other', label: 'Other'},
            { key: 'glc_p', value: 'Glc F/U - Parameters Set (P)',label: 'Glc F/U - Parameters Set (P)' },
            { key: 'glc_bo', value: 'Glc F/U - Borderline Probably Observe (BO)', label: 'Glc F/U - Borderline Probably Observe (BO)'},
            { key: 'glc_bt', value: 'Glc F/U - Borderline Probably Treat (BT)', label: 'Glc F/U - Borderline Probably Treat (BT)' },
            { key: 'gdgvf', value: 'Glc New - Good Disc Good VF', label: 'Glc New - Good Disc Good VF' },
            { key: 'bdgvf', value: 'Glc New - Bad Disc Good VF', label: 'Glc New - Bad Disc Good VF' },
            { key: 'gdbvf', value: 'Glc New - Good Disc Bad VF', label: 'Glc New - Good Disc Bad VF' },
            { key: 'bdbvf', value: 'Glc New - Bad Disc Bad VF', label: 'Glc New - Bad Disc Bad VF' },
        ],
        LOAD_FIVE_PATIENTS: 'Load 5 Patients',
        OMDR_EXAM_REVIEWED_PERIOD_OPTIONS : [
            { key: 'none', value: 'No Period Filter Selected', label: 'No Period Filter Selected'},
            { key: '1_hour', value: 'Reviewed Last 1 hr', label: 'Reviewed Last 1 hr' },
            { key: '6_hours', value: 'Reviewed Last 6 hrs', label: 'Reviewed Last 6 hrs' },
            { key: '12_hours', value: 'Reviewed Last 12 hrs', label: 'Reviewed Last 12 hrs' },
            { key: '24_hours', value: 'Reviewed Last 24 hrs', label: 'Reviewed Last 24 hrs' },
            { key: '24_48_hours', value: 'Reviewed Last 24~48 hrs', label: 'Reviewed Last 24~48 hrs' },
            { key: '48_72_hours', value: 'Reviewed Last 48~72 hrs', label: 'Reviewed Last 48~72 hrs' },
            { key: '72_hours_1_week', value: 'Reviewed Last 72hrs~1wk', label: 'Reviewed Last 72hrs~1wk' },
            { key: '30_days', value: 'Reviewed Last 30 days', label: 'Reviewed Last 30 days' },
        ],
        OMD_REQUIRED_SET_IPC: 'OMD is required to set IPC OMDC.',
        OMD_DATE_REQUIRED: 'Date is required to set IPC OMDC.',
        EXTERNAL_GP_STATUS_CHOICES: [
            { key: 'ready', value: 'Ready for EGP', label: 'Ready for EGP' },
            { key: 'reviewed', value: 'Referred by EGP', label: 'Referred by EGP' },
            { key: 'letter_sent', value: 'EGP Letter Sent', label: 'EGP Letter Sent' },
        ],
        INTERNAL_GP_STATUS_CHOICES: [
            { key: 'ready', value: 'Ready for IGP', label: 'Ready for IGP' },
            { key: 'reviewed', value: 'Reviewed by IGP', label: 'Reviewed by IGP' },
            { key: 'letter_sent', value: 'IGP Letter Sent', label: 'IGP Letter Sent' },
        ],
        IPC_STATUS_CHOICES: [
            { key: 'requested', value: 'IPC requested', label: 'IPC requested' },
            { key: 'ipc_only', value: 'IPC Only', label: 'IPC Only' },
            { key: 'addressed', value: 'IPC addressed', label: 'IPC addressed' },
            { key: 'omd_requested', value: 'Requested by OMD', label: 'Requested by OMD' },
        ],
        SPECIAL_ATTENTION: 'Special Attention',
        RETINA_DM_AMD_ERM: 'Retina (DM, AMD, ERM)',
        OTHER_TEXT: 'Other',
        GLC_PARAMETERS_SET: 'Glc F/U - Parameters Set (P)',
        GLC_BORDERLINE_OBSERVE: 'Glc F/U - Borderline Probably Observe (BO)',
        GLC_BORDERLINE_TREAT: 'Glc F/U - Borderline Probably Treat (BT)',
        GLC_GOOD_DISC_GOOD_VF: 'Glc New - Good Disc Good VF',
        GLC_BAD_DISC_GOOD_VF: 'Glc New - Bad Disc Good VF',
        GLC_GOOD_DISC_BAD_VF: 'Glc New - Good Disc Bad VF',
        GLC_BAD_DISC_BAD_VF: 'Glc New - Bad Disc Bad VF',
        TOTAL_STANDARDIZED_POINTS: 'Total Standardized Review Points',
        TOTAL_NUMBER_REVIEWS: 'Total Number of Reviews',
        TOTAL_CHARTS_PRE_REVIEWED: 'Total Charts to be Pre-Reviewed',
        OPTION_ALLOWS_GLCSUS: 'This option allows you to add a GlcSus (Suspicious for Glaucoma) diagnosis to the patient.',

        // Admin patient List
        ADMIN_ALERT_LISTS_TEXT: 'ALERT LISTS',
        ADMIN_DOCTERS_TEXT: 'DOCTORS',
        ADMIN_REPORTS_TEXT: 'REPORTS',
        ADMIN_TEXT: 'ADMIN',
        ADMIN_REPORTS_DASHBOARD_TEXT: 'REPORTS DASHBOARD',
        ADMIN_OMDC_READY_OMDC: 'OMDC',
        ADMIN_READY_TOTAL: 'Ready Total',
        ADMIN_OMDC_READY_CAT: 'Ready Cat',
        ADMIN_OMDC_READY_RETINA: 'Ready Early Retina',
        ADMIN_OMDC_READY_GLC: 'Ready Glc Sus',
        ADMIN_REVIEWED_TOTAL: 'Reviewed Total',
        ADMIN_OMDC_REVIEWED_CAT: 'Reviewed Cat',
        ADMIN_OMDC_REVIEWED_RETINA: 'Reviewed Early Retina',
        ADMIN_OMDC_REVIEWED_GLC: 'Reviewed Glc Sus',
        ADMIN_OMDC_PROCESSED: 'Processed Past 6 Mo',
        ADMIN_STANDARDIZED_REVIEW_POINTS: 'Standardized Review Points',
        ADMIN_OMDR: 'OMDR',
        ADMIN_SPECIAL_ATTENTION: 'Special Attention',
        ADMIN_RETINA: 'Retina',
        ADMIN_OTHER: 'Other',
        ADMIN_GLCP: 'Glc-P',
        ADMIN_GLCBO: 'Glc-BO',
        ADMIN_GLCBT: 'Glc-BT',
        ADMIN_GOOD_DISC_GOOD_VF: 'Good Disc Good VF',
        ADMIN_BAD_DISC_GOOD_VF: 'Bad Disc Good VF',
        ADMIN_GOOD_DISC_BAD_VF: 'Good Disc Bad VF',
        ADMIN_BAD_DISC_BAD_VF: 'Bad Disc Bad VF',
        ADMIN_TOTAL_SRP: 'Total SRP',
        ADMIN_REFERRED_PAST: 'Referred Past 12 Mo',
        ADMIN_IGP_TEXT: 'IGP',
        ADMIN_IGP_STATUS: 'IGP Status',
        ADMIN_REFRESH_TABLES: 'Refresh Tables',
        ADMIN_RESET_SORT: 'Reset Column Sort Order Prioritization',
        ADMIN_COLUMN_SORT_TOOLTIP: 'Columns are sorted according to the order in which column headers are selected. Click this button to reset the column headers and the sorting priority',
        ADMIN_TABLE_TEST: 'Test',
        ADMIN_TABLE_ALERTS: 'Alerts',
        ADMIN_TABLE_STATUS: 'Status',
        ADMIN_TABLE_SPECIAL_NOTES: 'Special Notes',
        ADMIN_TABLE_PR_ALERTS: 'PR Alerts',
        ADMIN_TABLE_EGP_STATUS: 'EGP Status',
        ADMIN_TABLE_IGP: 'IGP',
        ADMIN_TABLE_IGP_STATUS: 'IGP Status',
        ADMIN_TABLE_VISITS: 'Visits',
        ADMIN_TABLE_DOB: 'DOB',
        ADMIN_TABLE_OMDC: 'OMDC',
        ADMIN_TABLE_OMDC_STATUS: 'OMDC Status',
        ADMIN_TABLE_OD_GROUP: 'OD Group',
        ADMIN_TABLE_OD: 'OD',
        ADMIN_TABLE_PRV: 'PRV',
        ADMIN_TABLE_OMDR: 'OMDR',
        ADMIN_TABLE_LAST_EXAM: 'Last Exam',
        ADMIN_TABLE_REF_TYPE: 'Ref Type',
        ADMIN_TABLE_FIRST_NAME: 'First Name',
        ADMIN_TABLE_LAST_NAME: 'Last Name',
        ADMIN_TABLE_PR: 'PR',
        OPEN_SELECTED_PATIENTS: 'Open Selected Patients',
        USERNAME_HEADING: 'Username',

        // Doctors list
        DOCTORS_LIST_SEARCH_PLACEHOLDER: 'Search by first name, last name or username ',
        DOCTORS_LIST_ADD_NEW_OMD: 'Add New Ophthalmologists',
        DOCTORS_LIST_ADD_NEW_OD: 'Add New Optometrists',
        DOCTORS_LIST_ADD_NEW_GP: 'Add New Internal GP',
        DOCTORS_LIST_TEXT: 'Doctors List',
        DOCTORS_LIST_PRACTICES_GROUP: 'Practices Group',
        DOCTORS_LIST_ACTION: 'Action',
        DOCTORS_LIST_OMD_NETWORK: 'OMD Network',
        GROUP_PRACTICE_LIST_SEARCH_PLACEHOLDER: 'Search by group practice name',


        // Patient edit form
        FNAME_PLACEHOLDER_TEXT: 'Enter first name',
        LNAME_PLACEHOLDER_TEXT: 'Enter last name',
        PHONE_PLACEHOLDER_TEXT: 'Enter phone number',
        EMAIL_PLACEHOLDER_TEXT: 'Enter email address',
        DOB_PLACEHOLDER_TEXT: 'Select date of birth',
        PROVINCE_PLACEHOLDER_TEXT: 'Select province',
        STREET_PLACEHOLDER_TEXT: 'Enter street address',
        FIRST_NAME_LABEL_TEXT: 'First Name',
        EMAIL_LABEL_TEXT: 'Email',
        GENDER_LABEL_TEXT: 'Gender',
        GENDER_FEMALE_TEXT: 'Female',
        GENDER_MALE_TEXT: 'Male',
        GP2_FAX_NUMBER_LABEL_TEXT: 'GP2 Fax No',
        ARMED_PERSONNEL_LABEL_TEXT: 'Service Number',
        CITY_LABEL_TEXT: 'City',
        STREET_LABEL_TEXT: 'Street',
        GP2_LABEL_TEXT: 'MD#2',
        GP_LABEL_TEXT: 'MD',
        PHONE_LABEL_TEXT: 'Phone',
        PROVINCE_LABEL_TEXT: 'Province',
        LAST_NAME_LABEL_TEXT: 'Last Name',
        POSTAL_CODE_LABEL_TEXT: 'Postal Code',
        DOB_LABEL_TEXT: 'Date of Birth',
        GP_FAX_NUMBER_LABEL_TEXT: 'GP Fax No.',
        PLEASE_CONFIRM_LABEL_TEXT: 'Please Confirm',
        REQUIRED_FIELDS_LABEL_TEXT: 'Required Fields',
        CITY_PLACEHOLDER_TEXT: 'Select city',
        POSTAL_CODE_PLACEHOLDER_TEXT: 'XXX XXX',
        GP_PLACEHOLDER_TEXT: 'Search or enter GP',
        GP_FAX_PLACEHOLDER_TEXT: 'Enter GP fax no.',
        GP2_PLACEHOLDER_TEXT: 'Search or enter GP',
        GP2_FAX_PLACEHOLDER_TEXT: 'Enter GP fax no.',
        FORCES_PLACEHOLDER_TEXT: 'Enter service number',
        // eslint-disable-next-line
        GP_FAX_INFO_TEXT: 'Entering GP\'s fax number allows Care1 to send updates to patient\'s GP, \
            promoting your office as an access point for high-level medical care.',
        MD2_FAX_INFO_TEXT: 'Entering MD#2\'s fax number allows Care1 to automatically send updates to the patient\'s MD#2, promoting your office as an access point for high-level medical care.',
        PLEASE_CONFIRM_LIST_TEXT: [
            { key: 1, value: 'Patient requests their GP be informed of their case.' },
            { key: 2, value: 'Patient requests collaboration with an ophthalmologist.' },
            { key: 3, value: 'Patient consents to digital communication to discuss their chart, including the use of email.' },
            {
                key: 4,
                value: 'Patient consents to the use of their chart for research, medical presentations, program\n'
                    + 'improvement and medical advancement, both by Care1 or individuals/programs/organizations external to Care1.\n'
                    + 'Data used will be stripped of all identifying data.',
            },
        ],
        PLEASE_ENSURE_HEADING_TEXT: 'Please ensure that patient healthcare numbers are input accurately! ',
        ADD_PATIENT_CONFIRMATION_TEXT: 'New patient successfully added.',
        EDIT_PATIENT_CONFIRMATION_TEXT: 'New edits successfully added.',
        DEACTIVATE_PATIENT_CONFIRMATION_TEXT: 'Patient successfully deactivated.',
        ERROR_INVALID_PHONE_FORMAT: 'The phone number format is invalid',
        ERROR_INVALID_EMAIL_FORMAT: 'The email format is invalid',
        ERROR_INVALID_HN_FORMAT: 'The health number is invalid',
        ERROR_INVALID_POSTAL_CODE_FORMAT: 'The postal code is invalid',
        ERROR_PHN_ALREADY_EXISTS: 'The health number already exists',
        NEW_GP_TEXT: 'New GP',
        NEW_GP2_TEXT: 'New GP#2',
        MONTH_TEXT: 'Month',
        WEEK_TEXT: 'Week',
        DAY_TEXT: 'Day',
        YEAR_TEXT: 'Year',

        // Deactivate Confirmation Modal
        DEACTIVATE_CONFIRMATION_MODAL_TITLE: 'Are you sure you want to deactivate this exam?',
        DEACTIVATE_CONFIRMATION_MODAL_TEXT: 'You cannot revert this action afterwards.',
        DEACTIVATE_CONFIRMATION_OK_TEXT: 'Yes',
        DEACTIVATE_CONFIRMATION_CANCEL_TEXT: 'No',
        DEACTIVATE_PATIENT_CONFIRMATION_MODAL_TITLE: 'Are you sure you want to deactivate this patient?',

        // Patient list control bar
        PATIENT_GROUPING_OPTIONS: [
            { key: 'doctor', value: 'My Pts' },
            { key: 'clinic', value: 'Clinic Pts' },
        ],
        PATIENT_EXAM_URL: '/patient-exam',
        OPEN_SELECTED_VISITS: 'Open Selected Visits',
        PATIENT_LIST_TITLE_TEXT : 'Patient List',
        PATIENT_LIST_SEARCH_PLACEHOLDER : 'Search by name or health number…',
        EXAM_VISIT_DATE: 'Exam Visit Date',
        EXAM_CREATED_DATE: 'Exam created date',
        OD_PROGRAM_TEXT: 'OD Program',
        UNFLAGGED_AND_READY_OMD: 'Unflagged + Ready for OMD',
        FLAGGED_AND_READY_OMD: 'Flagged + Ready for OMD',
        MISCELLANEOUS_TEXT: 'Miscellaneous',
        SEARCH_FOR_OMD: 'Search for OMD',
        SEARCH_FOR_OD: 'Search for OD',
        SEARCH_FOR_GP_MD2: 'Search for GP/MD#2',
        USING_START_END_DATES: 'Using Start/End Dates',
        SELECT_BULK_ACTION: 'Select Bulk Action',
        SET_OMDR: 'Set OMDR',
        SET_OMDC: 'Set OMDC',
        SET_IGP: 'Set IGP',
        GET_FILTERED_LIST: 'Get Filtered List',
        CLEAR_FILTERS: 'Clear Filters',
        SEARCH_FOR_IGP: 'Search for IGP',
        NEW_PATIENT_TEXT : 'New Patient',
        STATUS_FILTER_BUTTON_TEXT : 'Status',
        LATEST_VISIT_TEXT : 'Latest Visit',
        EXAM_PERIOD_OPTIONS: [
            { key: 'all_time', value: 'All time', label: 'All time' },
            { key: '1_week', value: 'Last 1 week', label: 'Last 1 week'},
            { key: '1_month', value: 'Last 1 month',label: 'Last 1 month'},
            { key: '3_months', value: 'Last 3 months', label: 'Last 3 months' },
            { key: '6_months', value: 'Last 6 months', label: 'Last 6 months' },
            { key: '12_months', value: 'Last 12 months', label: 'Last 12 months'},
            { key: '12_18_months', value: 'Last 12~18 months', label: 'Last 12~18 months' },
            { key: '18_24_months', value: 'Last 18~24 months', label: 'Last 18~24 months' },
        ],
        EXAM_STATUS_TYPES: [
            { key: 'all_statuses', value: 'All Statuses', label: 'All Statuses' },
            { key: 'not_ready_od_review', value: 'Not Ready/OD to Review', label: 'Not Ready/OD to Review', color: globalColors['redTagColor'] },
            { key: 'not_ready', value: 'Not Ready', label: 'Not Ready', color: globalColors['redTagColor'] },
            { key: 'od_review', value: 'OD to Review', label: 'OD to Review', color: globalColors['yellowTagColor'] },
            { key: 'ready', value: 'Ready for OMD', label: 'Ready for OMD', color: globalColors['blueTagColor'] },
            { key: 'reviewed', value: 'OMD Reviewed', label: 'OMD Reviewed', color: globalColors['greenTagColor'] },
        ],
        EXAM_STATUS_REFERRAL_LETTER_TYPES: [
            { key: 'all_statuses', value: 'All Statuses', label: 'All Statuses' },
            { key: 'not_ready', value: 'Not Submitted', label: 'Not Submitted', color: globalColors['redTagColor'] },
            { key: 'od_review_ready', value: 'Submitted', label: 'Submitted', color: globalColors['yellowTagColor'] },
            { key: 'reviewed', value: 'Reviewed', label: 'Reviewed', color: globalColors['greenTagColor'] },
        ],
        OPEN_SELECTED_VISITS_TOOLTIP: 'The browser popup blocker must be disabled to allow all 5 exam rooms to open.',
        ASKED_PATIENT_TEXT: 'Asked Pt To Take',
        EXAM_STATUS_OPTIONS_MAP: {
            'not_ready': 'not ready for OMD',
            'od_review': 'OD to review',
            'ready': 'ready for OMD',
            'reviewed': 'OMD reviewed',
        },

        GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES: [
            { key: 'needed', value: 'Needs Appt', label: 'Needs Appt' },
            { key: 'skipped', value: 'Skipped', label: 'Skipped' },
            { key: 'booked', value: 'Booked', label: 'Booked' },
        ],

        // Patient list table column headings text.
        FIRST_NAME_HEADING : 'First Name',
        LAST_NAME_HEADING : 'Last Name',
        DOB_HEADING : 'DOB',
        DOCTOR_HEADING : 'OD',
        CHIEF_COMPLAINT_HEADING : 'Chief Complaint',
        NUMBER_VISITS_HEADING : 'Visits',
        LAST_VISIT_HEADING : 'Last Visit',
        LAST_REVIEWED_HEADING : 'Last Rev\'d',
        STATUS_HEADING : 'Status',
        SELECT_HEADING : 'Select',
        USERNAME_TEXT: 'Username',
        PHONE_NUMBER_TEXT: 'Phone Number',
        REFERRAL_STATUS_HEADING: 'Referral Status',

        // Modal buttons
        EDIT_BUTTON_TEXT: 'Edit',
        NEW_VISIT_BUTTON_TEXT: 'New Visit',
        CANCEL_BUTTON_TEXT: 'Cancel',
        CLOSE_BUTTON_TEXT: 'Close',
        DELETE_BUTTON_TEXT: 'Delete',
        GP_CONSULT_LETTER_TEXT: 'GP Consult',
        GP2_CONSULT_LETTER_TEXT: 'MD#2 Consult',
        VIEW_VISIT_TEXT: 'View',
        SUBMIT_BUTTON_TEXT: 'Submit',

        // Admin alerts list page
        ADMIN_ALERTS_OPEN_ALL: 'Open All',
        ADMIN_ALERTS_NO_OMDR: 'List of patients with no OMDR assigned:',
        ADMIN_ALERTS_WITH_OMDC: 'List of patients with OMDC status but no OMDC assigned:',
        ADMIN_ALERTS_WITH_NO_OMDC_STATUS: 'List of patients with OMDC assigned but no OMDC status:',
        ADMIN_ALERTS_MULTIPLE_EXAMS: 'List of patients with multiple exams having an OMDC status:',
        ADMIN_ALERTS_IPC_STATUS: 'List of patients with IPC status:',
        ADMIN_ALERTS_IPC_STATUS_REQUESTED_BY_OMD: 'List of patients with IPC status “Requested by OMD”:',
        ADMIN_ALERTS_PRIORITY_OD: 'Flagged as high priority (urgent) attention by the OD:',
        ADMIN_ALERTS_URGENT_REVIEW: 'Urgent to Review (patients set to ready for OMD over 2 weeks ago)',

        ADMIN_ALERTS_URGENT_PREREVIEW: 'Urgent to Pre-review (patients set to ready for OMD over 2 weeks ago  and not pre-reviewed):',
        ADMIN_ALERTS_OMDC_6MONTHS: 'Assigned to OMDC last visit 6 months ago:',
        ADMIN_ALERTS_BILLING_NOTES: 'Outstanding Billing Notes:',
        ADMIN_ALERTS_IOP_35: 'IOP greater than 35:',
        ADMIN_ALERTS_LIST_CONFLICTING: 'List of patients with conflicting OMD notes:',
        ADMIN_ALERTS_OMD_TIMESTAMP: 'List of patients who are OMD Reviewed with no Timestamp:',
        ADMIN_ALERTS_CRITICAL: 'Critical to pre-review (patients with latest exam not pre-reviewed and set to ready for OMD over 3 weeks ago or have an not pre-reviewed exam ready for OMD that\'s not the most recent exam):',

        // Login Page
        LOGIN_TITLE_TEXT: 'Care1',
        LOGIN_HEADING_TEXT: 'Digital accelerants for your eye care practice.',
        LOGIN_INVITATION_SUBJECT: 'Request an invitation',
        LOGIN_INVITATION_TEXT: 'Request an invitation at ',
        LOGIN_BANNER_RETINA_ENABLED_TITLE: 'Retina Enabled Clinics',
        LOGIN_BANNER_RETINA_ENABLED_SUBTITLE: 'Retina Telemedicine',
        LOGIN_BANNER_DRY_EYE_ENABLED_TITLE: 'Dry Eye Enabled Clinics',
        LOGIN_BANNER_DRY_EYE_ENABLED_SUBTITLE: 'Premium Dry Eye Services',
        LOGIN_BANNER_INTEGRATED_CLINICS_TITLE: 'Integrated Clinics',
        LOGIN_BANNER_OMD_CONSULTANT_TITLE: 'OMD Consultant',
        LOGIN_BANNER_OMD_REVIEWER_TITLE: 'OMD Reviewer',
        LOGIN_BANNER_INTEGRATED_CLINICS_SUBTITLE: 'Glaucoma and Retina Telemedicine',
        LOGIN_PLACEHOLDER_USERNAME: 'Username',
        LOGIN_PLACEHOLDER_PASSWORD: 'Password',
        LOGIN_TEXT: 'Log in',
        LOGIN_KEEP_ME: 'Keep me logged in',
        LOGIN_FORGOT_PASSWORD: 'Forgot password?',
        LOGIN_RETURN_TO_PRODUCT_MENU_TEXT: 'Return to product menu',
        LOGIN_FOOTER_COMPANY_NAME: 'Care1',
        LOGIN_CAROUSEL_AVOCADO_TITLE: 'Avocado Lenses',
        LOGIN_CAROUSEL_AVOCADO_SUBTEXT: 'Contact Lens Ordering Platform and Subscription Program',
        LOGIN_CAROUSEL_RETINA_TITLE: 'Retina Enabled Clinics',
        LOGIN_CAROUSEL_RETINA_SUBTEXT: 'Retinal Telemedical Collaboration for Optometrists and Ophthalmologists',
        LOGIN_CAROUSEL_DRY_EYE_TITLE: 'Dry Eye Enabled Clinics',
        LOGIN_CAROUSEL_DRY_EYE_SUBTEXT: 'Peri-Operative Programs for Premium Dry Eye Practices',
        LOGIN_CAROUSEL_INTEGRATED_TITLE: 'Integrated Clinics',
        LOGIN_CAROUSEL_INTEGRATED_SUBTEXT: 'Retinal and Glaucoma Telemedical Collaboration for Optometrists and Ophthalmologists',
        LOGIN_INVITATION_EMAIL_SUBJECT: 'Care1 Contact Lenses Subscription',
        LOGIN_OR_TEXT: 'OR',
        LOGIN_MISSING_USER_MESSAGE: 'Please input your user name!',
        LOGIN_MISSING_PASSWORD_MESSAGE: 'Please input your password!',
        LOGIN_MIGRATION_ERROR_MESSAGE: 'User is not enabled for this service.',

        DOB_ERROR_MESSAGE: 'Invalid DOB. Please double check entries and try again.',
        OPERATING_TEXT: 'Operating...',
        POSTAL_CODE_MASK: 'a9a 9a9',
        EDIT_PATIENT_TEXT: 'Edit Patient',

        PATIENTEXAMGLAUCOMASCORE_SUBHEADING_TEXT: 'Glaucoma Risk Score',
    },
    fr: {
        // Help Desk modal
        HELP_DESK_REQUEST: 'Service d\'Assistance',
        YOUR_NAME_TEXT: 'Nom',
        YOUR_EMAIL_TEXT: 'Votre Courriel (auquel nous vous répondrons)',
        YOUR_NAME_PLACEHOLDER: 'Nom de la personne qui fait la demande',
        CONTACT_EMAIL_TEXT: 'Vous contacter à ce courriel',
        HELP_DESK_DETAILS: 'Détails de la demande d\'aide',
        PROVINCE_TEXT: 'Province',
        CLINIC_TEXT: 'Clinique',
        DOCTOR_TEXT: 'Docteur',
        DATE_TEXT: 'Date',

        // Generate Letter Modal
        GENERATE_TEXT: 'Générer',
        CONSULTATION_LETTER_TEXT: 'Lettre de consultation',
        DOCTORS_NAME_TEXT: 'Nom du Docteur',
        TO_OTHER_TEXT: 'À d\'autres',
        TO_GP_TEXT: 'Au MD',
        TO_MD2_TEXT: 'Au MD#2',

        // Admin Reports Modal
        ADMIN_REPORTS_MODAL_TEXT: 'Rapports administratifs',
        LAST_OMDR_REVIEW_DATE: 'Date de la dernière révision par ophtalmo',
        OMD_REVIEWS_TEXT: 'Révisions ophtalmo',
        INTEGRATION_LIST_UPLOADS: 'Chargements de listes d\'intégration',
        BILLING_REPORT_EXAM_TEXT: 'Rapport de facturation (basé sur la date de la visite d\'examen)',
        GET_REPORT_TEXT: 'Obtenir le rapport',

        // Navigation bar
        HELP_DESK_TEXT: 'Service',
        PATIENT_LIST_TEXT: 'Liste des patients',
        PLATFORM_RETINA_ENABLED_TITLE: 'Rétine Activée',
        PLATFORM_INTEGRATED_TITLE: 'Cliniques Intégrées',
        PLATFORM_OMDC_TITLE: 'Consultant Ophtalmo',
        PLATFORM_OMDR_RE_TITLE: 'Ophtalmo Rétine Activée',
        PLATFORM_OMDR_IC_TITLE: 'Clinique(s) Ophtalmo',
        PLATFORM_ADMIN_TITLE: 'Admin',
        LOG_OUT_TEXT: 'Déconnexion',

        // Patient exam GP referred
        SUCCESFULLY_UPDATED_TEXT: 'is successfully uploaded',

        // Patient Exam Chief Complaint
        CHIEF_COMPLAINT_GLC_LABEL: 'Glc',
        CHIEF_COMPLAINT_OPTIC_NERVE_LABEL: 'Nerf Optique',
        CHIEF_COMPLAINT_GLCS_LABEL: 'GlcS',
        CHIEF_COMPLAINT_DM_LABEL: 'DB',
        CHIEF_COMPLAINT_AMD_LABEL: 'DMLA',
        CHIEF_COMPLAINT_ERM_LABEL: 'MER',
        CHIEF_COMPLAINT_CAT_REF_LABEL: 'Réf. Cat',
        CHIEF_COMPLAINT_POST_OP_LABEL: 'Cat Post-op',
        CHIEF_COMPLAINT_NAR_ANG_LABEL: 'Ang Fermés',
        CHIEF_COMPLAINT_TEXT: 'Motif de Consultation',

        // Exam Status text
        EXAM_STATUS_TITLE_TEXT: 'L\'ÉTAT DU DOSSIER EST:',
        OCT_HISTORY_TEXT: 'OCT Mac',
        OCT_RNFL_TEXT: 'OCT RNFL',
        VF_TEXT: 'CV',
        FUNDUS_HISTORY_TEXT: 'Photo du fond d\'œil',
        CONVERSATION_HISTORY_TEXT: 'Conversation entre opto et ophtalmo',
        OD_NOTES_TEXT: 'Afficher les notes de l\'O.D.',
        EXTRA_IMAGES_TEXT: 'Images supplémentaires',
        IOP_GRAPH_TEXT: 'PIO (vs âge)',
        BCVA_GRAPH_TEXT: 'MAVC (vs âge)',
        NKDA_TEXT: 'Aucune aux meds',
        SULFA_TEXT: 'Sulfa',
        NONE_TEXT: 'Aucune',
        NO_GLC_MEDS: 'Pas de méd. Glc',
        SAME_MEDS: 'Les mêmes médicaments',
        SAVE_BUTTON_TEXT: 'Enregistrer',
        SAVE_AND_CLOSE_BUTTON_TEXT:'Enregistrer & fermer',
        SAVE_BUTTON_FIELD_REQUIRED: 'Les champs recommandés ne sont pas obligatoires, mais en les complétant, vous améliorez la capacité de Soins1 à fournir les bons soins.',
        SAVE_BUTTON_ANYWAY: 'Sauvegarder quand même',
        SAVE_BUTTON_EDITING: 'Continuer les modifications',
        SAVE_BUTTON_EMPTY_FIELD_WARNING: 'Attention : Des champs recommandés (en jaune) sont encore im=ncomplets dans le dossier ',
        GP_CONSULTATION_LETTER_TEXT: 'LETTRE DE CONSULTATION DU GÉNÉRALISTE',
        CHIEF_COMPLAINT_PLACEHOLDER_TEXT: 'Chercher ou sauvegarder d\'autres plaintes',
        EXAM_NOTES_TITLE: 'Examen',
        EXAM_ONGOING_TITLE: 'En cours',
        ENTRY_REQUIRED: 'Saisie requise!',

        // Patient Visits Text
        PATIENT_VISIT_NUMBER_TEXT: 'Visite du patient',
        OF_TEXT: 'de',
        ITEMS_TEXT: 'articles',
        DOB_TEXT: 'Date de naissance:',
        TELEPHONE_TEXT: 'Tél:',
        GP_TEXT: 'MD:',
        GP2_TEXT: 'MD#2:',
        OMD_TEXT: 'OMD:',
        OD_TEXT: 'OD:',
        EXAM_DATE_TEXT: 'Date de l\'Examen',
        HIGH_PRIORITY_TEXT: 'Priorité élevée',
        CHART_STATUS_TEXT: 'Statut du dossier:',
        OD_SELECT_PLACEHOLDER_TEXT: 'Sélectionner le OD',
        NO_HISTORY_TEXT: 'Pas d\'antécédents',
        EXAM_NOTE_DEFAULT_TEXT: 'Cornées claires 2 yeux, Angles ouverts 2 yeux, Lentilles claires 2 yeux, et rétines normales.',
        EXAM_OMD_HISTORY_TITLE: 'Historique avec Ophtalmo',
        ALLERGIES_PLACEHOLDER: 'Medication allergies',
        YES_TEXT: 'Oui',
        NO_TEXT: 'Non',
        YES_TEXT_CAPITALIZED: 'OUI',
        NO_TEXT_CAPITALIZED: 'NON',
        GP_REFERRED_TEXT: 'Cette visite a été référée par un MD généraliste',
        UPLOAD_PROGRESS_ABORTED: 'Le téléchargement a été Arrêté.',
        PHN_TEXT: 'Numéro d\'assurance-maladie',
        ALLERGIES_TITLE: 'Allergies',
        OCT_MACULA_TEXT: 'OCT Mac',
        RIGHT_OCT_MACULA_TEXT: 'OCT mac droit',
        LEFT_OCT_MACULA_TEXT: 'OCT mac gauche',
        IOP_TEXT: 'PIO',
        VERT_CD_TEXT: 'C/D vertical',
        ISHIHARA_TEXT: 'Ishihara',
        INSTRUMENT_TEXT: 'Instrument',
        PACHS_TEXT: 'Pachy',
        RX_TEXT: 'Ordonnance',
        BCVA_TEXT: 'MAVC',
        AUTOFRACTION_SUFFICIENT_TEXT: 'autorefraction sufficient',
        EXAM_IMPRESSION_TITLE: 'Impression',
        OD_NOTES_PLAN_TITLE: 'Notes de l\'OD et plan proposé',
        GOVT_BILL_TEXT: 'Nous ne pouvions pas facturer le gouvernement pour',
        GOVT_BILL_EXPLANATION_TEXT: 'Il existe des fonds d\'étude supplémentaires si vous n\'avez pas été capables de facturer le gouvernement pour certains tests diagnostiques. Veuillez indiquer si vous n\'avez pas pu facturer le gouvernement pour ce patient..',
        FU_LETTER_TEXT: 'Patient revient pour un suivi dans',
        OTHER_OMD_TEXT: 'Patient consulte actuellement un ophtalmologiste',
        OMD_APT_TEXT: 'Soins1 référera le patient à un ophtalmologiste',
        UPLOAD_TEXT: 'Télécharger',
        UPLOADED_TEXT: 'Téléchargé',
        VIEW_ALL_TEXT: 'VOIR TOUTS',
        GENERATE_LETTERS: 'Générer des Lettres',
        WEEKS_TEXT: 'Semaines',
        MONTHS_TEXT: 'Mois',
        MONTHS: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],

        // Admin patient List
        ADMIN_ALERT_LISTS_TEXT: 'LISTE DES ALERTES',
        ADMIN_DOCTERS_TEXT: 'DOCTEURS',
        ADMIN_REPORTS_TEXT: 'RAPPORTS',
        ADMIN_TEXT: 'ADMIN',
        ADMIN_REPORTS_DASHBOARD_TEXT: 'REPORTS DASHBOARD',
        ADMIN_OMDC_READY_OMDC: 'OMDC',
        ADMIN_READY_TOTAL: 'Total prêt',
        ADMIN_OMDC_READY_CAT: 'Cat prêtes',
        ADMIN_OMDC_READY_RETINA: 'Maladie rétinienne stade précoce prête',
        ADMIN_OMDC_READY_GLC: 'Glauc. Sus prêt',
        ADMIN_REVIEWED_TOTAL: 'Éléments examinés',
        ADMIN_OMDC_REVIEWED_CAT: 'Cat. examinées',
        ADMIN_OMDC_REVIEWED_RETINA: 'Maladie rétinienne stade précoce examinée',
        ADMIN_OMDC_REVIEWED_GLC: 'Glauc. Sus examinées',
        ADMIN_OMDC_PROCESSED: 'Traité dern. 6 mois',
        ADMIN_STANDARDIZED_REVIEW_POINTS: 'Critères d\'examen standard',
        ADMIN_OMDR: 'OphtR',
        ADMIN_SPECIAL_ATTENTION: 'Mention spéciale',
        ADMIN_RETINA: 'Rétine',
        ADMIN_OTHER: 'Autre',
        ADMIN_GLCP: 'Glc-P',
        ADMIN_GLCBO: 'Glc-LS',
        ADMIN_GLCBT: 'Glc-LT',
        ADMIN_GOOD_DISC_GOOD_VF: 'Disque normal CV normal',
        ADMIN_BAD_DISC_GOOD_VF: 'Disque anormal, CV normal',
        ADMIN_GOOD_DISC_BAD_VF: 'Disque normal, CV anormal',
        ADMIN_BAD_DISC_BAD_VF: 'Disque anormal, CV anormal',
        ADMIN_TOTAL_SRP: 'Total des critères d\'examen standard',
        ADMIN_REFERRED_PAST: 'Référé au cours des 12 derniers mois',
        ADMIN_IGP_TEXT: 'IGP',
        ADMIN_IGP_STATUS: 'IGP Statut',
        ADMIN_REFRESH_TABLES: 'Actualiser les tableaux',
        ADMIN_RESET_SORT: 'Réinitialiser l\'ordre de tri des colonnes',
        ADMIN_COLUMN_SORT_TOOLTIP: 'Columns are sorted according to the order in which column headers are selected. Click this button to reset the column headers and the sorting priority',
        ADMIN_TABLE_TEST: 'Test',
        ADMIN_TABLE_ALERTS: 'Alertes',
        ADMIN_TABLE_STATUS: 'Statut',
        ADMIN_TABLE_SPECIAL_NOTES: 'Notes spéciales',
        ADMIN_TABLE_PR_ALERTS: 'PR Alerts',
        ADMIN_TABLE_EGP_STATUS: 'EGP Status',
        ADMIN_TABLE_IGP: 'IGP',
        ADMIN_TABLE_IGP_STATUS: 'IGP Statut',
        ADMIN_TABLE_VISITS: 'Visites',
        ADMIN_TABLE_DOB: 'Date de naissance',
        ADMIN_TABLE_OMDC: 'OphtC',
        ADMIN_TABLE_OMDC_STATUS: 'Statut de OMDC',
        ADMIN_TABLE_OD_GROUP: 'Groupe OD',
        ADMIN_TABLE_OD: 'OD',
        ADMIN_TABLE_PRV: 'Prov.',
        ADMIN_TABLE_OMDR: 'OMDR',
        ADMIN_TABLE_LAST_EXAM: 'Dernier examen',
        ADMIN_TABLE_REF_TYPE: 'Type de réf',
        ADMIN_TABLE_FIRST_NAME: 'Prénom',
        ADMIN_TABLE_LAST_NAME: 'Nom de famille',
        ADMIN_TABLE_PR: 'PR',
        OPEN_SELECTED_PATIENTS: 'Ouvrir les patients Sélectionnés',
        USERNAME_HEADING: 'Nom d\'usager',
        PHONE_NUMBER_HEADING: 'Téléphone',

        // Doctors list
        DOCTORS_LIST_SEARCH_PLACEHOLDER: 'Recherche par prénom, nom ou nom d\'utilisateur ',
        DOCTORS_LIST_ADD_NEW_OMD: 'Nouveaux ophtalmologistes',
        DOCTORS_LIST_ADD_NEW_OD: 'Nouveaux optométristes',
        DOCTORS_LIST_ADD_NEW_GP: 'Nouveaux internistes',
        DOCTORS_LIST_TEXT: 'Liste des Médecins',
        DOCTORS_LIST_PRACTICES_GROUP: 'Groupe de pratiques',
        DOCTORS_LIST_ACTION: 'Action',
        DOCTORS_LIST_OMD_NETWORK: 'Réseau d\'ophtalmologistes',
        GROUP_PRACTICE_LIST_SEARCH_PLACEHOLDER: 'Search by group practice name',

        // Patient Visit Exit Confirmation Modal
        EXIT_CONFIRMATION_TITLE: 'Vous êtes sûr de vouloir quitter cette visite?',
        EXIT_CONTENT_TEXT: 'Vous avez des données non sauvegardées.',
        CONFIRMATION_OK_TEXT: 'Oui',
        CONFIRMATION_CANCEL_TEXT: 'Non',

        // IC OD Patient Exam
        RIGHT_SIDE_TEXT: 'right',
        LEFT_SIDE_TEXT: 'left',
        DRAG_DROP_TEXT: 'Glisser-déposer pour téléverser.',
        EXTRA_DRAG_DROP_TEXT: 'Glisser-déposer\r\npour téléverser.',
        LEFT_FUNDUS_PHOTO_TITLE_TEXT: 'Photo du fond d\'œil gauche',
        RIGHT_FUNDUS_PHOTO_TITLE_TEXT: 'Photo FO droit',
        LEFT_STEREOPAIR_TITLE_TEXT: 'Deuxième photo du fond d\'œil gauche',
        RIGHT_STEREOPAIR_TITLE_TEXT: 'Deuxième photo du fond d\'œil droit',
        LEFT_STEREOPAIR_TITLE_TEXT_RE_OD: 'Paire Stéréo Gauche',
        RIGHT_STEREOPAIR_TITLE_TEXT_RE_OD: 'Paire Stéréo Droite',
        OCT_MACULA_PHOTO_DROP_TEXT: 'Glisser & Déposer pour télécharger.\r\nTélécharger une nouvelle OCT Mac à chaque visite.\r\nSi vous avez plusieurs coupes de l’OCT,\r\n veuillez télécharger le fichier unique qui semble le plus pathogène.',
        PHOTO_DROP_TEXT_SHORT: 'Glisser-déposer pour téléverser.',
        SUPPORTED_FORMAT_TEXT: 'Formats acceptés : jpg, png, tiff, pdf',
        LEFT_OCT_MACULA_PHOTO_TITLE_TEXT: 'OCT mac',
        LEFT_OCT_RNFL_PHOTO_TITLE_TEXT: 'OCT RNFL gauche',
        LEFT_VF_PHOTO_TITLE_TEXT: 'CV gauche',
        RIGHT_OCT_MACULA_PHOTO_TITLE_TEXT: 'OCT mac',
        RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT: 'OCT RNFL droit',
        RIGHT_VF_PHOTO_TITLE_TEXT: 'CV droit',
        FILE_UPLOAD_FAILED_TEXT: 'Les fichiers\r\n doivent être\r\nde mois de 1MB.',
        EXAM_PHOTO_FUNDUS: 'Photo du fond d\'œil',
        EXAM_PHOTO_STEREOPAIR: 'Deuxième photo du fond d\'œil',
        EXAM_PHOTO_STEREOPAIR_RE_OD: 'Paire stéréo',
        OD_LOCATION_TEXT: 'Emplacement de l\'O.D.',
        CLOSEST_OMD_TEXT: 'Ophtalmologiste le plus proche',
        OMDC_STATUS_TEXT: 'Statut du consultant ophtalmologiste',
        HISTORY_SYMPTOMS_TITLE: 'Antécédents',
        EXAM_HISTORY_PLACEHOLDER: 'Quelque chose de court, même seulement 1-3 phrases, est parfait.',
        CD_VALUES_BAND_TOOLTIP_TEXT: 'Vous pouvez sélectionner une valeur CD différente pour la photo OD et/ou OS Fundus.',

        // DeepMD
        DEEPMD_SUSPICIOUS_FEATURES_GLC: 'Caractéristiques suspectes du GLC',
        DEEPMD_REASSURING_FEATURES_GLC: 'Caractéristiques rassurantes du GLC',
        DEEPMD_ANALYZED_DATA: 'J\'ai analysé les données des patients téléchargées',
        DEEPMD_HELP_TRAIN: 'Aidez-moi à me perfectionner',
        DEEPMD_SUBHEADING_TEXT: 'Soins aux patients basés sur l\'IA',
        DEEPMD_INACCURACY_TOOLTIP: 'Veuillez m\'aider à devenir plus précis en soumettant un rapport d\'anomalie si vous pensez que mes recommandations ne sont pas exactes.',
        DEEPMD_ALERTS: 'Alertes',
        DEEPMD_COMFORT_ZONES: 'Zones de confort DeepMD',
        DEEPMD_CURRENT_COMFORT: 'Zones de confort actuelles',
        DEEPMD_COUNSELLING_RECOMMENDATIONS: 'Recommandations de conseil',

        // IC OMDR Patient Exam
        REVIEWER_SELECTORS_TEXT: 'Sélecteurs d\'examinateurs',
        OMDR_REVIEWER_NOTES_HEADING: 'Notes privées à l\'O.D.',
        OMD_REVIEW_NOTES_PLACEHOLDER: 'Saisissez des phrases complètes en utilisant votre clavier ou un logiciel de dictée. Faites attention à l\'orthographe! Vos notes seront copiées directement dans votre lettre de consultation.',
        OMDR_NOTES_TO_OD_AND_GP_HEADING: 'Notes à l\'O.D. et au médecin généraliste',
        PATIENT_EXAM_NOTES_PROVIDED: 'Ces notes seront fournies au médecin généraliste du patient en plus d\'être visibles par l\'optométriste et l\'ophtalmologiste. Ces notes ne sont PAS communiquées à chaque visite.',
        EXAM_NOTES_CLICK: 'Cliquez sur les boutons pour insérer des phrases pré-générées dans votre consultation, que vous pouvez modifier ou ajouter si nécessaire',
        PATIENT_EXAM_NOTES_TOOLTIP: 'Ces notes sont visibles par l\'optométriste et l\'ophtalmologiste et sont communiquées à chaque visite.',
        EXAM_NOTES_HISTORY_TITLE: 'Notes sur le patient',
        IOP_HISTORY_TITLE: 'Historique de la PIO',
        PATIENT_EXAM_HISTORY_MEDS_EXAMS_TITLE: 'Antécédents, examen et médicaments',
        PATIENT_EXAM_UNTIL_YESTERDAY: 'Gouttes (jusqu\'à hier)',
        PATIENT_EXAM_DROPS_ONGOING: 'Gouttes (en cours)',
        PATIENT_EXAM_ZOOM_CLICK: 'Cliquer pour agrandir, cliquer avec le bouton droit pour agrandir 5 fois.',
        STEREO_VIEWER_TEXT: 'Stéréoscope',
        VERTICAL_CD_VALUES: 'Valeurs C/D verticales',
        IOP_FOLLOWUP_TEXT: 'Recommandations finales de la PIO et suivi',
        SAVE_VIEW_TEXT: 'Sauvegarder la vue',
        RESET_TEXT: 'Réinitialiser',
        PRESAVED_VIEW_TEXT: 'Vue préenregistrée',
        DEFAULT_VIEW_TEXT: 'Vue par défaut',
        SWITCH_OS_PHOTOS: 'Changer les photos OG',
        SWITCH_OD_PHOTOS: 'Changer de photo OD',
        BASELINE_TESTING_IMAGE_LABEL: '[Les images du test de base sont affichées dans la première rangée]',
        SHOW_ADMIN_VIEW: 'Afficher la vue Admin',
        SECOND_OPINION_OPTION: 'Saisissez des phrases complètes en utilisant votre clavier ou un logiciel de dictée. Faites attention à l\'orthographe! Vos notes seront copiées directement dans votre lettre de consultation.',
        IOP_AIM_TOOLTIP_TEXT: "Lorsque la PIO visée est XXX, appuyez sur n'importe quel chiffre ou sur la touche Retour arrière pour supprimer tous les X dans le champ de saisie de la PIO visée.",
        SECOND_OPINIONS_TEXT: 'Deuxième avis',
        SELECT_REVIEWER_TEXT: 'Sélectionner l\'examinateur',
        OMD_SUBMIT_TOOLTIP: 'Appuyer sur Ctrl +Maj + X pour soumettre l\'examen et ouvrir un nouveau patient en arrière-plan. Appuyer sur Ctrl + Tab pour passer au patient suivant.',
        LAST_LETTER_TEXT: 'Dernière lettre au médecin généraliste:',
        GP_LETTER_TEXT: 'Lettre Med Fam',
        GP_LETTER_TOOLTIP: 'Les lettres des médecins généralistes sont envoyées automatiquement par le logiciel, environ une fois par an, mais en fonction de divers protocoles tels que la fréquence à laquelle le médecin généraliste souhaite recevoir des nouvelles de Soins1.',
        OMD_FOLLOWUP_OD_WILL_SEE: 'O.D. verra dans',
        OMD_FOLLOWUP_APPT: 'Rendez-vous en personne avec un ophtalmologiste',
        FOLLOWUP_TEXT: 'Suivi',
        VERBALIZE_TEXT: 'Verbaliser (Beta)',
        INITIATE_TREATMENT_TEXT: 'Débuter le traitement',
        NOTES_MOA_TEXT: 'Notes à l’assistant de bureau de médecin',
        NOTES_MOA_TOOLTIP: 'Ces notes sont à usage interne uniquement. Elles peuvent contenir des informations supplémentaires de la part de l\'assistant de bureau de médecin ou peuvent être utilisées pour envoyer des questions ou des instructions à celui-ci.',
        START_TEXT: 'Début',
        END_TEXT: 'Fin',
        PG_TEST: 'PG',
        TREATMENT_DROP_OPTIONS: [
            {
                value: 'prostaglandin',
                label: 'PG',
                is_default: true,
            },
            {
                value: 'betablocker',
                label: 'BB',
            },
            {
                value: 'pg_bb',
                label: 'PG-BB',
            },
        ],
        IOP_ZONE_TX: 'Zone PIO sous traitement',
        TREATMENT_IOP_OPTIONS: [
            {
                value: '14',
                label: '14',
            },
            {
                value: '17',
                label: '17',
            },
            {
                value: '21',
                label: '21',
            },
            {
                value: '24',
                label: '24',
            },
            {
                value: 'custom',
                label: 'Personnalisé',
            },
        ],
        SIDE_EFFECT_DROP_OPTIONS: [
            {
                value: 'different_pg',
                label: 'Différents PG',
                required_drops: ['prostaglandin', 'betablocker'],
                is_default: true,
            },
            {
                value: 'pg_bb',
                label: 'PG-BB',
                required_drops: ['prostaglandin', 'betablocker'],
            },
            {
                value: 'different_pg_bb',
                label: 'Différents PG-BB ',
                required_drops: ['pg_bb'],
                is_default: true,
            },
            {
                value: 'pg_slt',
                label: 'PG + TSL',
                required_drops: ['pg_bb'],
            },
            {
                value: 'bb_cai',
                label: 'BB-IAC',
                required_drops: ['pg_bb'],
            },
        ],
        CANCEL_TEXT: 'Annuler',
        FOLLOW_UP_TEXT: 'Suivre',
        TREATMENT_INITIATION_TEXT: 'Debut de Traitement',
        IF_SIDE_EFFECTS: 'Si présence d\'effets secondaires',
        IF_EFFECTIVE_TEXT: 'Si efficace',
        SWITCH_TO_TEXT: 'Basculer vers',
        DISCUSS_ARTIFICIAL_TEARS: 'Aborder le sujet de larmes artificielles',
        IF_NOT_ENOUGH_EFFECT: 'Si l\'effet n\'est pas suffisant',
        DISCUSS_REFRIDGERATION: 'Aborder le sujet de la réfrigération',
        FELLOW_EYE_START: 'Second oeil: Début',
        FOLLOW_UP_EVERY: 'Faire le suivi chaque:',
        DARK_BLUE_DTC_RANGE: 'Bleu foncé = gamme de courbes de tension diurnes',

        // Admin patient exam
        FAX_TEXT: 'Télécopie',
        IN_PERSON_CONSULT: 'Consultation en personne',
        EMAIL_NOT_VALID: 'Le courriel n\'est pas valide',
        EMAIL_REQUIRED: 'Le courriel est nécessaire',
        FAX_NUMBER_NOT_COMPLETE: 'Le numéro de télécopieur n\'est pas complet',
        FAX_REQUIRED: 'Une télécopie est nécessaire',
        FAX_EMAIL_MESSAGE: 'Message par courriel ou télécopie',
        IA_TEXT: 'Age non valide',
        G_TEXT: 'V',
        Y_TEXT: 'J',
        R_TEXT: 'R',
        NA_TEXT: 'S/O ',
        AUTOMATIC_CODE_TEXT: 'Codification Automatique',
        INVALID_TEXT: 'Non-valide',
        TELE_FU_TEXT: 'Suivi Télémédecine',
        GP_LETTER_TEXT_ADMIN: 'Lettre Med Fam',
        ISSUES_TEXT: 'Problèmes',
        OD_REPLY_TEXT: 'OD REPLY',
        OD_HAS_QUEST_ISSUE: 'L\'OD a des questions/problèmes',
        OD_RQST_OMD_APPT: 'OD réfère à l\'ophtalmologiste pour R-V',
        OD_DROPS: 'GOUTTES',
        CURT_UPCOMING_OMD: 'R-V avec Ophtalmologiste',
        GP_REF_DATES: 'Dates de référence par le Med Fam',
        SELECTED_GP_REF_DATE: 'Date de référence par le Med Fam choisie',
        MANUAL_GP_REF_DATE: 'Date de référence par le Med Fam manuelle',
        OCT_VF_TEXT: 'OCT/FV',
        OMDC_ONTARIO_BILLING_CODE: 'Code de facturation d\'Ontario OphtC',
        OMDR_ONTARIO_BILLING_CODE: 'Code de facturation d\'Ontario OphtR',
        IGP_STATUS_LABEL: 'MGI St:',
        IGP_LABEL: 'MGI:',
        SELECT_IGP_TEXT: 'Sélectionner MGI',
        SELECT_IGP_STATUS: 'Sélectionner MGI Statut',
        WNL_TEXT: 'Lim N.',
        GRS_TEXT: 'RGS',
        BGRS_TEXT: 'L-RGS',
        B_TEXT: 'L',
        ONL_TEXT: 'HLN',
        AHS_TEXT: 'SAÉ',
        MANUAL_CODE_OVERRIDE: 'Contournement manuel du code:',
        OCT_RNFL_AVERAGE: 'OCT RNFL moyen',
        VF_GHT_TEXT: 'CV THG',
        ADMIN_SELECTORS_TEXT: 'Sélecteurs Admin',
        GP_INFO_TEXT: 'INFO MEDFAM',
        NO_SURG_TEXT: 'PAS DE CHX ',
        OCT_TEXT: 'OCT',
        CATTM_TEXT: 'CatTM',
        PAST_OMD_TEXT: 'Ophtalmo précédent',
        PREREVIEW_INITIALS: 'Pré-révision & Initials',
        PREREVIEWED_TEXT: 'Pré-révisé',
        PREREVIEW_TEXT: 'Pré-révision',
        SECOND_REVIEW: '2ème révision',
        UNTIL_YESTERDAY_TITLE: 'Jusqu\'à hier',
        OD_BASELINE_TEXT: 'Valeur de départ OD',
        HXDHX_OD_TEXT: 'ATCD drance OD',
        HXDHX_OS_TEXT: 'ATCD drance OS ',
        PATIENT_HAS_MULTIPLE_EXAMS: 'Patient a plusieurs examens prêts pour OMD',
        PATIENT_HAS_NO_MULTIPLE_EXAMS: 'Le patient n\'a pas plusieurs examens prêts pour OMD',

        // Patient exam comorbitities
        FHX_GLC_TEXT: 'ATCD fam. Glc',
        HEART_BLOCK_TEXT: 'Bloc cardiaque',
        STROKE_TEXT: 'AVC',
        UVEITIS_OD_TEXT: 'Uvéite OD',
        UVEITIS_OS_TEXT: 'Uvéite OG',
        PXF_OD_TEXT: 'PXF OD',
        PXF_OS_TEXT: 'PXF OG',
        PDS_OD_TEXT: 'SDP OD',
        PDS_OS_TEXT: 'SDP OG',
        ASTHMA_TEXT: 'Asthme',
        COMORBIDITIES_TITLE: 'Comorbidités',

        // OMDR Patient review table
        REVIEW_TABLE_CATEGORY: 'Catégorie',
        PATIENTS_TO_REVIEW: 'Patients à passer en revue',
        TOTAL_PATIENTS: 'Total de patients',
        REVIEW_TABLE_PATIENTS: 'Les Patients',
        PRE_REVIEW_ALERTS: 'Alertes de pré-examen',
        OMD_REVIEWER_NOTES: 'Notes de l\'examinateur OMD',
        OMDC_NOTES_HEADING: 'Consultant',
        FOLLOW_UP_OPTIONS_HEADING: 'Options de suivi de l\'OMDC',

        // OMDC Patient List
        OMDC_STATUS_TYPES : [
            { key: 'all_statuses', value: 'Tous les statuts' },
            { key: 'ready', value: 'Prêt pour le consultant' },
            { key: 'reviewed', value: 'Consultant examiné or Examiné par le consultant' },
            { key: 'processed', value: 'Consultation Traitée' },
        ],

        // OMDR Patient List
        EXAM_ROOMS_FUNCTIONALITY: 'Pour prendre en charge la fonctionnalité des 5 salles d\'examen dans votre navigateur Chrome:',
        INSTALL_EXTENSION: 'Installer l\'extension',
        FOLLOW_STEPS: 'Suivre les étapes suivantes',
        NEW_TABS: 'New Tabs at End 3000', // Proper Noun - Name of extension
        FORCE_BACKGROUND: 'Force Background Tab', // Proper Noun - Name of extension
        OPEN_ALL_PATIENTS: 'Ouvrir tous mes patients',
        DISABLE_POPUP_BLOCKER: 'Désactiver le bloqueur de fenêtres contextuelles.',
        UNAUTHORIZED_MODAL_TITLE: 'A ressource demandée n\'est pas disponible',
        PROVINCE_HEADING: 'PRV',
        OD_HEADING: 'OD',
        GLC_CLASS_HEADING: 'Classification GLC',
        IOP_HEADING: 'PIO',
        BIOP_HEADING: 'vbPIO',
        BDISC_HEADING: 'vbDISQ',
        BFIELD_HEADING: 'vbCHAMP',
        LATEST_VISIT_HEADING: 'Dernière visite',
        COMMENTS_HEADING: 'Commentaires',
        NUMBER_OF_VISITS_HEADING: '# de Visites',
        EXAM_HEADING: 'Examen',
        UNAUTHORIZED_MODAL_CONTENT: 'Veuillez contacter hello@care1.ca si vous continuez à avoir des problèmes.',
        OMDR_IC_STATUS_TYPES : [
            { key: 'none', value: 'No Filter Selected', label: 'Aucun filtre sélectionné'},
            { key: 'ready', value: 'Ready for OMD', label: 'Prêt pour l\'ophtalmologiste' },
            { key: 'special_attention', value: 'Special Attention', label: 'Mention spéciale' },
            { key: 'retina1', value: 'Retina (DM, AMD, ERM)', label: 'Rétine (DM, AMD, ERM)' },
            { key: 'retina2', value: 'Other', label: 'Autre'},
            { key: 'glc_p', value: 'Glc F/U - Parameters Set (P)', label: 'Suivi GLC - Paramètres (P)' },
            { key: 'glc_bo', value: 'Glc F/U - Borderline Probably Observe (BO)', label: 'Suivi GLC - Limite, à surveiller (LS)'},
            { key: 'glc_bt', value: 'Glc F/U - Borderline Probably Treat (BT)', label: 'Suivi GLC - Limite, à traiter (LT)' },
            { key: 'gdgvf', value: 'Glc New - Good Disc Good VF', label: 'GLC nouveau - disque normal, champ visuel normal' },
            { key: 'bdgvf', value: 'Glc New - Bad Disc Good VF', label: 'GLC nouveau - disque anormal, champ visuel normal' },
            { key: 'gdbvf', value: 'Glc New - Good Disc Bad VF', label: 'GLC nouveau - disque normal, champ visuel anormal' },
            { key: 'bdbvf', value: 'Glc New - Bad Disc Bad VF', label: 'GLC nouveau - disque anormal, champ visuel anormal' },
        ],
        LOAD_FIVE_PATIENTS: 'Sélectionnez 5 patients',
        OMDR_EXAM_REVIEWED_PERIOD_OPTIONS : [
            { key: 'none', value: 'No Period Filter Selected', label: 'Aucun filtre de période sélectionné '},
            { key: '1_hour', value: 'Reviewed Last 1 hr', label: 'Révisé dans la dernière heure' },
            { key: '6_hours', value: 'Reviewed Last 6 hrs', label: 'Révisé dans les 6 dernières heures'},
            { key: '12_hours', value: 'Reviewed Last 12 hrs', label: 'Révisé dans les 12 dernières heures' },
            { key: '24_hours', value: 'Reviewed Last 24 hrs', label: 'Révisé dans les 24 dernières heures' },
            { key: '24_48_hours', value: 'Reviewed Last 24~48 hrs', label: 'Révisé dans les 24~48 dernières heures' },
            { key: '48_72_hours', value: 'Reviewed Last 48~72 hrs', label: 'Révisé dans les 48~72 dernières heures'},
            { key: '72_hours_1_week', value: 'Reviewed Last 72hrs~1wk', label: 'Révisé dans les dernières 72 heures à 1 semaine' },
            { key: '30_days', value: 'Reviewed Last 30 days', label: 'Révisé dans les 30 derniers jours' },
        ],
        OMD_REQUIRED_SET_IPC: 'Opht doit planifier CEP (consultation en personne) avec OphtC.',
        OMD_DATE_REQUIRED: 'Date requise pour fixer un CEP avec OphtC.',
        EXTERNAL_GP_STATUS_CHOICES: [
            { key: 'ready', value: 'Ready for EGP', label: 'Référé par MGE ' },
            { key: 'reviewed', value: 'Referred by EGP', label: 'Référé par MGE' },
            { key: 'letter_sent', value: 'EGP Letter Sent', label: 'Lettre envoyée au MGE' },
        ],
        INTERNAL_GP_STATUS_CHOICES: [
            { key: 'ready', value: 'Ready for IGP', label: 'Prêt pour MGI' },
            { key: 'reviewed', value: 'Reviewed by IGP', label: 'Réviser par MGI' },
            { key: 'letter_sent', value: 'IGP Letter Sent', label: 'Lettre envoyée au MGI ' },
        ],
        IPC_STATUS_CHOICES: [
            { key: 'requested', value: 'IPC requested', label: 'CEP requise' },
            { key: 'addressed', value: 'IPC addressed', label: 'CEP traîté' },
            { key: 'omd_requested', value: 'Requested by OMD', label: 'Requested by OMD' },
        ],
        SPECIAL_ATTENTION: 'Mention spéciale',
        RETINA_DM_AMD_ERM: 'Rétine (DM, AMD, ERM)',
        OTHER_TEXT: 'Autre',
        GLC_PARAMETERS_SET: 'Suivi GLC - Paramètres (P)',
        GLC_BORDERLINE_OBSERVE: 'Suivi GLC - Limite, à surveiller (LS,)',
        GLC_BORDERLINE_TREAT: 'Suivi GLC - Limite, à traiter (LT)',
        GLC_GOOD_DISC_GOOD_VF: 'GLC nouveau - disque normal, champ visuel normal',
        GLC_BAD_DISC_GOOD_VF: 'GLC nouveau - disque anormal, champ visuel normal',
        GLC_GOOD_DISC_BAD_VF: 'GLC nouveau - disque normal, champ visuel anormal',
        GLC_BAD_DISC_BAD_VF: 'GLC nouveau - disque anormal, champ visuel anormal',
        TOTAL_STANDARDIZED_POINTS: 'Total des points d\'examen standardisés',
        TOTAL_NUMBER_REVIEWS: 'Nombre total d\'évaluations',
        TOTAL_CHARTS_PRE_REVIEWED: 'Nombre de dossiers à réviser',
        OPTION_ALLOWS_GLCSUS: 'Cette option vous permet d\'ajouter SusGlc (Suspect pour Glaucome) comme diagnostic pour ce patient',

        // Patient edit form
        FNAME_PLACEHOLDER_TEXT: 'Entrer Prénom',
        LNAME_PLACEHOLDER_TEXT: 'Entrer Nom de famille',
        PHONE_PLACEHOLDER_TEXT: 'Entrer # téléphone',
        EMAIL_PLACEHOLDER_TEXT: 'Entrer adresse courriel',
        DOB_PLACEHOLDER_TEXT: 'Sélectionner la date de naissance',
        PROVINCE_PLACEHOLDER_TEXT: 'Choisir la province',
        STREET_PLACEHOLDER_TEXT: 'Indiquer l\'adresse',
        FIRST_NAME_LABEL_TEXT: 'Prénom',
        EMAIL_LABEL_TEXT: 'Adresse Courriel',
        GENDER_LABEL_TEXT: 'Sexe',
        GENDER_FEMALE_TEXT: 'Féminin',
        GENDER_MALE_TEXT: 'Masculin',
        GP2_FAX_NUMBER_LABEL_TEXT: '# Fax MD#2',
        ARMED_PERSONNEL_LABEL_TEXT: 'Numéro de service',
        CITY_LABEL_TEXT: 'Ville',
        STREET_LABEL_TEXT: 'Rue',
        GP2_LABEL_TEXT: 'GP#2',
        GP_LABEL_TEXT: 'GP',
        PHONE_LABEL_TEXT: 'Téléphone',
        PROVINCE_LABEL_TEXT: 'Province',
        LAST_NAME_LABEL_TEXT: 'Nom de famille',
        POSTAL_CODE_LABEL_TEXT: 'Code Postal',
        DOB_LABEL_TEXT: 'Date de naissance',
        GP_FAX_NUMBER_LABEL_TEXT: '# Fax GP',
        PLEASE_CONFIRM_LABEL_TEXT: 'SVP confirmer',
        REQUIRED_FIELDS_LABEL_TEXT: 'Required Fields',
        CITY_PLACEHOLDER_TEXT: 'Choisir Ville',
        POSTAL_CODE_PLACEHOLDER_TEXT: 'XXX XXX',
        GP_PLACEHOLDER_TEXT: 'Rechercher ou entrer GP',
        GP_FAX_PLACEHOLDER_TEXT: 'Entret # Fax GP',
        GP2_PLACEHOLDER_TEXT: 'Rechercher ou entrer GP',
        GP2_FAX_PLACEHOLDER_TEXT: 'Entret # Fax GP',
        FORCES_PLACEHOLDER_TEXT: 'Entrer numéro de service',
        GP_FAX_INFO_TEXT: `Le numéro de fax du GP permet à Soins1 d'envoyer les mises à jour des patients`,
        MD2_FAX_INFO_TEXT: 'Le numéro de fax du GP#2 permet à Soins1 d\'envoyer les mises à jour des patients',
        PLEASE_CONFIRM_LIST_TEXT: [
            { key: 1, value: 'Le pt désire que son GP soit informé de leur dossier.' },
            { key: 2, value: 'Le pt demande la collaboration d’un ophtalmologiste.' },
            { key: 3, value: 'Le pt consent à l\'utilisation de communications digitales pour discuter de son dossier, incluant les courriels.' },
            {
                key: 4,
                value: 'Le pt consent à ce que son dossier soit utilisé pour des présentations de\n'
                    + 'recherche médicale, pour l\'amélioration du programme et pour l\'avancement de \n'
                    + 'la médecine tant par Soins 1 ou des personnes/programmes/organisations à l\'extérieur \n' +
                    'de Soins 1.Tout ce qui permettrait l’identification du patient sera enlevé des données utilisées.',
            },
        ],
        PLEASE_ENSURE_HEADING_TEXT: 'Veuillez vous assurer que le numéro RAMQ du patient est entré correctement! ',
        ADD_PATIENT_CONFIRMATION_TEXT: 'New patient successfully added.',
        EDIT_PATIENT_CONFIRMATION_TEXT: 'New edits successfully added.',
        DEACTIVATE_PATIENT_CONFIRMATION_TEXT: 'Patient successfully deactivated.',
        ERROR_INVALID_PHONE_FORMAT: 'The phone number format is invalid',
        ERROR_INVALID_EMAIL_FORMAT: 'The email format is invalid',
        ERROR_INVALID_HN_FORMAT: 'The health number is invalid',
        ERROR_INVALID_POSTAL_CODE_FORMAT: 'The postal code is invalid',
        ERROR_PHN_ALREADY_EXISTS: 'Ce numéro RAMQ existe déjà',
        NEW_GP_TEXT: 'New GP',
        NEW_GP2_TEXT: 'New MD#2',
        MONTH_TEXT: 'Mois',
        WEEK_TEXT: 'Semaine',
        DAY_TEXT: 'Jour',
        YEAR_TEXT: 'An',

        // Deactivate Confirmation Modal
        DEACTIVATE_CONFIRMATION_MODAL_TITLE: 'Voulez-vous vraiment désactiver cet examen?',
        DEACTIVATE_CONFIRMATION_MODAL_TEXT: 'Vous ne pourrez pas réactiver cette option par la suite.',
        DEACTIVATE_CONFIRMATION_OK_TEXT: 'Oui',
        DEACTIVATE_CONFIRMATION_CANCEL_TEXT: 'Non',
        DEACTIVATE_PATIENT_CONFIRMATION_MODAL_TITLE: 'Voulez-vous vraiment désactiver ce patient?',


        // Patient list control bar
        PATIENT_GROUPING_OPTIONS: [
            { key: 'doctor', value: 'Mes Pts' },
            { key: 'clinic', value: 'Clinique Pts' },
        ],
        PATIENT_EXAM_URL: '/patient-exam',
        OPEN_SELECTED_VISITS: 'Ouvrir Visites sélectionnées',
        PATIENT_LIST_TITLE_TEXT: 'Liste des patients',
        PATIENT_LIST_SEARCH_PLACEHOLDER: 'Recherche par nom ou numéro d\'assurance-maladie…',
        EXAM_CREATED_DATE: 'Date de création de l\'examen',
        OD_PROGRAM_TEXT: 'Programme O.D.',
        UNFLAGGED_AND_READY_OMD: 'Non signalé + prêt pour l\'ophtalmologiste',
        FLAGGED_AND_READY_OMD: 'Signalé + prêt pour l\'ophtalmologiste',
        MISCELLANEOUS_TEXT: 'Divers',
        SEARCH_FOR_OMD: 'Chercher un ophtalmologiste',
        SEARCH_FOR_OD: 'Chercher un O.D.',
        SEARCH_FOR_GP_MD2: 'Chercher un médecin généraliste/médecin #2',
        USING_START_END_DATES: 'Avec dates de début/de fin',
        SELECT_BULK_ACTION: 'Sélectionner Action groupée',
        SET_OMDR: 'Set OMDR', // needs translation
        SET_OMDC: 'Set OMDC', // needs translation
        SET_IGP: 'Définir l\'interniste',
        GET_FILTERED_LIST: 'Obtenir une liste avec filtres de recherche',
        CLEAR_FILTERS: 'Effacer les Filtres',
        SEARCH_FOR_IGP: 'Chercher un interniste ',
        EXAM_VISIT_DATE: 'Date de visite de l\'examen',
        NEW_PATIENT_TEXT: 'Nouveau Patient',
        STATUS_FILTER_BUTTON_TEXT: 'Statut',
        LATEST_VISIT_TEXT: 'Dernière visite',
        EXAM_PERIOD_OPTIONS: [
            { key: 'all_time', value: 'All time', label: 'Tous' },
            { key: '1_week', value: 'Last 1 week', label: 'Dern 1 semaine' },
            { key: '1_month', value: 'Last 1 month', label: 'Dern 1 mois' },
            { key: '3_months', value: 'Last 3 months', label: 'Dern 3 mois' },
            { key: '6_months', value: 'Last 6 months', label: 'Dern 6 mois' },
            { key: '12_months', value: 'Last 12 months', label: 'Dern 12 mois' },
            { key: '12_18_months', value: 'Last 12~18 months', label: 'Dern 12~18 mois' },
            { key: '18_24_months', value: 'Last 18~24 months', label: 'Dern 18~24 mois' },
        ],
        EXAM_STATUS_TYPES: [
            { key: 'all_statuses', value: 'All Statuses', label: 'Tous les statuts' },
            { key: 'not_ready_od_review', value: 'Not Ready/OD to Review',
                label: 'Pas prêt/O.D. doit passer en revue', color: globalColors['redTagColor']},
            { key: 'not_ready', value: 'Not Ready', label: 'Pas prêt', color: globalColors['redTagColor'] },
            { key: 'od_review', value: 'OD to Review', label: 'OD doit passer en revue', color: globalColors['yellowTagColor'] },
            { key: 'ready', value: 'Ready for OMD', label: 'Prêt pour M.D.', color: globalColors['blueTagColor'] },
            { key: 'reviewed', value: 'OMD Reviewed', label: 'Examen effectué par M.D.', color: globalColors['greenTagColor'] }
        ],
        EXAM_STATUS_REFERRAL_LETTER_TYPES: [
            { key: 'all_statuses', value: 'All Statuses', label: 'Tous les statuts' },
            { key: 'not_ready', value: 'Not Ready', label: 'Pas soumis', color: globalColors['redTagColor'] },
            { key: 'od_review_ready', value: 'Submitted', label: 'Soumis', color: globalColors['yellowTagColor'] },
            { key: 'reviewed', value: 'Reviewed', label: 'Examen effectué', color: globalColors['greenTagColor'] }
        ],
        OPEN_SELECTED_VISITS_TOOLTIP: 'Le bloqueur de fenêtres contextuelles du navigateur doit être désactivé pour permettre l\'ouverture des 5 salles d\'examen.',
        ASKED_PATIENT_TEXT: 'Demandé Pt de prendre',
        EXAM_STATUS_OPTIONS_MAP: {
            'not_ready': 'Pas prêt',
            'od_review': 'OD doit passer en revue',
            'ready': 'Prêt pour M.D.',
            'reviewed': 'Examen effectué par M.D.',
        },

        GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES: [
            { key: 'needed', value: 'Needs Appt', label: 'Needs Appt' },
            { key: 'skipped', value: 'Skipped', label: 'Skipped' },
            { key: 'booked', value: 'Booked', label: 'Booked' },
        ],

        // Patient list table column headings text.
        FIRST_NAME_HEADING : 'Prénom',
        LAST_NAME_HEADING : 'Nom de famille',
        DOB_HEADING : 'Date de naissance',
        DOCTOR_HEADING : 'OD',
        CHIEF_COMPLAINT_HEADING : 'Motif de consultation',
        NUMBER_VISITS_HEADING : 'Visites',
        LAST_VISIT_HEADING : 'Dernière visite',
        LAST_REVIEWED_HEADING : 'Dernier examen',
        STATUS_HEADING : 'Statut',
        SELECT_HEADING : 'Sélectionner',
        USERNAME_TEXT: 'Nom d\'usager',
        PHONE_NUMBER_TEXT: 'Téléphone',
        REFERRAL_STATUS_HEADING: 'Referral Status',

        // Modal buttons
        EDIT_BUTTON_TEXT: 'Éditer',
        NEW_VISIT_BUTTON_TEXT: 'Nouvelle Visite',
        CANCEL_BUTTON_TEXT: 'Annuler',
        CLOSE_BUTTON_TEXT: 'Fermer',
        DELETE_BUTTON_TEXT: 'Effacer',
        GP_CONSULT_LETTER_TEXT: 'GP Consult',
        GP2_CONSULT_LETTER_TEXT: 'MD#2 Consult',
        VIEW_VISIT_TEXT: 'Voir',
        SUBMIT_BUTTON_TEXT: 'Soumettre',

        // Admin alerts list page
        ADMIN_ALERTS_OPEN_ALL: 'Ouvrir tout',
        ADMIN_ALERTS_NO_OMDR: 'Liste des patients sans Ophtalmologiste assigné:',
        ADMIN_ALERTS_WITH_OMDC: 'Liste des patients avec le statut de ophtalmo mais aucun ophtalmo assigné:',
        ADMIN_ALERTS_WITH_NO_OMDC_STATUS: 'Liste des patients auxquels la OMDC a été attribuée mais pas de statut de la OMDC:',
        ADMIN_ALERTS_MULTIPLE_EXAMS: 'Liste des patients avec plusieurs examens ayant un statut ophtalmo:',
        ADMIN_ALERTS_IPC_STATUS: 'Liste de patients avec statut CEP:',
        ADMIN_ALERTS_IPC_STATUS_REQUESTED_BY_OMD: 'List of patients with IPC status “Requested by OMD”:',
        ADMIN_ALERTS_PRIORITY_OD: 'Marqué comme haute priorité (attention urgente) par l\'optométriste:',
        ADMIN_ALERTS_URGENT_PREREVIEW: 'Urgent à pré-réviser (patient prêt pour Opht depuis 2 semaines mais pas encore été pre-révisé)',
        ADMIN_ALERTS_URGENT_REVIEW: 'Urgent à examen (patients prêts à recevoir un OMD il y a plus de 2 semaines et):',
        ADMIN_ALERTS_OMDC_6MONTHS: 'Référé à l\'ophtalmologiste, dernière visite il y a 6 mois:',
        ADMIN_ALERTS_BILLING_NOTES: 'Facturation passée date:',
        ADMIN_ALERTS_IOP_35: 'PIO supérieure à 35:',
        ADMIN_ALERTS_LIST_CONFLICTING: 'Liste de patients avec notes Opht contradictoires:',
        ADMIN_ALERTS_OMD_TIMESTAMP: 'Liste de patients qui sont Opht révisé mais sans date:',
        ADMIN_ALERTS_CRITICAL: 'Critique pré-réviser (patient avec le dernier examen pas pré-révisé et prêt pour Opht depuis 3 semaines ou ayant un examen sans pré-révisé prêt pour Opht qui n\'est pas l\'examen le plu récent):',

        // Login page
        LOGIN_TITLE_TEXT: 'Soin1',
        LOGIN_HEADING_TEXT: 'Des accélérateurs numériques pour votre clinique de soins de la vue.',
        LOGIN_INVITATION_SUBJECT: 'Demander une invitation',
        LOGIN_INVITATION_TEXT: 'Demandez une invitation à ',
        LOGIN_BANNER_RETINA_ENABLED_TITLE: 'Cliniques de rétine',
        LOGIN_BANNER_RETINA_ENABLED_SUBTITLE: 'Rétine Télémédecine',
        LOGIN_BANNER_DRY_EYE_ENABLED_TITLE: 'Cliniques de sécheresse oculaire',
        LOGIN_BANNER_DRY_EYE_ENABLED_SUBTITLE: 'Services premium de sécheresse oculaire',
        LOGIN_BANNER_INTEGRATED_CLINICS_TITLE: 'Cliniques intégrées',
        LOGIN_BANNER_OMD_CONSULTANT_TITLE: 'Consultant en ophtalmologiste',
        LOGIN_BANNER_OMD_REVIEWER_TITLE: 'Examinateur ophtalmologiste',
        LOGIN_BANNER_INTEGRATED_CLINICS_SUBTITLE: 'Glaucome et rétine Télémédecine',
        LOGIN_PLACEHOLDER_USERNAME: 'Nom d\'utilisateur',
        LOGIN_PLACEHOLDER_PASSWORD: 'Mot de passe',
        LOGIN_TEXT: 'Connexion',
        LOGIN_KEEP_ME: 'Rester connecté',
        LOGIN_FORGOT_PASSWORD: 'Mot de passe oublié?',
        LOGIN_RETURN_TO_PRODUCT_MENU_TEXT: 'Retour au menu produit',
        LOGIN_FOOTER_COMPANY_NAME: 'Soin1',
        LOGIN_CAROUSEL_AVOCADO_TITLE: 'Lentilles Avocado',
        LOGIN_CAROUSEL_AVOCADO_SUBTEXT: 'Plateforme de commande de lentilles cornéennes et programme d\'abonnement',
        LOGIN_CAROUSEL_RETINA_TITLE: 'Cliniques de rétine',
        LOGIN_CAROUSEL_RETINA_SUBTEXT: 'Collaboration télémédicale rétinienne pour les optométristes et les ophtalmologistes',
        LOGIN_CAROUSEL_DRY_EYE_TITLE: 'Cliniques compatibles avec la sécheresse oculaire',
        LOGIN_CAROUSEL_DRY_EYE_SUBTEXT: 'Programmes périopératoires pour cliniques de sécheresse oculaire',
        LOGIN_CAROUSEL_INTEGRATED_TITLE: 'Cliniques intégrées',
        LOGIN_CAROUSEL_INTEGRATED_SUBTEXT: 'Collaboration télémédicale rétinienne et glaucomateuse pour les optométristes et les ophtalmologistes',
        LOGIN_INVITATION_EMAIL_SUBJECT: 'Abonnement aux lentilles de contact Soin1',
        LOGIN_OR_TEXT: 'OU',
        LOGIN_MISSING_USER_MESSAGE: 'S\'il vous plaît entrer votre nom d\'utilisateur!',
        LOGIN_MISSING_PASSWORD_MESSAGE: 'S\'il vous plaît entrer votre mot de passe!',
        LOGIN_MIGRATION_ERROR_MESSAGE: 'L\'utilisateur n\'est pas activé pour ce service.',

        DOB_ERROR_MESSAGE: 'Date de naissance invalide. Veuillez revérifier les entrées et réessayer.',
        OPERATING_TEXT: 'en fonctionnement...',
        POSTAL_CODE_MASK: 'a9a 9a9',
        EDIT_PATIENT_TEXT: 'Modifier le patient',

        PATIENTEXAMGLAUCOMASCORE_SUBHEADING_TEXT: 'Score de glaucome du patient',
    }
});