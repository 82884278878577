import { LEFT_FUNDUS_FIELD, LEFT_OCT_MACULA_FIELD, LEFT_OCT_RNFL_FIELD, LEFT_VF_FIELD, RIGHT_FUNDUS_FIELD, RIGHT_OCT_MACULA_FIELD, RIGHT_OCT_RNFL_FIELD, RIGHT_VF_FIELD } from "../constants";
import { IDiagnosisListItem } from "../reducers/diagnosis-slice";
import { IAllExamImages, ICSVPhoto, IExamData, IExamMetrics, IExamsWithLeftOctRnfl, IExamsWithLeftVf, IExamsWithRightOctRnfl, IExamsWithRightVf, IExtraImage, IGraphBcva, IGraphIop, IGlaucomaScoreGraphData, IOmdReiewerOption, IOmdcTravelTime, IOptionItem, ISharedExamData } from "../reducers/patient-exam-slice";
import { getBackendMediaUrl } from "./media-image-convert";
import {  getDropzoneImageData, isRluIopHistoryEmpty, isRluOngoingEmpty, isRluUntilYesterdayEmpty } from "./patient-exam-convert";
import {  convertTimeToLongFormatLabel, convertUtcToLocal } from "./utilities";

// additional or different type definition compare to IExamData 
type OmitExamMetricsKeys = 'dtc_values';


export interface IExamDataResponse extends Omit<IExamMetrics, OmitExamMetricsKeys > {
    exam_date_utc: string;
    exam_is_retina_only: boolean;
    exam_is_perc: boolean;
    omd_history_values: string
    od_group_practice: number | null;
    od_group_practice_province: string;
    allergies_none: boolean;
    gp_refed: boolean;
    fu_letter: boolean;
    fu_number: string;
    other_omd: string;
    refer_in_person_omd_appt: string;
    cant_bill_oct: boolean;
    cant_bill_vf: boolean;
    iop_history_values: string;
    glc_past_drops_values: string;
    glc_current_drops_values: string;
    comorbidities_none: boolean;
    asthma: boolean;
    fhx: boolean;
    heart_block: boolean;
    uveitis_od: boolean;
    uveitis_os: boolean;
    hx_stroke: boolean;
    pxf_od: boolean;
    pxf_os: boolean;
    pds_od: boolean;
    pds_os: boolean;
    submission_date: string;
    optometrist_fullname: string;
    optometrist_odgrouppractice_name: string;
    optometrist_city: string;
    optometrist_province: string;
    optometrist_closest_omd_hours: string;
    optometrist_closest_omd_name: string;
    omdc_note_templates: number[];
    omd_note_string: string;
    omd_gp: string;
    flag: boolean;
    past_omd: boolean;
    cu_omd: boolean;
    ipc: string;
    od_req_omd: boolean;
    left_ght: string;
    right_ght: string;
    left_vf_md: string;
    right_vf_md: string;
    left_vf_psd: string;
    right_vf_psd: string;
    od_vf_analysis_vfi: string;
    os_vf_analysis_vfi: string;
    od_vf_analysis_vqi: string;
    os_vf_analysis_vqi: string;
    od_rnfl_analysis_avg: string;
    od_rnfl_analysis_sup: string;
    od_rnfl_analysis_inf: string;
    os_rnfl_analysis_avg: string;
    os_rnfl_analysis_sup: string;
    os_rnfl_analysis_inf: string;
    left_octav: string;
    left_octav_sup: string;
    left_octav_inf: string;
    right_octav: string;
    right_octav_sup: string;
    right_octav_inf: string;
    history_and_exam: string;
    selected_omd_note_options: number[];
    second_omdr: null | number;
    omd_textbox0: string;
    is_same_region: boolean;
    omdctraveltime: IOmdcTravelTime[];
    omdc: number | null;
    right_cd: string;
    left_cd: string;
    gp_letter_sent_date: string;
    is_gp_letter_sent_date_older_than_11_months: boolean;
    is_glaucoma: boolean;
    susp_features: string[];
    reas_features: string[];
    od_vf_override: string;
    os_vf_override: string;
    od_iop_aim: string;
    patient_status: string;
    followup_aim: string;
    os_iop_aim: string;
    prereview_alert: [];
    billing_addressed: boolean;
    od_question: boolean;
    second_omdr_reviewed: boolean;
    os_treatment_iop_zone: string | null;
    od_treatment_iop_zone: string | null;
    treatment_fu_zone: string;
    od_start_drops: boolean;
    auto_patient_status: boolean;
    last_exam_auto_patient_status: boolean;
    od_adb: string;
    os_adb: string;
    cpt: string;
    icd_10: string;
    omdr_auto_planned_billing_code: string;
    omdr_manual_planned_billing_code: string;
    omdc_auto_planned_billing_code: string;
    omdc_manual_planned_billing_code: string;
    od_is_integrated: boolean;
    dhx_od: boolean;
    dhx_os: boolean;
    od_baseline: boolean;
    exam_boct: string;
    exam_bfield: string;
    glc_type:string;
    prev_exams_have_glc_base: boolean;
    last_omd_gp: string;
    last_exam_omd_textbox0: string;
    last_omd_od: string;
    pre_reviewer_values: string;
    last_prereview_date: string;
    exam_prereview_date_after_cutoff: boolean;
    omdr_set_glc_suspect: boolean;
    is_inactive: boolean;
    exam_is_us_only: boolean;
    id: number | null;
    version: number;
    rr_glc: boolean;
    rr_glc_suspect: boolean;
    rr_dm: boolean;
    rr_amd: boolean;
    rr_erm: boolean;
    rr_others: string;
    rr_optic_nerve: boolean;
    rr_narrow_angles: boolean;
    rr_cataract: boolean;
    rr_cat_post_op: boolean;
    pmh_m: string;
    allergies: string;
    e_notes: string;
    od_omd: string;
    od_proposed_plan: string;
    omd_od: string;
    diagnostics_values: string;
    exam_status: string;
    exam_status_options: IOptionItem[];
    exam_date: string;
    is_urgent: boolean;
    visit_number: number;
    exam_count: number;
    omdr: string;
    optometrist: number | null;
    retina_only_omd_note_templates: number[];
    patient: number | null;
    eye_select_options: IOptionItem[];
    omdc_proposed_plan: string;
    billing_note: string;
    dtc_values: string;
    exam_status_od_allowed: string[];
    exam_status_od_can_set: string[];
    omdc_status: string;
    csv_left_switched: boolean;
    csv_right_switched: boolean;
    right_oct_autoset: boolean;
    left_oct_autoset: boolean;
    initials_select: string;
    left_fundus_photo: string;
    left_stereo_photo: string;
    left_oct_photo: string;
    left_vf_photo: string;
    right_fundus_photo: string;
    right_stereo_photo: string;
    right_oct_photo: string;
    right_vf_photo: string;
    referral_letter: string;
    left_oct_rnfl_photo: string;
    right_oct_rnfl_photo: string;
    right_vf_10_2_photo: string;
    left_vf_10_2_photo: string;
    right_oct_angle_photo: string;
    left_oct_angle_photo: string;
    right_gcc_photo: string;
    left_gcc_photo: string;
    csv_left_fundus: ICSVPhoto;
    csv_right_fundus: ICSVPhoto;
    csv_left_stereo: ICSVPhoto;
    csv_right_stereo: ICSVPhoto;
    optometrist_options: IOmdReiewerOption[];
    omd_reviewer_options: IOmdReiewerOption[];
    omd_reviewer: number | null;
    exams_with_left_oct: IAllExamImages[];
    exams_with_right_oct: IAllExamImages[];
    exams_with_left_fundus: IAllExamImages[];
    exams_with_right_fundus: IAllExamImages[];
    retina_only_omd_note_templates_options: {id: number, name: string}[];
    diagnosis_list: IDiagnosisListItem[];
    extra_images: IExtraImage[];
    external_gp_status: string;
    internal_gp_status: string;
    internal_gp: string;
    gp_referral_date: string;
    manual_gp_referral_date: string;
    past_od_pachs: string;
    past_os_pachs: string;
    past_od_iop: string;
    past_os_iop: string;
    past_od_ishihara: string;
    past_od_ishihara2: string;
    past_os_ishihara: string;
    past_os_ishihara2: string;
    past_od_cd: string;
    past_os_cd: string;
    past_od_bcva: string;
    past_os_bcva: string;
    past_od_sphere: string;
    past_os_sphere: string;
    past_od_cylinder: string;
    past_os_cylinder: string;
    past_od_axis: string;
    past_os_axis: string;
    past_applanation: string;
    past_dtc_hour: string;
    past_dtc_values: string;
    past_fhx: boolean,
    past_pxf_od: boolean,
    past_pxf_os: boolean,
    past_pds_od: boolean,
    past_pds_os: boolean,
    past_allergies: string,
    past_allergies_none: boolean,
    past_glc_past_drops_values: string,
    past_glc_current_drops_values: string;
    past_iop_history_values: string;
    past_left_fundus_photo: string;
    past_left_stereo_photo: string;
    past_left_oct_photo: string;
    past_left_oct_rnfl_photo: string;
    past_left_vf_photo: string;
    past_left_fundus_photo_date: string;
    past_left_stereo_photo_date: string;
    past_left_oct_photo_date: string;
    past_left_oct_rnfl_photo_date: string;
    past_left_vf_photo_date: string;
    past_right_fundus_photo: string;
    past_right_stereo_photo: string;
    past_right_oct_photo: string;
    past_right_oct_rnfl_photo: string;
    past_right_vf_photo: string;
    past_right_fundus_photo_date: string;
    past_right_stereo_photo_date: string;
    past_right_oct_photo_date: string;
    past_right_oct_rnfl_photo_date: string;
    past_right_vf_photo_date: string;
    past_right_vf_10_2_photo: string;
    past_right_vf_10_2_photo_date: string;
    past_left_vf_10_2_photo: string;
    past_left_vf_10_2_photo_date: string;
    past_right_oct_angle_photo: string;
    past_right_oct_angle_photo_date: string;
    past_left_oct_angle_photo: string;
    past_left_oct_angle_photo_date: string;
    past_right_gcc_photo: string;
    past_right_gcc_photo_date: string;
    past_left_gcc_photo: string;
    past_left_gcc_photo_date: string;
    past_right_cd: string;
    past_left_cd: string;
    past_od_iop_aim: string;
    past_os_iop_aim: string;
    past_patient_status: string;
    past_followup_aim: string;
    exams: ISharedExamData[];
    exams_with_left_oct_rnfl: IExamsWithLeftOctRnfl[];
    exams_with_right_oct_rnfl: IExamsWithRightOctRnfl[];
    exams_with_left_vf: IExamsWithLeftVf[];
    exams_with_right_vf: IExamsWithRightVf[];
    graph_iop: IGraphIop | null;
    graph_bcva: IGraphBcva | null;
    glaucoma_score_graph_data: IGlaucomaScoreGraphData[];
    error?: string;
    od_referral_letter: string;
    impression: string;
    exam_od_uses_referral_letter_pei: boolean;
    gpt_saved_values: string;
    gpt_near_miss_fields: string;
    gpt_response: string;
    od_gpt_vf_ght_saved_values: string;
    od_gpt_vf_ght_near_miss: boolean;
    os_gpt_vf_ght_saved_values: string;
    os_gpt_vf_ght_near_miss: boolean;
    od_gpt_rnfl_saved_values: string;
    od_gpt_rnfl_near_miss: boolean;
    os_gpt_rnfl_saved_values: string;
    os_gpt_rnfl_near_miss: boolean;
    is_referral_letter_upload_pei: boolean;
    is_oct_rnfl_od_category_near_miss: boolean;
    is_oct_rnfl_os_category_near_miss: boolean;
    is_vf_od_category_near_miss: boolean;
    is_vf_os_category_near_miss: boolean;
    highlight_omd_pdf: string;
    od_uses_rlu_pei: boolean;
    recipient_removed_pdf: string;
    assign_first: boolean;
    exam_od_essentials_only: boolean;
    exam_od_orientating: string | boolean;
    rlu_exam_is_premium: boolean;
    exam_od_has_pei_toggle: boolean;
    right_ml_drusen_segmentation_background_photo: string;
    left_ml_drusen_segmentation_background_photo: string;
    right_ml_drusen_segmentation_overlay_photo: string;
    left_ml_drusen_segmentation_overlay_photo: string;
    right_ml_micro_aneurysms_overlay_photo: string;
    left_ml_micro_aneurysms_overlay_photo: string;
    right_ml_hard_exudates_overlay_photo: string;
    left_ml_hard_exudates_overlay_photo: string;
    right_ml_disc_hemorrhage_overlay_photo: string;
    left_ml_disc_hemorrhage_overlay_photo: string;
    admin_private_notes_addressed: boolean;
    admin_private_notes: string;
    is_review_processed: boolean;
    gpt_ai_snapshot_band_impressions: string[];
    gpt_ai_snapshot_band_od_assessment: string;
    gpt_ai_snapshot_band_od_question: string;
    right_ml_drusen_segmentation_value: string;
    left_ml_drusen_segmentation_value: string;
    right_ml_drusen_segmentation_succeeded: boolean;
    left_ml_drusen_segmentation_succeeded: boolean;
    right_ml_micro_aneurysms_value: string;
    left_ml_micro_aneurysms_value: string;
    right_ml_micro_aneurysms_succeeded: boolean;
    left_ml_micro_aneurysms_succeeded: boolean;
    right_ml_micro_aneurysms_model_version: string;
    left_ml_micro_aneurysms_model_version: string;
    right_ml_micro_aneurysms_run_at: string;
    left_ml_micro_aneurysms_run_at: string;
    right_ml_hard_exudates_value: string;
    left_ml_hard_exudates_value: string;
    right_ml_hard_exudates_succeeded: boolean;
    left_ml_hard_exudates_succeeded: boolean;
    hide_exam_for_od: boolean;
    is_od_messaging_submission: boolean;
    past_od_referral_letter: string;
    past_od_referral_letter_date: string;
    past_highlight_omd_pdf: string;
    past_recipient_removed_pdf: string;
    is_fu_unknown: boolean;
    is_outbound_referral: boolean;
    cat_refer: boolean | null;
    referral_omd: number | null;
    ai_recommends_omd_report: boolean;
    exam_omd_is_re: boolean;
    second_opinion_teleconsult: number | null;
    od_wants_omd_report: boolean;
}

export const convertBackendExamData = (currentState: IExamData, data: IExamDataResponse): void => {
    const state = currentState;

    const mediaUrl = getBackendMediaUrl();

    state.operating = false;
    state.id = data.id;

    // Photos
    state.photoUrls.right_fundus_photo = data.right_fundus_photo ? mediaUrl + data.right_fundus_photo : '';
    state.photoUrls.right_stereo_photo = data.right_stereo_photo ? mediaUrl + data.right_stereo_photo : '';
    state.photoUrls.right_oct_photo = data.right_oct_photo ? mediaUrl + data.right_oct_photo : '';
    state.photoUrls.right_oct_rnfl_photo = data.right_oct_rnfl_photo ? mediaUrl + data.right_oct_rnfl_photo : '';
    state.photoUrls.right_vf_photo = data.right_vf_photo ? mediaUrl + data.right_vf_photo : '';
    state.photoUrls.right_vf_10_2_photo = data.right_vf_10_2_photo ? mediaUrl + data.right_vf_10_2_photo : '';
    state.photoUrls.right_oct_angle_photo = data.right_oct_angle_photo ? mediaUrl + data.right_oct_angle_photo : '';
    state.photoUrls.right_gcc_photo = data.right_gcc_photo ? mediaUrl + data.right_gcc_photo : '';
    
    state.photoUrls.left_fundus_photo = data.left_fundus_photo ? mediaUrl + data.left_fundus_photo : '';
    state.photoUrls.left_stereo_photo = data.left_stereo_photo ? mediaUrl + data.left_stereo_photo : '';
    state.photoUrls.left_oct_photo = data.left_oct_photo ? mediaUrl + data.left_oct_photo : '';
    state.photoUrls.left_oct_rnfl_photo = data.left_oct_rnfl_photo ? mediaUrl + data.left_oct_rnfl_photo : '';
    state.photoUrls.left_vf_photo = data.left_vf_photo ? mediaUrl + data.left_vf_photo : '';
    state.photoUrls.left_vf_10_2_photo = data.left_vf_10_2_photo ? mediaUrl + data.left_vf_10_2_photo : '';
    state.photoUrls.left_oct_angle_photo = data.left_oct_angle_photo ? mediaUrl + data.left_oct_angle_photo : '';
    state.photoUrls.left_gcc_photo = data.left_gcc_photo ? mediaUrl + data.left_gcc_photo : '';

    state.photoUrls.right_ml_drusen_segmentation_background_photo = data.right_ml_drusen_segmentation_background_photo ? mediaUrl + data.right_ml_drusen_segmentation_background_photo : '';
    state.photoUrls.left_ml_drusen_segmentation_background_photo = data.left_ml_drusen_segmentation_background_photo ? mediaUrl + data.left_ml_drusen_segmentation_background_photo : '';

    state.photoUrls.right_ml_drusen_segmentation_overlay_photo = data.right_ml_drusen_segmentation_overlay_photo ? mediaUrl + data.right_ml_drusen_segmentation_overlay_photo : '';
    state.photoUrls.left_ml_drusen_segmentation_overlay_photo = data.left_ml_drusen_segmentation_overlay_photo ? mediaUrl + data.left_ml_drusen_segmentation_overlay_photo : '';

    state.photoUrls.right_ml_micro_aneurysms_overlay_photo = data.right_ml_micro_aneurysms_overlay_photo ? mediaUrl + data.right_ml_micro_aneurysms_overlay_photo : '';
    state.photoUrls.left_ml_micro_aneurysms_overlay_photo = data.left_ml_micro_aneurysms_overlay_photo ? mediaUrl + data.left_ml_micro_aneurysms_overlay_photo : '';

    state.photoUrls.right_ml_hard_exudates_overlay_photo = data.right_ml_hard_exudates_overlay_photo ? mediaUrl + data.right_ml_hard_exudates_overlay_photo : '';
    state.photoUrls.left_ml_hard_exudates_overlay_photo = data.left_ml_hard_exudates_overlay_photo ? mediaUrl + data.left_ml_hard_exudates_overlay_photo : '';

    state.photoUrls.right_ml_disc_hemorrhage_overlay_photo = data.right_ml_disc_hemorrhage_overlay_photo ? mediaUrl + data.right_ml_disc_hemorrhage_overlay_photo : '';
    state.photoUrls.left_ml_disc_hemorrhage_overlay_photo = data.left_ml_disc_hemorrhage_overlay_photo ? mediaUrl + data.left_ml_disc_hemorrhage_overlay_photo : '';

    // Past Photos
    state.pastPhotos.past_right_fundus_photo = data.past_right_fundus_photo ? mediaUrl + data.past_right_fundus_photo : '';
    state.pastPhotos.past_right_stereo_photo = data.past_right_stereo_photo ? mediaUrl + data.past_right_stereo_photo : '';
    state.pastPhotos.past_right_oct_photo = data.past_right_oct_photo ? mediaUrl + data.past_right_oct_photo : '';
    state.pastPhotos.past_right_oct_rnfl_photo = data.past_right_oct_rnfl_photo ? mediaUrl + data.past_right_oct_rnfl_photo : '';
    state.pastPhotos.past_right_vf_photo = data.past_right_vf_photo ? mediaUrl + data.past_right_vf_photo : '';
    state.pastPhotos.past_right_vf_10_2_photo = data.past_right_vf_10_2_photo ? mediaUrl + data.past_right_vf_10_2_photo : '';
    state.pastPhotos.past_right_oct_angle_photo = data.past_right_oct_angle_photo ? mediaUrl + data.past_right_oct_angle_photo : '';
    state.pastPhotos.past_right_gcc_photo = data.past_right_gcc_photo ? mediaUrl + data.past_right_gcc_photo : '';

    state.pastPhotos.past_right_fundus_photo_date = data.past_right_fundus_photo_date ? data.past_right_fundus_photo_date : '';
    state.pastPhotos.past_right_stereo_photo_date = data.past_right_stereo_photo_date ? data.past_right_stereo_photo_date : '';
    state.pastPhotos.past_right_oct_photo_date = data.past_right_oct_photo_date ? data.past_right_oct_photo_date : '';
    state.pastPhotos.past_right_oct_rnfl_photo_date = data.past_right_oct_rnfl_photo_date ? data.past_right_oct_rnfl_photo_date : '';
    state.pastPhotos.past_right_vf_photo_date = data.past_right_vf_photo_date ? data.past_right_vf_photo_date : '';
    state.pastPhotos.past_right_vf_10_2_photo_date = data.past_right_vf_10_2_photo_date ? data.past_right_vf_10_2_photo_date : '';
    state.pastPhotos.past_right_oct_angle_photo_date = data.past_right_oct_angle_photo_date ? data.past_right_oct_angle_photo_date : '';
    state.pastPhotos.past_right_gcc_photo_date = data.past_right_gcc_photo_date ? data.past_right_gcc_photo_date : '';

    state.pastPhotos.past_left_fundus_photo = data.past_left_fundus_photo ? mediaUrl + data.past_left_fundus_photo : '';
    state.pastPhotos.past_left_stereo_photo = data.past_left_stereo_photo ? mediaUrl + data.past_left_stereo_photo : '';
    state.pastPhotos.past_left_oct_photo = data.past_left_oct_photo ? mediaUrl + data.past_left_oct_photo : '';
    state.pastPhotos.past_left_oct_rnfl_photo = data.past_left_oct_rnfl_photo ? mediaUrl + data.past_left_oct_rnfl_photo : '';
    state.pastPhotos.past_left_vf_photo = data.past_left_vf_photo ? mediaUrl + data.past_left_vf_photo : '';
    state.pastPhotos.past_left_vf_10_2_photo = data.past_left_vf_10_2_photo ? mediaUrl + data.past_left_vf_10_2_photo : '';
    state.pastPhotos.past_left_oct_angle_photo = data.past_left_oct_angle_photo ? mediaUrl + data.past_left_oct_angle_photo : '';
    state.pastPhotos.past_left_gcc_photo = data.past_left_gcc_photo ? mediaUrl + data.past_left_gcc_photo : '';

    state.pastPhotos.past_left_fundus_photo_date = data.past_left_fundus_photo_date ? data.past_left_fundus_photo_date : '';
    state.pastPhotos.past_left_stereo_photo_date = data.past_left_stereo_photo_date ? data.past_left_stereo_photo_date : '';
    state.pastPhotos.past_left_oct_photo_date = data.past_left_oct_photo_date ? data.past_left_oct_photo_date : '';
    state.pastPhotos.past_left_oct_rnfl_photo_date = data.past_left_oct_rnfl_photo_date ? data.past_left_oct_rnfl_photo_date : '';
    state.pastPhotos.past_left_vf_photo_date = data.past_left_vf_photo_date ? data.past_left_vf_photo_date : '';
    state.pastPhotos.past_left_vf_10_2_photo_date = data.past_left_vf_10_2_photo_date ? data.past_left_vf_10_2_photo_date : '';
    state.pastPhotos.past_left_oct_angle_photo_date = data.past_left_oct_angle_photo_date ? data.past_left_oct_angle_photo_date : '';
    state.pastPhotos.past_left_gcc_photo_date = data.past_left_gcc_photo_date ? data.past_left_gcc_photo_date : '';

    state.extra_images = data.extra_images;

    state.od_referral_letter = data.od_referral_letter ? mediaUrl + data.od_referral_letter : '';

    state.exams_with_right_oct = getDropzoneImageData(RIGHT_OCT_MACULA_FIELD, data.exams_with_right_oct);
    state.exams_with_right_oct_rnfl = getDropzoneImageData(RIGHT_OCT_RNFL_FIELD, data.exams_with_right_oct_rnfl);
    state.exams_with_right_fundus = getDropzoneImageData(RIGHT_FUNDUS_FIELD, data.exams_with_right_fundus);
    state.exams_with_right_vf = getDropzoneImageData(RIGHT_VF_FIELD, data.exams_with_right_vf);
    state.exams_with_left_oct = getDropzoneImageData(LEFT_OCT_MACULA_FIELD, data.exams_with_left_oct);
    state.exams_with_left_oct_rnfl = getDropzoneImageData(LEFT_OCT_RNFL_FIELD, data.exams_with_left_oct_rnfl);
    state.exams_with_left_fundus = getDropzoneImageData(LEFT_FUNDUS_FIELD, data.exams_with_left_fundus);
    state.exams_with_left_vf = getDropzoneImageData(LEFT_VF_FIELD, data.exams_with_left_vf);

    state.highlight_omd_pdf = data.highlight_omd_pdf ? mediaUrl + data.highlight_omd_pdf : '';
    state.recipient_removed_pdf = data.recipient_removed_pdf ? mediaUrl + data.recipient_removed_pdf : '';

    // Diagnostics
    state.rr_glc = data.rr_glc;
    state.rr_glc_suspect = data.rr_glc_suspect;
    state.rr_dm = data.rr_dm;
    state.rr_amd = data.rr_amd;
    state.rr_erm = data.rr_erm;
    state.rr_optic_nerve = data.rr_optic_nerve;
    state.rr_narrow_angles = data.rr_narrow_angles;
    state.rr_cataract = data.rr_cataract;
    state.rr_cat_post_op = data.rr_cat_post_op;
    state.rr_others = data.rr_others ?? '';
    state.glcNotSelectedByOD = data.rr_glc_suspect === false;
    // The following diagnostics come from the diagnostics values field, which is contained as one JSON.
    if (data.diagnostics_values) {
        const diagnosticsJSON = JSON.parse(data.diagnostics_values);
        state.ped = diagnosticsJSON.ped;
        state.macular_hole = diagnosticsJSON.macular_hole;
        state.nevus = diagnosticsJSON.nevus;
        state.plaquenil = diagnosticsJSON.plaquenil;
        state.rao = diagnosticsJSON.rao;
        state.vmt = diagnosticsJSON.vmt;
        state.nevus = diagnosticsJSON.nevus;
        state.diagnostics_values = data.diagnostics_values;
    }

    // Exam Metrics
    state.bcva_options = data.bcva_options;
    state.od_bcva = data.od_bcva ? data.od_bcva : 'ND';
    state.os_bcva = data.os_bcva ? data.os_bcva : 'ND';
    
    state.od_sphere = data.od_sphere ?? '';
    state.od_cylinder = data.od_cylinder ?? '';
    state.od_axis = data.od_axis ?? '';
    state.os_sphere = data.os_sphere ?? '';
    state.os_cylinder = data.os_cylinder ?? '';
    state.os_axis = data.os_axis ?? '';
    
    state.pmh_m = data.pmh_m ?? '';
    state.history_and_exam = data.history_and_exam ?? '';

    state.exam_status_options = data.exam_status_options;
    state.exam_status = data.exam_status ?? '';
    state.saved_exam_status = data.exam_status ?? '';
    state.exam_status_previous = data.exam_status ?? '';

    // Patient Data

    // Convert the exam date from UTC to local time. We use the full date time for this.
    state.exam_date = data.exam_date_utc ? convertUtcToLocal(data.exam_date_utc) : '';
    state.is_urgent = data.is_urgent;
    state.visit_number = data.visit_number;
    state.exam_count = data.exam_count;
    state.patient = data.patient;
    state.submission_date = data.submission_date ?? '';
    state.optometrist_odgrouppractice_name = data.optometrist_odgrouppractice_name ?? '';
    state.optometrist_fullname = data.optometrist_fullname ?? '';
    state.optometrist_city = data.optometrist_city ?? '';
    state.optometrist_province = data.optometrist_province ?? '';
    state.optometrist_closest_omd_hours = data.optometrist_closest_omd_hours ?? '';
    state.optometrist_closest_omd_name = data.optometrist_closest_omd_name ?? '';
    state.allergies = data.allergies ?? '';
    state.allergies_none = data.allergies ? false : true;
    state.gp_refed = data.gp_refed;
    state.referral_letter = data.referral_letter;
    state.fu_letter = data.fu_letter ? 'week' : 'month';
    state.fu_number = !data.fu_number || data.fu_number === 'null' ? '' : data.fu_number;
    state.other_omd = data.other_omd;
    state.cant_bill_oct = data.cant_bill_oct;
    state.cant_bill_vf = data.cant_bill_vf;
    state.refer_in_person_omd_appt = data.refer_in_person_omd_appt;
    state.e_notes = data.e_notes ?? '';
    state.od_omd = data.od_omd ?? '';
    state.optometrist_options = data.optometrist_options;
    state.optometrist = data.optometrist;
    state.omd_od = data.omd_od ?? '';

    state.impression = data.impression ?? '';

    state.exams = data.exams.map((exam: ISharedExamData) => ({
        ...exam,
        exam_date: exam.exam_date_utc ? convertUtcToLocal(exam.exam_date_utc) : '',
        label: convertTimeToLongFormatLabel(exam.exam_date),
    }))

    state.graph_iop = data.graph_iop;
    state.graph_bcva = data.graph_bcva;
    state.glaucoma_score_graph_data = data.glaucoma_score_graph_data;

    if(data.omd_history_values) {
        state.omd_history = {
            ...state.omd_history,
            values: JSON.parse(data.omd_history_values)
        }
    }
    if(data.iop_history_values) {
        const iopHistoryData = JSON.parse(data.iop_history_values);

        const iopHistoryValues = Array.isArray(iopHistoryData) ? 
            iopHistoryData.map((iopHistoryItem) => ({
                ...iopHistoryItem,
                // handle cases when iop history drops select is string instead of array (usually on very old iop history data)
                iop_history_drops_select: iopHistoryItem && !Array.isArray(iopHistoryItem.iop_history_drops_select) &&
                    typeof iopHistoryItem.iop_history_drops_select === 'string' ?
                    iopHistoryItem.iop_history_drops_select.split(',') :
                    iopHistoryItem.iop_history_drops_select
            })) : [];
        
        state.iop_history = {
            ...state.iop_history,
            values: iopHistoryValues,
        }
    }

    if(data.glc_past_drops_values) {
        state.until_yesterday = {
            ...state.until_yesterday,
            values: JSON.parse(data.glc_past_drops_values)
        }
    }
    if(data.glc_current_drops_values) {
        state.ongoing = {
            ...state.ongoing,
            values: JSON.parse(data.glc_current_drops_values)
        }
    }

    state.od_group_practice = data.od_group_practice;
    state.od_group_practice_province = data.od_group_practice_province;
    
    state.retina_only_omd_note_templates_options = data.retina_only_omd_note_templates_options;
    state.retina_only_omd_note_templates = data.retina_only_omd_note_templates;

    state.omdc = data.omdc ? data.omdc : null;
    state.exam_status_od_allowed = data.exam_status_od_allowed;
    state.exam_status_od_can_set = data.exam_status_od_can_set;
    state.omdr = data.omdr ?? '';

    state.diagnosis_list = data.diagnosis_list;
    state.eye_select_options = data.eye_select_options;

    if (data.dtc_values){
        state.dtc_values = JSON.parse(data.dtc_values);
    }
    state.dtc_hour = data.dtc_hour ?? '';

    state.od_cd = !data.od_cd || data.od_cd === 'None' ? '' : data.od_cd;
    state.os_cd = !data.os_cd || data.os_cd === 'None' ? '' : data.os_cd;

    state.od_pachs = data.od_pachs ?? '';
    state.os_pachs = data.os_pachs ?? '';
    state.past_od_pachs = data.past_od_pachs ?? '';
    state.past_os_pachs = data.past_os_pachs ?? '';
    state.past_od_iop = data.past_od_iop ?? '';
    state.past_os_iop = data.past_os_iop ?? '';
    state.past_od_ishihara = data.past_od_ishihara ?? '';
    state.past_od_ishihara2 = data.past_od_ishihara2 ?? '';
    state.past_os_ishihara = data.past_os_ishihara ?? '';
    state.past_os_ishihara2 = data.past_os_ishihara2 ?? '';
    state.past_od_cd = data.past_od_cd ?? '';
    state.past_os_cd = data.past_os_cd ?? '';
    state.past_od_bcva = data.past_od_bcva ?? '';
    state.past_os_bcva = data.past_os_bcva ?? '';
    state.past_od_sphere = data.past_od_sphere ?? '';
    state.past_os_sphere = data.past_os_sphere ?? '';
    state.past_od_cylinder = data.past_od_cylinder ?? '';
    state.past_os_cylinder = data.past_os_cylinder ?? '';
    state.past_od_axis = data.past_od_axis ?? '';
    state.past_os_axis = data.past_os_axis ?? '';
    state.past_applanation = data.past_applanation ?? '';

    if (data.past_dtc_values){
        state.past_dtc_values = JSON.parse(data.past_dtc_values);
    }
    state.past_dtc_hour = data.past_dtc_hour ?? '';

    state.past_fhx = data.past_fhx;
    state.past_pxf_od = data.past_pxf_od;
    state.past_pxf_os = data.past_pxf_os;
    state.past_pds_od = data.past_pds_od;
    state.past_pds_os = data.past_pds_os;
    state.past_allergies = data.past_allergies ?? '';
    state.past_allergies_none = data.past_allergies_none;

    if(data.past_glc_past_drops_values) {
        state.past_until_yesterday = {
            ...state.past_until_yesterday,
            values: JSON.parse(data.past_glc_past_drops_values),
        }

        // For OD messaging exams, also use the past values as current values.
        if (state.is_od_messaging_submission && isRluUntilYesterdayEmpty(state.until_yesterday)) {
            state.until_yesterday = {
                ...state.until_yesterday,
                values: JSON.parse(data.past_glc_past_drops_values),
            }
        }
    }

    if(data.past_glc_current_drops_values) {
        state.past_ongoing = {
            ...state.past_ongoing,
            values: JSON.parse(data.past_glc_current_drops_values),
        }

        // For OD messaging exams, also use the past values as current values.
        if (state.is_od_messaging_submission && isRluOngoingEmpty(state.ongoing)) {
            state.ongoing = {
                ...state.ongoing,
                values: JSON.parse(data.past_glc_current_drops_values),
            }
        }
    }

    if(data.past_iop_history_values) {
        state.past_iop_history = {
            ...state.past_iop_history,
            values: JSON.parse(data.past_iop_history_values),
        }

        // For OD messaging exams, also use the past values as current values.
        if (state.is_od_messaging_submission && isRluIopHistoryEmpty(state.iop_history)) {
            state.iop_history = {
                ...state.iop_history,
                values: JSON.parse(data.past_iop_history_values),
            }
        }
    }

    state.right_cd = data.right_cd ?? '';
    state.left_cd = data.left_cd ?? '';
    state.past_right_cd = data.past_right_cd ?? '';
    state.past_left_cd = data.past_left_cd ?? '';

    state.od_ishihara = data.od_ishihara ?? '';
    state.os_ishihara = data.os_ishihara ?? '';
    state.od_ishihara2 = data.od_ishihara2 ?? '';
    state.os_ishihara2 = data.os_ishihara2 ?? '';

    state.od_iop = data.od_iop ?? '';
    state.os_iop = data.os_iop ?? '';
    state.applanation = !data.applanation || data.applanation === 'undefined' ? '' : data.applanation;

    state.omdc_proposed_plan = data.omdc_proposed_plan ?? '';
    state.billing_note = data.billing_note ?? '';
    state.billing_note_cache = data.billing_note;
    state.old_billing_note = data.billing_note ?? '';
    state.billing_addressed = data.billing_addressed;

    state.exam_is_retina_only = data.exam_is_retina_only;
    state.exam_is_perc = data.exam_is_perc;

    state.comorbidities_none = data.comorbidities_none;
    state.asthma = data.asthma;
    state.fhx = data.fhx;
    state.heart_block = data.heart_block;
    state.uveitis_od = data.uveitis_od;
    state.uveitis_os = data.uveitis_os;
    state.hx_stroke = data.hx_stroke;
    state.pxf_od = data.pxf_od;
    state.pxf_os = data.pxf_os;
    state.pds_od = data.pds_od;
    state.pds_os = data.pds_os;

    state.omdc_note_templates = data.omdc_note_templates;
    state.omd_note_string = data.omd_note_string;
    state.omd_gp = data.omd_gp;

    state.omdc_status = data.omdc_status;
    state.is_pre_reviewed = data.flag;

    state.past_omd = data.past_omd;
    state.cu_omd = data.cu_omd;
    state.ipc = data.ipc;
    state.od_req_omd = data.od_req_omd;

    // Selections used to determine VF and RNFL OCT Dropzone border colors
    state.left_ght = data.left_ght;
    state.right_ght = data.right_ght;
    state.left_vf_md = data.left_vf_md;
    state.right_vf_md = data.right_vf_md;
    state.left_vf_psd = data.left_vf_psd;
    state.right_vf_psd = data.right_vf_psd;
    state.od_vf_analysis_vfi = data.od_vf_analysis_vfi;
    state.os_vf_analysis_vfi = data.os_vf_analysis_vfi;
    state.od_vf_analysis_vqi = data.od_vf_analysis_vqi;
    state.os_vf_analysis_vqi = data.os_vf_analysis_vqi;
    state.od_rnfl_analysis_avg = data.od_rnfl_analysis_avg;
    state.od_rnfl_analysis_sup = data.od_rnfl_analysis_sup;
    state.od_rnfl_analysis_inf = data.od_rnfl_analysis_inf;
    state.os_rnfl_analysis_avg = data.os_rnfl_analysis_avg;
    state.os_rnfl_analysis_sup = data.os_rnfl_analysis_sup;
    state.os_rnfl_analysis_inf = data.os_rnfl_analysis_inf;
    state.left_octav = data.left_octav;
    state.left_octav_sup = data.left_octav_sup;
    state.left_octav_inf = data.left_octav_inf;
    state.left_oct_autoset = data.left_oct_autoset;
    state.right_octav = data.right_octav;
    state.right_octav_sup = data.right_octav_sup;
    state.right_octav_inf = data.right_octav_inf;
    state.right_oct_autoset = data.right_oct_autoset;

    // Values needed to display and position the CSV stereo viewer
    state.csv_left_fundus =  data.csv_left_fundus ?? '';
    state.csv_right_fundus =  data.csv_right_fundus ?? '';
    state.csv_left_stereo = data.csv_left_stereo ?? '';
    state.csv_right_stereo = data.csv_right_stereo ?? '';
    state.csv_left_switched = data.csv_left_switched || false;
    state.csv_right_switched = data.csv_right_switched || false;

    state.selected_omd_note_options = data.selected_omd_note_options;
    state.second_omdr = data.second_omdr;
    state.omd_textbox0 = data.omd_textbox0;

    state.prereview_alert = data.prereview_alert;

    // set the selected_omd_note_options and Retina Referral value
    if (data.selected_omd_note_options) {
        let selected_options = data.selected_omd_note_options;
        state.selected_omd_note_options = selected_options;
        const RETINA_REFERRAL_OPTIONAL_ID = 59
        const RETINA_REFERRAL_NEEDED_ID = 60
        if (selected_options && selected_options.includes(RETINA_REFERRAL_OPTIONAL_ID)){
            state.retina_referral = 'optional';
        }
        if (selected_options && selected_options.includes(RETINA_REFERRAL_NEEDED_ID)){
            state.retina_referral = 'needed';
        }

    } else {
        state.selected_omd_note_options = []
    }

    state.is_same_region = data.is_same_region;

    state.omdctraveltime = data.omdctraveltime;

    state.gp_letter_sent_date = data.gp_letter_sent_date;
    state.is_gp_letter_sent_date_older_than_11_months = data.is_gp_letter_sent_date_older_than_11_months;

    state.is_glaucoma = data.is_glaucoma;
    state.susp_features = data.susp_features;
    state.reas_features = data.reas_features;

    state.od_vf_override = data.od_vf_override;
    state.os_vf_override = data.os_vf_override;
    state.od_iop_aim = data.od_iop_aim;
    state.patient_status = data.patient_status;
    state.followup_aim = data.followup_aim;
    state.os_iop_aim = data.os_iop_aim;
    state.past_od_iop_aim = data.past_od_iop_aim;
    state.past_os_iop_aim = data.past_os_iop_aim;
    state.past_patient_status = data.past_patient_status;
    state.past_followup_aim = data.past_followup_aim;
    state.odDiagnosedGlaucoma = data.is_glaucoma;

    state.od_question = data.od_question;
    state.second_omdr_reviewed = data.second_omdr_reviewed;
    state.os_treatment_iop_zone = data.os_treatment_iop_zone;
    state.od_treatment_iop_zone = data.od_treatment_iop_zone;
    state.treatment_fu_zone = data.treatment_fu_zone;

    state.od_start_drops = data.od_start_drops;
    state.auto_patient_status = data.auto_patient_status;
    state.last_exam_auto_patient_status = data.last_exam_auto_patient_status;
    state.od_adb = data.od_adb;
    state.os_adb = data.os_adb;
    state.cpt = data.cpt;
    state.icd_10 = data.icd_10;

    state.external_gp_status = data.external_gp_status === '' ? null : data.external_gp_status;
    state.internal_gp = data.internal_gp === '' ? null : data.internal_gp;
    state.internal_gp_status = data.internal_gp_status === '' ? null : data.internal_gp_status;

    state.omdr_auto_planned_billing_code = data.is_outbound_referral ? 'no_billing_code' : data.omdr_auto_planned_billing_code;
    state.omdr_manual_planned_billing_code = data.is_outbound_referral ? 'no_billing_code' : data.omdr_manual_planned_billing_code;
    state.omdc_auto_planned_billing_code = data.is_outbound_referral ? 'no_billing_code' : data.omdc_auto_planned_billing_code;
    state.omdc_manual_planned_billing_code = data.is_outbound_referral ? 'no_billing_code' : data.omdc_manual_planned_billing_code;
    state.od_is_integrated = data.od_is_integrated;
    state.dhx_od = data.dhx_od;
    state.dhx_os = data.dhx_os;
    state.od_baseline = data.od_baseline;

    state.omd_reviewer = data.omd_reviewer;
    state.omd_reviewer_options = data.omd_reviewer_options ?? [];
                                        
    state.gp_referral_date = data.gp_referral_date;
    state.manual_gp_referral_date = data.manual_gp_referral_date;

    state.initials_select = data.initials_select;
    state.exam_boct = data.exam_boct;
    state.exam_bfield = data.exam_bfield;
    state.glc_type = data.glc_type;
    state.prev_exams_have_glc_base = data.prev_exams_have_glc_base;
    state.last_omd_gp = data.last_omd_gp;
    state.last_exam_omd_textbox0 = data.last_exam_omd_textbox0;
    state.last_omd_od = data.last_omd_od;

    state.pre_reviewer_values = data.pre_reviewer_values;
    state.last_prereview_date = data.last_prereview_date;
    state.exam_prereview_date_after_cutoff = data.exam_prereview_date_after_cutoff;
    state.omdrSetGlcSusDiagnosis = data.omdr_set_glc_suspect;
    state.is_inactive = data.is_inactive;
    state.exam_is_us_only = data.exam_is_us_only;

    state.exam_od_uses_referral_letter_pei = data.exam_od_uses_referral_letter_pei;

    state.gpt_saved_values = data.gpt_saved_values;
    state.gpt_near_miss_fields = data.gpt_near_miss_fields;
    state.gpt_response = data.gpt_response;

    state.od_gpt_vf_ght_saved_values = data.od_gpt_vf_ght_saved_values;
    state.od_gpt_vf_ght_near_miss = data.od_gpt_vf_ght_near_miss;

    state.os_gpt_vf_ght_saved_values = data.os_gpt_vf_ght_saved_values;
    state.os_gpt_vf_ght_near_miss = data.os_gpt_vf_ght_near_miss;

    state.od_gpt_rnfl_saved_values = data.od_gpt_rnfl_saved_values;
    state.od_gpt_rnfl_near_miss = data.od_gpt_rnfl_near_miss;

    state.os_gpt_rnfl_saved_values = data.os_gpt_rnfl_saved_values;
    state.os_gpt_rnfl_near_miss = data.os_gpt_rnfl_near_miss;

    state.is_referral_letter_upload_pei = data.is_referral_letter_upload_pei;
    state.is_oct_rnfl_od_category_near_miss = data.is_oct_rnfl_od_category_near_miss;
    state.is_oct_rnfl_os_category_near_miss = data.is_oct_rnfl_os_category_near_miss;
    state.is_vf_od_category_near_miss = data.is_vf_od_category_near_miss;
    state.is_vf_os_category_near_miss = data.is_vf_os_category_near_miss;

    state.od_uses_rlu_pei = data.od_uses_rlu_pei;
    state.assign_first = data.assign_first;
    state.exam_od_essentials_only = data.exam_od_essentials_only;
    state.exam_od_orientating = data.exam_od_orientating === 'True';
    state.version = data.version;
    state.rlu_exam_is_premium = data.rlu_exam_is_premium;
    state.exam_od_has_pei_toggle = data.exam_od_has_pei_toggle;
    state.admin_private_notes_addressed = data.admin_private_notes_addressed;
    state.admin_private_notes = data.admin_private_notes;
    state.is_review_processed = data.is_review_processed;

    state.gpt_ai_snapshot_band_impressions = data.gpt_ai_snapshot_band_impressions;
    state.gpt_ai_snapshot_band_od_assessment = data.gpt_ai_snapshot_band_od_assessment;
    state.gpt_ai_snapshot_band_od_question = data.gpt_ai_snapshot_band_od_question;

    state.right_ml_drusen_segmentation_value = data.right_ml_drusen_segmentation_value;
    state.left_ml_drusen_segmentation_value = data.left_ml_drusen_segmentation_value;
    state.right_ml_micro_aneurysms_value = data.right_ml_micro_aneurysms_value;
    state.left_ml_micro_aneurysms_value = data.left_ml_micro_aneurysms_value;
    state.right_ml_hard_exudates_value = data.right_ml_hard_exudates_value;
    state.left_ml_hard_exudates_value = data.left_ml_hard_exudates_value;
    
    state.right_ml_drusen_segmentation_succeeded = data.right_ml_drusen_segmentation_succeeded;
    state.left_ml_drusen_segmentation_succeeded = data.left_ml_drusen_segmentation_succeeded;
    state.right_ml_micro_aneurysms_succeeded = data.right_ml_micro_aneurysms_succeeded;
    state.left_ml_micro_aneurysms_succeeded = data.left_ml_micro_aneurysms_succeeded;
    state.right_ml_hard_exudates_succeeded = data.right_ml_hard_exudates_succeeded;
    state.left_ml_hard_exudates_succeeded = data.left_ml_hard_exudates_succeeded;
    
    state.right_ml_micro_aneurysms_model_version = data.right_ml_micro_aneurysms_model_version;
    state.left_ml_micro_aneurysms_model_version = data.left_ml_micro_aneurysms_model_version;
    state.right_ml_micro_aneurysms_run_at = data.right_ml_micro_aneurysms_run_at;
    state.left_ml_micro_aneurysms_run_at = data.left_ml_micro_aneurysms_run_at;

    state.hide_exam_for_od = data.hide_exam_for_od;

    state.is_od_messaging_submission = data.is_od_messaging_submission;
    state.past_od_referral_letter = data.past_od_referral_letter ? mediaUrl + data.past_od_referral_letter : '';
    state.past_od_referral_letter_date = data.past_od_referral_letter_date;
    state.past_highlight_omd_pdf = data.past_highlight_omd_pdf ? mediaUrl + data.past_highlight_omd_pdf : '';
    state.past_recipient_removed_pdf = data.past_recipient_removed_pdf ? mediaUrl + data.past_recipient_removed_pdf : '';
    state.is_fu_unknown = data.is_fu_unknown;
    
    state.is_outbound_referral = data.is_outbound_referral;
    state.referral_omd = data.referral_omd;
    state.cat_refer = data.cat_refer;
    state.ai_recommends_omd_report = data.ai_recommends_omd_report;
    state.exam_omd_is_re = data.exam_omd_is_re;
    state.second_opinion_teleconsult = data.second_opinion_teleconsult;
    state.od_wants_omd_report = data.od_wants_omd_report;
}