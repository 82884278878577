import { useEffect, useState } from 'react';
import { Table, Input, Modal, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { localizedText } from '../../localizedText';
import '../../../static/css/components/patient-list-table-glaucoma-program.scss';

// Component imports
import GlaucomaProgramBookingStatusSelect from './glaucoma-program-booking-status-select';

// Action imports
import { IGlaucomaProgramPatientListItem
    } from '../../reducers/patient-list-slice';

// Helper imports
import { compareStrings, sortDates } from '../../helpers/sorting';
import { getOptionsRequest } from '../../reducers/options-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { useEditGlaucomaProgramMutation } from '../../services/glaucoma-program-api';
import { getGlaucomaProgramIopClassName, getGlaucomaProgramCdClassName, getGlaucomaProgramVFClassName } from '../../helpers/patient-list-convert';

const GlaucomaProgramPatientListTable = ({ tableList } : { tableList: IGlaucomaProgramPatientListItem[]}) => {
    const { FIRST_NAME_HEADING, LAST_NAME_HEADING, CHIEF_COMPLAINT_HEADING, LAST_VISIT_HEADING,
        OF_TEXT, ITEMS_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();
    const [editGlaucomaProgram] = useEditGlaucomaProgramMutation();

    const [ scrollClass, setScrollClass ] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // Make requests
        dispatch(getOptionsRequest());

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const onEditComments = async (newValue: string, record: IGlaucomaProgramPatientListItem) => {
        try {
            if (newValue !== record.comments) {
                // we use the editGlaucomaProgram function after the form is complete and submit/save button is clicked
                // unwrap() gives the access to error or success payload
                const result = await editGlaucomaProgram({exam_id: record.exam_id, booking_status: record.booking_status,
                                                        comments: newValue}).unwrap();
                if (result.success) {
                    Modal.info({
                        className: 'info-modal',
                        title: 'Glaucoma program updated successfully.',
                    });
                } else {
                    throw new Error(result?.error);
                }
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const onEditBookingStatus = async (newValue: string, record: IGlaucomaProgramPatientListItem) => {
        try {
            if (newValue !== record.booking_status) {
                // we use the editGlaucomaProgram function after the form is complete and submit/save button is clicked
                // unwrap() gives the access to error or success payload
                const result = await editGlaucomaProgram({exam_id: record.exam_id, booking_status: newValue,
                                                        comments: record.comments}).unwrap();
                if (result.success) {
                    Modal.info({
                        className: 'info-modal',
                        title: 'Glaucoma program updated successfully.',
                    });
                } else {
                    throw new Error(result?.error);
                }
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const columns: ColumnsType<IGlaucomaProgramPatientListItem> = [{
        className: 'first-name-column',
        title: FIRST_NAME_HEADING,
        dataIndex: 'first_name',
        sorter: (a, b) => compareStrings(a.first_name, b.first_name),
        render: (text, record) => <div data-testid={`first-name-${record.key}`}>{text}</div>
    }, {
        className: 'last-name-column',
        title: LAST_NAME_HEADING,
        dataIndex: 'last_name',
        sorter: (a, b) => compareStrings(a.last_name, b.last_name),
        render: (text, record) => <div data-testid={`last-name-${record.key}`}>{text}</div>
    }, {
        className: 'complaint-column',
        title: CHIEF_COMPLAINT_HEADING,
        dataIndex: 'chief_complaint',
        sorter: (a, b) => compareStrings(a.chief_complaint, b.chief_complaint),
        render: (text, record) => <div data-testid={`chief-complaint-${record.key}`}>{text}</div>
    }, {
        className: 'last-visit-column',
        title: LAST_VISIT_HEADING,
        dataIndex: 'last_visit',
        sorter: (a, b) => {
            const firstDate = a.last_visit ? a.last_visit : '';
            const secondDate = b.last_visit ? b.last_visit : '';
            return sortDates(firstDate, secondDate);
        },
        render: (text, record) => <div data-testid={`last-visit-${record.key}`}>{text}</div>
    }, {
        className: 'suggested-next-visit-column',
        title: 'Sugg Next Visit',
        dataIndex: 'next_suggested_visit',
        sorter: (a, b) => {
            const firstDate = a.next_suggested_visit ? a.next_suggested_visit : '';
            const secondDate = b.next_suggested_visit ? b.next_suggested_visit : '';
            return sortDates(firstDate, secondDate);
        },
        render: (text, record) => <div data-testid={`suggested-next-visit-${record.key}`}>{text}</div>
    }, {
        className: 'testing-needed-column',
        title: 'Testing Needed',
        dataIndex: 'testing_needed',
        sorter: (a, b) => compareStrings(a.testing_needed, b.testing_needed),
        render: (text, record) => <div data-testid={`testing-needed-${record.key}`}>{text}</div>
    }, {
        className: 'testing-frequency-column',
        title: 'Testing Freq',
        dataIndex: 'testing_frequency',
        sorter: (a, b) => compareStrings(a.testing_frequency, b.testing_frequency),
        render: (text, record) => <div data-testid={`testing-frequency-${record.key}`}>{text}</div>
    }, {
        className: 'booking-status-column',
        title: 'Booking Status',
        dataIndex: 'booking_status',
        sorter: (a, b) => compareStrings(a.booking_status, b.booking_status),
        render: (text, record) => <GlaucomaProgramBookingStatusSelect className="patient-exam-status-tag" bookingStatusKey={record.booking_status}
                                    onEditStatus={(value) => {
                                        onEditBookingStatus(value, record);
                                    }} />,
    }, {
        className: 'comments-column',
        title: 'Comments',
        dataIndex: 'comments',
        sorter: (a, b) => compareStrings(a.comments, b.comments),
        render: (text, record) => <div data-testid={`comments-${record.key}`}>
                                    <Tooltip placement="bottom" title={record.comments} >
                                        <Input
                                            className="glaucoma-program-comment-input"
                                            placeholder={'Type a note...'}
                                            defaultValue={record.comments}
                                            onBlur={(event) => {
                                                onEditComments(event.target.value, record);
                                            }}
                                            onPressEnter={(event) => {
                                                onEditComments(event.currentTarget.value, record);
                                            }}
                                        />
                                    </Tooltip>
                                </div>
    }, {
        className: 'iop-column',
        title: 'IOP',
        dataIndex: 'iop',
        render: (data) => (
            <>
                <span className={getGlaucomaProgramIopClassName(data.od_iop)}>{data.od_iop ? data.od_iop : ''}</span>
                <br />
                <span className={getGlaucomaProgramIopClassName(data.os_iop)}>{data.os_iop ? data.os_iop : ''}</span>
            </>
        ),
    }, {
        className: 'cd-column',
        title: 'C/D',
        dataIndex: 'cd',
        render: (data) => (
            <>
                <span className={getGlaucomaProgramCdClassName(data.od_cd)}>{data.od_cd ? data.od_cd : ''}</span>
                <br />
                <span className={getGlaucomaProgramCdClassName(data.os_cd)}>{data.os_cd ? data.os_cd : ''}</span>
            </>
        ),
    }, {
        className: 'vf-column',
        title: 'VF',
        dataIndex: 'vf',
        render: (data) => (
            <>
                <span className={getGlaucomaProgramVFClassName(data.od_vf)}>{data.od_vf ? data.od_vf.toUpperCase() : ''}</span>
                <br />
                <span className={getGlaucomaProgramVFClassName(data.os_vf)}>{data.os_vf ? data.os_vf.toUpperCase() : ''}</span>
            </>
        ),
    }];

    return (
        <div className={'glaucoma-program-patient-list-table'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={tableList}
                />
            </div>
        </div>
    );
}

export default GlaucomaProgramPatientListTable;
