import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

// Components
import PDFViewer from "../../retina/pdf-viewer";
import GptPatientEditForm from './gpt-patient-edit-form';
import GptPatientDemographicsChat from './gpt-patient-demographics-chat';
import GptPatientDemographicsVision from './gpt-patient-demographics-vision';

// Actions
import { getEditPatientData } from '../../../reducers/patient-edit-slice';
import { useGetGpsByProvinceQuery } from '../../../services/doctor-api';

// Helpers
import { formatGPName } from '../../../helpers/patient-edit-convert';

import '../../../../static/css/components/admin-gpt.scss';
import '../../../../static/css/pages/admin-patient-exam-page.scss';

import { CAF_SERVICE_NUMBER_FIELD, CITY_FIELD, DOB_DAY_FIELD, 
    DOB_MONTH_FIELD, DOB_YEAR_FIELD, EMAIL_FIELD, FIRST_NAME_FIELD,
    GP_CURRENT_FIELD, GP_FAX_NUMBER_FIELD, HAS_NO_PHN_FIELD, IS_CAF_PERSONNEL_FIELD, 
    LAST_NAME_FIELD, PHN_FIELD, PHONE_FIELD,
    POSTAL_CODE_FIELD, PROVINCE_FIELD, SECONDARY_PHN_FIELD, STREET_FIELD, IS_ON_OLD_OHIP_FIELD,
    GENDER_FIELD, PHN_NAME_FIELD, SECONDARY_PHN_NAME_FIELD, SECONDARY_PHN_REQUIRED_FIELD,
    PHN_VALIDATION_FORMAT_FIELD, SECONDARY_PHN_VALIDATION_FORMAT_FIELD, FRONTEND_LONG_DATE_FORMAT } from '../../../constants';
import { IGptPatientEditData } from '../../../reducers/gpt-patient-demographics-slice';

const GptPatientDemographics = () => {
    const dispatch = useCare1AppDispatch();
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const apiStatus = useCare1AppSelector(store => store.referralLetterAnalysis.api_status);
    const currentPhotoURL = useCare1AppSelector(store => store.examData.od_referral_letter);
    const patientId = useCare1AppSelector(store => store.examData.patient);

    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // patient edit form field values
    const firstName = useCare1AppSelector(store => store.patientEdit.details[FIRST_NAME_FIELD]);
    const lastName = useCare1AppSelector(store => store.patientEdit.details[LAST_NAME_FIELD]);
    const gender = useCare1AppSelector(store => store.patientEdit.details[GENDER_FIELD]);
    const phone = useCare1AppSelector(store => store.patientEdit.details[PHONE_FIELD]);
    const email = useCare1AppSelector(store => store.patientEdit.details[EMAIL_FIELD]);
    const dobDayValue = useCare1AppSelector(store => store.patientEdit.details[DOB_DAY_FIELD]);
    const dobMonthValue = useCare1AppSelector(store => store.patientEdit.details[DOB_MONTH_FIELD]) || undefined;
    const dobYearValue = useCare1AppSelector(store => store.patientEdit.details[DOB_YEAR_FIELD]);
    const patientDOB = `${dobMonthValue} ${dobDayValue}, ${dobYearValue}`;

    // When province is empty string, set it to null so that placeholder value is displayed
    const province = useCare1AppSelector(store => store.patientEdit.details[PROVINCE_FIELD]) || undefined;
    const PHN = useCare1AppSelector(store => store.patientEdit.details[PHN_FIELD]);
    const secondPHN = useCare1AppSelector(store => store.patientEdit.details[SECONDARY_PHN_FIELD]);
    const street = useCare1AppSelector(store => store.patientEdit.details[STREET_FIELD]);
    const city = useCare1AppSelector(store => store.patientEdit.details[CITY_FIELD]);
    const postalCode = useCare1AppSelector(store => store.patientEdit.details[POSTAL_CODE_FIELD]);
    const hasNoGp = useCare1AppSelector(store => store.patientEdit.details.has_no_gp);
    const examDate = useCare1AppSelector(store => store.examData.exam_date);
    const gpId = useCare1AppSelector(store => store.patientEdit.details[GP_CURRENT_FIELD]) || undefined;
    let gpFax = useCare1AppSelector(store => store.patientEdit.details[GP_FAX_NUMBER_FIELD]);
    const isTestPatient = useCare1AppSelector(store => store.patientEdit.details['is_test_patient']);
    const CAFServiceNumber = useCare1AppSelector(store => store.patientEdit.details[CAF_SERVICE_NUMBER_FIELD]);
    const isCAF = useCare1AppSelector(store => store.patientEdit.details[IS_CAF_PERSONNEL_FIELD]);
    const hasNoPHN = useCare1AppSelector(store => store.patientEdit.details[HAS_NO_PHN_FIELD]);
    const isOldOHIP = useCare1AppSelector(store => store.patientEdit.details[IS_ON_OLD_OHIP_FIELD]);
    const PHNLabel = useCare1AppSelector(store => store.patientEdit.details[PHN_NAME_FIELD]);
    const PHNSecondaryLabel = useCare1AppSelector(store => store.patientEdit.details[SECONDARY_PHN_NAME_FIELD]);
    const PHNSecondaryRequired = useCare1AppSelector(store => store.patientEdit.details[SECONDARY_PHN_REQUIRED_FIELD]);
    const PHNValidationFormat = useCare1AppSelector(store => store.patientEdit.details[PHN_VALIDATION_FORMAT_FIELD]);
    const PHNSecondaryValidationFormat = useCare1AppSelector(store => store.patientEdit.details[SECONDARY_PHN_VALIDATION_FORMAT_FIELD]);
    const gptVisionPatientDemographicsData = useCare1AppSelector(store => store.gptPatientDemographics.gpt_vision_patient_demographics_data);
    const gptChatPatientDemographicsData = useCare1AppSelector(store => store.gptPatientDemographics.gpt_chat_patient_demographics_data);
    const odProvince = useCare1AppSelector(store => store.user.odProvince);
    const optometristProvince = useCare1AppSelector(store => store.examData.optometrist_province);
    const provinceCode = userIsADMIN ? optometristProvince : odProvince;

    const {data : gpList =[], isFetching} = useGetGpsByProvinceQuery(provinceCode, {skip: !provinceCode });

    let gpObj = null;
    if (gpId) {
        gpObj = gpList.find((entry) => entry.id === gpId);
        if (gpObj) {
            gpFax = gpObj.fax_number;
        }
    }

    const gp = gpObj ? formatGPName(gpObj) : '';

    const convertGptPatientDemographicsDataToProps = (gptData: IGptPatientEditData) => {
        const province = provinces.find(province => province.code === gptData.province);
        const provinceId = province ? province.id : null;

        return {
            firstName: gptData.first_name,
            lastName: gptData.last_name,
            gender: gptData.gender,
            phone: gptData.primary_phone,
            email: gptData.email,
            province: provinceId,
            PHN: gptData.phn,
            secondPHN: gptData.secondary_phn,
            street: gptData.address,
            city: gptData.city,
            postalCode: gptData.postal_code,
            gp: gptData.gp,
            gpFax: gptData.fax_number,
            CAFServiceNumber: gptData.service_number,
            isCAF: gptData.caf_personnel,
            hasNoPHN: gptData.has_no_phn,
            patientDOB: gptData.patient_dob,
            examDate: gptData.exam_date,
            isOldOHIP: gptData.is_on_old_ohip,
            userIsADMIN, hasNoGp, isTestPatient, PHNLabel,
            PHNSecondaryLabel, PHNSecondaryRequired, PHNValidationFormat, PHNSecondaryValidationFormat
        };
    }

    const dataStoreProps = {
        examDate: dayjs(examDate).format(FRONTEND_LONG_DATE_FORMAT),
        firstName, lastName, gender, phone, email, province,
        PHN, secondPHN, street, city, postalCode, gp, gpFax, CAFServiceNumber, isCAF,
        hasNoPHN, isTestPatient, userIsADMIN, hasNoGp, patientDOB,
        isOldOHIP, PHNLabel, PHNSecondaryLabel, PHNSecondaryRequired, PHNValidationFormat, PHNSecondaryValidationFormat
    };

    const gptChatProps = convertGptPatientDemographicsDataToProps(gptChatPatientDemographicsData);
    const gptVisionProps = convertGptPatientDemographicsDataToProps(gptVisionPatientDemographicsData);

    useEffect(() => {
        dispatch(getEditPatientData(patientId!));
    }, [dispatch, patientId]);

    const onPDFLoadSuccess = (numPages: number) => {
    }

    const onPDFLoadError = () => {
    }

    const onClose = () => {
    }

    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={apiStatus === 'loading' || isFetching}
        >
            <div className="patient-demographics-container">
                <Row>
                    <Col
                        span={12}
                        className={'pdfViewerModal no-overflow padding-col'}>
                        <PDFViewer
                            file={currentPhotoURL.replace('png', 'pdf')}
                            onPDFLoadSuccess={onPDFLoadSuccess}
                            onPDFLoadError={onPDFLoadError}
                            onClose={onClose}
                            isGPTTab={true}
                        />
                    </Col>
                    <Col span={12}>
                        <span className='patient-demographics-data'>From Database:</span>
                        <GptPatientEditForm patientID={patientId} {...dataStoreProps} />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <span className='patient-demographics-data'>From GPT Chat:</span>
                        <GptPatientEditForm patientID={patientId} {...gptChatProps} />
                    </Col>
                    <Col span={12}>
                        <span className='patient-demographics-data'>From GPT Vision:</span>
                        <GptPatientEditForm patientID={patientId} {...gptVisionProps} />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <GptPatientDemographicsChat />
                    </Col>
                    <Col span={12}>
                        <GptPatientDemographicsVision />
                    </Col>
                </Row>
            </div>
        </Spin>)
}

export default GptPatientDemographics;