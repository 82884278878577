// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { fetchPatientValidationChatPrompt } from '../../../reducers/patient-validation-slice';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';
import { convertResponseToPatientDemographicsData } from '../../../reducers/gpt-patient-demographics-slice';


interface Message {
    input: string;
    output: string;
    meta: string;
}

export interface PatientValidationType {
    right_fundus_photo: number | null;
    left_fundus_photo: number | null;
    right_stereo_photo: number | null;
    left_stereo_photo: number | null;
    right_vf_photo: number | null;
    left_vf_photo: number | null;
    right_oct_rnfl_photo: number | null;
    left_oct_rnfl_photo: number | null;
    right_oct_photo: number | null;
    left_oct_photo: number | null;
    unclassified?: number[] | null;
}

export interface PatientDemographicsType {
    [k: string]: any;
}

type ComponentProps = {
    examId: number | null,
    onChatStatusChanged: (loading: boolean) => void,
    rightFundus: string,
    leftFundus: string,
    rightStereoFundus: string,
    leftStereoFundus: string,
    rightVfPhoto: string,
    leftVfPhoto: string,
    rightOctRnflPhoto: string,
    leftOctRnflPhoto: string,
    rightOCTMacula: string,
    leftOCTMacula: string,
    onImageIdentifierChatResult: (image_urls: string[], output: PatientValidationType) => void,
    referralLetter: string,
    onPatientDemographicsChatResult: (output: PatientDemographicsType) => void,
}


const PatientValidationChat = ({ examId, onChatStatusChanged,
    rightFundus, leftFundus, rightStereoFundus, leftStereoFundus,
    rightVfPhoto, leftVfPhoto, rightOctRnflPhoto, leftOctRnflPhoto,
    rightOCTMacula, leftOCTMacula, onImageIdentifierChatResult,
    referralLetter, onPatientDemographicsChatResult }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [imageIdentifierMessage, setImageIdentifierMessage] = useState<string>("");
    const [patientDemographicsMessage, setPatientDemographicsMessage] = useState<string>("");
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const handleSocketOnMessage = (text: string) => {
        let output = {};
        let base64ImageList = {};
        let meta = {};
        try {
            const obj = JSON.parse(text);
            output = obj['message']['output'];
            base64ImageList = obj['message']['base64_image_list'];
            meta = `${obj['message']['meta']}`;

            setIsAssistantTyping(false);
            onChatStatusChanged(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting upload reconciler output',
            });
        };

        try {
            if ('image_identifier' in output) {

                const outputStr: string = `${output['image_identifier']}`;

                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const outputObj = JSON.parse(jsonResponse);

                    if ('image_identifier' in base64ImageList && Array.isArray(base64ImageList['image_identifier'])) {
                        onImageIdentifierChatResult([...base64ImageList['image_identifier']], outputObj);
                    }

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }

            if ('patient_demographics' in output) {

                const outputStr: string = `${output['patient_demographics']}`;

                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const outputObj = JSON.parse(jsonResponse);

                    onPatientDemographicsChatResult(convertResponseToPatientDemographicsData(outputObj));

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors parsing upload reconciler output',
            });
        };
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/upload_reconciler/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        if (examId !== null) {
            handleReset();
        }
    }, [examId]);


    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            const image_urls: string[] = [];
            if (rightFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightFundus));
            }
            if (leftFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftFundus));
            }
            if (rightStereoFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightStereoFundus));
            }
            if (leftStereoFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftStereoFundus));
            }
            if (rightVfPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightVfPhoto));
            }
            if (leftVfPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftVfPhoto));
            }
            if (rightOctRnflPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightOctRnflPhoto));
            }
            if (leftOctRnflPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftOctRnflPhoto));
            }
            if (rightOCTMacula !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightOCTMacula));
            }
            if (leftOCTMacula !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftOCTMacula));
            }

            const referral_letter_urls: string[] = [];
            referral_letter_urls.push(getFilepathFromBackendMediaUrl(referralLetter));

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'exam_id': examId,
                    'image_identifier': {
                        'input': imageIdentifierMessage,
                        'image_urls': image_urls,
                    },
                    'patient_demographics': {
                        'input': patientDemographicsMessage,
                        'image_urls': referral_letter_urls,
                    },
                    'history': []
                }
            }));

            setIsAssistantTyping(true);
            onChatStatusChanged(true);
        }
    }

    const handleReset = async () => {

        if (examId === null) {
            return
        }

        try {
            const result = await dispatch(fetchPatientValidationChatPrompt(examId)).unwrap();
            if (result && result.success) {
                setImageIdentifierMessage(result.gpt_image_identifier_chat_prompts);
                setPatientDemographicsMessage(result.gpt_patient_demographics_chat_prompts);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting upload reconciler prompts',
            });
        }
    }

    return (
        <div className="chat-container patient-validation-chat">
            <Row>
                <Col span={24}>
                    <div className="chat-button">
                        <Button
                            className={''}
                            onClick={sendMessage}
                            type="primary"
                            size="large"
                        >
                            Test Upload
                        </Button>

                    </div>
                </Col>
            </Row>
        </div>)
}

export default PatientValidationChat;
