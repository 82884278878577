import { useEffect } from 'react';
import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';
import { Card, Radio, Form, Row, Col, Typography, Select, Button } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { useFormValidation } from '../../context/useFormValidationContext'
import { localizedText } from '../../localizedText';
import '../../../static/css/components/patient-exam-billing-rules.scss';
import { CARE1_UPLOAD_TEXT, OUTBOUNT_REFERRAL_TEXT } from '../../constants';
import PatientExamBillingRulesReferralOmd from './patient-exam-billing-rules-referral-omd';


const PatientExamBillingRulesReferralLetter = ({ disabled }: { disabled: boolean }) => {
    const { NO_TEXT, YES_TEXT} = localizedText;

    const isUrgent = useCare1AppSelector(store => store.examData.is_urgent);
    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);
    const catRefer = useCare1AppSelector(store => store.examData.cat_refer);
    const referralOmd = useCare1AppSelector(store => store.examData.referral_omd);
    const odWantsOmdReport = useCare1AppSelector(store => store.examData.od_wants_omd_report);
    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const { setBillingRulesForm } = useFormValidation();

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = !isOutboundReferral ? {
            is_urgent: isUrgent,
            is_outbound_referral: isOutboundReferral,
            od_wants_omd_report: odWantsOmdReport
        } : {
            is_outbound_referral: isOutboundReferral,
            cat_refer: catRefer,
            referral_omd: referralOmd,
        };
        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();
        // pass the form instance to useFormValidation hooks

        setBillingRulesForm(form);
        // clean up function when billingRules component unmounts
        return () => {
            setBillingRulesForm(null);
        }
    }, [isUrgent,form, setBillingRulesForm, isOutboundReferral, referralOmd, catRefer, odWantsOmdReport]);

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'is_urgent':
                        dispatch(setExamDataValue('is_urgent', field.value));
                        break;
                    case 'is_outbound_referral':
                        dispatch(setExamDataValue('is_outbound_referral', field.value));
                        dispatch(setExamDataValue('is_urgent', false));
                        break;
                    case 'cat_refer':
                        dispatch(setExamDataValue('cat_refer', field.value));
                        dispatch(setExamDataValue('rr_cataract', field.value));
                        break;
                    case 'referral_omd':
                        dispatch(setExamDataValue('referral_omd', field.value));
                        break;
                    case 'od_wants_omd_report':
                            dispatch(setExamDataValue('od_wants_omd_report', field.value));
                            break;
                    default:
                        break;
                }
            }
        });
    };

    return (
        <Card
            className='patient-exam-billing-rules patient-exam-billing-rules-referral-letter'
            bordered={false}
        >
            <Form
                form={form}
                labelCol={{ span: 19 }}
                wrapperCol={{ span: 5 }}
                colon={false}
                labelAlign='left'
                onFieldsChange={onFieldsChange}
                validateMessages={{required: ''}}
                requiredMark={false}
            >

                <Form.Item
                    label='Type of Upload'
                    name='is_outbound_referral'
                    rules={[{ required: true }]}
                    labelCol={{span: 13}}
                    wrapperCol={{span: 11}}
                >
                     <Radio.Group
                        className={'sees-omd'}
                        buttonStyle="solid"
                        size="small"
                    >
                        <Radio.Button
                            disabled={disabled}
                            value={false}
                        >
                            Care1 Upload
                        </Radio.Button>
                        <Radio.Button
                            disabled={disabled}
                            value={true}
                        >
                            Outbound Referral
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Row>
                    <Col span={24} className='upload-type-text'>
                        <Typography.Text>
                            {!isOutboundReferral ? 
                                CARE1_UPLOAD_TEXT :
                                OUTBOUNT_REFERRAL_TEXT
                               }
                        </Typography.Text>
                    </Col>
                </Row>
                {
                    !isOutboundReferral ?
                    <>
                    <Form.Item
                        label='Please process urgently'
                        name='is_urgent'
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            className={'sees-omd'}
                            buttonStyle="solid"
                            size="small"
                        >
                            <Radio.Button
                                disabled={disabled}
                                value={true}
                            >
                                {YES_TEXT.toUpperCase()}
                            </Radio.Button>
                            <Radio.Button
                                disabled={disabled}
                                value={false}
                            >
                                {NO_TEXT.toUpperCase()}
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label='Request an OMD Report'
                        name='od_wants_omd_report'
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            className={'sees-omd'}
                            buttonStyle="solid"
                            size="small"
                        >
                            <Radio.Button
                                disabled={disabled}
                                value={true}
                            >
                                {YES_TEXT.toUpperCase()}
                            </Radio.Button>
                            <Radio.Button
                                disabled={disabled}
                                value={false}
                            >
                                {NO_TEXT.toUpperCase()}
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    </>
                    :
                    <>
                        <Form.Item
                            label='Is this a cataract referral?'
                            name='cat_refer'
                            rules={[{ required: true }]}
                        >
                            <Radio.Group
                                className={'sees-omd'}
                                buttonStyle="solid"
                                size="small"
                            >
                                <Radio.Button
                                    disabled={disabled}
                                    value={true}
                                >
                                    {YES_TEXT.toUpperCase()}
                                </Radio.Button>
                                <Radio.Button
                                    disabled={disabled}
                                    value={false}
                                >
                                    {NO_TEXT.toUpperCase()}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <PatientExamBillingRulesReferralOmd disabled={disabled} />
                    </>
                } 
            </Form>
        </Card>
    );
}

export default PatientExamBillingRulesReferralLetter;