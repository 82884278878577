import { convertTimeToShortFormatLabel } from '../../helpers/utilities';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import { setAIIngisghtsHistoricalAnalysisData, openSegmentationModal } from '../../reducers/ai-insights-historical-analysis-slice';

type ComponentProps = {
    examId: number,
    fundusPhoto: string,
    useDrusenSegmentationPhoto: boolean,
    mlDrusenSegmentationBackgroundPhoto: string,
    mlDrusenSegmentationOverlayPhoto: string,
    mlDrusenSegmentationValue: string,
    mlDrusenSegmentationSucceeded: boolean,
    useMicroAneurysmsPhoto: boolean,
    mlMicroAneurysmsOverlayPhoto: string,
    mlMicroAneurysmsValue: string,
    mlMicroAneurysmsSucceeded: boolean,
    mlMicroAneurysmsModelVersion: string,
    mlMicroAneurysmsRunAt: string,
    useHardExudatesPhoto: boolean,
    mlHardExudatesOverlayPhoto: string,
    mlHardExudatesValue: string,
    mlHardExudatesSucceeded: boolean,
    examDate: string,
}

// The preGeneratedThumbnail flag controls whether we use a pre-generated thumbnail. When set to false, we rely
// on the lightbox component to auto-generate for us. When set to true, we use a pre-generated thumbnail.
const AdminAIInsightsHistoryImage = ({ examId, fundusPhoto, 
    useDrusenSegmentationPhoto, mlDrusenSegmentationBackgroundPhoto, mlDrusenSegmentationOverlayPhoto, mlDrusenSegmentationValue, mlDrusenSegmentationSucceeded, 
    useMicroAneurysmsPhoto, mlMicroAneurysmsOverlayPhoto, mlMicroAneurysmsValue, mlMicroAneurysmsSucceeded, mlMicroAneurysmsModelVersion, mlMicroAneurysmsRunAt, 
    useHardExudatesPhoto, mlHardExudatesOverlayPhoto, mlHardExudatesValue, mlHardExudatesSucceeded, 
    examDate }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const mainSrcUrl = getBackendMediaUrl() + fundusPhoto;

    const handleclick = () => {
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_exam_id', value: examId}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_exam_date', value: examDate}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_fundus_photo', value: fundusPhoto === '' ? '' : getBackendMediaUrl() + fundusPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_use_drusen_segmentation_photo', value: useDrusenSegmentationPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_drusen_segmentation_background_photo', value: mlDrusenSegmentationBackgroundPhoto === '' ? '' : getBackendMediaUrl() + mlDrusenSegmentationBackgroundPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_drusen_segmentation_overlay_photo', value: mlDrusenSegmentationOverlayPhoto === '' ? '' : getBackendMediaUrl() + mlDrusenSegmentationOverlayPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_drusen_segmentation_value', value: mlDrusenSegmentationValue}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_drusen_segmentation_succeeded', value: mlDrusenSegmentationSucceeded}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_use_micro_aneurysms_photo', value: useMicroAneurysmsPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_overlay_photo', value: mlMicroAneurysmsOverlayPhoto === '' ? '' : getBackendMediaUrl() + mlMicroAneurysmsOverlayPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_value', value: mlMicroAneurysmsValue}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_succeeded', value: mlMicroAneurysmsSucceeded}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_model_version', value: mlMicroAneurysmsModelVersion}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_run_at', value: mlMicroAneurysmsRunAt}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_use_hard_exudates_photo', value: useHardExudatesPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_hard_exudates_overlay_photo', value: mlHardExudatesOverlayPhoto === '' ? '' : getBackendMediaUrl() + mlHardExudatesOverlayPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_hard_exudates_value', value: mlHardExudatesValue}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_hard_exudates_succeeded', value: mlHardExudatesSucceeded}));
        dispatch(openSegmentationModal());
    }

    return (
        <div className='ai-insights-history-image'>
            <img
                role="img"
                alt=""
                className=""
                src={mainSrcUrl}
                onClick={handleclick}
            />
            <div className='indexDate'>
                <div className="indexExamDate">{convertTimeToShortFormatLabel(examDate)}</div>
            </div>
        </div>
    );
}

export default AdminAIInsightsHistoryImage;