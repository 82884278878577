import { baseApi } from './base-api'

export interface ICsvStereoViwerNearMissIdentifiableRequest {
    id: string;
}

export interface ICsvStereoViwerNearMissIdentifiableResponse {
    success: boolean;
    error?: string;
}

export interface ICsvStereoViwerNearMissNotIdentifiableRequest {
    id: string;
}

export interface ICsvStereoViwerNearMissNotIdentifiableResponse {
    success: boolean;
    error?: string;
}

export interface ICsvStereoViwerNearMissListResponse {
    near_miss_list: [];
}

export interface ICsvStereoViwerNearMissNumUnreconciledResponse {
    num: number;
}

export const csvStereoViwerNearMissApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCsvStereoViwerNearMissList: builder.query<ICsvStereoViwerNearMissListResponse, void>({
            query: () => 'admin/csv_stereo_viewer_near_miss/list/',

            // Give a tag to staffs so that other API actions can invalidate CsvStereoViwerNearMissList
            providesTags: [{type: 'CsvStereoViwerNearMissList', id: 'LIST'}]
        }),
        getCsvStereoViwerNearMissNumUnreconciled: builder.query<ICsvStereoViwerNearMissNumUnreconciledResponse, void>({
            query: () => 'admin/csv_stereo_viewer_near_miss/num_unreconciled/',
        }),
        csvStereoViwerNearMissIdentifiable: builder.mutation<Omit<ICsvStereoViwerNearMissIdentifiableResponse, 'vf_near_miss'>, ICsvStereoViwerNearMissIdentifiableRequest>({
            query(body) {
                return {
                    url: 'admin/csv_stereo_viewer_near_miss/identifiable/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['CsvStereoViwerNearMissList']
        }),
        csvStereoViwerNearMissNotIdentifiable: builder.mutation<Omit<ICsvStereoViwerNearMissNotIdentifiableResponse, 'vf_near_miss'>, ICsvStereoViwerNearMissNotIdentifiableRequest>({
            query(body) {
                return {
                    url: 'admin/csv_stereo_viewer_near_miss/not_identifiable/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['CsvStereoViwerNearMissList']
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {useGetCsvStereoViwerNearMissListQuery, useGetCsvStereoViwerNearMissNumUnreconciledQuery,
    useCsvStereoViwerNearMissIdentifiableMutation, useCsvStereoViwerNearMissNotIdentifiableMutation
} = csvStereoViwerNearMissApi;