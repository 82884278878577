import { useEffect, useState } from 'react';
import { FlagOutlined } from '@ant-design/icons';
import { Table, Modal, Button, Tooltip } from 'antd';
import * as Constants from '../../constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import '../../../static/css/components/patient-list-table-admin.scss';
import '../../../static/css/shared/patient-list-table.scss';

// Component imports
import ExamStatusTag from '../retina/exam-status-tag';
import PatientDetailsModal from '../retina/patient-details-modal';

// Action imports
import { getAdminPatientsToReviewDataRequest, getOMDCStatusDataRequest,
    getIGPStatusDataRequest, 
    getPatientsToReviewAdminRequest} from '../../reducers/admin-review-lists-slice';
import { getIGPListRequest } from '../../reducers/igp-list-slice';
import { getAdminPatientListRequest, IAdminPatientListItem, setPatientListFieldData } from '../../reducers/patient-list-slice';

// Helper imports
import { compareStrings, sortDates } from '../../helpers/sorting';
import { getPreReviewAlertColor } from '../../helpers/patient-list-convert';
import { getOptionsRequest } from '../../reducers/options-slice';
import { localizedText } from '../../localizedText';
import { addToExamRooms, IExamRoom, removeFromExamRooms, setPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { toggleNotAuthorizedForExam } from '../../reducers/user-slice';
import { goToExamInNewTab } from '../../reducers/patient-exam-slice';


const PatientListTableADMIN = () => {
    const { ADMIN_REFRESH_TABLES, ADMIN_RESET_SORT, ADMIN_COLUMN_SORT_TOOLTIP,
        ADMIN_TABLE_TEST, ADMIN_TABLE_ALERTS, ADMIN_TABLE_STATUS, ADMIN_TABLE_SPECIAL_NOTES,
        ADMIN_TABLE_PR_ALERTS, ADMIN_TABLE_EGP_STATUS, ADMIN_TABLE_IGP, ADMIN_TABLE_IGP_STATUS,
        ADMIN_TABLE_VISITS, ADMIN_TABLE_DOB, ADMIN_TABLE_OMDC, ADMIN_TABLE_OMDC_STATUS,
        ADMIN_TABLE_OD_GROUP, ADMIN_TABLE_OD, ADMIN_TABLE_PRV, ADMIN_TABLE_OMDR, ADMIN_TABLE_LAST_EXAM,
        ADMIN_TABLE_REF_TYPE, ADMIN_TABLE_FIRST_NAME, ADMIN_TABLE_LAST_NAME,
        OPEN_SELECTED_PATIENTS, ADMIN_TABLE_PR } = localizedText;

    const tableList = useCare1AppSelector(store => store.patientList.adminTableList);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const notAuthorizedForExam = useCare1AppSelector(store => store.user.notAuthorizedForExam);
    const prereviewAlertOptions = useCare1AppSelector(store => store.options.prereviewAlerts);
    const examRooms = useCare1AppSelector(store => store.patientExams.rooms);

    const dispatch = useCare1AppDispatch();

    const [ scrollClass, setScrollClass ] = useState('');

    // Local state variables to manage the column sorting priorization indexes.
    // A higher index has priority over a lower index. The initial column will have the BASE_PRIORITY_INDEX
    // and subsequently selected columns will have decremented numbers to ensure the priority
    // of the earlier selected columns.
    const BASE_PRIORITY_INDEX = 100;
    const [ priorityIndex, setPriorityIndex ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityFlag, setPriorityFlag ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityPR, setPriorityPR ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityLastName, setPriorityLastName ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityFirstName, setPriorityFirstName ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityRefType, setPriorityRefType ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityLastExam, setPriorityLastExam ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityOMDR, setPriorityOMDR ] = useState(BASE_PRIORITY_INDEX);
    const [ prioritySubmissionDate, setPrioritySubmissionDate ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityProvince, setPriorityProvince ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityOD, setPriorityOD ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityODGroup, setPriorityODGroup ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityOMDC, setPriorityOMDC ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityOMDCStatus, setPriorityOMDCStatus ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityDOB, setPriorityDOB ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityVisits, setPriorityVisits ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityVisitNumber, setPriorityVisitNumber ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityIGPStatus, setPriorityIGPStatus ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityIGP, setPriorityIGP ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityEGPStatus, setPriorityEGPStatus ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityPRAlerts, setPriorityPRAlerts ] = useState(BASE_PRIORITY_INDEX);
    const [ prioritySpecialNotes, setPrioritySpecialNotes ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityExamStatus, setPriorityExamStatus ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityAlerts, setPriorityAlerts ] = useState(BASE_PRIORITY_INDEX);
    const [ priorityTestPatients, setPriorityTestPatient ] = useState(BASE_PRIORITY_INDEX);

    // Ant Design columns have a sortOrder, which is the direction of the sort: ascend, descend, null (cancelled)
    // This is local state to keep the column sortOrder data so that it can be cancelled when the reset button is clicked.
    const [ sortOrderInfo, setSortOrderInfo ] = useState<SorterResult<IAdminPatientListItem> | SorterResult<IAdminPatientListItem>[]>()

    const getSelectedRowKeys = ()=> {
        return examRooms.map(function (room: {examId: number}) {
            return room.examId;
          });
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    // Format the Open Selected Patients tooltip text
    const getOpenSelectedTooltipText = () => {
        return (
            <div className='admin-load-patients-tooltip'>
                In order to open the exam(s) in a separate tab and remain on the current page, please install extension
                <a
                    href='https://chrome.google.com/webstore/detail/force-background-tab/gidlfommnbibbmegmgajdbikelkdcmcl'
                    target="_blank"
                    rel="noreferrer"
                    onClick={(event) => event.stopPropagation()}
                >
                    Force Background Tab
                </a>
            </div>
        )
    }

    // only run when the component first mounts
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // Set the default filter values
        dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));

        // Make requests
        dispatch(getOptionsRequest());
        dispatch(getAdminPatientListRequest());
        dispatch(getAdminPatientsToReviewDataRequest());
        dispatch(getPatientsToReviewAdminRequest());
        dispatch(getOMDCStatusDataRequest());
        dispatch(getIGPListRequest());
        dispatch(getIGPStatusDataRequest());

        if (notAuthorizedForExam) {
            Modal.info({
                className: 'info-modal',
                title: Constants.UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                    dispatch(toggleNotAuthorizedForExam(false));
                },
            });
        }

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Reload the tables when Admin clicks the Refresh Tables button
    const handleRefreshTables = () => {
        dispatch(getAdminPatientListRequest());
        dispatch(getAdminPatientsToReviewDataRequest());
        dispatch(getPatientsToReviewAdminRequest());
        dispatch(getOMDCStatusDataRequest());
        dispatch(getIGPStatusDataRequest());
    }

    // Open selected exams in new tabs and stay on the patient list page.
    // This is achieved by using the Force Background Chrome extension
    const handleOpenSelectedPatients = () => {
        if(examRooms && examRooms.length){
            for (let i=0; i<examRooms.length; i++) {
                const examUrl = `${Constants.PATIENT_EXAM_URL}/${examRooms[i].examId}`;
                goToExamInNewTab(examUrl);
            }
        }
    }

    // Open the exam in a new tab and stay on the patient list page.
    // This achieved by using the Force Background Chrome extension
    const onTableRowClick = (record: IAdminPatientListItem) => {
        const examUrl = `${Constants.PATIENT_EXAM_URL}/${record.exam_id}`;
        goToExamInNewTab(examUrl);
    }

    const onRowSelected = (record: IAdminPatientListItem, selected: boolean) => {
        if(record && record.exam_id){
            const examId = record.exam_id;
            const { first_name, last_name } = record;
            const patientName = `${last_name}, ${first_name}`;
            if(selected){
                dispatch(addToExamRooms({ examId, patientName, 'patient': record }));
            } else {
                dispatch(removeFromExamRooms(examId));
            }
        }
    }

    const onAllRowSelected = (selected: boolean, selectedRows: IAdminPatientListItem[]) => {
        const examRooms: IExamRoom[] = [];
        if (selected && selectedRows.length) {
            selectedRows.forEach((patient) => {
                const {exam_id, first_name, last_name} = patient;
                if(exam_id) {
                    examRooms.push({
                        examId: exam_id,
                        patientName: `${last_name}, ${first_name}`,
                        patient,
                    });
                }
            })
            dispatch(setPatientExamRoomsData({ rooms: examRooms }));
        } else if (!selected) {
            dispatch(setPatientExamRoomsData({ rooms: [] }));
        }

    }

    // Update the column sortOrder local state object
    const handleTableChange = (sorter: SorterResult<IAdminPatientListItem> | SorterResult<IAdminPatientListItem>[] ) => {
        setSortOrderInfo(sorter);
    }

    // Each column needs to get the current state of its sortOrder
    const getSortOrder = (key: string) => {
        // If only one column is sorted, sortOrderInfo is an object.
        // If two or more columns are sorted, sortOrderInfo is an array.
        if(Array.isArray(sortOrderInfo)){
            const sortOrderItem = sortOrderInfo.length > 0 && sortOrderInfo.find(so => so.columnKey === key);
            return sortOrderItem ? sortOrderItem.order : null;

        } else {
            return sortOrderInfo?.columnKey === key ? sortOrderInfo.order : null
        }
    }

    // Reset all priority indexes to the base number.
    const handleClearPriorityIndexes = () => {
        setSortOrderInfo([]);
        setPriorityIndex(BASE_PRIORITY_INDEX);
        setPriorityFlag(BASE_PRIORITY_INDEX);
        setPriorityPR(BASE_PRIORITY_INDEX);
        setPriorityLastName(BASE_PRIORITY_INDEX);
        setPriorityFirstName(BASE_PRIORITY_INDEX);
        setPriorityRefType(BASE_PRIORITY_INDEX);
        setPriorityLastExam(BASE_PRIORITY_INDEX);
        setPriorityOMDR(BASE_PRIORITY_INDEX);
        setPrioritySubmissionDate(BASE_PRIORITY_INDEX);
        setPriorityProvince(BASE_PRIORITY_INDEX);
        setPriorityOD(BASE_PRIORITY_INDEX);
        setPriorityODGroup(BASE_PRIORITY_INDEX);
        setPriorityOMDC(BASE_PRIORITY_INDEX);
        setPriorityOMDCStatus(BASE_PRIORITY_INDEX);
        setPriorityDOB(BASE_PRIORITY_INDEX);
        setPriorityVisits(BASE_PRIORITY_INDEX);
        setPriorityVisitNumber(BASE_PRIORITY_INDEX);
        setPriorityIGPStatus(BASE_PRIORITY_INDEX);
        setPriorityIGP(BASE_PRIORITY_INDEX);
        setPriorityEGPStatus(BASE_PRIORITY_INDEX);
        setPriorityPRAlerts(BASE_PRIORITY_INDEX);
        setPrioritySpecialNotes(BASE_PRIORITY_INDEX);
        setPriorityExamStatus(BASE_PRIORITY_INDEX);
        setPriorityAlerts(BASE_PRIORITY_INDEX);
        setPriorityTestPatient(BASE_PRIORITY_INDEX);
    }

    // Ant Design assigns priority to the column with the higher 'mulitple' index number.
    // When a column is first clicked, it should be assigned the next decremented priority index
    const setColumnSortPriority = (dataIndex: string, sorterMultiple: number ) => {

        // If the column's priority index is not the base index value that means it has already been indexed.
        // As such, the function expression should be exited since the column's index should remain unchanged.
        if(sorterMultiple !== BASE_PRIORITY_INDEX){
            return;
        }

        // Decrement the priority index base number.
        // The asynchronous setting of the useState variable will be completed by the time the user selects another column.
        setPriorityIndex(priorityIndex - 1);

        // Set the selected column with the decremented priority index value.
        switch(dataIndex){
            case 'flag':
                setPriorityFlag(priorityIndex - 1);
                break;
            case 'pre_reviewer':
                setPriorityPR(priorityIndex - 1);
                break;
            case 'last_name':
                setPriorityLastName(priorityIndex - 1);
                break;
            case 'first_name':
                setPriorityFirstName(priorityIndex - 1);
                break;
            case 'referral_type':
                setPriorityRefType(priorityIndex - 1);
                break;
            case 'latest_visit':
                setPriorityLastExam(priorityIndex - 1);
                break;
            case 'submission_date':
                setPrioritySubmissionDate(priorityIndex - 1);
                break;
            case 'omdr':
                setPriorityOMDR(priorityIndex - 1);
                break;
            case 'province':
                setPriorityProvince(priorityIndex - 1);
                break
            case 'od':
                setPriorityOD(priorityIndex - 1);
                break;
            case 'od_group':
                setPriorityODGroup(priorityIndex - 1);
                break;
            case 'omdc':
                setPriorityOMDC(priorityIndex - 1);
                break;
            case 'omdc_status':
                setPriorityOMDCStatus(priorityIndex - 1);
                break;
            case 'dob':
                setPriorityDOB(priorityIndex - 1);
                break;
            case 'visits':
                setPriorityVisits(priorityIndex - 1);
                break;
            case 'igp_status':
                setPriorityIGPStatus(priorityIndex - 1);
                break;
            case 'igp':
                setPriorityIGP(priorityIndex - 1);
                break;
            case 'egp_status':
                setPriorityEGPStatus(priorityIndex - 1);
                break;
            case 'pr_alerts':
                setPriorityPRAlerts(priorityIndex - 1);
                break;
            case 'special_notes':
                setPrioritySpecialNotes(priorityIndex - 1);
                break;
            case 'status':
                setPriorityExamStatus(priorityIndex - 1);
                break;
            case 'alerts':
                setPriorityAlerts(priorityIndex - 1);
                break;
            case 'test_patient':
                setPriorityTestPatient(priorityIndex - 1);
                break;
            default:
                break;
        }
    }

    const getAdminOdClassName = ({isOdOrientating, isOdSpecialAttention, od_integrated, od_retina_only, isUsOnly, od}: IAdminPatientListItem) => {
        let odTextClassName = '', odBgClassName = '';
        if (od !== 'None') {
            if (isOdOrientating) {
                odTextClassName =  'od-orientating';
            } else if (isOdSpecialAttention) {
                odTextClassName = 'od-special-attention';
            } else if ( !od_integrated ) {
                odTextClassName = 'od-not-integrated';
            } else {
                odTextClassName =  '';
            }
            if (isUsOnly) {
                odBgClassName = 'us-only';
            } else if (od_retina_only) {
                odBgClassName = 'od-retina-only';
            }
        }

        return `${odTextClassName} ${odBgClassName}`;
    }

    // Each column needs to have priority sorting.
    // The column's priority sorting value is sorter.multiple, and is set by setColumnSortPriority()
    // The column's sortOrder (ascend, descend) is kept in local state (sortOrderInfo)
    // The priority sorting and the sortOrder is resettable via the Reset button.
    const columns: ColumnsType<IAdminPatientListItem> = [{
        className: 'flag-column',
        width: '40px',
        title: () => (<FlagOutlined />),
        dataIndex: 'flag',
        key: 'flag',
        render: (isFlagged, record) => {
            if (record.red_flag) {
                return <FlagOutlined style={{ color: 'red' }} />;
            }
            else {
                return (isFlagged ? (<FlagOutlined />) : null);
            }            
        },
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('flag', priorityFlag)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.flag.toString(), b.flag.toString()),
            multiple: priorityFlag,
        },
        sortOrder: getSortOrder('flag')
    }, {
        className: 'pre-reviewer-column',
        width: '70px',
        title: ADMIN_TABLE_PR,
        dataIndex: 'pre_reviewer',
        key: 'pre_reviewer',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('pre_reviewer', priorityPR)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.pre_reviewer, b.pre_reviewer),
            multiple: priorityPR,
        },
        sortOrder: getSortOrder('pre_reviewer')
    }, {
        className: 'last-name-column',
        title: ADMIN_TABLE_LAST_NAME,
        dataIndex: 'last_name',
        key: 'last_name',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('last_name', priorityLastName)
            }
        },
        render: (text, record) => {
            return (
                <span className={`${record.patient_deactivated ? 'patient-deactivated' : ''}`}>
                    {text}
                </span>
        )},
        sorter: {
            compare: (a, b) => compareStrings(a.last_name, b.last_name),
            multiple: priorityLastName,
        },
        sortOrder: getSortOrder('last_name')
    }, {
        className: 'first-name-column',
        title: ADMIN_TABLE_FIRST_NAME,
        dataIndex: 'first_name',
        key: 'first_name',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('first_name', priorityFirstName)
            }
        },
        render: (text, record) => {
            return (
                <span className={`${record.patient_deactivated ? 'patient-deactivated' : ''}`}>
                    {text}
                </span>
        )},
        sorter: {
            compare: (a, b) => compareStrings(a.first_name, b.first_name),
            multiple: priorityFirstName,
        },
        sortOrder: getSortOrder('first_name')
    }, {
        className: 'referral-type-column',
        width: '175px',
        title: ADMIN_TABLE_REF_TYPE,
        dataIndex: 'referral_type',
        key: 'referral_type',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('referral_type', priorityRefType)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.referral_type, b.referral_type),
            multiple: priorityRefType,
        },
        sortOrder: getSortOrder('referral_type')
    }, {
        className: 'latest-visit-column',
        width: '100px',
        title: ADMIN_TABLE_LAST_EXAM,
        dataIndex: 'latest_visit',
        key: 'latest_visit',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('latest_visit', priorityLastExam)
            }
        },
        render: (text, record) => {
            return (
                <span className={`${record.is_inactive ? 'exam-deactivated' : ''}`}>
                    {text}
                </span>
        )},
        sorter: {
            compare: (a, b) => {
                const firstDate = a.latest_visit ? a.latest_visit : '';
                const secondDate = b.latest_visit ? b.latest_visit : '';
                return sortDates(firstDate, secondDate);
            },
            multiple: priorityLastExam,
        },
        sortOrder: getSortOrder('latest_visit')
    }, {
        className: 'submission_date-column',
        title: 'Submission Date',
        dataIndex: 'submission_date',
        key: 'submission_date',
        width: '145px',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('submission_date', prioritySubmissionDate)
            }
        },
        sorter: {
            compare: (a, b) => sortDates(a.submission_date, b.submission_date),
            multiple: prioritySubmissionDate,
        },
        sortOrder: getSortOrder('submission_date')
    },{
        className: 'omdr-column',
        title: ADMIN_TABLE_OMDR,
        dataIndex: 'omdr',
        key: 'omdr',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('omdr', priorityOMDR)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.omdr, b.omdr),
            multiple: priorityOMDR,
        },
        sortOrder: getSortOrder('omdr')
    },{
        className: 'province-column',
        width:  '60px',
        title: ADMIN_TABLE_PRV,
        dataIndex: 'province',
        key: 'province',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('province', priorityProvince)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.province, b.province),
            multiple: priorityProvince,
        },
        sortOrder: getSortOrder('province')
    }, {
        className: 'od-column',
        title: ADMIN_TABLE_OD,
        dataIndex: 'od',
        key: 'od',
        render: (data, record) => <span className={getAdminOdClassName(record)}>{data}</span>,
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('od', priorityOD)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.od, b.od),
            multiple: priorityOD,
        },
        sortOrder: getSortOrder('od'),
    }, {
        className: 'od-group-column',
        title: ADMIN_TABLE_OD_GROUP,
        dataIndex: 'od_group',
        key: 'od_group',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('od_group', priorityODGroup)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.od_group, b.od_group),
            multiple: priorityODGroup,
        },
        sortOrder: getSortOrder('od_group'),
    }, {
        className: 'omdc-column',
        title: ADMIN_TABLE_OMDC,
        dataIndex: 'omdc',
        key: 'omdc',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('omdc', priorityOMDC)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.omdc, b.omdc),
            multiple: priorityOMDC,
        },
        sortOrder: getSortOrder('omdc')
    }, {
        className: 'omdc-status-column',
        width: '120px',
        align: 'center',
        title: ADMIN_TABLE_OMDC_STATUS,
        dataIndex: 'omdc_status',
        key: 'omdc_status',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('omdc_status', priorityOMDCStatus)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.omdc_status, b.omdc_status),
            multiple: priorityOMDCStatus,
        },
        sortOrder: getSortOrder('omdc_status')
    }, {
        className: 'dob-column',
        width: '130px',
        title: ADMIN_TABLE_DOB,
        dataIndex: 'dob',
        key: 'dob',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('dob', priorityDOB)
            }
        },
        sorter: {
            compare: (a, b) => {
                const firstDate = a.dob ? a.dob : '';
                const secondDate = b.dob ? b.dob : '';
                return sortDates(firstDate, secondDate);
            },
            multiple: priorityDOB,
        },
        sortOrder: getSortOrder('dob')
    }, {
        className: 'visits-column',
        width: '70px',
        align: 'center',
        title: ADMIN_TABLE_VISITS,
        dataIndex: 'visits',
        key: 'visits',
        onHeaderCell: () => {
            return {
                onClick: () => (priorityVisits === 0) && setColumnSortPriority('visits', priorityVisits)
            }
        },
        sorter: {
            compare: (a, b) => b.visits - a.visits,
            multiple: priorityVisits,
        },
        sortOrder: getSortOrder('visits')
    }, {
        className: 'visits-column',
        width: '100px',
        title: 'This Visit',
        dataIndex: 'visit_number',
        key: 'visit_number',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('visit_number', priorityVisitNumber)
            }
        },
        sorter: {
            compare: (a, b) => a.visit_number - b.visit_number,
            multiple: priorityVisitNumber,
        },
        sortOrder: getSortOrder('visit_number')
    },{
        className: 'igp-status-column',
        width: '100px',
        title: ADMIN_TABLE_IGP_STATUS,
        dataIndex: 'igp_status',
        key: 'igp_status',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('igp_status', priorityIGPStatus)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.igp_status, b.igp_status),
            multiple: priorityIGPStatus,
        },
        sortOrder: getSortOrder('igp_status')
    }, {
        className: 'igp-column',
        title: ADMIN_TABLE_IGP,
        dataIndex: 'igp',
        key: 'igp',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('igp', priorityIGP)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.igp, b.igp),
            multiple: priorityIGP,
        },
        sortOrder: getSortOrder('igp')
    },
    {
        className: 'egp-status-column',
        width: '100px',
        title: ADMIN_TABLE_EGP_STATUS,
        dataIndex: 'egp_status',
        key: 'egp_status',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('egp_status', priorityEGPStatus)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.egp_status, b.egp_status),
            multiple: priorityEGPStatus,
        },
        sortOrder: getSortOrder('egp_status')
    }, {
        className: 'pre-review-alerts-column',
        title: ADMIN_TABLE_PR_ALERTS,
        dataIndex: 'pr_alerts',
        key: 'pr_alerts',
        render: (data) => {
            return data && Array.isArray(data) && data.map((alert, index) => {

                // Get the prereview alert color and assign it as a class name
                const color = getPreReviewAlertColor(alert, prereviewAlertOptions);

                return (
                    <span
                        className={`${color}`}
                        key={`pre_review_alert_${index}`}
                    >
                        {/* add comma between each alert */}
                        {`${index ? ', ' : ''}${alert}`}
                    </span>
                )
            })
        },
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('pr_alerts', priorityPRAlerts)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.pr_alerts.toString(), b.pr_alerts.toString()),
            multiple: priorityPRAlerts,
        },
        sortOrder: getSortOrder('pr_alerts')
    }, {
        className: 'special-notes-column',
        title: ADMIN_TABLE_SPECIAL_NOTES,
        dataIndex: 'special_notes',
        key: 'special_notes',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('special_notes', prioritySpecialNotes)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.special_notes, b.special_notes),
            multiple: prioritySpecialNotes,
        },
        sortOrder: getSortOrder('special_notes')
    }, {
        className: 'status-column',
        align: 'center',
        width:  '130px',
        title: ADMIN_TABLE_STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (text) => <ExamStatusTag className="patient-exam-status-tag" examStatusKey={text} />,
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('status', priorityExamStatus)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.status, b.status),
            multiple: priorityExamStatus,
        },
        sortOrder: getSortOrder('status')
    }, {
        className: 'alerts-column',
        align: 'center',
        width: '110px',
        title: ADMIN_TABLE_ALERTS,
        dataIndex: 'alerts',
        key: 'alerts',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('alerts', priorityAlerts)
            }
        },
        sorter: {
            compare: (a, b) => compareStrings(a.alerts, b.alerts),
            multiple: priorityAlerts,
        },
        sortOrder: getSortOrder('alerts')
    }, {
        className: 'test-patient-column',
        width: '60px',
        title: ADMIN_TABLE_TEST,
        dataIndex: 'test_patient',
        key: 'test_patient',
        render: (text) => text && 'YES',
        onHeaderCell: () => {
            return {
                onClick: () => setColumnSortPriority('test_patient', priorityTestPatients)
            }
        },
        sorter: {
            compare: (a, b) => (a.test_patient === b.test_patient) ? 0 : a.test_patient ? -1 : 1,
            multiple: priorityTestPatients,
        },
        sortOrder: getSortOrder('test_patient')
    }];

    return (
        <div className="patient-list-table patient-list-table-admin">
            <div className='button-actions-div'>
                <Button
                    className='open-selected-button'
                    type='primary'
                    onClick={handleOpenSelectedPatients}
                    disabled={examRooms && examRooms.length === 0}
                >
                    {OPEN_SELECTED_PATIENTS}
                    <Tooltip
                        className="col-sort-order-tooltip"
                        placement='topLeft'
                        title={getOpenSelectedTooltipText}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Button>

                <Button
                    className='clear-priority-indexes-button'
                    type='primary'
                    onClick={handleClearPriorityIndexes}
                    disabled={ priorityIndex === BASE_PRIORITY_INDEX }
                >
                    {ADMIN_RESET_SORT}
                    <Tooltip
                        className="col-sort-order-tooltip"
                        placement='topLeft'
                        title={ADMIN_COLUMN_SORT_TOOLTIP}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Button>

                <Button
                    className='refresh-tables-button'
                    type='primary'
                    onClick={handleRefreshTables}
                >
                    {ADMIN_REFRESH_TABLES}
                </Button>
            </div>
            <div className={scrollClass}>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        onSelect: onRowSelected,
                        onSelectAll: onAllRowSelected,
                        selectedRowKeys: getSelectedRowKeys(),
                    }}
                    columns={columns}
                    scroll={{ x: 2600 }}
                    sticky={true}
                    dataSource={tableList}
                    pagination={false}
                    onChange={(pagination, filters, sorter) => handleTableChange(sorter)}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onTableRowClick(record);
                            },
                        };
                    }}
                />
                {showDetails && <PatientDetailsModal />}
            </div>
        </div>
    );
}

export default PatientListTableADMIN;