import { useState } from 'react';
import { CloseCircleOutlined, LeftOutlined, RightOutlined, SyncOutlined, ZoomInOutlined, ZoomOutOutlined,
    } from '@ant-design/icons';
import { Modal } from 'antd';
import { pdfjs, Page, Document } from 'react-pdf';
import { PDF_VIEWER_DEFAULT_SCALE, PDF_VIEWER_MAX_SCALE, PDF_VIEWER_MIN_SCALE, PDF_VIEWER_SCALE_MULTIPLIER,
} from '../../constants';

type ComponentProps = {
    className: string,
    file: string,
    onPDFViewClose: () => void,
}


const PatientExamGpReferredPDFViewerModal = ({ className, file, onPDFViewClose }: ComponentProps) => {

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(PDF_VIEWER_DEFAULT_SCALE);

    const options = {
        standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
      };

    const onPDFLoadSuccess = ({numPages}: {numPages: number}) => {
        setNumPages(numPages);
    }

    const onPDFLoadError = () => {

    }

    const onClose = () => {
        onPDFViewClose();
    }

    const zoomIn = () => {
        if (scale < PDF_VIEWER_MAX_SCALE) {
            setScale(scale * PDF_VIEWER_SCALE_MULTIPLIER);
        }
    }

    const zoomOut = () => {
        if (scale > PDF_VIEWER_MIN_SCALE) {
            setScale(scale / PDF_VIEWER_SCALE_MULTIPLIER);
        }
    }

    const zoomReset = () => {
        setScale(PDF_VIEWER_DEFAULT_SCALE);
    }

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    return (
        <Modal
            className={className}
            open
            width="540"
            onCancel={onClose}
            closable
            footer={null}
        >
            <div className="pdfViewerContent">
                <div className="pdfViewerControlsContainer">
                    <div className="pdfViewerControls">
                        <LeftOutlined
                            className="control-icon"
                            onClick={goToPrevPage}
                            disabled={pageNumber === 1} />
                        <span className="page-num">
                            {pageNumber}
                            /
                            {numPages}
                        </span>
                        <RightOutlined
                            className="control-icon"
                            onClick={goToNextPage}
                            disabled={pageNumber === numPages} />
                        <ZoomInOutlined
                            className="control-icon"
                            onClick={zoomIn}
                            disabled={scale > PDF_VIEWER_MAX_SCALE} />
                        <SyncOutlined
                            className="control-icon"
                            onClick={zoomReset}
                            disabled={scale === PDF_VIEWER_DEFAULT_SCALE} />
                        <ZoomOutOutlined
                            className="control-icon"
                            onClick={zoomOut}
                            disabled={scale < PDF_VIEWER_MIN_SCALE} />
                        <CloseCircleOutlined className="control-icon" onClick={onClose} />
                    </div>
                </div>
                <Document options={options} file={file} onLoadSuccess={onPDFLoadSuccess} onLoadError={onPDFLoadError}>
                    <Page pageNumber={pageNumber} scale={scale} />
                </Document>
            </div>
        </Modal>
    );
}

export default PatientExamGpReferredPDFViewerModal;