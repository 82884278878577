import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { IExamMetrics } from "../reducers/patient-exam-slice";
import { isIopHistoryEntryEmpty, isOmdHistoryEntryEmpty, isOngoingEntryEmpty, isUntilYesterdayEntryEmpty } from "./patient-exam-convert";

// additional or different type definition compare to IExamData 
type OmitExamMetricsKeys = 'bcva_options' | 'dtc_values';

export interface IExamSubmitData extends Omit<IExamMetrics, OmitExamMetricsKeys > {
    exam: number;
    pmh_m: string;
    exam_status: string;
    omd_od: string;
    allergies: string;
    allergies_none: boolean;
    gp_refed: boolean;
    fu_number: string;
    fu_letter: boolean;
    other_omd: string;
    cant_bill_oct: boolean;
    cant_bill_vf: boolean;
    refer_in_person_omd_appt: string;
    e_notes: string;
    od_omd: string;
    omd_note_string: string;
    omd_gp: string;
    second_omdr: number | null;
    omd_textbox0: string;
    omd_history_values: string;
    iop_history_values: string;
    glc_past_drops_values: string;
    glc_current_drops_values: string;
    comorbidities_none: boolean;
    asthma: boolean;
    fhx: boolean
    heart_block: boolean
    uveitis_od: boolean;
    uveitis_os: boolean;
    hx_stroke: boolean;
    pxf_od: boolean;
    pxf_os: boolean;
    pds_od: boolean;
    pds_os: boolean;
    omdc_status: string;
    optometrist: number | null;
    retina_only_omd_note_templates: string;
    omdc_note_templates: string;
    selected_omd_note_options: string;
    exam_date: string;
    omdc_proposed_plan: string;
    billing_note: string;
    is_urgent: boolean;
    past_omd: boolean;
    cu_omd: boolean;
    ipc: string;
    od_req_omd: boolean;
    history_and_exam: string;
    flag: boolean;
    od_vf_override: string;
    os_vf_override: string;
    billing_addressed: boolean;
    od_iop_aim: string;
    os_iop_aim: string;
    patient_status: string;
    followup_aim: string;
    left_cd: string;
    right_cd: string;
    os_treatment_iop_zone: string | null;
    od_treatment_iop_zone: string | null;
    treatment_fu_zone: string;
    cpt: string;
    icd_10: string;
    external_gp_status: string | null;
    internal_gp: string | null;
    internal_gp_status: string | null;
    manual_planned_billing_code: string;
    omdc_manual_planned_billing_code: string;
    dhx_od: boolean;
    dhx_os: boolean;
    od_baseline: boolean;
    od_question: boolean;
    od_start_drops: boolean;
    omd_reviewer: number | null;
    omd_consultant: number | null;
    gp_referral_date: string;
    manual_gp_referral_date: string;
    pre_reviewer_values: string;
    right_octav: string;
    right_octav_sup: string;
    right_octav_inf: string;
    right_oct_autoset: boolean;
    left_octav: string;
    left_octav_sup: string;
    left_octav_inf: string;
    left_oct_autoset: boolean;
    initials_select: string;
    omdr_set_glc_suspect: boolean;
    left_ght: string;
    right_ght: string;
    version: number;
    dtc_values: string;
    rr_glc: boolean;
    rr_glc_suspect: boolean;
    rr_dm: boolean;
    rr_amd: boolean;
    rr_erm: boolean;
    rr_optic_nerve: boolean;
    rr_narrow_angles: boolean;
    rr_cataract: boolean;
    rr_cat_post_op: boolean;
    rr_others: string;
    diagnostics_values: string;
    overwrite?: boolean;
    impression: string;
    assign_first: boolean;
    rlu_exam_is_premium: boolean;
    admin_private_notes: string;
    admin_private_notes_addressed: boolean;
    is_review_processed: boolean;
    gpt_ai_snapshot_band_impressions: string[];
    gpt_ai_snapshot_band_od_assessment: string;
    gpt_ai_snapshot_band_od_question: string;
    gpt_chat_ai_snapshot_band_impressions: string[];
    gpt_chat_ai_snapshot_band_od_assessment: string;
    gpt_chat_ai_snapshot_band_od_question: string;
    is_fu_unknown: boolean;
    is_outbound_referral: boolean;
    cat_refer: boolean | null;
    referral_omd: number | null;
    ai_recommends_omd_report: boolean;
    second_opinion_teleconsult: number | null;
    od_wants_omd_report: boolean;
}


export const getSaveDataFromState = () => (dispatch: AppDispatch, getState: () => RootState): IExamSubmitData =>{

    const {examData, gptAiSnapshot} = getState();

    // Remove empty entries from these 4 DivTag array values
    const omdHistoryExamData = examData.omd_history.values.filter(entry => !isOmdHistoryEntryEmpty(entry));

    const untilYesterdayExamData = examData.until_yesterday.values.filter(entry => !isUntilYesterdayEntryEmpty(entry));

    const ongoingExamData = examData.ongoing.values.filter((entry) => !isOngoingEntryEmpty(entry));

    const iopHistoryExamData = examData.iop_history.values.filter((entry) => !isIopHistoryEntryEmpty(entry));

    
    const {ped, macular_hole, nevus, plaquenil, rao, vmt} = examData;
    const pmhValue = examData.pmh_m;

    return {
        exam: examData.id!,
        rr_dm: examData.rr_dm,
        rr_amd: examData.rr_amd,
        rr_erm: examData.rr_erm,
        rr_glc: examData.rr_glc,
        rr_glc_suspect: examData.rr_glc_suspect,
        rr_others: examData.rr_others,
        rr_optic_nerve: examData.rr_optic_nerve,
        rr_narrow_angles: examData.rr_narrow_angles,
        rr_cataract: examData.rr_cataract,
        rr_cat_post_op: examData.rr_cat_post_op,
        od_bcva: examData.od_bcva ? examData.od_bcva : 'ND',
        os_bcva: examData.os_bcva ? examData.os_bcva : 'ND',
        od_sphere: isNaN(Number(examData.od_sphere)) ? '' : examData.od_sphere,
        od_cylinder: isNaN(Number(examData.od_cylinder)) ? '' : examData.od_cylinder,
        od_axis: isNaN(Number(examData.od_axis)) ? '' : examData.od_axis,
        os_sphere: isNaN(Number(examData.os_sphere)) ? '' : examData.os_sphere,
        os_cylinder: isNaN(Number(examData.os_cylinder)) ? '' : examData.os_cylinder,
        os_axis: isNaN(Number(examData.os_axis)) ? '' : examData.os_axis,
        pmh_m: pmhValue,
        exam_status: examData.exam_status,
        omd_od: examData.omd_od,
        allergies: examData.allergies,
        allergies_none: examData.allergies_none,
        gp_refed: examData.gp_refed,
        fu_number: examData.fu_number,
        fu_letter: examData.fu_letter === 'month' ? false: true,
        dtc_hour: examData.dtc_hour,
        dtc_values: JSON.stringify(examData.dtc_values),
        od_cd: examData.od_cd,
        os_cd: examData.os_cd,
        od_pachs: examData.od_pachs,
        os_pachs: examData.os_pachs,
        od_ishihara: examData.od_ishihara,
        os_ishihara: examData.os_ishihara,
        od_ishihara2: examData.od_ishihara2,
        os_ishihara2: examData.os_ishihara2,
        other_omd: examData.other_omd,
        cant_bill_oct: examData.cant_bill_oct,
        cant_bill_vf: examData.cant_bill_vf,
        refer_in_person_omd_appt: examData.refer_in_person_omd_appt,
        e_notes: examData.e_notes,
        od_omd: examData.od_omd,
        omd_note_string: examData.omd_note_string,
        omd_gp: examData.omd_gp,
        second_omdr: examData.second_omdr,
        omd_textbox0: examData.omd_textbox0,
        omd_history_values: JSON.stringify(omdHistoryExamData),
        iop_history_values: JSON.stringify(iopHistoryExamData),
        glc_past_drops_values: JSON.stringify(untilYesterdayExamData),
        glc_current_drops_values: JSON.stringify(ongoingExamData),
        comorbidities_none: examData.comorbidities_none,
        asthma: examData.asthma,
        fhx: examData.fhx,
        heart_block: examData.heart_block,
        uveitis_od: examData.uveitis_od,
        uveitis_os: examData.uveitis_os,
        hx_stroke: examData.hx_stroke,
        pxf_od: examData.pxf_od,
        pxf_os: examData.pxf_os,
        pds_od: examData.pds_od,
        pds_os: examData.pds_os,
        omdc_status: examData.omdc_status,
        diagnostics_values: JSON.stringify({
            ped, macular_hole, nevus, plaquenil, rao, vmt,
            ...JSON.parse(examData.diagnostics_values)
        }),

        optometrist: examData.optometrist,
        retina_only_omd_note_templates: JSON.stringify(examData.retina_only_omd_note_templates),
        omdc_note_templates: JSON.stringify(examData.omdc_note_templates),
        selected_omd_note_options: JSON.stringify(examData.selected_omd_note_options),
        od_iop: examData.od_iop,
        os_iop: examData.os_iop,
        applanation: examData.applanation || '',
        exam_date: examData.exam_date,
        omdc_proposed_plan: examData.omdc_proposed_plan,
        billing_note: examData.billing_note,
        is_urgent: examData.is_urgent,
        past_omd: examData.past_omd,
        cu_omd: examData.cu_omd,
        ipc: examData.ipc,
        od_req_omd: examData.od_req_omd,
        history_and_exam: examData.history_and_exam,
        flag: examData.is_pre_reviewed,
        od_vf_override: examData.od_vf_override,
        os_vf_override: examData.os_vf_override,
        billing_addressed: examData.billing_addressed,
        od_iop_aim: examData.od_iop_aim,
        os_iop_aim: examData.os_iop_aim,
        patient_status: examData.patient_status,
        followup_aim: examData.followup_aim,
        left_cd: examData.left_cd,
        right_cd: examData.right_cd,
        os_treatment_iop_zone: examData.os_treatment_iop_zone,
        od_treatment_iop_zone: examData.od_treatment_iop_zone,
        treatment_fu_zone: examData.treatment_fu_zone,
        cpt: examData.cpt,
        icd_10: examData.icd_10,
        external_gp_status: examData.external_gp_status,
        internal_gp: examData.internal_gp!,
        internal_gp_status: examData.internal_gp_status!,
        manual_planned_billing_code: examData.omdr_manual_planned_billing_code,
        omdc_manual_planned_billing_code: examData.omdc_manual_planned_billing_code,
        dhx_od: examData.dhx_od,
        dhx_os: examData.dhx_os,
        od_baseline: examData.od_baseline,
        od_question: examData.od_question,
        od_start_drops: examData.od_start_drops,
        omd_reviewer: examData.omd_reviewer,
        omd_consultant: examData.omdc,
        gp_referral_date: examData.gp_referral_date,
        manual_gp_referral_date: examData.manual_gp_referral_date,
        pre_reviewer_values: examData.pre_reviewer_values,
        right_octav: examData.right_octav,
        right_octav_sup: examData.right_octav_sup,
        right_octav_inf: examData.right_octav_inf,
        right_oct_autoset: examData.right_oct_autoset,
        left_octav: examData.left_octav,
        left_octav_sup: examData.left_octav_sup,
        left_octav_inf: examData.left_octav_inf,
        left_oct_autoset: examData.left_oct_autoset,
        initials_select: examData.initials_select,
        omdr_set_glc_suspect: examData.omdrSetGlcSusDiagnosis,
        left_ght: examData.left_ght,
        right_ght: examData.right_ght,
        version: examData.version,
        impression: examData.impression,
        assign_first: examData.assign_first,
        rlu_exam_is_premium: examData.rlu_exam_is_premium,
        admin_private_notes: examData.admin_private_notes,
        admin_private_notes_addressed: examData.admin_private_notes_addressed,
        is_review_processed: examData.is_review_processed,
        gpt_ai_snapshot_band_impressions: gptAiSnapshot.gpt_ai_snapshot_band_impressions,
        gpt_ai_snapshot_band_od_assessment: gptAiSnapshot.gpt_ai_snapshot_band_od_assessment,
        gpt_ai_snapshot_band_od_question: gptAiSnapshot.gpt_ai_snapshot_band_od_question,
        gpt_chat_ai_snapshot_band_impressions: gptAiSnapshot.gpt_chat_ai_snapshot_band_impressions,
        gpt_chat_ai_snapshot_band_od_assessment: gptAiSnapshot.gpt_chat_ai_snapshot_band_od_assessment,
        gpt_chat_ai_snapshot_band_od_question: gptAiSnapshot.gpt_chat_ai_snapshot_band_od_question,
        is_fu_unknown: examData.is_fu_unknown,
        is_outbound_referral: examData.is_outbound_referral,
        cat_refer: examData.cat_refer,
        referral_omd: examData.referral_omd,
        ai_recommends_omd_report: examData.ai_recommends_omd_report,
        second_opinion_teleconsult: examData.second_opinion_teleconsult,
        od_wants_omd_report: examData.od_wants_omd_report,
    }
}