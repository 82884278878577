import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Layout, Divider, Row, Col, Select, Input, DatePicker } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router';
import {
    OD_SELECT_PLACEHOLDER_TEXT, FRONTEND_LONG_DATE_FORMAT, OPTOMETRIST_OPTIONS, PHONE_MASK_FORMAT,
    PHN_NAME_FIELD, PROVINCE_FIELD
} from '../../../constants';
import InputMask from 'react-input-mask';

import { lengthEqual } from '../../../reducers/patient-exam-slice';
import { localizedText } from '../../../localizedText';
import { IInsuranceTypeOption } from '../../../reducers/options-slice';
import { getEditPatientData } from '../../../reducers/patient-edit-slice';

import '../../../../static/css/components/_patient-edit-form.scss';


type ComponentProps = {
    patientId: number | null,
    gptOd: number | null,
    setGptOd: (v: number | null) => void,
    gptExamDate: dayjs.Dayjs | null,
    setGptExamDate: (v: dayjs.Dayjs | null) => void,
    gptDobDay: string | null,
    setGptDobDay: (v: string | null) => void,
    gptDobMonth: string | null,
    setGptDobMonth: (v: string | null) => void,
    gptDobYear: string | null,
    setGptDobYear: (v: string | null) => void,
    gptFirstname: string | null,
    setGptFirstname: (v: string | null) => void,  
    gptLastname: string | null,
    setGptLastname: (v: string | null) => void,
    gptPrimaryPhone: string | null,
    setGptPrimaryPhone: (v: string | null) => void,
    gptProvince: number | null,
    setGptProvince: (v: number | null) => void,         
    gptInsurance: string | null,
    setGptInsurance: (v: string | null) => void,
    gptInsuranceType: string | null,
    setGptInsuranceType: (v: string | null) => void,
}

const PatientValidation = ({ patientId,
    gptOd, setGptOd, gptExamDate, setGptExamDate, 
    gptDobDay, setGptDobDay, gptDobMonth, setGptDobMonth, gptDobYear, setGptDobYear, 
    gptFirstname, setGptFirstname, gptLastname, setGptLastname, gptPrimaryPhone, setGptPrimaryPhone,
    gptProvince, setGptProvince, gptInsurance, setGptInsurance, gptInsuranceType, setGptInsuranceType
}: ComponentProps) => {

    const { FNAME_PLACEHOLDER_TEXT, LNAME_PLACEHOLDER_TEXT, PHONE_PLACEHOLDER_TEXT,
        MONTHS, YEAR_TEXT, MONTH_TEXT, DAY_TEXT, PROVINCE_PLACEHOLDER_TEXT,
    } = localizedText;

    const dispatch = useCare1AppDispatch();

    const optometristOptions = useCare1AppSelector(store => store.examData[OPTOMETRIST_OPTIONS], lengthEqual);
    const provinces = useCare1AppSelector(store => store.options.provinces);

    const userIsUsOnly = useCare1AppSelector(store => store.user.isUsOnly);
    const examIsUsOnly = useCare1AppSelector(store => store.examData.exam_is_us_only);
    const usOnly = userIsUsOnly || examIsUsOnly;

    const provincePlaceholderText = usOnly ? 'Select state' : PROVINCE_PLACEHOLDER_TEXT;
    const countryCode = usOnly ? 'US' : 'CA';
    const provincesToDisplay = provinces.filter(entry => entry.country === countryCode);

    const patientEditDetails = useCare1AppSelector(store => store.patientEdit.details);
    const optometristODgrouppracticeName = useCare1AppSelector(store => store.examData.optometrist_odgrouppractice_name);
    const optometristFullname = useCare1AppSelector(store => store.examData.optometrist_fullname);
    const submissionDate = useCare1AppSelector(store => store.examData.submission_date);

    const details = patientEditDetails;
    const PHNLabel = details[PHN_NAME_FIELD];
    const isOntarioPatient = details[PROVINCE_FIELD] === 9;
    const insuranceTypeOptions: IInsuranceTypeOption[] = useCare1AppSelector(store => store.options.insuranceTypeOptions);
    const insuranceTypeDisplayOptions = insuranceTypeOptions.reduce((reducedOptions, insuranceTypeOption) => {
        // Don't display old OHIP if this is not in Ontario.
        if (isOntarioPatient || insuranceTypeOption.value !== 'old_ohip') {
            let displayLabel = insuranceTypeOption.label;
            if (insuranceTypeOption.value === 'provincial') {
                displayLabel = PHNLabel;
            }

            reducedOptions.push({
                value: insuranceTypeOption.value,
                label: displayLabel,
            });
        }

        return reducedOptions;
    }, [] as IInsuranceTypeOption[]);

    useEffect(() => {
        if (patientId !== null) {
            dispatch(getEditPatientData(patientId));
        }
    }, [dispatch, patientId]);

    return (<div className="patient-validation">
        <Row gutter={16}>
            <Col span={8}>
                Clinic: {optometristODgrouppracticeName}
            </Col>
            <Col span={8}>
                OD: {`Dr. ${optometristFullname}`}
            </Col>
            <Col span={8}>
                Upload Date: {submissionDate}
            </Col>
        </Row>
        <Row>
            <Col span={24} className="divider">
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={24}>
                <div className='title'>Patient Validation</div>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="OD">OD</label>
                    </Col>
                    <Col span={24}>
                        <Select
                            className="patient-exam-od-select"
                            showSearch
                            allowClear={true}
                            optionFilterProp="label"
                            placeholder={OD_SELECT_PLACEHOLDER_TEXT}
                            options={optometristOptions.map((od, index) => { return { value: od.value, label: `Dr. ${od.label}` } })}
                            value={gptOd}
                            onChange={(v) => setGptOd(Number(v))}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Exam Visit Date">Exam Visit Date</label>
                    </Col>
                    <Col span={24}>
                        <DatePicker
                            data-testid='exam-date-picker'
                            className="patient-exam-date-input"
                            showNow={false}
                            format={FRONTEND_LONG_DATE_FORMAT}
                            value={gptExamDate}
                            onChange={(date, dateString) => { setGptExamDate(date) }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={2}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Date of Birth">Date of Birth</label>
                    </Col>
                    <Col span={11}>
                        <Select
                            className={'dob-month'}
                            showSearch
                            allowClear={true}
                            optionFilterProp="label"
                            placeholder={MONTH_TEXT}
                            options={MONTHS.map((month, index) => { return { value: month, label: month } })}
                            value={gptDobMonth}
                            onChange={(v) => setGptDobMonth(v) }
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            className={'dob-day'}
                            placeholder={DAY_TEXT}
                            value={gptDobDay !== null ? `${gptDobDay}` : ''}
                            onChange={(e) => setGptDobDay(e.currentTarget.value) }
                        />
                    </Col>
                    <Col span={7}>
                        <Input
                            className={'dob-year'}
                            placeholder={YEAR_TEXT}
                            value={gptDobYear !== null ? `${gptDobYear}` : ''}
                            onChange={(e) => setGptDobYear(e.currentTarget.value) }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="First Name">First Name</label>
                    </Col>
                    <Col span={24}>
                        <Input
                            className={'first-name-input'}
                            placeholder={FNAME_PLACEHOLDER_TEXT}
                            value={gptFirstname !== null ? `${gptFirstname}` : ''}
                            onChange={(e) => setGptFirstname(e.currentTarget.value) }
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Last Name">Last Name</label>
                    </Col>
                    <Col span={24}>
                        <Input
                            className={'last-name-input'}
                            placeholder={LNAME_PLACEHOLDER_TEXT}
                            value={gptLastname !== null ? `${gptLastname}` : ''}
                            onChange={(e) => setGptLastname(e.currentTarget.value) }
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Phone">Phone</label>
                    </Col>
                    <Col span={24}>
                        <InputMask
                            className={'phone-number-input'}
                            mask={PHONE_MASK_FORMAT}
                            placeholder={PHONE_MASK_FORMAT}
                            value={gptPrimaryPhone !== null ? `${gptPrimaryPhone}` : ''}
                            onChange={(e) => setGptPrimaryPhone(e.currentTarget.value) }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Province">Province</label>
                    </Col>
                    <Col span={24}>
                        <Select
                            placeholder={provincePlaceholderText}
                            showSearch
                            allowClear={true}
                            optionFilterProp="label"
                            className="patient-province-input"
                            options={provincesToDisplay.map((province, index) => { return { value: province.id, label: province.name } })}
                            value={gptProvince !== null ? gptProvince : ''}
                            onChange={(v) => setGptProvince(Number(v)) }                            
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Type of Insurance">Type of Insurance</label>
                    </Col>
                    <Col span={24}>
                        <Select
                            placeholder={'Select Insurance Type'}
                            showSearch
                            allowClear={true}
                            optionFilterProp="label"
                            className="insurance-type-input"
                            data-testid='insurance-type-select'
                            options={insuranceTypeDisplayOptions.map((insuranceTypeOption, index) => { return { value: insuranceTypeOption.value, label: insuranceTypeOption.label } })}
                            value={gptInsuranceType !== null ? `${gptInsuranceType}` : ''}
                            onChange={(v) => setGptInsuranceType(v) }                           />
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="ant-form-item-no-colon" title="Insurace No">Insurace No</label>
                    </Col>
                    <Col span={24}>
                        <Input
                            className={'insurance-input'}
                            placeholder={'Enter insurance No'}
                            value={gptInsurance !== null ? `${gptInsurance}` : ''}
                            onChange={(e) => setGptInsurance(e.currentTarget.value) }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col span={24} className="divider">
            </Col>
        </Row>
    </div>);

}

export default PatientValidation;;
