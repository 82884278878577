import { Button, Modal, Space} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { saveExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { useFormValidation } from '../../../context/useFormValidationContext';
import dayjs from 'dayjs'

const ReferralLetterButtons = () => {

    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const odReferralLetter = useCare1AppSelector(store => store.examData.od_referral_letter);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const examDate = useCare1AppSelector(store => store.examData.exam_date);
    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);

    const isReadOnly = examStatus !== 'not_ready';

    const dispatch = useCare1AppDispatch();

    const { billingRulesForm } = useFormValidation();

   
    const handleSubmit = () => {
    
        Promise.all([
            billingRulesForm && billingRulesForm.validateFields(),
        ])
        .then(() => {
            // Only RLU Premium accounts need to have a referral letter.
            if (odReferralLetter === '' && rluExamPremium) {
                Modal.error({
                    className: 'info-modal',
                    title: 'Referral Letter is required',
                });
            } else if (dayjs().isBefore(dayjs(examDate))) {
                // Check whether today is before exam date, an exam with a future date cannot be submitted
                Modal.error({
                    className: 'info-modal',
                    title: `An exam with a future date cannot be submitted. Please submit this exam on or after ${dayjs(examDate).format('MMM DD YYYY')}`,
                })

            } else {
                if (isOutboundReferral) {
                    dispatch(setExamDataValue('ipc', 'ipc_only'));
                }

                dispatch(setExamDataValue('exam_status', 'od_review'));
                dispatch(saveExamData());
            }
           
        })
        .catch(() => {
            Modal.error({
                className: 'info-modal',
                title: 'Exam cannot be submitted until all required fields ' +
                        'are completed. Please fill out required fields.',
            });
        })
        
    }

    // Takes a boolean value that if true, the page should close after save
    const handleSave = () => {
        dispatch(saveExamData());
    }

    return (
        <Space>
                <Button
                    onClick={handleSave}
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    disabled={isReadOnly}
                    style={{width:'8.25rem'}}
                >
                    Save
                </Button>

                <Button
                    onClick={handleSubmit}
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    disabled={isReadOnly}
                    style={{width: '10rem'}}
                >
                    Submit to Care1
                </Button>
        </Space> 
        
    )
}

export default ReferralLetterButtons;