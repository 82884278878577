import React from 'react';
import { Layout, Spin } from 'antd';
import NavigationControlBar from '../components/navigation-control-bar';

import ReviewProcessingControlBar from '../components/admin/ReviewProcessing/review-processing-control-bar';
import ReviewProcessingTable from '../components/admin/ReviewProcessing/review-processing-table';
import { OPERATING_TEXT } from '../constants';


const { Header, Content } = Layout;

const ReviewProcessingPage: React.FC = () => {


    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={false}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <ReviewProcessingControlBar />
                    
                    <ReviewProcessingTable />
                </Content>
            </Spin>
        </Layout>
    );
}

export default ReviewProcessingPage;