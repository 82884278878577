import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IGptPatientDemographicsPrompt {
    gpt_chat_patient_demographics_api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_chat_patient_demographics_prompts: string,
    gpt_vision_patient_demographics_api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_vision_patient_demographics_prompts: string,
    gpt_vision_patient_demographics_images: string[],
    gpt_chat_patient_demographics_data: IGptPatientEditData,
    gpt_vision_patient_demographics_data: IGptPatientEditData,
}

export interface IGptPatientEditData {
    first_name: string;
    last_name: string;
    gender: string ;
    primary_phone: string;
    email: string;
    province: string;
    phn: string;
    secondary_phn: string;
    address: string;
    city: string;
    postal_code: string;
    gp: string;
    fax_number: string;
    service_number: string;
    caf_personnel: boolean;
    has_no_phn: boolean;
    patient_dob: string;
    exam_date: string;
    is_on_old_ohip: boolean;
}

const patientDemographicsDataInitialState: IGptPatientEditData = {
    first_name: '',
    last_name: '',
    gender: '',
    primary_phone: '',
    email: '',
    province: '',
    phn: '',
    secondary_phn: '',
    address: '',
    city:'',
    postal_code: '',
    gp: '',
    fax_number: '',
    service_number: '',
    caf_personnel: false,
    has_no_phn: false,
    patient_dob: '',
    exam_date: '',
    is_on_old_ohip: false,
};

const initialState: IGptPatientDemographicsPrompt = {
    gpt_chat_patient_demographics_api_status: 'idle',
    gpt_chat_patient_demographics_prompts: '',
    gpt_vision_patient_demographics_api_status: 'idle',
    gpt_vision_patient_demographics_prompts: '',
    gpt_vision_patient_demographics_images: [],
    gpt_chat_patient_demographics_data: patientDemographicsDataInitialState,
    gpt_vision_patient_demographics_data: patientDemographicsDataInitialState,
};

export const fetchAutoGptVisionPatientDemographicsResponse = createAsyncThunk(
    'gptPatientDemographics/fetchAutoGptVisionPatientDemographicsResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_patient_demographics_vision_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)


export const fetchAutoGptChatPatientDemographicsResponse = createAsyncThunk(
    'gptPatientDemographics/fetchAutoGptChatPatientDemographicsResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_patient_demographics_chat_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatPatientDemographicsPrompt = createAsyncThunk(
    'gptPatientDemographics/fetchGptChatPatientDemographicsPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_patient_demographics_chat_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptVisionPatientDemographicsPrompt = createAsyncThunk(
    'gptPatientDemographics/fetchGptVisionPatientDemographicsPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_patient_demographics_vision_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptVisionPatientDemographicsImages = createAsyncThunk(
    'gptPatientDemographics/fetchGptVisionPatientDemographicsImages',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_patient_demographics_images/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const convertResponseToPatientDemographicsData = (responseOutputJSON: any) => {
    return {
        first_name: responseOutputJSON['first_name'] || '',
        last_name: responseOutputJSON['last_name'] || '',
        gender: responseOutputJSON['gender'] || null,
        primary_phone: responseOutputJSON['primary_phone'] || '',
        email: responseOutputJSON['email'] || '',
        province: responseOutputJSON['province'] || '',
        phn: responseOutputJSON['phn'] || '',
        secondary_phn: responseOutputJSON['secondary_phn'] || '',
        address: responseOutputJSON['address'] || '',
        city: responseOutputJSON['city'] || '',
        postal_code: responseOutputJSON['postal_code'] || '',
        gp: responseOutputJSON['gp'] || '',
        fax_number: responseOutputJSON['fax_number'] || '',
        service_number: responseOutputJSON['service_number'] || '',
        caf_personnel: (responseOutputJSON['caf_personnel'] && responseOutputJSON['caf_personnel'] === 'true') ? true: false,
        has_no_phn: (responseOutputJSON['has_no_phn'] && responseOutputJSON['has_no_phn'] === 'true') ? true: false,
        patient_dob: responseOutputJSON['patient_dob'] || '',
        exam_date: responseOutputJSON['exam_date'] || '',
        is_on_old_ohip: (responseOutputJSON['is_on_old_ohip'] && responseOutputJSON['is_on_old_ohip'] === 'true') ? true: false,
    }
}

export const gptPatientDemographicsSlice = createSlice({
    name: 'gptPatientDemographicsSlice',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setGptChatPatientDemographicsData: (state, action) => {
              // Use regex to extract the JSON object part
            const jsonPattern = /{[^]*}/;
            const jsonMatches = action.payload.dataString.match(jsonPattern);

            if (jsonMatches) {
                const jsonResponse = jsonMatches[0];
                const responseOutputJSON = JSON.parse(jsonResponse);
                state.gpt_chat_patient_demographics_data = convertResponseToPatientDemographicsData(responseOutputJSON);
            }
        },
        setGptVisionPatientDemographicsData: (state, action) => {
            // Use regex to extract the JSON object part
            const jsonPattern = /{[^]*}/;
            const jsonMatches = action.payload.dataString.match(jsonPattern);

            if (jsonMatches) {
                const jsonResponse = jsonMatches[0];
                const responseOutputJSON = JSON.parse(jsonResponse);
                state.gpt_vision_patient_demographics_data = convertResponseToPatientDemographicsData(responseOutputJSON);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGptChatPatientDemographicsPrompt.pending, (state) => {
                state.gpt_chat_patient_demographics_api_status = 'loading';
                state.gpt_chat_patient_demographics_prompts = '';
            })
            .addCase(fetchGptChatPatientDemographicsPrompt.fulfilled, (state, action) => {
                state.gpt_chat_patient_demographics_api_status = 'succeeded';
                state.gpt_chat_patient_demographics_prompts = action.payload.gpt_patient_demographics_chat_prompts;
            })
            .addCase(fetchGptChatPatientDemographicsPrompt.rejected, (state) => {
                state.gpt_chat_patient_demographics_api_status = 'failed';
                state.gpt_chat_patient_demographics_prompts = '';
            })
            .addCase(fetchGptVisionPatientDemographicsPrompt.pending, (state) => {
                state.gpt_vision_patient_demographics_api_status = 'loading';
                state.gpt_vision_patient_demographics_prompts = '';
            })
            .addCase(fetchGptVisionPatientDemographicsPrompt.fulfilled, (state, action) => {
                state.gpt_vision_patient_demographics_api_status = 'succeeded';
                state.gpt_vision_patient_demographics_prompts = action.payload.gpt_patient_demographics_chat_prompts;
            })
            .addCase(fetchGptVisionPatientDemographicsPrompt.rejected, (state) => {
                state.gpt_vision_patient_demographics_api_status = 'failed';
                state.gpt_vision_patient_demographics_prompts = '';
            })
            .addCase(fetchGptVisionPatientDemographicsImages.pending, (state) => {
                state.gpt_vision_patient_demographics_api_status = 'loading';
                state.gpt_vision_patient_demographics_prompts = '';
            })
            .addCase(fetchGptVisionPatientDemographicsImages.fulfilled, (state, action) => {
                state.gpt_vision_patient_demographics_api_status = 'succeeded';
                state.gpt_vision_patient_demographics_images = action.payload.gpt_patient_demographics_images;
            })
            .addCase(fetchGptVisionPatientDemographicsImages.rejected, (state) => {
                state.gpt_vision_patient_demographics_api_status = 'failed';
                state.gpt_vision_patient_demographics_prompts = '';
            })
            .addCase(fetchAutoGptVisionPatientDemographicsResponse.pending, (state) => {
                state.gpt_vision_patient_demographics_api_status = 'loading';
                state.gpt_vision_patient_demographics_data = patientDemographicsDataInitialState;
            })
            .addCase(fetchAutoGptVisionPatientDemographicsResponse.fulfilled, (state, action) => {
                state.gpt_vision_patient_demographics_api_status = 'succeeded';
                const responseOutput = JSON.parse(action.payload.gpt_vision_patient_demographics_response)['message']['output'];

                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = responseOutput.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const responseOutputJSON = JSON.parse(jsonResponse);
                    state.gpt_vision_patient_demographics_data = convertResponseToPatientDemographicsData(responseOutputJSON);
                }
            })
            .addCase(fetchAutoGptVisionPatientDemographicsResponse.rejected, (state) => {
                state.gpt_chat_patient_demographics_api_status = 'failed';
                state.gpt_chat_patient_demographics_data = patientDemographicsDataInitialState;
            })
            .addCase(fetchAutoGptChatPatientDemographicsResponse.pending, (state) => {
                state.gpt_chat_patient_demographics_api_status = 'loading';
                state.gpt_chat_patient_demographics_data = patientDemographicsDataInitialState;
            })
            .addCase(fetchAutoGptChatPatientDemographicsResponse.fulfilled, (state, action) => {
                state.gpt_chat_patient_demographics_api_status = 'succeeded';
                const responseOutput = JSON.parse(action.payload.gpt_chat_patient_demographics_response)['message']['output'];

                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = responseOutput.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const responseOutputJSON = JSON.parse(jsonResponse);
                    state.gpt_chat_patient_demographics_data = convertResponseToPatientDemographicsData(responseOutputJSON);
                }
            })
            .addCase(fetchAutoGptChatPatientDemographicsResponse.rejected, (state) => {
                state.gpt_chat_patient_demographics_api_status = 'failed';
                state.gpt_chat_patient_demographics_data = patientDemographicsDataInitialState;
            })
    },
});

export const { setGptChatPatientDemographicsData, setGptVisionPatientDemographicsData
    } = gptPatientDemographicsSlice.actions;

export default gptPatientDemographicsSlice.reducer;
