// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState } from "react";
import { Row, Col, Spin, Card } from 'antd';
import '../../../../static/css/components/admin-gpt.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import PDFViewer from "../../retina/pdf-viewer";
import GptAISnapshotDisease from './gpt-ai-snapshot-disease';
import GptAISnapshotOcular from './gpt-ai-snapshot-ocular';
import GptAISnapshotAssessment from "./gpt-ai-snapshot-assessment";
import GptAISnapshotSystemicTreatmentImportant from "./gpt-ai-snapshot-systemic-treatment-important";
import GptAISnapshotBand from './gpt-ai-snapshot-band';
import GptChatAISnapshotDisease from "./gpt-chat-ai-snapshot-disease";
import GptChatAISnapshotOcular from "./gpt-chat-ai-snapshot-ocular";
import GptChatAISnapshotAssessment from "./gpt-chat-ai-snapshot-assessment";
import GptChatAISnapshotSystemicTreatmentImportant from "./gpt-chat-ai-snapshot-systemic-treatment-important";

import { retrieveGptAiSnapshotBandValues, retrieveGptChatAiSnapshotBandValues } from '../../../reducers/gpt-ai-snapshot-slice';

const GptAISnapshot = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dispatch = useCare1AppDispatch();

    const apiStatus = useCare1AppSelector(store => store.referralLetterAnalysis.api_status);

    const visionImpressions = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_impressions);
    const visionOdAssessment = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_od_assessment);
    const visionOdQuestion = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_od_question);
    const visionExamODNotes = useCare1AppSelector(store => store.examData.od_omd);
    const chatImpressions = useCare1AppSelector(store => store.gptAiSnapshot.gpt_chat_ai_snapshot_band_impressions);
    const chatOdAssessment = useCare1AppSelector(store => store.gptAiSnapshot.gpt_chat_ai_snapshot_band_od_assessment);
    const chatOdQuestion = useCare1AppSelector(store => store.gptAiSnapshot.gpt_chat_ai_snapshot_band_od_question);
    const chatExamODNotes = useCare1AppSelector(store => store.examData.od_omd);
    const examId = useCare1AppSelector(store => store.examData.id);
    const currentPhotoURL = useCare1AppSelector(store => store.examData.od_referral_letter);

    const divStyle = {
        height: `${windowHeight - 100}px`,
    };

    const getVisionAiSnapshotBandValues = () => {
        if (examId != null) {
            dispatch(retrieveGptAiSnapshotBandValues(examId));
        }
    }

    const getChatAiSnapshotBandValues = () => {
        if (examId != null) {
            dispatch(retrieveGptChatAiSnapshotBandValues(examId));
        }
    }

    const visionAISnapshotBandProps = {
        snapshotType: 'GPT Vision',
        impressions: visionImpressions,
        odAssessment: visionOdAssessment,
        odQuestion: visionOdQuestion,
        examODNotes: visionExamODNotes,
        impressionsField: 'gpt_ai_snapshot_band_impressions',
        odAssessmentField: 'gpt_ai_snapshot_band_od_assessment',
        odQuestionField: 'gpt_ai_snapshot_band_od_question',
        examODNotesField: 'od_omd',
        getAiSnapshotBandValues: getVisionAiSnapshotBandValues
    };

    const chatAISnapshotBandProps = {
        snapshotType: 'GPT Chat',
        impressions: chatImpressions,
        odAssessment: chatOdAssessment,
        odQuestion: chatOdQuestion,
        examODNotes: chatExamODNotes,
        impressionsField: 'gpt_chat_ai_snapshot_band_impressions',
        odAssessmentField: 'gpt_chat_ai_snapshot_band_od_assessment',
        odQuestionField: 'gpt_chat_ai_snapshot_band_od_question',
        examODNotesField: 'od_omd',
        getAiSnapshotBandValues: getChatAiSnapshotBandValues
    };

    const onPDFLoadSuccess = (numPages: number) => {
    }

    const onPDFLoadError = () => {
    }

    const onClose = () => {
    }

    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={apiStatus === 'loading'}
        >
            <div className="chat-container" style={divStyle}>
                <Row>
                    <Col
                        span={12}
                        className={'pdfViewerModal no-overflow padding-col'}>
                        <PDFViewer
                            file={currentPhotoURL.replace('png', 'pdf')}
                            onPDFLoadSuccess={onPDFLoadSuccess}
                            onPDFLoadError={onPDFLoadError}
                            onClose={onClose}
                            isGPTTab={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <GptAISnapshotBand {...visionAISnapshotBandProps}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <GptAISnapshotBand {...chatAISnapshotBandProps}/>
                    </Col>
                </Row>
                <div className="gpt-ai-snapshot" >
                    <br />
                    <Row gutter={6} >
                        <Col span={12}>
                            <div>GPT Vision AI Snapshot OD Assessment</div>
                            <GptAISnapshotAssessment />
                        </Col>
                        <Col span={12}>
                            <div>GPT Chat AI Snapshot OD Assessment</div>
                            <GptChatAISnapshotAssessment />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={6} >
                        <Col span={12}>
                            <div>GPT Vision AI Snapshot Systemic, Treatment, Important</div>
                            <GptAISnapshotSystemicTreatmentImportant />
                        </Col>
                        <Col span={12}>
                            <div>GPT Chat AI Snapshot Systemic, Treatment, Important</div>
                            <GptChatAISnapshotSystemicTreatmentImportant />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={6} >
                        <Col span={12}>
                            <div>Gpt Vision AI Snapshot Disease</div>
                            <GptAISnapshotDisease />
                        </Col>
                        <Col span={12}>
                            <div>Gpt Chat AI Snapshot Disease</div>
                            <GptChatAISnapshotDisease />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={6} >
                        <Col span={12}>
                            <div>Gpt Vision AI Snapshot Ocular</div>
                            <GptAISnapshotOcular />
                        </Col>
                        <Col span={12}>
                            <div>Gpt Chat AI Snapshot Ocular</div>
                            <GptChatAISnapshotOcular />
                        </Col>
                    </Row>
                    <br />
                </div>
            </div>
        </Spin>)
}

export default GptAISnapshot;