import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SorterResult } from 'antd/es/table/interface';
import { IReviewProcessingTableItem, reviewProcessingApi } from '../services/review-processing-api';
import {Dayjs} from 'dayjs';

const TemplateText = 'Hello\n\n' + 
'Please see below for comments about your patients. These are patients that either you or the Care1 AI have requested an OMD report to be emailed.\n\n' + 
'Sincerely,\n\n' + 
'Care1 Team\n\n';

const InternalReviewTemplateText = 'To whom it may concern:';

export interface IReviewProcessingPatientInfo {
    key: React.Key;
    patientName: string;
    examDate: string;
    omdNotes: string;
}

export interface IReviewProcessingTaskItem {
    guid: string;
    examId: number | undefined;
}

export interface IReviewProcessing {
    status: string;
    error: string;
    selectedRowKeys: React.Key[];
    sortOrder: string[];
    sortedInfo: SorterResult<IReviewProcessingTableItem> | SorterResult<IReviewProcessingTableItem>[];
    previewModalOpen: boolean;
    emailConfirmationModalOpen: boolean;
    isDarkTheme: boolean;
    startDate: string;
    endDate: string;
    odId: number | null;
    selectedOdName: string;
    odEmails: string[];
    emailSubject: string;
    patientReviewInfoText: string;
    templateText: string;
    taskItems: IReviewProcessingTaskItem[];
    odAiRequestsReport: boolean;
}

const initialState: IReviewProcessing = {
    selectedRowKeys: [],
    sortOrder: [],
    sortedInfo: {},
    previewModalOpen: false,
    emailConfirmationModalOpen: false,
    status: 'idle',
    error: '',
    isDarkTheme: true,
    startDate: '',
    endDate: '',
    odId: null,
    selectedOdName: '',
    odEmails: [],
    emailSubject: 'Care1 Reviews',
    patientReviewInfoText: '',
    templateText: TemplateText,
    taskItems: [],
    odAiRequestsReport: true,
};

export const reviewProcessingSlice = createSlice({
    name: 'reviewProcessing',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setReviewProcessingDataValue: (state, action: PayloadAction<{key: string, value: boolean | string[] | string}>) => {
            // sample action.payload {key:'memberId', value: '12345'}
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        },
        resetPreReview: () => initialState,
        setSelectedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
            state.selectedRowKeys = action.payload;
        },
        setTaskItems: (state, action: PayloadAction<IReviewProcessingTaskItem[]>) => {
            state.taskItems = action.payload;
        },
        setSortOrder: (state, action: PayloadAction<string[]>) => {
            state.sortOrder = action.payload;
        },
        setSortedInfo: (state, action: PayloadAction<SorterResult<IReviewProcessingTableItem> | SorterResult<IReviewProcessingTableItem>[]>) => {
            return {
                ...state,
                sortedInfo: action.payload,
            }
        },
        togglePreviewModal: (state) => {
            state.previewModalOpen = !state.previewModalOpen;
        },
        toggleEmailConfirmationModal: (state) => {
            state.emailConfirmationModalOpen = !state.emailConfirmationModalOpen;
        },
        toggleReviewProcessingModalTheme: (state) => {
            state.isDarkTheme = !state.isDarkTheme;
        },
        setReviewProcessingRequestData: (state, action: PayloadAction<{date: Dayjs[], od_id: number | null, od_ai_requests_report: boolean}>) => {
            const [startDate, endDate] = action.payload.date;
            state.startDate = startDate.format('YYYY-MM-DD');
            state.endDate = endDate.format('YYYY-MM-DD');
            state.odId = action.payload.od_id;
            state.odAiRequestsReport = action.payload.od_ai_requests_report;
            if (action.payload.od_ai_requests_report) {
                state.templateText = TemplateText;
            } else {
                state.templateText = InternalReviewTemplateText;
            }
        },
        setSelectedOdName: (state, action : PayloadAction<string>) => {
            state.selectedOdName = action.payload;
        },
        setOdEmails: (state, action : PayloadAction<string[]>) => {
            state.odEmails = action.payload;
        },
        // setReviewProcessingPatientInfo: (state, action: PayloadAction<IReviewProcessingPatientInfo[]>) => {
        //     state.patientInfo = action.payload;
        // },
        setReviewProcessingPatientInfoText: (state, action: PayloadAction<string>) => {
            state.patientReviewInfoText = action.payload;
        },
        setEmailSubject: (state, action: PayloadAction<string>) => {
            state.emailSubject = action.payload;
        },
        setTemplateText: (state, action: PayloadAction<string>) => {
            state.templateText = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(reviewProcessingApi.endpoints.getReviewProcessingOdEmails.matchFulfilled, (state, action) => {
            state.odEmails = action.payload.success ? action.payload.data : [];
        });
        builder.addMatcher(reviewProcessingApi.endpoints.getReviewProcessingOmdInternalReviewEmails.matchFulfilled, (state, action) => {
            state.odEmails = action.payload.success ? action.payload.data : [];
        });
    }
});

export const {  setReviewProcessingDataValue, setSelectedRowKeys, setTaskItems, setSortOrder, setSortedInfo, togglePreviewModal, toggleReviewProcessingModalTheme, setReviewProcessingRequestData, setSelectedOdName, setOdEmails, toggleEmailConfirmationModal, setReviewProcessingPatientInfoText, setEmailSubject, setTemplateText } = reviewProcessingSlice.actions;

export default reviewProcessingSlice.reducer;