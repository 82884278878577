import { useEffect, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Modal, DatePicker, Form, Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';

import '../../../static/css/components/exam-date-select-modal.scss';
import { closeExamDateSelectModal, openPatientDetailsModal } from '../../reducers/patient-details-slice';
import { getNewPatientExam } from '../../reducers/patient-exam-slice';


const ExamDateSelectModal = () => {

    const [examDate, setExamDate] = useState<Dayjs | null>(dayjs());
    const isExamDateModalOpen = useCare1AppSelector(store => store.patientDetails.examDateModalVisible);
    const patientID = useCare1AppSelector(store => store.patientDetails.examDateSelectPatientId);
    const lastOmdReviewedOrReadyVisitDate = useCare1AppSelector(store => store.patientDetails.details.last_omd_reviewed_or_ready_visit_date);
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);
    const referralLetterExamDateAllowedToSet = useCare1AppSelector(store => store.patientDetails.details.referral_letter_exam_date_allowed_to_set);
    

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const onCreateButtonClick = () => {
        dispatch(getNewPatientExam(patientID!, examDate?.format()!, history));
        dispatch(closeExamDateSelectModal());
    }

    const onCancelButtonClick = () => {
        dispatch(closeExamDateSelectModal());
        // Case 1, new exam is created by clicking 'New Visit' in patient details modal
        // Case 2, new exam is created by clicking 'New Visit' in patient list table (when patient has
        // 0 exams, 'New Visit' will display instead of 'Latest Visit')
        // Case 3, new exam is created after new patient is added
        // For case 1, reopen the paitent details modal
        // For case 2 and 3, don't do anything
        patientID && dispatch(openPatientDetailsModal());
    }

    const handleDateChange = (date: Dayjs | null) => {
        const dateToCheck = odUsesReferralLetterPei ? referralLetterExamDateAllowedToSet : lastOmdReviewedOrReadyVisitDate;
        if(date && dateToCheck){
            if(date.isBefore(dayjs(dateToCheck))){
                Modal.info({
                    className: 'info-modal white-space-modal',
                    title: `New Exam date cannot be older than the most recent submitted exam date. \n
                        The most recent submitted exam for this patient has an exam date of ${dayjs(dateToCheck).format('YYYY-MM-DD')}. 
                        Please create a new exam with an exam date later than ${dayjs(dateToCheck).format('YYYY-MM-DD')}.`
                })
            } else {
                setExamDate(date);
            }
        } else {
            if(date){
                setExamDate(date);
            }
        }
    }

    useEffect(() => {
        // set the default exam date to today's date when exam-date-select mounts
        const currentDate = dayjs();
        setExamDate(currentDate);
        // clear the default exam date
        return () => {
            setExamDate(null);
        }
    },[])

    return (
        <Modal
            open={isExamDateModalOpen}
            className='exam-date-select-modal'
            footer={null}
            width={360}
            onCancel={() => onCancelButtonClick()}
        >
            <Row className='header-section' align='middle' justify='center'>
                <Col>
                    <div className='modal-title'>Select Exam Date</div>
                </Col>
            </Row>
            <Row gutter={5} align='middle' justify='center'>
                <Col>
                    <Form.Item
                        label="Exam Date"
                        colon={false}
                    >
                        <DatePicker
                            onChange={date => handleDateChange(date)}
                            value={examDate}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='center' gutter={20} className='buttons-group'>
                <Col>
                    <Button onClick={onCreateButtonClick} className='create-button' >
                        <SaveOutlined />Create
                    </Button>
                </Col>
                <Col>
                    <Button onClick={onCancelButtonClick} className='cancel-button' >
                        <CloseCircleOutlined />Cancel
                    </Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ExamDateSelectModal;