import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { Modal } from 'antd';
import Care1LightBox from './care1-light-box';

import { DELETE_CONFIRMATION_MODAL_TITLE, DELETE_CONFIRMATION_MODAL_TEXT, DELETE_CONFIRMATION_OK_TEXT,
    DELETE_CONFIRMATION_CANCEL_TEXT, FILE_UPLOAD_FAILED_TEXT, API_UPLOAD_IMG_URL
} from '../../constants';
import { InfoCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import TrashButton from '../../../static/images/trash_button.svg';
import '../../../static/css/components/photo-dropzone.scss';

// Helpers
import { photoDropzoneValidityCheck } from '../../helpers/patient-exam-convert';
import { getFilepathFromBackendMediaUrl } from '../../helpers/media-image-convert';

// Components
import { DropzoneComponent } from 'react-dropzone-component';
import SwapImageDropdown from '../admin/swap-image-dropdown';
import dayjs from 'dayjs';
import { dropzoneSlice } from '../../reducers/dropzone-slice';
import { HistoryDropzoneField, IAllExamImages, retrievePhotoUrls } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

import Dropzone from 'dropzone';
import * as ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';


type ComponentProps = {
    disabled: boolean,
    field: HistoryDropzoneField,
    label: string,
    dropText: string,
    formatText: string,
    carousel: IAllExamImages[] | null,
}


const displayNone = { display: 'none' };

const getWindowDimensions = () => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
        windowWidth,
        windowHeight
    };
  }
  
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}


  
const PhotoDropzone = ({ disabled, field, label, dropText, formatText, carousel }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();
    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const examId = useCare1AppSelector(store => store.examData.id);
    const photoUrls = useCare1AppSelector(store => store.examData.photoUrls, shallowEqual);
    const pastPhotos = useCare1AppSelector(store => store.examData.pastPhotos, shallowEqual);
    const dropzones = useCare1AppSelector(store => store.dropzones);
    const csrfToken = useCare1AppSelector(store => store.user.csrfToken);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const [dropzoneObject, setDropzoneObject] = useState<Dropzone | null>(null);
    const [carouselPhotoIndex, setCarouselPhotoIndex] = useState(-1);
    const [carouselStartIndex, setCarouselStartIndex] = useState(-1);
    const examDate = useCare1AppSelector(store => store.examData.exam_date);

    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const {windowWidth, windowHeight} = useWindowDimensions();

    const dropzoneInstance = dropzoneSlice[field];

    const { deletePhotoCancelled, deletePhotoConfirm, retrievePhotoRequest, retrievePhotoSuccess, setUploadProgress, toggleLightbox, uploadPhotoFailure, uploadPhotoRequest, uploadPhotoSuccess} = dropzoneInstance.actions;

    const { deletePhoto } = dropzoneInstance;

    // determine whether the height is bigger or width is bigger, only scale images with small width and height
    const SCALE_RATIO = 1.5
    const dropzoneWrapperClassName = useMemo(() => {
        return imageHeight && imageWidth
                ? (imageHeight > imageWidth) && (windowHeight/imageHeight >= SCALE_RATIO)
                    ? 'height-constraint-image' 
                    : (imageHeight < imageWidth) && (windowWidth/imageWidth >= SCALE_RATIO)
                        ? 'width-constraint-image'
                        : ''
                :'';
    }, [imageHeight, imageWidth, windowHeight, windowWidth]) 

    const acceptFileFormat = 'image/jpeg,image/jpg,image/png,image/gif,image/bmp,application/pdf,application/x-pdf';

    const componentConfig = { postUrl: `${process.env.REACT_APP_BACKENDURL}${API_UPLOAD_IMG_URL}` };

    const onInit = (dropzone: Dropzone) => {
        setDropzoneObject(dropzone);
    }

    const sendUploadPhotoRequest = (e: DragEvent) => {
        // Do not allow photo upload if component is disabled as read only
        if (!disabled) {

            const file = (e?.dataTransfer?.files.length) ? e.dataTransfer.files[0] : '';
            if (file) {
                try {
                    const ext = file.name.split('.').pop();
                    if (ext) {
                        if (acceptFileFormat.indexOf(ext.toLowerCase()) !== -1) {
                            
                            // Clear the current file. Sorry, yes I know this makes the class stateful, but this is required by the way
                            // the dropzone component works.
                            if (dropzoneObject) {
                                dropzoneObject.removeAllFiles();
                            }
    
                            dispatch(uploadPhotoRequest(file));
                        }
                    }
                }
                catch {
    
                }

            }
        }
    }

    const sendRetrievePhotoSuccess = (file: Dropzone.DropzoneFile, dataUrl: string) => {
        // Dispatch a retrieve photo success, since the fact that there is a thumbnail means the retrieve
        // succeeded.
        dispatch(retrievePhotoSuccess({photo: file, thumbnailUrl: dataUrl}));
    }

    const sendUploadProgress = (_: Dropzone.DropzoneFile, percentage: number) => {
        dispatch(setUploadProgress(percentage));
    }

    const sendUploadPhotoFailure = (file: Dropzone.DropzoneFile, requestError: string | Error) => {

        try {
            const ext = file.name.split('.').pop();
            if (ext) {
                if (acceptFileFormat.indexOf(ext.toLowerCase()) === -1) {

                    const message = 'This photo is not supported';
                    Modal.error({
                        className: 'info-modal',
                        content: message,
                        title: 'Photo not supported',
                    });      
                    return;
                }
            }
        }
        catch {

        }

        // If this is an error from a request, use the request status.
        let error;
        if (requestError) {
            error = FILE_UPLOAD_FAILED_TEXT;
        } else {
            error = 'Errors uploading photos';
        }

        dispatch(uploadPhotoFailure(error));
    }

    const sendUploadPhotoSuccess = (_: Dropzone.DropzoneFile, __: Object) => {
        dispatch(uploadPhotoSuccess());

        // Also retrieve the updated exam data after uploading successfully.
        dispatch(retrievePhotoUrls(examId!));
    }

    const eventHandlers = {
        init: onInit,
        drop: sendUploadPhotoRequest,
        thumbnail: sendRetrievePhotoSuccess,
        uploadprogress: sendUploadProgress,
        error: sendUploadPhotoFailure,
        success: sendUploadPhotoSuccess,
    };

    const sendDeletePhotoConfirm = () => {
        dispatch(deletePhotoConfirm());
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: DELETE_CONFIRMATION_MODAL_TITLE,
            content: DELETE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: DELETE_CONFIRMATION_OK_TEXT,
            cancelText: DELETE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: { className: 'confirm-exit' },
            onCancel: () => {
                sendDeletePhotoCancelled();
            },
            onOk: () => {
                sendDeletePhotoRequest();
            },
        });
    }

    const sendDeletePhotoCancelled = () => {
        // Send the action to close the confirmation dialog without doing anything.
        dispatch(deletePhotoCancelled());
    }

    const sendDeletePhotoRequest = async () => {
        try {
            await dispatch(deletePhoto());
            dispatch(retrievePhotoUrls(examId!));
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors deleting photo',
            });
        }
    }

    const sendToggleLightbox = () => {
        setCarouselPhotoIndex(carouselStartIndex);
        // setOpenCare1LightBox(! lightboxOn);
        dispatch(toggleLightbox());
    }

    /*** IMAGE RENDER LOGIC START ***/
    // The useCallback function allows sendRetrievePhotoRequest to be reusable in two uses:
    // 1. when the component mounts; 2. for every re-render caused by a change to the currentPhotoURL
    const currentPhotoURL = photoUrls[field];
    const sendRetrievePhotoRequest = useCallback(() => {
        // Disable dropzone image caching by the browser
        const photoUrl = currentPhotoURL ? `${currentPhotoURL}?r=${Math.random()}` : currentPhotoURL;

        // With a retrieve request, we're just letting the dropzone component get the photo from the backend using
        // the photo URL.
        if (photoUrl && dropzoneObject) {

            dropzoneObject.removeAllFiles();

            const urlNamePortions = photoUrl.split('/');
            const file = { name: urlNamePortions[urlNamePortions.length - 1] } as File;
            dispatch(retrievePhotoRequest(file));
        }
    }, [dispatch, dropzoneObject, currentPhotoURL, retrievePhotoRequest]);

    // This useEffect is triggered when component mounts and then again when the image changes
    useEffect(() => {
        sendRetrievePhotoRequest();
    }, [sendRetrievePhotoRequest]);
    /*** RENDER IMAGE LOGIC END ***/

    const hasPhoto = dropzones[field].photo !== '';
    const photoName = hasPhoto ? (dropzones[field].photo as File).name : '';
    const hasError = dropzones[field].error !== '';
    const errorMessage = hasError ? dropzones[field].error : '';
    const inProgress = dropzones[field].operationRunning;
    const progressPercentage = dropzones[field].percentage;
    const photoUrl = photoUrls[field];
    const { lightboxOn } = dropzones[field];
    const isValid = photoDropzoneValidityCheck(dropzones[field]);
    const pastPhotoUrl = pastPhotos[`past_${field}`];
    const pastExamDate = pastPhotos[`past_${field}_date`];

    // safety check incase photoUrl or pastPhotoUrl is undefined or null
    const extensionIndex = photoUrl ? photoUrl.lastIndexOf(".") : -1;
    const photoThumbnailUrl = photoUrl ? `${photoUrl.substring(0, extensionIndex)}_largethumb${photoUrl.substring(extensionIndex)}` : '';
    const pastExtensionIndex = pastPhotoUrl ? pastPhotoUrl.lastIndexOf(".") : -1;
    const pastPhotoThumbnailUrl = pastPhotoUrl ? `${pastPhotoUrl.substring(0, pastExtensionIndex)}_largethumb${pastPhotoUrl.substring(pastExtensionIndex)}` : '';

    // carousel
    const carouselValidImagesSet = new Set();
    const carouselValidImages: (string | undefined)[] = [];
    const carouselValidThumbnails = [];
    const carouselValidImageTitleSet = new Set();
    const carouselValidImageTitle = [];
    if (carousel) {
        for (let i = 0; i < carousel.length; i++) {
            const historyImages = carousel[i];
            if (historyImages[field]) {

                const carouselPhotoUrl = historyImages[field];

                const carouselExtensionIndex = carouselPhotoUrl ? carouselPhotoUrl.lastIndexOf(".") : -1;
                const carouselPhotoThumbnailUrl = carouselPhotoUrl ? `${carouselPhotoUrl.substring(0, carouselExtensionIndex)}_largethumb${carouselPhotoUrl.substring(carouselExtensionIndex)}` : '';

                carouselValidImages.push(carouselPhotoUrl);
                carouselValidThumbnails.push(carouselPhotoThumbnailUrl);

                carouselValidImagesSet.add(carouselPhotoUrl);

                carouselValidImageTitleSet.add(historyImages['exam_date']);
                carouselValidImageTitle.push(historyImages['exam_date']);
            }
        }

        if (photoUrl) {
            if (!carouselValidImagesSet.has(photoUrl)) {
                carouselValidImages.unshift(photoUrl);
                carouselValidThumbnails.unshift(photoThumbnailUrl);
            }
            const examDateString = dayjs(examDate).format('MMMM D, YYYY');
            if (!carouselValidImageTitleSet.has(examDateString)){
                carouselValidImageTitle.unshift(examDateString);
            }
        }
        else if (pastPhotoUrl) {
            if (!carouselValidImagesSet.has(pastPhotoUrl)) {
                carouselValidImages.unshift(pastPhotoUrl);
                carouselValidThumbnails.unshift(pastPhotoThumbnailUrl);
            }
            const pastExamDateString = dayjs(pastExamDate).format('MMMM D, YYYY');
            if (!carouselValidImageTitleSet.has(pastExamDateString)){
                carouselValidImageTitle.unshift(pastExamDateString);
            }
        }

        if (carouselPhotoIndex === -1) {
            setCarouselPhotoIndex(0);
            setCarouselStartIndex(0);
        }

    }

    const djsConfig = {
        acceptedFiles: acceptFileFormat,
        clickable: false,
        createImageThumbnails: false,
        withCredentials: true,
        headers: { 'X-CSRFToken': csrfToken },
        maxFiles: disabled ? 0 : 1,
        paramName: field,
        params: { exam: examId },
        // Some of the divs in the preview area are needed by the react dropzone component, so they are permanently
        // hidden, but are not used by us.
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview">
                <div className="dz-details" style={displayNone}>
                    <div className="dz-filename"><label data-dz-name="true" /></div>
                </div>
                <div className="dz-progress" style={displayNone}><label className="dz-upload" data-dz-uploadprogress="true" /></div>
                <div className="dz-error-message" style={displayNone}><label data-dz-errormessage="true" /></div>
            </div>,
        )
    };

    // Determine the classes used for the picture preview zone.
    // By default nothing is displayed, but in the case of an error, the error is displayed
    const errorMessageClass = classNames({
        'dropzone-error': true,
        'dz-hide': disabled || !hasError,
    });

    // If an operation is in progress, the progress bar is shown.
    const progressBarClass = classNames({
        'dropzone-progress-container': true,
        'dz-hide': !inProgress,
    });

    const progressWidth = { width: `${progressPercentage}%` };

    // The preview image is shown if there is no error and nothing is in progress.
    const detailsClass = classNames({
        'dropzone-details': true,
        'dz-hide': (!disabled && hasError) || !hasPhoto,
    });

    // Determine the classes used for the borders of the dropzone.
    // If there is a photo, the dropzone is considered valid, and it's considered invalid if there is no photo.
    // Validity causes a green or red border to show.
    const dropzoneBorderClass = classNames({
        'photo-dropzone': true,
        'photo-dropzone-valid': isValid,
        'photo-dropzone-invalid': !isValid,
        'photo-dropzone-disabled': disabled,
    });

    // The dropzone message (e.g. drog photos here...) is only displayed if there are no photos or errors.
    const dropzoneMessageClass = classNames({
        'dz-message': true,
        'dz-hide': hasPhoto || (!disabled && hasError),
    });

    const dropzoneDeleteClass = classNames({
        'dropzone-delete': true,
        'dz-hide': disabled,
    });

    const download = (file: string) => {
        const fileUrl = getFilepathFromBackendMediaUrl(file);

        const a = document.createElement('a');
        a.href = `${process.env.REACT_APP_BACKENDURL}/data/download/${fileUrl}`;
        a.download = fileUrl.split('/')[fileUrl.split('/').length - 1];

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const downloadCarouselValidImages = () => {
        const mainSrc : string = `${carouselValidImages[carouselPhotoIndex]}`;
        download(mainSrc);
    }

    return (
        examId ?
        /* @ts-ignore:next-line */
        <DropzoneComponent
            class={dropzoneBorderClass}
            id={field}
            config={componentConfig}
            djsConfig={djsConfig}
            eventHandlers={eventHandlers}
        >
            {!hasPhoto && pastPhotoUrl ? (
                <div className="dz-message past-photo" onClick={sendToggleLightbox} aria-hidden="true">
                    <img src={pastPhotoUrl} data-testid='past-image' className="dropzone-past-image" alt="" />
                    <div className="dropzone-empty-overlay">
                        <div className='past-photo-date' data-testid='past-photo-date'>{pastExamDate}</div>
                    </div>
                </div>
            ) : (
                    <div className={dropzoneMessageClass}>
                        { userIsOD &&

                            <div className="dropzone-empty-image"></div>
                        }
                        <div className="dropzone-empty-label">{label}</div>
                        { userIsOD &&
                            <div className="dropzone-empty-text">{dropText}</div>
                        }
                        <div className="dropzone-empty-formats" />
                    </div>
                )}

            <div className={errorMessageClass}>
                <label>{errorMessage}</label>
                <p />
                <label data-testid='image-file' className="dropzone-filename">{photoName}</label>
            </div>
            <div className={detailsClass}>
                <img
                    className="dropzone-image"
                    src={photoThumbnailUrl}
                    alt={photoName}
                    onClick={sendToggleLightbox}
                    onError={
                        (e)=>{
                            // If the the thumbnail image can't be found, just use the full image.
                            if (e.currentTarget.src !== photoUrl) {
                                e.currentTarget.src=photoUrl;
                            }
                        }
                    }
                />

                <img
                    className={dropzoneDeleteClass}
                    onClick={sendDeletePhotoConfirm}
                    src={TrashButton}
                    alt="Delete"
                />

                { userIsAdmin && <SwapImageDropdown field={field}/> }

            </div>
            <div className={progressBarClass}>
                <div className="dropzone-progress" style={progressWidth} />
            </div>
            {photoUrl && !carousel
                && (
                    <Care1LightBox
                        field={field}
                        isOpen={lightboxOn}
                        mainSrc={photoUrl}
                        mainSrcThumbnail={photoThumbnailUrl}
                        onCloseRequest={sendToggleLightbox}
                        wrapperClassName={dropzoneWrapperClassName}
                        /* @ts-ignore:next-line */
                        onImageLoad={(src, imageType, image) => {
                            setImageHeight(image.naturalHeight);
                            setImageWidth(image.naturalWidth);
                        }}
                        toolbarButtons={[<CloudDownloadOutlined className="care1-lightbox-download-icon" onClick={() => download(photoUrl)} />]}
                    />
                )}
            {photoUrl && carousel
                && (
                    <Care1LightBox
                        field={field}
                        isOpen={lightboxOn}
                        mainSrc={carouselValidImages[carouselPhotoIndex] ?? ''}
                        nextSrc={carouselValidImages[(carouselPhotoIndex + 1) % carouselValidImages.length]}
                        prevSrc={carouselValidImages[(carouselPhotoIndex + carouselValidImages.length - 1) % carouselValidImages.length]}
                        onMovePrevRequest={() =>
                            setCarouselPhotoIndex((carouselPhotoIndex + carouselValidImages.length - 1) % carouselValidImages.length)
                        }
                        onMoveNextRequest={() =>
                            setCarouselPhotoIndex((carouselPhotoIndex + 1) % carouselValidImages.length)
                        }
                        onCloseRequest={sendToggleLightbox}
                        wrapperClassName={dropzoneWrapperClassName}
                        /* @ts-ignore:next-line */
                        onImageLoad={(src: string,imageType: string,image: HTMLImageElement) => {
                            setImageHeight(image.naturalHeight);
                            setImageWidth(image.naturalWidth);
                        }}
                        imageTitle={carouselValidImageTitle[carouselPhotoIndex]}
                        toolbarButtons={[<CloudDownloadOutlined className="care1-lightbox-download-icon" onClick={() => downloadCarouselValidImages()} />]}
                    />
                )}
            {pastPhotoUrl && !photoUrl && !carousel
                && (
                    <Care1LightBox
                        field={field}
                        isOpen={lightboxOn}
                        mainSrc={pastPhotoUrl}
                        mainSrcThumbnail={pastPhotoThumbnailUrl}
                        onCloseRequest={sendToggleLightbox}
                        wrapperClassName={dropzoneWrapperClassName}
                        /* @ts-ignore:next-line */
                        onImageLoad={(src: string, imageType: string, image: HTMLImageElement) => {
                            setImageHeight(image.naturalHeight);
                            setImageWidth(image.naturalWidth);
                        }}
                        toolbarButtons={[<CloudDownloadOutlined className="care1-lightbox-download-icon" onClick={() => download(pastPhotoUrl)} />]}
                    />
                )}
                {pastPhotoUrl && !photoUrl && carousel
                && (
                    <Care1LightBox
                        field={field}
                        isOpen={lightboxOn}
                        mainSrc={carouselValidImages[carouselPhotoIndex] ?? ''}
                        nextSrc={carouselValidImages[(carouselPhotoIndex + 1) % carouselValidImages.length]}
                        prevSrc={carouselValidImages[(carouselPhotoIndex + carouselValidImages.length - 1) % carouselValidImages.length]}
                        onMovePrevRequest={() =>
                            setCarouselPhotoIndex((carouselPhotoIndex + carouselValidImages.length - 1) % carouselValidImages.length)
                        }
                        onMoveNextRequest={() =>
                            setCarouselPhotoIndex((carouselPhotoIndex + 1) % carouselValidImages.length)
                        }
                        onCloseRequest={sendToggleLightbox}
                        wrapperClassName={dropzoneWrapperClassName}
                        /* @ts-ignore:next-line */
                        onImageLoad={(src: string, imageType: string, image: HTMLImageElement) => {
                            setImageHeight(image.naturalHeight);
                            setImageWidth(image.naturalWidth);
                        }}
                        imageTitle={carouselValidImageTitle[carouselPhotoIndex]}
                        toolbarButtons={[<CloudDownloadOutlined className="care1-lightbox-download-icon" onClick={() => downloadCarouselValidImages()} />]}
                    />
                )}
        </DropzoneComponent>
        :
        null
    );
}

export default PhotoDropzone;
