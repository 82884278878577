import  { useEffect, useRef } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';

interface JqPlotObject {
    replot: () => void;
}


const AdminAIInsightsScoreProgressionGraph = ({ side }: { side: string }) => {

    const jqChart = useRef<JqPlotObject | null>(null);

    const scoreProgressionGraph = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.score_progression_graph);

    const classname = side === 'od' ? 'default_od_ai_insights_score_progression_graph' : 'default_os_ai_insights_score_progression_graph';
    const graphWidth = '630px';

    const NORMAL_Value_BGC = 'rgba(102,251,88, 0.5)';
    const WARNING_Value_BGC = 'rgba(255,165,0, 0.8)';

    useEffect(() => {
        // Only consume IGraphIop if it is of type IGraphBcva
        if(scoreProgressionGraph){
            const data = side === 'od' ? scoreProgressionGraph.od_value_points : scoreProgressionGraph.os_value_points;
            const $ = window.$;

            const buildOptions = () => {
                const optionSeries = side === 'od' ? scoreProgressionGraph.od_series : scoreProgressionGraph.os_series;
                const gridBgColor = side === 'od' ? scoreProgressionGraph.od_grid_bg_color : scoreProgressionGraph.os_grid_bg_color;

                const minValue = scoreProgressionGraph.min_value;
                const maxValue = scoreProgressionGraph.max_value;
                const minYearValue = scoreProgressionGraph.min_year;
                const maxYearValue = scoreProgressionGraph.max_year;

                return {
                    series: optionSeries,
                    grid: {
                        background: gridBgColor,
                        gridLineColor: 'rgba(179,217,255,0.2)',
                        borderWidth: 0,
                        shadow: false,
                        gridLineWidth: 1,
                    },
                    axesDefaults: {
                        tickOptions: {
                            showMark: true,
                            textColor: '#bfdfff',
                        },
                    },
                    axes: {
                        xaxis: {
                            renderer:$.jqplot.DateAxisRenderer, 
                            tickOptions:{
                                formatString:'%Y-%m-%d'
                            },
                            min: minYearValue,
                            max: maxYearValue,
                        },
                        yaxis: {
                            min: ((minValue >= 0) ? minValue : null),
                            max: ((maxValue >= 0) ? maxValue : null),
                            tickOptions: {
                                formatString: '%.2f%%',
                            },
                        },
                    }
                };
            }

            if (data !== undefined) {
                if (data[0] && data[0].length > 0) {
                    jqChart.current = $.jqplot(classname, data, buildOptions());
                } else {
                    jqChart.current = $.jqplot(classname, [[null]], buildOptions());
                }

                if (jqChart.current) {
                    jqChart.current.replot();
                }
            }
        }
    }, [jqChart, scoreProgressionGraph, classname, side])

    /* Inline width/height is intentionally used to limit the graph size */
    // {Value-GRAPH 001, 002}
    return <div data-testid={`admin-ai-insights-score-progression-graph-${side}`} id={classname} style={{ width: graphWidth, height: '310px' }} />;
}

export default AdminAIInsightsScoreProgressionGraph;