
import { baseApi } from './base-api'

export interface IAdminReportsResponse {
    success: boolean;
    reports: IAdminReport[];
    error?: string;
}

export interface IAdminReportRequest {
    type: string;
    start_date: string;
    end_date: string;
}

export interface IAdminReport {
    key: number;
    report_name: string;
    created_date: string;
    start_date: string;
    end_date: string;
    user: string;
    status: string;
    file_path: string;
    id: number;
}

export interface IAdminUserResponse {
    success: boolean;
    users: IAdminUser[];
    error?: string;
}

export interface IAdminUser {
    label: string;
    value: number;
}

export interface IReportsFilter {
    name?: string;
    start_date?: string;
    end_date?: string;
    user?: number;
}


export const adminReportsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        getAdminReports: builder.query<IAdminReport[], IReportsFilter>({
            query: (data) => ({
                url: 'admin/reports/',
                params: data,
            }),
            transformResponse: (response: IAdminReportsResponse, meta, arg) => response.reports,
            providesTags: (result, error, tag) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Report' as const, id })), 'Report']
                    : ['Report'],
        }),
        createExamReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/exam/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        createOmdReviewReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/omd_review/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        createIntegrationListReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/integration_list/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        createBillingReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/billing_report/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        createPreReviewsReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/pre_reviews/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        createPreReviewsTimeReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/pre_reviews_time/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        createPatientHandoutReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, IAdminReportRequest>({
            query(body) {
                return {
                    url: 'admin/reports/patient_handout/generate/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Report']
        }),
        deleteAdminReport: builder.mutation<Omit<IAdminReportsResponse, 'reports'>, number>({
            query(reportId) {
                return {
                    url: `admin/reports/delete/${reportId}/`,
                    method: 'POST',
                }
            },
            invalidatesTags: ['Report']
        }),
        getAdminUsers: builder.query<IAdminUserResponse, void>({
            query: () => 'admin/reports/users/',
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetAdminReportsQuery, useLazyGetAdminReportsQuery, useCreateExamReportMutation, useDeleteAdminReportMutation, 
    useCreateOmdReviewReportMutation, useCreateBillingReportMutation, useCreatePreReviewsReportMutation, 
    useCreateIntegrationListReportMutation, useCreatePreReviewsTimeReportMutation, useGetAdminUsersQuery,
    useCreatePatientHandoutReportMutation } = adminReportsApi;