import { Card, Row } from 'antd';
import DeepMDLogoWhite from '../../../../static/images/logo-deepmd-white.svg';
import { Col } from 'antd';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const DeepMdOdMedicalOpinion = () => {

    const rightIopComfortZone = useCare1AppSelector(store => store.txalgo3.od_iop_aim);
    const worstRecClass = useCare1AppSelector(store => store.txalgo3.class_aim);
    const shortestRecFu = useCare1AppSelector(store => store.txalgo3.followup_aim);
    const leftIopComfortZone = useCare1AppSelector(store => store.txalgo3.os_iop_aim);
    const fuPeriods = useCare1AppSelector(store => store.options.fuPeriods);

    const patientStatusClassName = worstRecClass === 'BO' ?  'status-observe'
        : worstRecClass === 'BT' ? 'status-treat'
        : 'status-stable';

    const patientStatusDisplay = worstRecClass === 'BO' ?  'Watch Carefully'
        : worstRecClass === 'BT' ? 'Watch Carefully - May Need Tx'
        : worstRecClass === 'P' ? 'Patient is Stable'
        : '';

    // Get the follow up period display form the fuPeriod options based on the shortestRecFu value.
    const followUpDisplay = () => {
        const fuPeriod = fuPeriods.find(period => period[0] === shortestRecFu);
        return fuPeriod ? fuPeriod[1] : '';
    }

    return (
        <Card className={'component-input-card medical-opinion'} bordered={false}>
            <Row>
                <Col>
                    <img src={DeepMDLogoWhite} className='deepmd-speech-image' alt='Deep MD Logo' />
                </Col>
                <Col>
                    <div className='deepmd-speech-bubble'>
                        {`Here is my medical opinion.`}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="heading patient-status">{`Status:`}</div>
                </Col>
                <Col>
                    <div className={`patient-status-display ${patientStatusClassName}`} data-testid='omdr_txalgo3_class_prev'>
                        {patientStatusDisplay}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={14}>
                    <Row>
                        <div className="heading iop-comfort-zone">IOP Comfort Zone</div>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <div className='comfort-zone-side od'>OD</div>
                            </Row>
                            <Row>
                                <div className="comfort-zone od" data-testid='omdr_txalgo3_od_prev'>
                                    {rightIopComfortZone}
                                </div>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <div className='comfort-zone-side os'>OS</div>
                            </Row>
                            <Row>
                                <div className="comfort-zone os" data-testid='omdr_txalgo3_os_prev'>
                                    {leftIopComfortZone}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={10}>
                    <Row>
                        <div className="heading follow-up">Follow Up</div>
                    </Row>
                    <Row>
                        <div className="follow-up-period" data-testid='omdr_txalgo3_fu_prev'>
                            {followUpDisplay()}
                        </div>
                    </Row>
                </Col>

            </Row>
        </Card>
    );
}

export default DeepMdOdMedicalOpinion;