import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { logout } from './user-slice';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";


export interface IDiseaseResourceListItem {
    resource_id: string;
    disease: string;
    pdf_url: string;
    video_url: string;
    applicable_programs: string;
}

export interface IDiseaseResourceList {
    [key: string]: IDiseaseResourceListItem[];
}

export interface IDiseaseResource {
    apiListStatus: 'loading' | 'succeeded' | 'failed' | '';
    list: IDiseaseResourceList;
    isShareModelOpen: boolean;
    shareEmails: string[];
    shareResourceId: string;
    shareResourcePdfUrl: string;
    shareEmailSendingStatus: 'loading' | 'succeeded' | 'failed' | '';
}

const initialState: IDiseaseResource = {
    apiListStatus: '',
    list: {},
    isShareModelOpen: false,
    shareEmails: [],
    shareResourceId: '',
    shareResourcePdfUrl: '',
    shareEmailSendingStatus: '',
}


export const getDiseaseResourceList = createAsyncThunk(
    `diseaseResource/getDiseaseResourceList`,
    async (_: void, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id} } = getState() as { user: {csrfToken: string}, examData: {id: number}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }
        
        const URL = `${process.env.REACT_APP_BACKENDURL}/data/disease_resource/list/`;
        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const createDiseaseResourceLog = createAsyncThunk(
    'diseaseResource/createDiseaseResourceLog',
    async ({resource_id, delivery_method}: {resource_id: string, delivery_method: string}, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken, userID } } = getState() as { user: { csrfToken: string, userID: number }};

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const requestData = {
            'resource_id': resource_id,
            'delivery_method': delivery_method,
            'user_id': userID,
        }

        const data = new FormData();
        data.append('disease_resource_log_create_request', JSON.stringify(requestData));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/disease_resource_log/create/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const diseaseResourceShareEmail = createAsyncThunk(
    'diseaseResource/diseaseResourceShareEmail',
    async ({resource_id, emails}: {resource_id: string, emails: string[]}, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken, userID } } = getState() as { user: { csrfToken: string, userID: number }};

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const requestData = {
            'resource_id': resource_id,
            'emails': emails,
            'user_id': userID,
        }

        const data = new FormData();
        data.append('disease_resource_share_email_request', JSON.stringify(requestData));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/disease_resource/share_email/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)


export const diseaseResourceSlice = createSlice({
    name: 'diseaseResource',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        openShareModal: (state) => {
            state.isShareModelOpen = true;
        },
        closeShareModal: (state) => {
            state.isShareModelOpen = false;
        },
        setShareEmails: (state, action : PayloadAction<string[]>) => {
            state.shareEmails = action.payload;
            if (state.shareEmails.length > 0) {
                state.shareEmailSendingStatus = '';
            }
        },
        setShareResourceId: (state, action : PayloadAction<string>) => {
            state.shareResourceId = action.payload;
        },
        setShareResourcePdfUrl: (state, action : PayloadAction<string>) => {
            state.shareResourcePdfUrl = action.payload;
        },
        resetShareEmailSendingStatus: (state) => {
            state.shareEmailSendingStatus = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDiseaseResourceList.pending, (state) => {
                state.apiListStatus = 'loading';
            })
            .addCase(getDiseaseResourceList.fulfilled, (state, action) => {
                state.apiListStatus = 'succeeded';
                state.list = action.payload.disease_resource_list;
            })
            .addCase(getDiseaseResourceList.rejected, (state) => {
                state.apiListStatus = 'failed';
            })    

            .addCase(diseaseResourceShareEmail.pending, (state) => {
                state.shareEmailSendingStatus = 'loading';
            })
            .addCase(diseaseResourceShareEmail.fulfilled, (state, action) => {
                state.shareEmailSendingStatus = 'succeeded';
                state.shareEmails = [];
            })
            .addCase(diseaseResourceShareEmail.rejected, (state) => {
                state.shareEmailSendingStatus = 'failed';
            })            
    },
});

export const { openShareModal, closeShareModal, setShareEmails, setShareResourceId, setShareResourcePdfUrl, resetShareEmailSendingStatus
} = diseaseResourceSlice.actions;

export default diseaseResourceSlice.reducer;
