import { useEffect, useState } from 'react';
import { Table, Modal, Tag } from 'antd';
import * as Constants from '../../constants';
import { localizedText } from '../../localizedText';
import '../../../static/css/components/_patient-list-table-re.scss';
import '../../../static/css/shared/patient-list-table.scss';

// Component imports
import ExamStatusTag from './exam-status-tag';
import LatestVisitButton from './button-latest-visit';
import NewVisitButton from './button-new-visit';
import PatientDetailsModal from './patient-details-modal';

// Action imports
import { getODPatientListRequest, IODPatientListItem, setPatientListFieldData
    } from '../../reducers/patient-list-slice';

// Helper imports
import { compareStrings, sortDates } from '../../helpers/sorting';
import { getOptionsRequest } from '../../reducers/options-slice';
import { clearPatientDetailsData, getPatientDetailsRequest, openPatientDetailsModal } from '../../reducers/patient-details-slice';
import { addToExamRooms, removeFromExamRooms, setPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { toggleNotAuthorizedForExam } from '../../reducers/user-slice';


const PatientListTable = () => {
    const { FIRST_NAME_HEADING, LAST_NAME_HEADING, DOB_HEADING, DOCTOR_HEADING, CHIEF_COMPLAINT_HEADING,
        NUMBER_VISITS_HEADING, LAST_VISIT_HEADING, REFERRAL_STATUS_HEADING, EXAM_HEADING,
        STATUS_HEADING, NEW_VISIT_BUTTON_TEXT, LATEST_VISIT_TEXT, OF_TEXT, ITEMS_TEXT } = localizedText;

    const tableList = useCare1AppSelector(store => store.patientList.odTableList);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const notAuthorizedForExam = useCare1AppSelector(store => store.user.notAuthorizedForExam);
    const isRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);
    const isOD = useCare1AppSelector(store => store.user.isOD);


    const dispatch = useCare1AppDispatch();

    const [ scrollClass, setScrollClass ] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // Reset the default values
        // the default exam status filter for referral letter PEI is not ready
        odUsesReferralLetterPei ?
            dispatch(setPatientListFieldData({ key: 'examStatus', value: 'not_ready' })) :
            dispatch(setPatientListFieldData({ key: 'examStatus', value: 'not_ready_od_review' }));
        dispatch(setPatientListFieldData({ key: 'examPeriod', value: '12_months' }));
        dispatch(setPatientListFieldData({ key: 'patientGrouping', value: 'doctor' }));
        dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));

        // Make requests
        dispatch(getOptionsRequest());
        dispatch(getODPatientListRequest());

        if (notAuthorizedForExam) {
            Modal.info({
                className: 'info-modal',
                title: Constants.UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                    dispatch(toggleNotAuthorizedForExam(false));
                },
            });
        }
        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onTableRowClick = (record: IODPatientListItem) => {
        dispatch(clearPatientDetailsData());
        dispatch(getPatientDetailsRequest(record.key))
            .then(() => dispatch(openPatientDetailsModal()));
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const columns: ColumnsType<IODPatientListItem> = [{
        className: 'first-name-column',
        title: FIRST_NAME_HEADING,
        dataIndex: Constants.FIRST_NAME_FIELD,
        sorter: (a, b) => compareStrings(a[Constants.FIRST_NAME_FIELD], b[Constants.FIRST_NAME_FIELD]),
        render: (text, record) => <div data-testid={`first-name-${record.key}`}>{text}</div>
    }, {
        className: 'last-name-column',
        title: LAST_NAME_HEADING,
        dataIndex: Constants.LAST_NAME_FIELD,
        sorter: (a, b) => compareStrings(a[Constants.LAST_NAME_FIELD], b[Constants.LAST_NAME_FIELD]),
        render: (text, record) => <div data-testid={`last-name-${record.key}`}>{text}</div>
    }, {
        className: 'dob-column',
        title: DOB_HEADING,
        dataIndex: Constants.DOB_FIELD,
        sorter: (a, b) => compareStrings(a[Constants.AGE_FIELD].toString(), b[Constants.AGE_FIELD].toString()),
        render: (text, record) => <div data-testid={`dob-${record.key}`}>{text}</div>
    }, {
        className: 'doctor-column',
        title: DOCTOR_HEADING,
        dataIndex: Constants.DOCTOR_NAME_FIELD,
        sorter: (a, b) => compareStrings(a[Constants.DOCTOR_NAME_FIELD], b[Constants.DOCTOR_NAME_FIELD]),
        render: (text, record) => <div data-testid={`od-name-${record.key}`}>{text}</div>
    }, {
        className: 'complaint-column',
        title: CHIEF_COMPLAINT_HEADING,
        dataIndex: Constants.CHIEF_COMPLAINT_FIELD,
        sorter: (a, b) => compareStrings(a[Constants.CHIEF_COMPLAINT_FIELD], b[Constants.CHIEF_COMPLAINT_FIELD]),
        render: (text, record) => <div data-testid={`chief-complaint-${record.key}`}>{text}</div>
    }, {
        className: 'visits-column',
        title: NUMBER_VISITS_HEADING,
        dataIndex: Constants.NUMBER_VISITS_FIELD,
        sorter: (a, b) => a[Constants.NUMBER_VISITS_FIELD] - b[Constants.NUMBER_VISITS_FIELD],
        render: (text, record) => <div data-testid={`total-visits-${record.key}`}>{text}</div>
    }, {
        className: 'submission-date-column',
        title: 'Submission Date',
        dataIndex: 'submission_date',
        sorter: {
            compare: (a, b) => sortDates(a.submission_date, b.submission_date),
        },
        render: (text, record) => <div data-testid={`submission-date-${record.key}`}>{text}</div>
    }, {
        className: 'last-visit-column',
        title: LAST_VISIT_HEADING,
        dataIndex: Constants.LAST_VISIT_DATE_FIELD,
        sorter: (a, b) => {
            const firstDate = a[Constants.LAST_VISIT_DATE_FIELD] ? a[Constants.LAST_VISIT_DATE_FIELD] : '';
            const secondDate = b[Constants.LAST_VISIT_DATE_FIELD] ? b[Constants.LAST_VISIT_DATE_FIELD] : '';
            return sortDates(firstDate, secondDate);
        },
        render: (text, record) => <div data-testid={`last-visit-${record.key}`}>{text}</div>
    }, {
        className: 'referral-status-column',
        title: REFERRAL_STATUS_HEADING,
        dataIndex: 'referral_status',
        sorter: (a, b) => compareStrings(a.referral_status, b.referral_status),
        render: (statusKey, record) => {
            const referralStatus = Constants.REFERRAL_STATUS_TYPES.find(entry=>entry.key===statusKey);
            let statusText = '';
            let statusColour = '';
            if (referralStatus) {
                statusText = referralStatus.value;
                statusColour = referralStatus.color;
            }
            return statusText && <Tag
                        data-testid={`referral-status-${record.key}`}
                        className={`referral-status-tag ${statusKey}`}
                        color={statusColour}
                    >
                        {statusText}
                    </Tag>
        },
    }, {
        className: 'status-column',
        title: STATUS_HEADING,
        dataIndex: Constants.LATEST_EXAM_STATUS_FIELD,
        sorter: (a, b) => compareStrings(a[Constants.LATEST_EXAM_STATUS_FIELD], b[Constants.LATEST_EXAM_STATUS_FIELD]),
        render: (text, record) => <ExamStatusTag testId={`status-${record.key}`} className="patient-exam-status-tag" examStatusKey={text} />,
    }, {
        className: 'latest-visit-column',
        title: EXAM_HEADING,
        dataIndex: Constants.LATEST_EXAM_ID_FIELD,
        render: (text, record) => {
            if (text) {
                return  <LatestVisitButton
                            className="button-latest-visit"
                            buttonText={LATEST_VISIT_TEXT}
                            visitID={text}
                            newTab={true}
                        />;
            }

            // Every created patient will have at least one visit so this is unreachable business logic
            // We should confirm/update the RQs and then probably remove this code
            // or update it with a RQs comment
            return <NewVisitButton className="button-new-visit" buttonText={NEW_VISIT_BUTTON_TEXT} patientID={record.key} />;
        },
    }];

    const onRowSelected = (record: IODPatientListItem, selected: boolean) => {
        if(record && record.latest_exam_id){
            const examId = record.latest_exam_id;
            const { first_name, last_name } = record;
            const patientName = `${last_name}, ${first_name}`;
            if(selected){
                dispatch(addToExamRooms({ examId, patientName }));
            } else {
                dispatch(removeFromExamRooms(examId));
            }
        }
    }

    const onAllRowSelected = (selected: boolean, selectedRows: IODPatientListItem[]) => {
        const examRooms : {examId: number, patientName: string}[] = [];
        if (selected && selectedRows.length) {
            selectedRows.forEach(({latest_exam_id, first_name, last_name}
                : {latest_exam_id: number, first_name: string, last_name: string}) => {
                if(latest_exam_id) {
                    examRooms.push({
                        examId: latest_exam_id,
                        patientName: `${last_name}, ${first_name}`,
                    });
                }
            })
        }
        dispatch(setPatientExamRoomsData({ rooms: examRooms }));
    }

    return (
        <div className={`patient-list-table patient-list-table-re ${odUsesReferralLetterPei && isOD ? 'referral-letter-od' : ''}`}>
            <div className={scrollClass}>
                <Table
                    rowSelection={!isRE ? {
                        type: 'checkbox',
                        onSelect: onRowSelected,
                        onSelectAll: onAllRowSelected,
                    } : undefined}
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={tableList}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onTableRowClick(record);
                            },
                        };
                    }}
                />
                {showDetails && <PatientDetailsModal />}
            </div>
        </div>
    );
}

export default PatientListTable;