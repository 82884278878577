import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Modal, Col, Row, Input, Form } from 'antd';
import '../../../static/css/components/_patient-add-gp-modal.scss';

// Helper imports
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';

// Action imports
import { FIRST_NAME_LABEL_TEXT, FNAME_PLACEHOLDER_TEXT, GP_FAX_PLACEHOLDER_TEXT, LAST_NAME_LABEL_TEXT,
    LNAME_PLACEHOLDER_TEXT, PHONE_MASK_FORMAT } from '../../constants';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IGP, useGetGpsByProvinceQuery, useUpdateGpMutation } from '../../services/doctor-api';
import { closeEditGPModal, setPatientEditValue } from '../../reducers/patient-edit-slice';

const FormItem = Form.Item;


const PatientEditGPModal = () => {

    const patientEditGPModalOpen = useCare1AppSelector(store => store.patientEdit.editGPModalOpen);
    const gpId = useCare1AppSelector(store => store.patientEdit.details.current_gp);
    const gp2Id = useCare1AppSelector(store => store.patientEdit.details.current_gp2);
    const odProvince = useCare1AppSelector(store => store.user.odProvince);
    const optometristProvince = useCare1AppSelector(store => store.examData.optometrist_province);
    const gpField = useCare1AppSelector(store => store.patientEdit.addEditGpField);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const provinceCode = userIsADMIN ? optometristProvince : odProvince;
    const {data: gpList = [] } = useGetGpsByProvinceQuery(provinceCode, {skip: !provinceCode });
    const gp = gpField === 'current_gp' ? gpId : gp2Id;

    const gpObj = (gpList && gpList.length > 0 && gpList.find(item => item.id === gp)) ? gpList.find(item => item.id === gp) : {} as IGP;
    const {first_name='', last_name='', fax_number=''} = gpObj as IGP;

    const [updateGp] = useUpdateGpMutation({
        fixedCacheKey: 'shared-edit-gp',
    });

    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({first_name, last_name, fax_number});
    }, [form, first_name, last_name, fax_number])

    const onCancel = () => {
        // Clear the data and close.
        dispatch(closeEditGPModal());
        form.resetFields();
    }

    const onFinish = async (values: IGP) => {
        const gpData: Required<Omit<IGP, 'province_id'>> = {...values, province: provinceCode, id:gp!};

        try {
            const result = await updateGp(gpData).unwrap();
            
            if (result && result.success) {
                dispatch(setPatientEditValue({field: gpField, value: result.id!}));
                Modal.info({
                    className: 'info-modal',
                    title: 'Doctor Successfully Edited!',
                });
            }
            form.resetFields();
            dispatch(closeEditGPModal());

        } catch(error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    return (
        <Modal
            className="patient-add-gp-modal"
            open={patientEditGPModalOpen}
            closable={false}
            onOk={() => form.submit()}
            onCancel={onCancel}
            okText={'Save'}
            cancelText={'Cancel'}
            okButtonProps={{className: 'save-gp-button'}}
            cancelButtonProps={{className: 'cancel-gp-button'}}
        >
            <Form
                form={form}
                className="patient-add-gp-modal-content"
                onFinish={onFinish}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                        Edit GP
                    </div>
                </Row>
                <Row gutter={24} className={'input-row-one'}>
                    <Col span={12}>
                        <FormItem
                            label={FIRST_NAME_LABEL_TEXT}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={'first_name'}
                            initialValue={''}
                            rules={[{
                                required: true,
                                whitespace: true,
                                message: buildRequiredErrorMessage(FIRST_NAME_LABEL_TEXT),
                            }]}
                        >
                            <Input
                                className={`first-name-input`}
                                placeholder={FNAME_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={LAST_NAME_LABEL_TEXT}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={'last_name'}
                            initialValue={''}
                            rules={[{
                                required: true,
                                whitespace: true,
                                message: buildRequiredErrorMessage(LAST_NAME_LABEL_TEXT),
                            }]}
                        >
                            <Input
                                className={`last-name-input`}
                                placeholder={LNAME_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24} className={'input-row-two'}>
                    <Col span={12}>
                        <FormItem
                            label={'GP Fax No.'}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={'fax_number'}
                            initialValue={''}
                            rules={[{
                                required: true,
                                pattern: /^[^_]+$/,
                                message: buildRequiredErrorMessage('GP Fax No.'),
                            }]}
                        >
                            <InputMask
                                placeholder={GP_FAX_PLACEHOLDER_TEXT}
                                className="patient-gp-fax-input"
                                mask={PHONE_MASK_FORMAT}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default PatientEditGPModal;