import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Col, Row, } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import AdminAIInsightsHistoryImage from './admin-ai-insights-history-image';
import * as Constants from '../../constants';
import { IAIInsightsHistoricalAnalysisItem, fetchAIInsightsHistoricalAnalysis } from '../../reducers/ai-insights-historical-analysis-slice';

const AdminAIInsightsHistoricalAnalysis = ({ side, useDrusenSegmentationPhoto, useMicroAneurysmsPhoto, useHardExudatesPhoto }: { side: string, useDrusenSegmentationPhoto: boolean, useMicroAneurysmsPhoto: boolean, useHardExudatesPhoto: boolean }) => {

    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData[Constants.EXAM_ID]);

    const right_ai_insights_historical_analysis = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.right_ai_insights_historical_analysis);
    const left_ai_insights_historical_analysis = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.left_ai_insights_historical_analysis);

    const bandColumnWidth = 6;
    const IMAGES_PER_ROW = 4;

    useEffect(() => {
        if (examId) {
            dispatch(fetchAIInsightsHistoricalAnalysis());
        }
    }, [examId, dispatch]);


    const getImageCards = () => {
        let currentImages: IAIInsightsHistoricalAnalysisItem[];

        if (side === Constants.RIGHT_SIDE_TEXT) {
            currentImages = right_ai_insights_historical_analysis || [];
        } else {
            currentImages = left_ai_insights_historical_analysis || [];
        }

        const imageSlice =currentImages;

        const sideImageCards = [];

        imageSlice.forEach((image, index) => {
           
            sideImageCards.push(
                <Col key={index} className={'image-col'} span={bandColumnWidth}>
                    <Card key={index} bordered={false}>
                        <AdminAIInsightsHistoryImage
                            examId={image.exam_id}
                            fundusPhoto={image.fundus_photo}
                            useDrusenSegmentationPhoto={useDrusenSegmentationPhoto}
                            mlDrusenSegmentationBackgroundPhoto={image.ml_drusen_segmentation_background_photo}
                            mlDrusenSegmentationOverlayPhoto={image.ml_drusen_segmentation_overlay_photo}
                            mlDrusenSegmentationValue={image.ml_drusen_segmentation_value}
                            mlDrusenSegmentationSucceeded={image.ml_drusen_segmentation_succeeded}
                            useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                            mlMicroAneurysmsOverlayPhoto={image.ml_micro_aneurysms_overlay_photo}
                            mlMicroAneurysmsValue={image.ml_micro_aneurysms_value}
                            mlMicroAneurysmsSucceeded={image.ml_micro_aneurysms_succeeded}
                            mlMicroAneurysmsModelVersion={image.ml_micro_aneurysms_model_version}
                            mlMicroAneurysmsRunAt={image.ml_micro_aneurysms_run_at}
                            useHardExudatesPhoto={useHardExudatesPhoto}
                            mlHardExudatesOverlayPhoto={image.ml_hard_exudates_overlay_photo}
                            mlHardExudatesValue={image.ml_hard_exudates_value}
                            mlHardExudatesSucceeded={image.ml_hard_exudates_succeeded}
                            examDate={image.exam_date_utc}
                        />
                    </Card>
                </Col>
            );
        });

        // Display blank image for empty images
        const numImages = sideImageCards.length;
        const maxImages = (Math.floor(sideImageCards.length / 4) + 1) * IMAGES_PER_ROW;
        for (let i = numImages; i < maxImages; i += 1) {
            sideImageCards.push(
                <Col key={i} span={bandColumnWidth}>
                    <Card key={i} bordered={false}>
                        <div className='blank-history-image'>
                            <PictureOutlined />
                        </div>
                    </Card>
                </Col>
            );
        }

        return sideImageCards;
    }

    
    const heading = side === 'right' ? 'Right Historical Analysis' : 'Left Historical Analysis';

    return (
        <Card className={`patient-exam-fundus-history images-history-card`}  bordered={false}>

            <div className="ai-title">
                <span className='ai-heading'>{heading}</span>
            </div>

            <Card bordered={false}>
                {/* OMDs top row should be oldest images and remaining rows should be newest images */}
                <>
                    <Row className='ai-insights-history'>
                        { getImageCards()}
                    </Row>
                </>
            </Card>
        </Card>
    );
}

export default AdminAIInsightsHistoricalAnalysis;