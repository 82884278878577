import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IGptVisionPrompt {
    patient_validation_chat_api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_image_identifier_chat_prompts: string,
    gpt_patient_demographics_chat_prompts: string,
}

const initialState: IGptVisionPrompt = {
    patient_validation_chat_api_status: 'idle',
    gpt_image_identifier_chat_prompts: '',
    gpt_patient_demographics_chat_prompts: '',
};


export const fetchPatientValidationChatPrompt = createAsyncThunk(
    'patientValidation/fetchPatientValidationChatPrompt',
    async (examId: number, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken } } = getState() as { user: { csrfToken: string } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/patient_validation_chat_prompts/${examId}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)


export const patientValidationSlice = createSlice({
    name: 'patientValidation',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPatientValidationChatPrompt.pending, (state) => {
                state.patient_validation_chat_api_status = 'loading';
                state.gpt_image_identifier_chat_prompts = '';
                state.gpt_patient_demographics_chat_prompts = '';
            })
            .addCase(fetchPatientValidationChatPrompt.fulfilled, (state, action) => {
                state.patient_validation_chat_api_status = 'succeeded';
                state.gpt_image_identifier_chat_prompts = action.payload.gpt_image_identifier_chat_prompts;
                state.gpt_patient_demographics_chat_prompts = action.payload.gpt_patient_demographics_chat_prompts;
            })
            .addCase(fetchPatientValidationChatPrompt.rejected, (state) => {
                state.patient_validation_chat_api_status = 'failed';
                state.gpt_image_identifier_chat_prompts = '';
                state.gpt_patient_demographics_chat_prompts = '';
            })
            
    },
});

export default patientValidationSlice.reducer;


