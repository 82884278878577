import { Fragment } from 'react';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Card, Col, Flex } from 'antd';
import * as Constants from '../constants';

// Components
import DeepMDBand from '../components/reviewer/deep-md/patient-exam-deep-md';
import OMDSubmitButtons from '../components/reviewer/omd-submit-buttons';
import PatientExamAllergies from '../components/integrated/patient-exam-allergies';
import PatientExamBCVA from '../components/retina/patient-exam-bcva';
import PatientExamBCVAGraph from '../components/integrated/patient-exam-bcva-graph';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamComorbidities from '../components/integrated/patient-exam-comorbidities'
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFollowUpWithOMD from '../components/reviewer/patient-exam-fu-with-omd';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamHistoryAndExam from '../components/retina/patient-exam-history-and-exam';
import PatientExamHistoryMedsExams from '../components/reviewer/patient-exam-history-meds-exams';
import PatientExamImpressions from '../components/integrated/patient-exam-impressions';
import PatientExamInitiateTreatment from '../components/reviewer/patient-exam-initiate-treatment';
import PatientExamIOPHistory from '../components/integrated/patient-exam-iop-history';
import PatientExamLastLetterToGP from '../components/reviewer/patient-exam-last-letter-gp';
import PatientExamMetrics from '../components/integrated/metrics/patient-exam-metrics';
import PatientExamNotesToMOA from '../components/reviewer/patient-exam-notes-to-moa';
import PatientExamNotesToODAndGP from '../components/reviewer/patient-exam-notes-to-od-and-gp';
import PatientExamNotesToODCarryOver from '../components/reviewer/patient-exam-notes-to-od-carry-over';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';
import PatientExamOMDHistory from '../components/integrated/patient-exam-omd-history';
import PatientExamOMDRCDValuesBand from '../components/reviewer/patient-exam-omdr-cd-values-band';
import PatientExamOMDReviewerSelectors from '../components/reviewer/patient-exam-omd-reviewer-selectors';
import PatientExamOMDRIOPFinalRecommendations from '../components/reviewer/patient-exam-omdr-iop-final-recommendations';
import PatientExamOMDRNotes from '../components/reviewer/patient-exam-omdr-notes';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamSecondOpinionSelectors from '../components/reviewer/patient-exam-second-opinion-selectors';
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamVerbalize from '../components/reviewer/patient-exam-verbalize';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamCpt from '../components/reviewer/us/patient-exam-cpt';
import PatientExamIcd10 from '../components/reviewer/us/patient-exam-icd-10';

// Helpers
import '../../static/css/pages/omdr-patient-exam-page.scss';
import { localizedText } from '../localizedText';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';
import OmdrReReviewerBand from '../components/reviewer/patient-exam-omdr-re-reviewer-band';
import commonLayoutStyles from '../../static/css/components/common-layout.module.scss';


const OMDRLegacyExamPage = () => {
    const { BCVA_GRAPH_TEXT,  PHOTO_DROP_TEXT_SHORT } = localizedText;

    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);
    const isUsOnly = useCare1AppSelector(store => store.user.isUsOnly);

    const isReadOnly = userIsOMDR;
    const isOMDReviewed = examStatus === 'reviewed';

    return (
        <>
            <Row>
                <PatientExamHeader />
            </Row>

            {/* START | Section 1 - Left images panel */}
            <Row gutter={8}>
                <Col span={examIsRE ? 8 : 6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed={!examIsRE}
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        {!examIsRE &&
                            <Fragment>
                                <Col span={12}>
                                    <PatientExamOCTMacula
                                        dropText={PHOTO_DROP_TEXT_SHORT}
                                        disabled={isReadOnly}
                                        side={Constants.RIGHT_SIDE_TEXT}
                                        tabbed={true}
                                    />
                                    <PatientExamOCTRnfl disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamVF disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                                </Col>
                            </Fragment>
                        }
                        {examIsRE &&
                            <Col span={24}>
                                <PatientExamOCTMacula
                                    dropText={PHOTO_DROP_TEXT_SHORT}
                                    disabled={isReadOnly}
                                    side={Constants.RIGHT_SIDE_TEXT}
                                    tabbed={false} />

                            </Col>
                        }
                    </Row>
                </Col>
                {/* END | Section 1 - Left images panel */}

                {/* START | Section 2 - Middle diagnostics panel */}
                <Col span={examIsRE ? 8 : 12}>
                    {!examIsRE &&
                        <Row gutter={8}>
                            <Col span={6}>
                                <Row>
                                    <PatientExamChiefComplaint disabled={isReadOnly} />
                                </Row>
                            </Col>
                            <Col span={14}>
                                <PatientExamOMDHistory disabled={isReadOnly} />
                            </Col>
                            <Col span={4}>
                                <PatientExamAllergies disabled={isReadOnly} />
                            </Col>
                        </Row>
                    }
                    {examIsRE &&
                        <Fragment>
                            <Row gutter={8}>
                                <PatientExamChiefComplaint disabled={isReadOnly} />
                            </Row>
                            <Row>
                                <Card className={'patient-exam-re-bcva'} bordered={false}>
                                    <Row>
                                        <label className="heading">{'BCVA:'}</label>
                                    </Row>
                                    <Col span={12} className={'bcva-side'}>
                                        <Row>
                                            <div className={'bcva-label'}>
                                                <label className="heading">{'Right Eye:'}</label>
                                            </div>
                                            <div className={'bcva-value'}>
                                                <PatientExamBCVA side='od' disabled={true}
                                                />
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col span={12} className={'bcva-side'}>
                                        <Row>
                                            <div className={'bcva-label os'}>
                                                <label className="heading left-eye">{'Left Eye:'}</label>
                                            </div>
                                            <div className={'bcva-value os'}>
                                                <PatientExamBCVA side='os' disabled={true}
                                                />
                                            </div>
                                        </Row>
                                    </Col>
                                </Card>
                            </Row>
                        </Fragment>
                    }
                    <Row gutter={8}>
                        <Col span={24}>
                            {examIsRE &&
                                <Fragment>
                                    <Card className="empty-diagnostics-card" bordered={false} />
                                    <PatientExamHistoryAndExam disabled={isReadOnly} />
                                </Fragment>
                            }
                            {!examIsRE &&
                                <PatientExamHistoryMedsExams disabled={isReadOnly} />
                            }
                        </Col>
                    </Row>
                    {!examIsRE &&
                        <Fragment>
                            <Row>
                                <PatientExamMetrics disabled={isReadOnly} />
                            </Row>

                            <Row gutter={8}>
                                <Col span={12}>
                                    <PatientExamImpressions disabled={isReadOnly} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamODNotes disabled={isReadOnly} />
                                </Col>
                            </Row>
                        </Fragment>
                    }
                </Col>
                {/* END | Section 2 - Middle diagnostics panel */}

                {/* START | Section 3 - Right images panel */}
                <Col span={examIsRE ? 8 : 6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed={!examIsRE}
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        {!examIsRE &&
                            <Fragment>
                                <Col span={12}>
                                    <PatientExamOCTMacula
                                        dropText={PHOTO_DROP_TEXT_SHORT}
                                        disabled={isReadOnly}
                                        side={Constants.LEFT_SIDE_TEXT}
                                        tabbed={true}
                                    />
                                    <PatientExamOCTRnfl disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamVF disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                                </Col>
                            </Fragment>
                        }
                        {examIsRE &&
                            <Col span={24}>
                                <PatientExamOCTMacula
                                    dropText={PHOTO_DROP_TEXT_SHORT}
                                    disabled={isReadOnly}
                                    side={Constants.LEFT_SIDE_TEXT}
                                    tabbed={false}
                                />
                            </Col>
                        }
                    </Row>
                </Col>
                {/* END | Section 3 - Left images panel */}
            </Row>

            {/* START | Section 4 - Glaucoma band */}
            {
                !examIsRE && isGlaucoma &&
                <Row gutter={8}>
                    <Col span={6} className="pei-iop-history-col">
                        <PatientExamIOPHistory disabled={false} />
                    </Col>
                    <Col span={6} className="pei-until-yesterday-col">
                        <PatientExamUntilYesterday disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-ongoing-col">
                        <PatientExamOngoing disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-comorbidities-col">
                        <PatientExamComorbidities disabled={isReadOnly} />
                    </Col>
                </Row>
            }
            {/* END | Section 4 - Glaucoma band */}

            {/* START | Section 5 RE - OMDR RE Reviewer band */}
            {
                userIsRE &&
                <OmdrReReviewerBand isOMDReviewed={isOMDReviewed} />
            }
            {/* END | Section 5 - OMDR RE Reviewer band */}

            {/* START | Section 5 IC - OMDR IC Reviewer band & DeepMD bands */}
            {
                !userIsRE &&
                <Fragment>
                    {/* START | OMDR IC Reviewer Band */}
                    <Row gutter={4} className={`${isUsOnly ? 'is-us-only' : ''}`}>
                        <Col span={5}>
                            <PatientExamNotesToODCarryOver disabled={false} />
                        </Col>
                        <Col span={12}>
                        <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                            <Col span={24} flex={'0 1 86px'}>
                                <PatientExamOMDReviewerSelectors />
                            </Col>
                            <Col span={24} flex={'1 1 100%'}>
                                <Flex className={commonLayoutStyles.verticalFlex}>
                                    <Col span={12}>
                                        <PatientExamOMDRNotes disabled={false} />
                                    </Col>
                                    <Col span={12}>
                                        <PatientExamNotesToODAndGP disabled={false} />
                                    </Col>
                                </Flex>
                            </Col>
                        </Flex>
                            {isUsOnly &&
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <PatientExamCpt />
                                    </Col>
                                    <Col span={12}>
                                        <PatientExamIcd10 />
                                    </Col>
                                </Row>
                            }

                        </Col>
                        <Col span={3}>
                            <PatientExamInitiateTreatment />
                            <PatientExamVerbalize />
                            <PatientExamNotesToMOA disabled={false} />
                        </Col>
                        <Col span={4}>
                            <PatientExamFollowUpWithOMD />
                            <PatientExamLastLetterToGP />
                            <OMDSubmitButtons disabled={isOMDReviewed} />
                            <PatientExamSecondOpinionSelectors disabled={false} />
                        </Col>
                    </Row>
                    {/* END | OMDR IC Reviewer Band */}

                    {/* START | OMDR IC DeepMD Band */}
                    <Row gutter={8}>
                        <DeepMDBand />
                    </Row>
                    {/* END | OMDR IC DeepMD  Band */}


                </Fragment>
            }
            {/* END | Section 5 IC - OMDR IC Reviewer & DeepMD bands */}

            {/* START | CD Values */}
            {
                !userIsRE && !examIsRE && isGlaucoma &&
                <Row>
                    <PatientExamOMDRCDValuesBand />
                </Row>
            }
            {/* END | CD Values */}

            {/* START | Section 6 - Stereo Viewer */}
            {
                ((userIsRE && !examIsRE) || !userIsRE) &&
                <PatientExamStereoViewer inGlaucomaWorksheet={false} />
            }
            {/* END | Section 6 RE - Stereo Viewer */}

            {/* START | Final Recommendations Bands */}
            {
                !userIsRE && !examIsRE && isGlaucoma &&
                <Row>
                    <PatientExamOMDRIOPFinalRecommendations />
                </Row>
            }
            {/* END | Final Recommendations Bands */}

            {/* START | Section 7 - Extra images band */}
            <PatientExamExtraImages
                disabled={isReadOnly}
                className="patient-exam-extra-images"
            />
            {/* END | Section 7 - Extra Images band */}

            {/* START | Section 8a RE exam - Graph Bands */}
            {
                examIsRE &&
                <Row gutter={8}>
                    <Col span={12}>
                        <Card className="patient-exam-bcva-graph" bordered={false}>
                            <div className="heading">{`${BCVA_GRAPH_TEXT} OD`}</div>
                            <Row>
                                <PatientExamBCVAGraph side={'od'} />
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="patient-exam-bcva-graph" bordered={false}>
                            <div className="heading">{`${BCVA_GRAPH_TEXT} OS`}</div>
                            <Row>
                                <PatientExamBCVAGraph side={'os'} />
                            </Row>
                        </Card>
                    </Col>
                </Row>
            }
            {/* END | Section 8a RE exam - Graph Bands */}

            {/* START | Section 8b IC exam - Graph Bands */}
            {
                !examIsRE &&
                <IopBcvaGraphBand />
            }
            {/* END | Section 8 IC exam - Graph Bands */}

            {/* START | Section 9 - Conversation band */}
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* END | Section 9 - Conversation band */}

            {
                !examIsRE &&
                <Fragment>
                    {/* START | Section 10 - VF History band */}
                    <Row gutter={8}>
                        <PatientExamVFImageHistory />
                    </Row>
                    {/* END | Section 10 - VF band */}

                    {/* START | Section 11 - RNFL History band */}
                    <Row gutter={8}>
                        <PatientExamOCTRNFLImageHistory />
                    </Row>
                    {/* END | Section 11 - RNFL History band */}

                    {/* START | Section 12 - Macula History band*/}
                    <Row gutter={8}>
                        <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                    </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                    <Row gutter={8}>
                        <PatientExamFundusImageHistory />
                    </Row>
                    {/* END | Section 13 - Fundus History band */}
                </Fragment>
            }
            {
                examIsRE &&
                <Fragment>
                    {/* START | Section 12 - Macula History band*/}
                    <Row gutter={8}>
                        <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                    </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                    <Row gutter={8}>
                        <PatientExamFundusImageHistory />
                    </Row>
                    {/* END | Section 13 - Fundus History band */}
                </Fragment>
            }
        </>
    );
}

export default OMDRLegacyExamPage;
