import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Spin, DatePicker, Button } from 'antd';
import dayjs, {Dayjs} from 'dayjs';

import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import NavigationControlBar from '../components/navigation-control-bar';
import SalesIntegrationReportTable from '../components/sales/sales-integration-report-table';
import SalesIntegrationReportSummaryTable from '../components/sales/sales-integration-report-summary-table';
import SalesIntegrationMetricsFilter from '../components/sales/sales-integration-report-exam-metrics-filter';
import SalesPageOdStatusFilter from '../components/sales/sales-page-od-status-filter';
import SalesPageOdGroupPracticeStatusFilter from '../components/sales/sales-page-od-group-practice-status-filter';
import { ISalesIntegrationReportSummary, ISalesIntegrationReportItem, getSalesIntegrationReportExcelRequest, getSalesIntegrationReportOriginalPeiRequest, getSalesIntegrationReportReferralLetterRequest } from '../reducers/sales-integration-report-slice';

import '../../static/css/pages/sales-page.scss';
import '../../static/css/shared/date-picker.scss';

import * as Constants from '../constants';

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

const SalesPage: React.FC = () => {

    const dispatch = useCare1AppDispatch();

    const originalPeiOperating: boolean = useCare1AppSelector(store => store.salesIntegrationReport.original_pei_operating);

    const salesIntegrationReportOriginalPei: ISalesIntegrationReportItem[] = useCare1AppSelector(store => store.salesIntegrationReport.original_pei_report);
    const salesIntegrationReportSummaryOriginalPei: ISalesIntegrationReportSummary | undefined = useCare1AppSelector(store => store.salesIntegrationReport.original_pei_summary);
    const salesIntegrationWeekColumnOriginalPei: string[] = useCare1AppSelector(store => store.salesIntegrationReport.original_pei_week_column);
    const salesIntegrationLastUpdatedOriginalPei: string = useCare1AppSelector(store => store.salesIntegrationReport.original_pei_last_updated);

    const [salesIntegrationReportOriginalPeiExamMetricsFilter, setSalesIntegrationReportOriginalPeiExamMetricsFilter] = useState<string>("omd_ready");

    const referralLetterOperating: boolean = useCare1AppSelector(store => store.salesIntegrationReport.referral_letter_operating);

    const salesIntegrationReportReferralLetter: ISalesIntegrationReportItem[] = useCare1AppSelector(store => store.salesIntegrationReport.referral_letter_report);
    const salesIntegrationReportSummaryReferralLetter: ISalesIntegrationReportSummary | undefined = useCare1AppSelector(store => store.salesIntegrationReport.referral_letter_summary);
    const salesIntegrationWeekColumnReferralLetter: string[] = useCare1AppSelector(store => store.salesIntegrationReport.referral_letter_week_column);
    const salesIntegrationLastUpdatedReferralLetter: string = useCare1AppSelector(store => store.salesIntegrationReport.referral_letter_last_updated);

    const [salesIntegrationReportReferralLetterExamMetricsFilter, setSalesIntegrationReportReferralLetterExamMetricsFilter] = useState<string>("od_to_review");

    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const [salesIntegrationReportOriginalPeiOdStatusFilter, setSalesIntegrationReportOriginalPeiOdStatusFilter] = useState<string>('active');
    const [salesIntegrationReportReferralLetterOdStatusFilter, setSalesIntegrationReportReferralLetterOdStatusFilter] = useState<string>('active');
    const [salesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter, setSalesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter] = useState<string>('active');
    const [salesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter, setSalesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter] = useState<string>('active');

    const handleDateChange = (values: any, formatString: [string, string]) => {
        setStartDate(formatString[0]);
        setEndDate(formatString[1]);
    }

    const defaultEndDate = dayjs().format('YYYY-MM-DD');
    const defaultStartDate = dayjs().subtract(7, 'w').format('YYYY-MM-DD');

    // Set default values as initial values.
    if (!startDate && !endDate) {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
    }

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(getSalesIntegrationReportOriginalPeiRequest({
                start_date: startDate as string,
                end_date: endDate as string,
                exam_metrics: salesIntegrationReportOriginalPeiExamMetricsFilter,
                od_status: salesIntegrationReportOriginalPeiOdStatusFilter,
                od_group_practice_status: salesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter,
            }));
        }
    }, [dispatch, startDate, endDate, salesIntegrationReportOriginalPeiExamMetricsFilter,
        salesIntegrationReportOriginalPeiOdStatusFilter, salesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter])

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(getSalesIntegrationReportReferralLetterRequest({
                start_date: startDate as string,
                end_date: endDate as string,
                exam_metrics: salesIntegrationReportReferralLetterExamMetricsFilter,
                od_status: salesIntegrationReportReferralLetterOdStatusFilter,
                od_group_practice_status: salesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter,
            }));

        }
    }, [dispatch, startDate, endDate, salesIntegrationReportReferralLetterExamMetricsFilter,
        salesIntegrationReportReferralLetterOdStatusFilter, salesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter])

    const onSelectOriginalPeiExamMetricsFilter = (value: string) => {
        setSalesIntegrationReportOriginalPeiExamMetricsFilter(value);
    }

    const onSelectReferralLetterExamMetricsFilter = (value: string) => {
        setSalesIntegrationReportReferralLetterExamMetricsFilter(value);
    }

    const onSelectOriginalPeiOdStatusFilter = (value: string) => {
        setSalesIntegrationReportOriginalPeiOdStatusFilter(value);
    }

    const onSelectReferralLetterOdStatusFilter = (value: string) => {
        setSalesIntegrationReportReferralLetterOdStatusFilter(value);
    }

    const onSelectOriginalPeiOdGroupPracticeStatusFilter = (value: string) => {
        setSalesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter(value);
    }

    const onSelectReferralLetterOdGroupPracticeStatusFilter = (value: string) => {
        setSalesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter(value);
    }

    const getLastUpdated = (date: string) => {
        if (date === '') {
            return '';
        }
        else {
            return `Last updated: ${dayjs(date).format('YYYY-MM-DD HH:mm:ss')}`;
        }
    }

    const handleSalesReportDownload = (_event: any): void => {
        if (startDate && endDate) {
            dispatch(getSalesIntegrationReportExcelRequest({
                start_date: startDate as string,
                end_date: endDate as string,
                original: salesIntegrationReportOriginalPeiExamMetricsFilter,
                refltr: salesIntegrationReportReferralLetterExamMetricsFilter,
                original_od_status: salesIntegrationReportOriginalPeiOdStatusFilter,
                original_od_group_practice_status: salesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter,
                refltr_od_status: salesIntegrationReportReferralLetterOdStatusFilter,
                refltr_od_group_practice_status: salesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter,
            }));

        }
    }

    return (
        <Layout className='salesReportPage'>
            <Header>
                <NavigationControlBar
                    className="patient-list-navigation-control-bar"
                    breadcrumbItems={[]}
                />
            </Header>
            <Content>
                <div className='sales-page'>
                    <Row className='reportFilter'>
                        <Col>
                            <RangePicker
                                popupClassName='sales-page'
                                picker="week"
                                value={[dayjs(startDate), dayjs(endDate)]}
                                onChange={handleDateChange}
                                format="YYYY-MM-DD"
                            />
                        </Col>
                        <Col>
                            <Button
                                className={'download-button'}
                                onClick={handleSalesReportDownload}
                                type="primary"
                                size='small'
                            >
                                Download
                            </Button>
                        </Col>
                    </Row>
                    <div className="caseUploadStatus">
                        <Col className='titleWrapper'>
                            <span className="list-title">Case uploads - Original Upload Interface</span>
                            <span className="last-updated">{ getLastUpdated(salesIntegrationLastUpdatedOriginalPei) }</span>
                        </Col>

                        <div className='filterDropdowns'>
                            <SalesIntegrationMetricsFilter
                                onSelect={onSelectOriginalPeiExamMetricsFilter}
                                defaultSelectedKey={salesIntegrationReportOriginalPeiExamMetricsFilter}
                                selectedKey={salesIntegrationReportOriginalPeiExamMetricsFilter}
                            />
 
                            <SalesPageOdStatusFilter
                                onSelect={onSelectOriginalPeiOdStatusFilter}
                                defaultSelectedKey={salesIntegrationReportOriginalPeiOdStatusFilter}
                                selectedKey={salesIntegrationReportOriginalPeiOdStatusFilter}
                            />
                        </div>
                    </div>
                    <Row>
                        <Col span={24}>
                            <Spin
                                className="loading-spinner"
                                spinning={originalPeiOperating}
                                size="large"
                                tip={Constants.OPERATING_TEXT}
                            >
                                <SalesIntegrationReportTable
                                    salesIntegrationReport={salesIntegrationReportOriginalPei}
                                    salesIntegrationWeekColumn={salesIntegrationWeekColumnOriginalPei}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <Row className='titleArea'>
                        <Col span={24} className='textArea'>
                            <span className="list-title">Summary - Total Uploads Original Upload Interface</span>
                            <span className="last-updated">{ getLastUpdated(salesIntegrationLastUpdatedOriginalPei) }</span>
                            <SalesPageOdGroupPracticeStatusFilter
                                onSelect={onSelectOriginalPeiOdGroupPracticeStatusFilter}
                                defaultSelectedKey={salesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter}
                                selectedKey={salesIntegrationReportSummaryOriginalPeiOdGroupPracticeStatusFilter}
                            />
                        </Col>
                    </Row>
                    <Row className='totalUploadsOriginal'>
                        <Col span={24}>
                            <Spin
                                className="loading-spinner"
                                spinning={originalPeiOperating}
                                size="large"
                                tip={Constants.OPERATING_TEXT}
                            >
                                <SalesIntegrationReportSummaryTable
                                    salesIntegrationReportSummary={salesIntegrationReportSummaryOriginalPei}
                                    salesIntegrationWeekColumn={salesIntegrationWeekColumnOriginalPei}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <Row className='titleArea'>
                        <Col span={24} className='textArea'>
                            <span className="list-title">Case uploads - Referral Letter Upload Interface</span>
                            <span className="last-updated">{ getLastUpdated(salesIntegrationLastUpdatedReferralLetter) }</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3} className='filterDropdowns referralFilter'>
                            <SalesIntegrationMetricsFilter
                                onSelect={onSelectReferralLetterExamMetricsFilter}
                                defaultSelectedKey={salesIntegrationReportReferralLetterExamMetricsFilter}
                                selectedKey={salesIntegrationReportReferralLetterExamMetricsFilter}
                            />
                        </Col>
                        <Col span={3}>
                            <SalesPageOdStatusFilter
                                onSelect={onSelectReferralLetterOdStatusFilter}
                                defaultSelectedKey={salesIntegrationReportReferralLetterOdStatusFilter}
                                selectedKey={salesIntegrationReportReferralLetterOdStatusFilter}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Spin
                                className="loading-spinner"
                                spinning={referralLetterOperating}
                                size="large"
                                tip={Constants.OPERATING_TEXT}
                            >
                                <SalesIntegrationReportTable
                                    salesIntegrationReport={salesIntegrationReportReferralLetter}
                                    salesIntegrationWeekColumn={salesIntegrationWeekColumnReferralLetter}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <Row className='titleArea'>
                        <Col span={24} className='textArea'>
                            <span className="list-title">Summary - Total Uploads Referral Letter Upload Interface</span>
                            <span className="last-updated">{ getLastUpdated(salesIntegrationLastUpdatedReferralLetter) }</span>
                            <SalesPageOdGroupPracticeStatusFilter
                                onSelect={onSelectReferralLetterOdGroupPracticeStatusFilter}
                                defaultSelectedKey={salesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter}
                                selectedKey={salesIntegrationReportSummaryReferralLetterOdGroupPracticeStatusFilter}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Spin
                                className="loading-spinner"
                                spinning={originalPeiOperating}
                                size="large"
                                tip={Constants.OPERATING_TEXT}
                            >
                                <SalesIntegrationReportSummaryTable
                                    salesIntegrationReportSummary={salesIntegrationReportSummaryReferralLetter}
                                    salesIntegrationWeekColumn={salesIntegrationWeekColumnReferralLetter}
                                />
                            </Spin>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>
    );
}

export default SalesPage;