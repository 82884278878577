import React from 'react';
import { Card, Input } from 'antd';
import classnames from 'classnames';
import styles from '../../static/css/components/component-textarea-card.module.scss';

type ComponentProps = {
    disabled?: boolean;
    title: React.ReactNode;
    testId?: string;
    value: string;
    placeholder?: string;
    lightBlueBackground?: boolean;
    redBorder?: boolean;
    className?: string;
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const ComponentTextareaCard = ({
    disabled,
    title,
    testId,
    value,
    placeholder,
    lightBlueBackground=false,
    redBorder=false,
    className,
    handleChange,
}: ComponentProps) => {

    const classNames = classnames(className, {
        [styles.lightBlueBackground]: lightBlueBackground,
        [styles.redBorder]: redBorder,
    })
   
    return (
        <div className={styles.textareaCardContainer}>
            <Card bordered={false}>
                <div className='heading'>
                    {title}
                </div>
                <Input.TextArea
                    disabled={disabled}
                    data-testid={testId ?? ''}
                    autoSize={false}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className={classNames}
                />
            </Card>
        </div>
    );
};

export default ComponentTextareaCard;
