import { Fragment } from 'react';
import { Row, Card, Tag, Tooltip } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-fu-options-omdr-re.scss';

const { CheckableTag } = Tag;

const PatientExamFUOptionsOMDRRE = ({ disabled }: { disabled?: boolean }) => {

    const omdrFuOptions = useCare1AppSelector(store => store.options.omdrFuOptions);
    const selectedOmdNoteOptions = useCare1AppSelector(store => store.examData.selected_omd_note_options);
    const notesToOdGp = useCare1AppSelector(store => store.examData.omd_gp);
    const retinaReferral = useCare1AppSelector(store => store.examData.retina_referral);
    const dispatch = useCare1AppDispatch();

    const TELE_FU_ID = 15;
    const teleFuOption = omdrFuOptions.find(option => option.id === TELE_FU_ID);
    const TELE_FU_TEXT = teleFuOption ? teleFuOption.note_to_od : '';

    const handleChanges = (id: number, text: string, checked: boolean) => {
        if(checked){
            dispatch(setExamDataValue('selected_omd_note_options', [...selectedOmdNoteOptions, id]));
            dispatch(setExamDataValue('omd_gp', `${notesToOdGp} ${text}`));
        } else {
            dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(option => option !== id)));
            dispatch(setExamDataValue('omd_gp', notesToOdGp.replace(text, '')));
        }
    }

    const handleReferralChange = (checked: boolean, value: string) => {
        if(checked) {
            dispatch(setExamDataValue('retina_referral', value));

            // If 'optional' or 'needed' is selected, then check if 'follow-up with OD' is checked
            // If it is, then deselect 'follow-up with OD' and remove the follow up with OD string
            if(value && selectedOmdNoteOptions.includes(TELE_FU_ID)){
                handleChanges(TELE_FU_ID, TELE_FU_TEXT, false);
            }
        } else {
            dispatch(setExamDataValue('retina_referral', ''));
        }
    }

    return (
        <Fragment>
        <Card
            className={`component-input-card fu-options-omdr-re fu-od${disabled ? ' is-disabled' : ''}`}
            bordered={false}
        >
            <Row>
                <div className="fu-with-od">
                    <CheckableTag
                        className={`${disabled ? 'is-disabled' : ''}`}
                        checked={selectedOmdNoteOptions.indexOf(TELE_FU_ID) > -1}
                        onChange={checked => handleChanges(TELE_FU_ID, TELE_FU_TEXT, checked)}
                    >
                        {`Follow Up with OD`}
                        <Tooltip placement='topRight' title={'If selected, the OD gets your consultation, and the patient will not be referred to your office.'}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </CheckableTag>
                </div>
            </Row>
        </Card>
         <Card
            className={`component-input-card fu-options-omdr-re retina-refer ${disabled ? ' is-disabled' : ''}`}
            bordered={false}
        >
            <Row>
                <div className="heading">{'In Person Retina Referral:'}</div>
            </Row>
            <Row>
                <div className="retina-refer-none">
                    <CheckableTag
                        className={`${disabled ? 'is-disabled' : ''}`}
                        checked={retinaReferral === ''}
                        onChange={(checked) => handleReferralChange(checked, '')}
                    >
                        {`N/A`}
                        <Tooltip placement='topRight' title={'Care1 will not send a referral letter to your office. If the OD wants a referral with you, they will arrange it with Care1 staff.'}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </CheckableTag>
                </div>
                <div className="retina-refer-optional">
                    <CheckableTag
                        className={`${disabled ? 'is-disabled' : ''}`}
                        checked={retinaReferral === 'optional'}
                        onChange={(checked) => handleReferralChange(checked, 'optional')}
                    >
                        {`Optional`}
                        <Tooltip placement='topRight' title={"Care1 will not send a referral letter to your office. If the OD wants a referral for their patient, they will contact Care1, who will then send a referral to your office. Examples when this button may be used include ERM patients with borderline VA deficits, or patients where it is not clear if the patient wants to travel to the ophthalmologist's office"}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </CheckableTag>
                </div>
                <div className="retina-refer-needed">
                    <CheckableTag
                        className={`${disabled ? 'is-disabled' : ''}`}
                        checked={retinaReferral === 'needed'}
                        onChange={(checked) => handleReferralChange(checked, 'needed')}
                    >
                        {`Needed`}
                        <Tooltip placement='topRight' title={"If the patient is in your catchment area, Care1 will send a referral letter to your office within 2 business days. If the patient is not in your catchment area, Care1 will assist the OD to make a referral to a local retina specialist."}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </CheckableTag>
                </div>
            </Row>
        </Card>
        </Fragment>
    );
}

export default PatientExamFUOptionsOMDRRE;