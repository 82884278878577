import { Card, Row, Col, Tooltip } from 'antd';
import { TrophyFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import DeepMDLogoWhite from '../../../../static/images/logo-deepmd-white.svg';
import globalColors from '../../../../static/css/shared/global-colors.scss';
import { lengthEqual } from '../../../reducers/patient-exam-slice';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const DeepMdOdBaselineTesting = () => {
    // Number of tests required to achieve the baseline.
    const BASELINE_TESTS = 3;

    const examsWithLeftVF = useCare1AppSelector(store => store.examData.exams_with_left_vf, lengthEqual);
    const examsWithRightVF = useCare1AppSelector(store => store.examData.exams_with_right_vf, lengthEqual);
    const examsWithLeftRNFL = useCare1AppSelector(store => store.examData.exams_with_left_oct_rnfl, lengthEqual);
    const examsWithRightRNFL = useCare1AppSelector(store => store.examData.exams_with_right_oct_rnfl, lengthEqual);
    const examsWithIOPLeftIOP = useCare1AppSelector(store => store.examData.graph_iop?.os_iop_points ?? [], lengthEqual);
    const examsWithIOPRightIOP = useCare1AppSelector(store => store.examData.graph_iop?.od_iop_points ?? [], lengthEqual);

    // Return a number in the range of 0-3 to represent how many of the three
    // baseline tests have been done for the VF images.
    const baselineVF = () => {
        const leftVFTotal = examsWithLeftVF ? examsWithLeftVF.length : 0;
        const rightVFTotal = examsWithRightVF ? examsWithRightVF.length : 0;
        const maxNumOfTests = Math.max(leftVFTotal, rightVFTotal);

        return maxNumOfTests > BASELINE_TESTS ? BASELINE_TESTS : maxNumOfTests;
    }

    // Return a number in the range of 0-3 to represent how many of the three
    // baseline tests have been done for the VF images.
    const baselineRNFL = () => {
        const leftRNFLTotal = examsWithLeftRNFL ? examsWithLeftRNFL.length : 0;
        const rightRNFLTotal = examsWithRightRNFL ? examsWithRightRNFL.length : 0;
        const maxNumOfTests = Math.max(leftRNFLTotal, rightRNFLTotal);

        return maxNumOfTests > BASELINE_TESTS ? BASELINE_TESTS : maxNumOfTests;
    }

    // Return a number in the range of 0-3 to represent how many of the three
    // baseline tests have been done for IOP readings.
    // The IOP Graph array has one more entry than the total entries, so we need to subract
    // 1 entry from the total in the logic below
    const baselineIOP = () => {
        const leftIOPTotal = examsWithIOPLeftIOP ? examsWithIOPLeftIOP.length - 1 : 0;
        const rightIOPTotal = examsWithIOPRightIOP ? examsWithIOPRightIOP.length -1 : 0;
        const maxNumOfTests = Math.max(leftIOPTotal, rightIOPTotal);

        return maxNumOfTests > BASELINE_TESTS ? BASELINE_TESTS : maxNumOfTests;
    }

    // Calculate how many test remain for baseline testing.
    const testsRemaining = () => {
        const minTests = Math.min(baselineVF(), baselineRNFL(), baselineIOP());
        return minTests < BASELINE_TESTS ? `: ${3 - minTests} remaining.` : ` is complete.`
    }

    const data = [
        {
            type: 'IOP',
            tests: baselineIOP(),
        },
        {
            type: 'RNFL',
            tests: baselineRNFL(),
        },
        {
            type: 'VF',
            tests: baselineVF(),
        }
    ]

    // Graph congigurations https://charts.ant.design/en/examples/column/basic#basic
    const config = {
        theme: "classicDark",
        data,
        xField: 'type',
        yField: 'tests',
        style: {
            fill: ({ type }: { type: string }) => {
                return type === 'IOP' ? globalColors['baselineTestColumnIOP']
                    : type === 'RNFL' ? globalColors['baselineTestColumnRNFL']
                    : globalColors['baselineTestColumnVF'];
                },
        },
        scale: {
            x: {
                padding: 0.5,
            },
            y: { 
                tickCount: 3,
            }
        },
        meta: {
            tests: {
                max: 3,
            }
        },
        tooltip: false,
        axis: {
            x: {
                labelStroke: 'white',
                lineStroke: 'white',
                tickStroke: 'white',
                tickStrokeOpacity: 1,
                line: true,
                tick: true,
            },
            y: {
                labelStroke: 'white',
                lineStroke: 'white',
                tickStroke: 'white',
                tickStrokeOpacity: 1,
                tickLineDash: [1, 0],
                lineLineDash: [1, 0],
                labelLineDash: [1, 0],
                label: true,
                line: true,
                tick: true,
            },
        },
    }


    return (
        <Card className={'component-input-card baseline-testing'} bordered={false}>
            <Row>
                <Col>
                    <img src={DeepMDLogoWhite} className='deepmd-speech-image' alt='Deep MD Logo' />
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <div className='deepmd-speech-bubble'>
                                {`Baseline testing ${testsRemaining()}`}
                            </div>
                        </Col>
                        <Col>
                            <Tooltip
                                className={'baseline-tooltip'}
                                placement='topLeft'
                                title={`In order for DeepMD to provide a full analysis we \
                                    need complete baseline testing comprised of IOP values, \
                                    OCT RNFL images and VF images for three exams.`}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className='baseline-trophy gold-trophy'>
                        <TrophyFilled />
                    </Row>
                    <Row className='baseline-trophy silver-trophy'>
                        <TrophyFilled />
                    </Row>
                    <Row className='baseline-trophy bronze-trophy'>
                        <TrophyFilled />
                    </Row>
                </Col>
                <Col>
                    <Column
                        className='baseline-testing-graph'
                        {...config}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default DeepMdOdBaselineTesting;