import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { downloadBinaryFile } from '../helpers/patient-exam-convert';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest, httpFileRequest } from '../services/api-request';
import { logout } from './user-slice';


export interface ISalesIntegrationReportForm {
    start_date: string;
    end_date: string;
    exam_metrics: string;
    od_status: string;
    od_group_practice_status: string;
}

export interface ISalesIntegrationReportExcelForm {
    start_date: string;
    end_date: string;
    original: string;
    refltr: string;
    original_od_status: string;
    original_od_group_practice_status: string;
    refltr_od_status: string;
    refltr_od_group_practice_status: string;
}

export interface ISalesIntegrationReportItem {
    id: number;
    program: string;
    province: string;
    region: string;
    odgrouppractice: string;
    doctor: string;
    [weekkey: string]: string | number;
}

export interface ISalesIntegrationReportSummaryGroupBy {
    id: number;
    [weekkey: string]: string | number;
}

export interface ISalesIntegrationReportSummaryGroupByProvince {
    [province: string]: ISalesIntegrationReportSummaryGroupBy;
}

export interface ISalesIntegrationReportSummaryGroupByOdGroupPractice {
    [odgrouppractice: string]: ISalesIntegrationReportSummaryGroupBy;
}

export interface ISalesIntegrationReportSummaryGroupByProvinceOdgrouppractice {
    [province: string]: ISalesIntegrationReportSummaryGroupByOdGroupPractice;
}

export interface ISalesIntegrationReportSummary {
    group_by: ISalesIntegrationReportSummaryGroupBy;
    group_by_province: ISalesIntegrationReportSummaryGroupByProvince;
    group_by_province_odgrouppractice: ISalesIntegrationReportSummaryGroupByProvinceOdgrouppractice;
}

export interface ISalesIntegrationReport {
    original_pei_operating: boolean;
    original_pei_week_column: string[];
    original_pei_report: ISalesIntegrationReportItem[];
    original_pei_summary: ISalesIntegrationReportSummary | undefined;
    original_pei_last_updated: string;

    referral_letter_operating: boolean;
    referral_letter_week_column: string[];
    referral_letter_report: ISalesIntegrationReportItem[];
    referral_letter_summary: ISalesIntegrationReportSummary | undefined;
    referral_letter_last_updated: string;
}

interface ISalesIntegrationReportResponseData {
    week_column: string[];
    report: ISalesIntegrationReportItem[];
    summary: ISalesIntegrationReportSummary;
    last_updated: string;
}

const initialState : ISalesIntegrationReport = {
    original_pei_operating: false,
    original_pei_week_column: [],
    original_pei_report: [],
    original_pei_summary: undefined,
    original_pei_last_updated: '',

    referral_letter_operating: false,
    referral_letter_week_column: [],
    referral_letter_report: [],
    referral_letter_summary: undefined,
    referral_letter_last_updated: '',
};


// GET: getSalesIntegrationReportExcelRequest
export const getSalesIntegrationReportExcelRequest = createAsyncThunk(
    'salesIntegrationReport/getSalesIntegrationReportExcelRequest',
    async (data: ISalesIntegrationReportExcelForm, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const formData = new FormData();
        formData.append('start_date', `${data['start_date']}`);
        formData.append('end_date', `${data['end_date']}`);
        formData.append('original', `${data['original']}`);        
        formData.append('refltr', `${data['refltr']}`);
        formData.append('original_od_status', `${data['original_od_status']}`)
        formData.append('original_od_group_practice_status', `${data['original_od_group_practice_status']}`)
        formData.append('refltr_od_status', `${data['refltr_od_status']}`)
        formData.append('refltr_od_group_practice_status', `${data['refltr_od_group_practice_status']}`)
        
        const URL = `${process.env.REACT_APP_BACKENDURL}/data/sales/integration/report_excel/`;

        try {
            const response = await httpFileRequest('POST', URL, csrfToken, formData);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: getSalesIntegrationReportOriginalPeiRequest
export const getSalesIntegrationReportOriginalPeiRequest = createAsyncThunk(
    'salesIntegrationReport/getSalesIntegrationReportOriginalPeiRequest',
    async (data: ISalesIntegrationReportForm, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const formData = new FormData();
        formData.append('start_date', `${data['start_date']}`);
        formData.append('end_date', `${data['end_date']}`);
        formData.append('exam_metrics', `${data['exam_metrics']}`);        
        formData.append('uses_referral_letter_pei', 'false');
        formData.append('od_status', `${data['od_status']}`)
        formData.append('od_group_practice_status', `${data['od_group_practice_status']}`)
        
        const URL = `${process.env.REACT_APP_BACKENDURL}/data/sales/integration/report/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: getSalesIntegrationReportReferralLetterRequest
export const getSalesIntegrationReportReferralLetterRequest = createAsyncThunk(
    'salesIntegrationReport/getSalesIntegrationReportReferralLetterRequest',
    async (data: ISalesIntegrationReportForm, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const formData = new FormData();
        formData.append('start_date', `${data['start_date']}`);
        formData.append('end_date', `${data['end_date']}`);
        formData.append('exam_metrics', `${data['exam_metrics']}`);        
        formData.append('uses_referral_letter_pei', 'true');        
        formData.append('od_status', `${data['od_status']}`)
        formData.append('od_group_practice_status', `${data['od_group_practice_status']}`)
        
        const URL = `${process.env.REACT_APP_BACKENDURL}/data/sales/integration/report/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const salesIntegrationReportSlice = createSlice({
    name: 'salesIntegrationReport',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getSalesIntegrationReportOriginalPeiRequest.pending, (state) => {
            state.original_pei_operating = true;
            state.original_pei_week_column = [];
            state.original_pei_report = [];
            state.original_pei_summary = undefined;
            state.original_pei_last_updated = '';
        });
        builder.addCase(getSalesIntegrationReportOriginalPeiRequest.fulfilled, (state, action : PayloadAction<ISalesIntegrationReportResponseData>) => {
            state.original_pei_operating = false;
            state.original_pei_week_column = action.payload.week_column;
            state.original_pei_report = action.payload.report;
            state.original_pei_summary = action.payload.summary;
            state.original_pei_last_updated = action.payload.last_updated;
        });
        builder.addCase(getSalesIntegrationReportOriginalPeiRequest.rejected, (state, action) => {
            state.original_pei_operating = false;
            state.original_pei_week_column = [];
            state.original_pei_report = [];
            state.original_pei_summary = undefined;
            state.original_pei_last_updated = '';
            Modal.error({
                className: 'info-modal',
                title: `Errors getting Sales Integration Report. ${action.payload}`,
            })
        });

        builder.addCase(getSalesIntegrationReportReferralLetterRequest.pending, (state) => {
            state.referral_letter_operating = true;
            state.referral_letter_week_column = [];
            state.referral_letter_report = [];
            state.referral_letter_summary = undefined;
            state.referral_letter_last_updated = '';
        });
        builder.addCase(getSalesIntegrationReportReferralLetterRequest.fulfilled, (state, action : PayloadAction<ISalesIntegrationReportResponseData>) => {
            state.referral_letter_operating = false;
            state.referral_letter_week_column = action.payload.week_column;
            state.referral_letter_report = action.payload.report;
            state.referral_letter_summary = action.payload.summary;
            state.referral_letter_last_updated = action.payload.last_updated;
        });
        builder.addCase(getSalesIntegrationReportReferralLetterRequest.rejected, (state, action) => {
            state.referral_letter_operating = false;
            state.referral_letter_week_column = [];
            state.referral_letter_report = [];
            state.referral_letter_summary = undefined;
            state.referral_letter_last_updated = '';
            Modal.error({
                className: 'info-modal',
                title: `Errors getting Sales Integration Report. ${action.payload}`,
            })
        });

        builder.addCase(getSalesIntegrationReportExcelRequest.fulfilled, (state, action) => {
            const data = action.payload;
            if( data && data.filename && data.docx) {
                downloadBinaryFile(data.docx, data.filename);
            }
        });
        builder.addCase(getSalesIntegrationReportExcelRequest.rejected, (state, action) => {

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting report. ${action.payload}`,
            })
        });
    }
})

export default salesIntegrationReportSlice.reducer;
