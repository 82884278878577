import { Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import { Col, Card, Row } from 'antd';
import DeepMDLogoWhite from '../../../../static/images/logo-deepmd-white.svg';
import { localizedText } from '../../../localizedText';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const DeepMdOdGlcFeatures= () => {
    const { DEEPMD_SUSPICIOUS_FEATURES_GLC, DEEPMD_REASSURING_FEATURES_GLC,
        DEEPMD_ANALYZED_DATA } = localizedText;
    const suspFeatures = useCare1AppSelector(store => store.examData.susp_features, shallowEqual);
    const reasFeatures = useCare1AppSelector(store => store.examData.reas_features, shallowEqual);

    return (
        <Fragment>
            <Card className={'component-input-card glc-features'} bordered={false}>
                <Row>
                    <Col>
                        <img src={DeepMDLogoWhite} className='deepmd-speech-image' alt='Deep MD Logo' />
                    </Col>
                    <Col>
                        <div className='deepmd-speech-bubble'>
                            {DEEPMD_ANALYZED_DATA}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Row>
                            <div className="heading">{DEEPMD_SUSPICIOUS_FEATURES_GLC}</div>
                        </Row>
                        <Row>
                            <div className='component-body suspicious-glc' data-testid='suspicious-glc'>
                                {
                                    suspFeatures.map((sf, idx) =>
                                            <div key={idx}>&#x2022;  {sf}</div>
                                    )
                                }
                            </div>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            <div className="heading">{DEEPMD_REASSURING_FEATURES_GLC}</div>
                        </Row>
                        <Row>
                            <div className='component-body reassuring-glc' data-testid='reassuring-glc'>
                                {
                                    reasFeatures.map((sf, idx) =>
                                        <div key={idx}>&#x2022; {sf}</div>
                                    )
                                }
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
}

export default DeepMdOdGlcFeatures;