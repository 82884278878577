import { Menu, Row, Badge } from 'antd';
import type { MenuProps } from 'antd';
import { useCare1AppDispatch } from '../../apps/care1-hooks';

import { showReportModal } from '../../reducers/admin-reports-slice';
import { useGetCsvStereoViwerNearMissNumUnreconciledQuery } from '../../services/admin-csv-stereo-viewer-near-miss-api';

import '../../../static/css/components/patient-list-menu-admin.scss';
import AdminReportsModal from './admin-reports-modal';
import { localizedText } from '../../localizedText';

const PatientListMenuAdmin = () => {
    const { ADMIN_ALERT_LISTS_TEXT, ADMIN_DOCTERS_TEXT, ADMIN_REPORTS_TEXT, 
        ADMIN_TEXT, ADMIN_REPORTS_DASHBOARD_TEXT } = localizedText;
    
    type MenuItem = Required<MenuProps>['items'][number];
    
    const dispatch = useCare1AppDispatch();

    const {data: csvStereoViwerNearMissResponse, isFetching: isCsvStereoViwerNearMissFetching} = useGetCsvStereoViwerNearMissNumUnreconciledQuery();
    
    const handleAlertListsSelection = () => {
        window.open('/alert-lists/', '_blank')?.focus();
    }

    const handleDoctorsSelection = () => {
        window.open('/doctors/', '_blank')?.focus();
    }

    const handleReportsSelection = () => {
        dispatch(showReportModal());
    }

    const handleAdminSelection = () => {
        window.open('https://app.care1.ca/admin', '_blank')?.focus();
    }

    const handleReportsDashboardSelection = () => {
        window.open('/reports-dashboard/', '_blank')?.focus();

    }

    const handleSalesDashboardSelection = () => {
        window.open('/sales-dashboard/', '_blank')?.focus();

    }

    const handleAdminOctRnflNearMissSelection = () => {
        window.open('/admin_oct_rnfl_near_miss/', '_blank')?.focus();

    }

    const handleAdminVfNearMissSelection = () => {
        window.open('/admin_vf_near_miss/', '_blank')?.focus();

    }

    const handleAdminGptChatNearMissSelection = () => {
        window.open('/admin_gpt_chat_near_miss/', '_blank')?.focus();

    }

    const handleAdminCsvNearMissSelection = () => {
        window.open('/admin_csv_near_miss/', '_blank')?.focus();
        
    }

    const handleAdminHealthCheckSelection  = () => {
        window.open('/services-health-check/', '_blank')?.focus();
        
    }

    const handleReviewProcessingSelection = () => {
        window.open('/review-processing/', '_blank')?.focus();
    }

    const items: MenuItem[] = [
        {label: ADMIN_ALERT_LISTS_TEXT, key: 'alert_list', onClick: handleAlertListsSelection},
        {label: 'Review Processing', key: 'review_processing', onClick: handleReviewProcessingSelection},
        {label: ADMIN_DOCTERS_TEXT, key: 'doctors', onClick: handleDoctorsSelection},
        {label: ADMIN_REPORTS_TEXT, key: 'reports', onClick: handleReportsSelection},
        {label: ADMIN_TEXT, key: 'admin', onClick: handleAdminSelection},
        {label: ADMIN_REPORTS_DASHBOARD_TEXT, key: 'report_dashboard', onClick: handleReportsDashboardSelection},
        {label: 'SALES DASHBOARD', key: 'sales_dashboard', onClick: handleSalesDashboardSelection},
        {label: 'OCT RNFL Near Miss', key: 'admin_oct_rnfl_near_miss', onClick: handleAdminOctRnflNearMissSelection},
        {label: 'VF Near Miss', key: 'admin_vf_near_miss', onClick: handleAdminVfNearMissSelection},
        {label: 'Exam Metrics Near Miss', key: 'admin_gpt_chat_near_miss', onClick: handleAdminGptChatNearMissSelection},
        {label: 'CSV Near Miss', key: 'admin_csv_near_miss', onClick: handleAdminCsvNearMissSelection},
        {label: 'Health Check', key: 'health_check_dashboard', onClick: handleAdminHealthCheckSelection},
    ]

    return (
        <Row className='patient-list-menu-admin'>
            <AdminReportsModal />
            <Menu
                mode='horizontal'
                items={items}
            />
                
        </Row>

    )
}

export default PatientListMenuAdmin;