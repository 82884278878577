import { Modal, Row, Col } from 'antd';
import '../../../static/css/components/admin-exam-metrics-info-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import dayjs from 'dayjs';
import { closeSegmentationModal } from '../../reducers/ai-insights-historical-analysis-slice';
import AdminDrusenSegmentation from './admin-drusen-segmentation';


const AdminAIInsightsModal = () => {

    const showModal = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.show_modal);
    const slecetedExamId = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_exam_id);
    const selectedFundusPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_fundus_photo);
    const useDrusenSegmentationPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_use_drusen_segmentation_photo);
    const selectedMLDrusenSegmentationBackgroundPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_drusen_segmentation_background_photo);
    const selectedMLDrusenSegmentationOverlayPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_drusen_segmentation_overlay_photo);
    const selectedMLDrusenSegmentationValue = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_drusen_segmentation_value);
    const selectedMLDrusenSegmentationSucceeded = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_drusen_segmentation_succeeded);
    const useMicroAneurysmsPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_use_micro_aneurysms_photo);
    const selectedMLMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_overlay_photo);
    const selectedMLMicroAneurysmsValue = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_value);
    const selectedMLMicroAneurysmsSucceeded = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_succeeded);
    const selectedMLMicroAneurysmsModelVersion = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_model_version);
    const selectedMLMicroAneurysmsRunAt = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_run_at);
    const useHardExudatesPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_use_hard_exudates_photo);
    const selectedMLHardExudatesOverlayPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_hard_exudates_overlay_photo);
    const selectedMLHardExudatesValue = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_hard_exudates_value);
    const selectedMLHardExudatesSucceeded = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_hard_exudates_succeeded);
    const selectedExamDate = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_exam_date);

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(closeSegmentationModal());
    };

    return (
        <Modal
            className='ai-insights-modal'
            open={showModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
        <Row className='drusen-segmentation'>
            <Col span={24}>
                <AdminDrusenSegmentation                             
                    fundusPhoto={selectedFundusPhoto} 
                    useDrusenSegmentationPhoto={useDrusenSegmentationPhoto}
                    mlDrusenSegmentationBackgroundPhoto={selectedMLDrusenSegmentationBackgroundPhoto}
                    mlDrusenSegmentationOverlayPhoto={selectedMLDrusenSegmentationOverlayPhoto}
                    mlDrusenSegmentationValue={selectedMLDrusenSegmentationValue}
                    mlDrusenSegmentationSucceeded={selectedMLDrusenSegmentationSucceeded}
                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                    mlMicroAneurysmsOverlayPhoto={selectedMLMicroAneurysmsOverlayPhoto}
                    mlMicroAneurysmsValue={selectedMLMicroAneurysmsValue}
                    mlMicroAneurysmsSucceeded={selectedMLMicroAneurysmsSucceeded}
                    mlMicroAneurysmsModelVersion={selectedMLMicroAneurysmsModelVersion}
                    mlMicroAneurysmsRunAt={selectedMLMicroAneurysmsRunAt}
                    useHardExudatesPhoto={useHardExudatesPhoto}
                    mlHardExudatesOverlayPhoto={selectedMLHardExudatesOverlayPhoto}
                    mlHardExudatesValue={selectedMLHardExudatesValue}
                    mlHardExudatesSucceeded={selectedMLHardExudatesSucceeded}
                    title={'FUNDUS'} 
                    examDate={dayjs(selectedExamDate).format('D MMM YYYY')} 
                />
            </Col>
        </Row>    
        </Modal>
    );

}

export default AdminAIInsightsModal;
