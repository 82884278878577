import { Card, Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import DeepMDLogoWhite from '../../../../static/images/logo-deepmd-white.svg';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { compareStrings } from '../../../helpers/sorting';
import { IFinalDeepmdRecommendationsDetails } from '../../../reducers/txalgo3-slice';
import { DEEPMD_RECOMMENDATION_TEXT_BY_USER } from '../../../constants';

const DeepMdOdCounsellingRecommendations = () => {
    const finalDeepMDRecommendations = useCare1AppSelector(store => store.txalgo3.final_deepmd_recommendations);
    const allLeftComparisons = useCare1AppSelector(store => store.txalgo3.left_comparisons);
    const allRightComparisons = useCare1AppSelector(store => store.txalgo3.right_comparisons);

    const getComparisonText = (side: 'left' | 'right') => {

        const comparisonList = (side === 'right') ? allRightComparisons : allLeftComparisons;

        for (let index = 0; index < comparisonList.length; index++) {
            if (comparisonList[index].dx !== '-') {
                return comparisonList[index].discuss;
            }
        }

        return '';
    };


    const getSelectedFinalDeepMDRecommendationsText = (side: 'left' | 'right') => {
        const finalSelectedDeepMDRecommendations = getSelectedFinalDeepMDRecommendations(side);

        if (finalSelectedDeepMDRecommendations) {

            return finalSelectedDeepMDRecommendations.discuss;

        }

        return '';
    }

    const getSelectedFinalDeepMDRecommendations = (side: 'left' | 'right') => {
        const updatedDeepMDRecommendations = updateDeepMDRecommendations();

        const finalDeepMDRecommendations = (side === 'right') ?
            updatedDeepMDRecommendations.allRightFinalDeepMDRecommendations :
            updatedDeepMDRecommendations.allLeftFinalDeepMDRecommendations;

        let selectedRecommendations: IFinalDeepmdRecommendationsDetails | null = null;
        for (let index = 0; index < finalDeepMDRecommendations.length; index++) {
            if (finalDeepMDRecommendations[index].selected === 'Y') {
                selectedRecommendations = finalDeepMDRecommendations[index];
            }
        }

        return selectedRecommendations;
    }

    const updateDeepMDRecommendations = () => {
        const leftFinalDeepMDRecommendations = [];
        const rightFinalDeepMDRecommendations = [];

        for (let index = 0; index < finalDeepMDRecommendations.length; index++) {
            if (finalDeepMDRecommendations[index].left) {
                leftFinalDeepMDRecommendations.push(finalDeepMDRecommendations[index].left);
            }
            if (finalDeepMDRecommendations[index].right) {
                rightFinalDeepMDRecommendations.push(finalDeepMDRecommendations[index].right);
            }
        }

        return {
            allLeftFinalDeepMDRecommendations: leftFinalDeepMDRecommendations,
            allRightFinalDeepMDRecommendations: rightFinalDeepMDRecommendations,
        }
    }

    const deepmdEyeSummary = (side: 'left' | 'right') => {
        const comparisonDeepMDString = getComparisonText(side);
        const recommendationsDeepMDString = getSelectedFinalDeepMDRecommendationsText(side);
        const odString = comparisonDeepMDString && recommendationsDeepMDString ? recommendationsDeepMDString + '; ' + comparisonDeepMDString
            : recommendationsDeepMDString ? recommendationsDeepMDString
            : comparisonDeepMDString ? comparisonDeepMDString
            : ''

        if(odString && odString !== ' -'){
            // Break up the string into items, which should be delimited with commas, but may also have a semicolon.
            const odRecommendations = odString.split(/,|;/);

            // Get a list of categories from the entries and move entries to each.
            const categoryNamesSet = new Set(Object.values(DEEPMD_RECOMMENDATION_TEXT_BY_USER['OD'])
                .filter(item => item.category)
                .map(item => item.category));
            const categoryNames = Array.from(categoryNamesSet);
            let categories = categoryNames.map(category => ({category: category, entries: [] })) as {[key: string] : any}[];

            odRecommendations.forEach((value, index) => {
                const keyValue = value.trim();
                const currentCategory: string = keyValue in DEEPMD_RECOMMENDATION_TEXT_BY_USER['OD'] ?
                    DEEPMD_RECOMMENDATION_TEXT_BY_USER['OD'][keyValue].category : '';
                const foundCategory = categories.find(entry=>entry.category===currentCategory);
                if (foundCategory) {
                    foundCategory.entries.push(DEEPMD_RECOMMENDATION_TEXT_BY_USER['OD'][keyValue]);
                }
            });

            categories.sort((entry1, entry2) => compareStrings(entry1.category, entry2.category));

            return (
                <div className={`recommendation-summary ${side}`}>
                    <div className={`recommendation-title`}>
                        {`Recommendation for the ${side} eye:`}
                    </div>
                    <div>
                    {
                        categories.map((category) => {
                            return (
                            <div>
                                <div>{category.category}</div>
                            {
                                category.entries.length ?
                                category.entries.map((entry: {[key: string] : any}, index: number) => {
                                    // Get the user-friendly text for the key value.   
                                    if (!entry.text || entry.text === '-') {
                                        return '';
                                    }
    
                                    return (<div key={index} className={`${entry.color}`}>&#x2022;  {entry.text}</div>)
                                }) :
                                <div key={0}>&#x2022;  N/A</div>
                            }
                            </div>

                            )
                        })
                    }
                    </div>
                </div>
            )
        } else{
            return '';
        }
    }

    return (
        <Card className={'component-input-card counselling-recommendations'} bordered={false}>
            <Row>
                <Col>
                    <img src={DeepMDLogoWhite} className='deepmd-speech-image' alt='Deep MD Logo' />
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <div className='deepmd-speech-bubble'>
                                {`Here are my counseling recommendations.`}
                            </div>
                        </Col>
                        <Col>
                            <Tooltip
                                className={'recommendations-tooltip'}
                                placement='topLeft'
                                title={`l am still in beta testing, so please do not \
                                    utilize my recommendations yet.`}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                {deepmdEyeSummary('right')} 
            </Row>
            <Row>
                {deepmdEyeSummary('left')}
            </Row>
        </Card>
    );
}

export default DeepMdOdCounsellingRecommendations;