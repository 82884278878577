import { useState } from "react";
import { FullscreenOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import PhotoDropzoneExtraImage from '../../../components/retina/photo-dropzone-extra-image';
import '../../../../static/css/components/_patient-exam-extra-images.scss';
import { localizedText } from '../../../localizedText';
import { viewAll } from '../../../reducers/extra-image-slice';
import { IExtraImage } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../../helpers/media-image-convert';
import { convertUNIXTimeToShortFormatLabel } from '../../../helpers/utilities';
import GptImageIdentifierDNDImg from './gpt-image-identifier-dnd-img';

type ComponentProps = {
    extraImages: string[],
    setGptExtraImagesAtIndex: (img: string, idx: number) => void,
    moveBase64Images: (fromField: string, toField: string) => void,
    deleteBase64Image: (img: string) => void,
}

const GptImageIdentifierDNDExtraImages = ({ extraImages, 
    setGptExtraImagesAtIndex, moveBase64Images, deleteBase64Image }: ComponentProps) => {

    const { EXTRA_IMAGES_TEXT, VIEW_ALL_TEXT, UPLOADED_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();

    const imagesToDisplay = 6;

    const [showAllImages, setShowAllImages] = useState(false);
    const [dummyBase64, setDummyBase64] = useState('');

    const getImageCards = (isModal = false) => {
        return extraImages.map((base64Image, index) => (
            <Col key={index} span={isModal ? 4 : 3}>
                <Card key={index} className={`extra-image extra-image-mt2 extra-image-${index}`} bordered={false}>
                    <div className="extra-image image">
                        <div className="img-wrapper">
                            <GptImageIdentifierDNDImg
                                className="img-thumbnail"
                                isModal={isModal}
                                field={`extra-image[${index}]`}
                                base64Image={base64Image}
                                setBase64Image={(img: string) => { setGptExtraImagesAtIndex(img, index)}}
                                moveBase64Images={moveBase64Images}
                                deleteBase64Image={deleteBase64Image}
                            />
                        </div>
                    </div>
                </Card>
            </Col>
        )
        );

    }

    return (
        <Card className='patient-exam-extra-images' bordered={false}>
            <Modal
                className="view-all-modal"
                open={showAllImages}
                width="840"
                onCancel={() => { setShowAllImages(false); }}
                closable
                footer={null}
            >
                <Row gutter={8}>
                    {getImageCards(true)}
                </Row>
            </Modal>
            <Row gutter={8}>
                <Col span={3}>
                    <Card className="extra-image extra-image-new" bordered={false}>
                        <div className="heading">{EXTRA_IMAGES_TEXT}</div>
                        <GptImageIdentifierDNDImg
                            className="img-thumbnail"
                            field={`extra-image-new`}
                            base64Image={dummyBase64}
                            setBase64Image={(img: string) => { setGptExtraImagesAtIndex(img, -1) }}
                            moveBase64Images={(fromField: string, toField: string) => {}}
                            deleteBase64Image={deleteBase64Image}
                            />
                    </Card>
                </Col>
                {getImageCards().slice(0, imagesToDisplay)}
                {getImageCards().length > imagesToDisplay
                    && (
                        <Col span={3}>
                            <Card className="extra-image view-all" bordered={false}>
                                <div className="heading">&nbsp;</div>
                                <button className="view-all-text" onClick={() => { setShowAllImages(true); }}>
                                    <FullscreenOutlined className="fullscreen-icon" />
                                    <div className="text">
                                        {VIEW_ALL_TEXT}
                                    </div>
                                </button>
                            </Card>
                        </Col>
                    )}
            </Row>
        </Card>
    );
}

export default GptImageIdentifierDNDExtraImages;
