import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Col, Row, } from 'antd';
import AdminAIInsightsRetinaHotspotsGraph from './admin-ai-insights-retina-hotspots-graph';
import * as Constants from '../../constants';
import { fetchAIInsightsRetinaHotspotsGraph } from '../../reducers/ai-insights-historical-analysis-slice';

const AdminAIInsightsRetinaHotspots = () => {

    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData[Constants.EXAM_ID]);

    useEffect(() => {
        if (examId) {
            dispatch(fetchAIInsightsRetinaHotspotsGraph());
        }
    }, [examId, dispatch]);

    return (
        <Row>
            <Col span={12}>
                <div className="ai-title">
                    <span className='ai-heading'>{"Microaneurysms Graph"}</span><span className='ai-sub-heading'>{"Right Eye"}</span>
                </div>

                <AdminAIInsightsRetinaHotspotsGraph side="od" />
            </Col>
            <Col span={12}>
                <div className="ai-title">
                    <span className='ai-heading'>{"Microaneurysms Graph"}</span><span className='ai-sub-heading'>{"Left Eye"}</span>
                </div>

                <AdminAIInsightsRetinaHotspotsGraph side="os" />
            </Col>
        </Row>
    );
}

export default AdminAIInsightsRetinaHotspots;