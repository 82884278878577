import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import InputNumberSuffix from '../input-number-suffix';
import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import ExamMetricValidationModal from './exam-metric-validation-modal';
import { DTC_HOUR, DTC_HOUR_SUFFIX, DTC_HOUR_VALIDATION_MSG } from '../../../constants';
import { Form } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { InputNumberProps } from 'antd/lib/input-number';


const PatientExamDtcHour = ({ disabled }: { disabled: boolean }) => {

    const dtcHour = useCare1AppSelector(store => store.examData[DTC_HOUR]);
    const pastDtcHour = useCare1AppSelector(store => store.examData.past_dtc_hour);
    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const validateDTCHourValue = () => {
        const value = dtcHour;

        if (value && typeof (value) === 'number' && (value < 7 || value > 18)) {
            dispatch(setExamDataValue(DTC_HOUR, ''));
            ExamMetricValidationModal(DTC_HOUR_VALIDATION_MSG);
        }
    };

    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [DTC_HOUR]: dtcHour
        };
        form.setFieldsValue(newValidationFieldValues);
    }, [dtcHour, form]);

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case DTC_HOUR:
                        dispatch(setExamDataValue(field.name[0], formatValue
                            ? formatValue(field.value, { userTyping: false, input: '' })
                            : field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // All dtc hour entries should be in the format of # or ##
    const formatValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([0-9]?)([0-9]?).*$/;
        const matches = String(value).match(regex);

        if (matches && matches[1] && matches[2]) {
            return String(Number(`${matches[1]}${matches[2]}`));
        } else if (matches && matches[1]) {
            return String(Number(matches[1]));
        } else {
            return '';
        }
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <Form.Item
                help=""
                validateStatus={ !dtcHour ? 'warning' : '' }
                name={DTC_HOUR}
                initialValue={dtcHour}
            >
                {/* DTC-HR 001, DTC-HR 002, DTC-HR 003, DTC-HR 004 */}
                {/* Use onKeyPress event to prevent letters */}
                {/* Use formatter to enforce a max of two digits */}
                <InputNumberSuffix
                    className="dtc-iop-hour"
                    data-testid='dtc-hour'
                    size={'small'}
                    disabled={disabled}
                    step={1}
                    suffix={DTC_HOUR_SUFFIX}
                    onKeyPress={(event) => allowPosNumberOnlyKeys(event)}
                    formatter={formatValue}
                    onBlur={validateDTCHourValue}
                    placeholder={pastDtcHour || ''}
                />
            </Form.Item>
        </Form>
    );
}

export default PatientExamDtcHour;