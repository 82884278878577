import { useState } from 'react';
import { Col, Row, Button } from 'antd';
import * as Constants from '../../constants';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import dayjs from 'dayjs';
import { FRONTEND_SHORT_DATE_FORMAT} from '../../constants';

import '../../../static/css/components/ai-insights.scss';
import AdminDrusenSegmentation from './admin-drusen-segmentation';
import AdminAIInsightsHistoricalAnalysis from './admin-ai-insights-historical-analysis';
import AdminAIInsightsModal from './admin-ai-insights-modal';
import AdminAIInsightsScoreProgression from './admin-ai-insights-score-progression';
import AdminAIInsightsRetinaHotspots from './admin-ai-insights-retina-hotspots';
import PatientExamOCTMaculaImageHistory from '../image-histories/patient-exam-oct-macula-image-history';

const AdminAIInsights = () => {

    const examDate = useCare1AppSelector(store => store.examData[Constants.EXAM_DATE]);

    const firstName = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const lastName = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const dob = useCare1AppSelector(store => store.patientDetails.details.dob_date);
    const age = useCare1AppSelector(store => store.patientDetails.details.age);
    const sex = useCare1AppSelector(store => store.patientDetails.details.gender);

    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const isSCIENCEUSER = useCare1AppSelector(store => store.user.isSCIENCEUSER);
    const isValidUser = (isADMIN || isSCIENCEUSER);

    const leftFundus = useCare1AppSelector(store => store.examData.photoUrls.left_fundus_photo);
    const rightFundus = useCare1AppSelector(store => store.examData.photoUrls.right_fundus_photo);

    const rightMlDrusenSegmentationBackgroundPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_drusen_segmentation_background_photo);
    const leftMlDrusenSegmentationBackgroundPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_drusen_segmentation_background_photo);

    const rightMlDrusenSegmentationOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_drusen_segmentation_overlay_photo);
    const leftMlDrusenSegmentationOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_drusen_segmentation_overlay_photo);

    const rightMlDrusenSegmentationValue = useCare1AppSelector(store => store.examData.right_ml_drusen_segmentation_value);
    const leftMlDrusenSegmentationValue = useCare1AppSelector(store => store.examData.left_ml_drusen_segmentation_value);

    const rightMlDrusenSegmentationSucceeded = useCare1AppSelector(store => store.examData.right_ml_drusen_segmentation_succeeded);
    const leftMlDrusenSegmentationSucceeded = useCare1AppSelector(store => store.examData.left_ml_drusen_segmentation_succeeded);

    const [useDrusenSegmentationPhoto, setUseDrusenSegmentationPhoto] = useState(true);

    const rightMlMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_micro_aneurysms_overlay_photo);
    const leftMlMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_micro_aneurysms_overlay_photo);

    const rightMlMicroAneurysmsValue = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_value);
    const leftMlMicroAneurysmsValue = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_value);
        
    const rightMlMicroAneurysmsSucceeded = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_succeeded);
    const leftMlMicroAneurysmsSucceeded = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_succeeded);

    const rightMlMicroAneurysmsModelVersion = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_model_version);
    const leftMlMicroAneurysmsModelVersion = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_model_version);

    const rightMlMicroAneurysmsRunAt = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_run_at);
    const leftMlMicroAneurysmsRunAt = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_run_at);
    
    const [useMicroAneurysmsPhoto, setUseMicroAneurysmsPhoto] = useState(true);

    const rightMlHardExudatesOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_hard_exudates_overlay_photo);
    const leftMlHardExudatesOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_hard_exudates_overlay_photo);

    const rightMlHardExudatesValue = useCare1AppSelector(store => store.examData.right_ml_hard_exudates_value);
    const leftMlHardExudatesValue = useCare1AppSelector(store => store.examData.left_ml_hard_exudates_value);
        
    const rightMlHardExudatesSucceeded = useCare1AppSelector(store => store.examData.right_ml_hard_exudates_succeeded);
    const leftMlHardExudatesSucceeded = useCare1AppSelector(store => store.examData.left_ml_hard_exudates_succeeded);

    const [useHardExudatesPhoto, setUseHardExudatesPhoto] = useState(true);

    // For display purposes, the birth date needs to be converted from full month names to abbreviated month names.
    const shortDOB = dob ? dayjs(dob).format(FRONTEND_SHORT_DATE_FORMAT): '';

    return (
        <>
        <Row className='drusen-segmentation'>
            <Col span={24}>
                <Row className='title'>
                    <Col span={24}>
                        <div className='title-text'>
                            <span className='heading'>{`${lastName}, ${firstName}`}</span>
                        </div>                  
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className='dob'>
                        <label htmlFor={'DOB'} className="patient-exam-header-label">
                           DOB:
                        </label>
                        <label htmlFor={shortDOB} className="patient-exam-header-content dob-age-sex">
                            {`${shortDOB} (${age}, ${sex})`}
                        </label>
                    </Col>
                </Row>
                <Row className='title'>
                    <Col span={24}>
                        <div className='title-text'>
                            <span className='heading'>Retina Worksheet</span>
                            <span className='sub-heading'>In Beta Testing</span>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='title-button-left'>
                            {isValidUser && 
                            <Button
                                className={(useDrusenSegmentationPhoto)? 'original-button' : 'original-button-not-selected'}
                                onClick={() => { 
                                    setUseDrusenSegmentationPhoto(!useDrusenSegmentationPhoto); 
                                }}
                            >Fovea Irregularity </Button>
                            }
                            <Button
                                className={(useMicroAneurysmsPhoto)? 'ai-button' : 'ai-button-not-selected'}
                                onClick={() => { 
                                    setUseMicroAneurysmsPhoto(!useMicroAneurysmsPhoto); 
                                }}
                            >Microaneurysms</Button>
                            <Button
                                className={(useHardExudatesPhoto)? 'ai2-button' : 'ai2-button-not-selected'}
                                onClick={() => { 
                                    setUseHardExudatesPhoto(!useHardExudatesPhoto); 
                                }}
                            >Hard Exudates</Button>
                        </div>   
                    </Col>
                </Row>
                <Row>
                    <Col span={12} className='container-left-offset'>
                        <AdminDrusenSegmentation                             
                            fundusPhoto={rightFundus} 
                            useDrusenSegmentationPhoto={useDrusenSegmentationPhoto}
                            mlDrusenSegmentationBackgroundPhoto={rightMlDrusenSegmentationBackgroundPhoto}
                            mlDrusenSegmentationOverlayPhoto={rightMlDrusenSegmentationOverlayPhoto}
                            mlDrusenSegmentationValue={rightMlDrusenSegmentationValue}
                            mlDrusenSegmentationSucceeded={rightMlDrusenSegmentationSucceeded}
                            useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                            mlMicroAneurysmsOverlayPhoto={rightMlMicroAneurysmsOverlayPhoto}
                            mlMicroAneurysmsValue={rightMlMicroAneurysmsValue}
                            mlMicroAneurysmsSucceeded={rightMlMicroAneurysmsSucceeded}
                            mlMicroAneurysmsModelVersion={rightMlMicroAneurysmsModelVersion}
                            mlMicroAneurysmsRunAt={rightMlMicroAneurysmsRunAt}
                            useHardExudatesPhoto={useHardExudatesPhoto}
                            mlHardExudatesOverlayPhoto={rightMlHardExudatesOverlayPhoto}
                            mlHardExudatesValue={rightMlHardExudatesValue}
                            mlHardExudatesSucceeded={rightMlHardExudatesSucceeded}
                            title={'RIGHT FUNDUS'} 
                            examDate={dayjs(examDate).format('D MMM YYYY')} 
                        />
                    </Col>
                    <Col span={12}>
                        <AdminDrusenSegmentation 
                            fundusPhoto={leftFundus} 
                            useDrusenSegmentationPhoto={useDrusenSegmentationPhoto}
                            mlDrusenSegmentationBackgroundPhoto={leftMlDrusenSegmentationBackgroundPhoto}
                            mlDrusenSegmentationOverlayPhoto={leftMlDrusenSegmentationOverlayPhoto}
                            mlDrusenSegmentationValue={leftMlDrusenSegmentationValue}
                            mlDrusenSegmentationSucceeded={leftMlDrusenSegmentationSucceeded}
                            useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                            mlMicroAneurysmsOverlayPhoto={leftMlMicroAneurysmsOverlayPhoto}
                            mlMicroAneurysmsValue={leftMlMicroAneurysmsValue}
                            mlMicroAneurysmsSucceeded={leftMlMicroAneurysmsSucceeded}
                            mlMicroAneurysmsModelVersion={leftMlMicroAneurysmsModelVersion}
                            mlMicroAneurysmsRunAt={leftMlMicroAneurysmsRunAt}
                            useHardExudatesPhoto={useHardExudatesPhoto}
                            mlHardExudatesOverlayPhoto={leftMlHardExudatesOverlayPhoto}
                            mlHardExudatesValue={leftMlHardExudatesValue}
                            mlHardExudatesSucceeded={leftMlHardExudatesSucceeded}
                            title={'LEFT FUNDUS'} 
                            examDate={dayjs(examDate).format('D MMM YYYY')} 
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        {isValidUser && 
        <Row className='ai-insights'>
            <Col span={24}>
                <AdminAIInsightsScoreProgression />
            </Col>
        </Row>
        }
        <Row className='ai-insights'>
            <Col span={24}>
                <AdminAIInsightsRetinaHotspots />
            </Col>
        </Row>
        <Row className='ai-insights'>
            <Col span={12}>
                <AdminAIInsightsHistoricalAnalysis 
                    side="right" 
                    useDrusenSegmentationPhoto={useDrusenSegmentationPhoto}
                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                    useHardExudatesPhoto={useHardExudatesPhoto}
                />
            </Col>
            <Col span={12}>
                <AdminAIInsightsHistoricalAnalysis 
                    side="left" 
                    useDrusenSegmentationPhoto={useDrusenSegmentationPhoto}
                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                    useHardExudatesPhoto={useHardExudatesPhoto}
                />
            </Col>
        </Row>
        <Row gutter={8}>
            <PatientExamOCTMaculaImageHistory 
                orderBy={'desc'} 
                showHint={false} 
                showBaselineBorder={false} 
                showEmptyRow={false}
            />
        </Row>
        <AdminAIInsightsModal />
        </>
    );
}

export default AdminAIInsights;
