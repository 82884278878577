import { Row } from 'antd';

type Note = {
    color: string;
    content: string;
}

type ComponentProps ={
    type: string;
    side: string;
    date: string;
    isProposedPlan?: boolean;
    showAdminView?: boolean;
    odName?: string;
    omdcName?: string;
    odProposedPlan?: string;
    odOMDText?: string;
    omdNotesText?: string | Note[];
    omdrName?: string;
    adminNotesText?: string;
    odWantsOmdReport?: boolean;
}

function OMDODNotesBubble(props: ComponentProps) {
    const {
        type, isProposedPlan, showAdminView, side, date, odName, omdcName,
        odProposedPlan, odOMDText, omdNotesText, omdrName, adminNotesText,
        odWantsOmdReport
    } = props;
    let bubbleClass = '';
    const headerClass = 'bubble-header heading';
    const headerDate = 'bubble-header-date';
    if (type === 'OD') {
        bubbleClass = 'od-notes-bubble';
        if (isProposedPlan) {
            return (
                <>
                <div className={`od-notes-${side.toLowerCase()}`}>
                    <Row className={bubbleClass}><div>{odProposedPlan}</div></Row>
                    <div className={headerClass}>
                        OD Dr.
                        {' '}
                        {odName}
                        <span className={headerDate}>{date}</span>
                    </div>
                </div>
                </>
            );
        }
        return (
            <div className={`od-notes-${side.toLowerCase()}`}>
                <Row className={bubbleClass}>
                    <div>{odOMDText}</div>
                    {' '}
                    <br />
                    {' '}
                    {odProposedPlan}
                </Row>
                <div className={headerClass}>
                    OD Dr.
                    {' '}
                    {odName}
                    <span className={headerDate}>{date}</span>
                </div>
            </div>
        );
    } else if (type === 'OMDC') {
        if (showAdminView) {
            bubbleClass = 'omdc-admin-notes-bubble';
        } else {
            bubbleClass = 'omdc-notes-bubble';
        }

        return (
            <div className={`omd-notes-${side.toLowerCase()}`}>
                <Row className={bubbleClass}>
                    { !showAdminView && typeof omdNotesText === "string" &&
                        <div
                            dangerouslySetInnerHTML={{ __html: String(omdNotesText) }}
                        />
                    }
                    { showAdminView && Array.isArray(omdNotesText) &&
                        omdNotesText.map((note: Note, idx: number) => (
                            <span
                                key={idx}
                                className={`note-str-color-${note["color"]}`}>{note['content']}
                            </span>
                        ))
                    }
                </Row>
                <div className={`${headerClass} omdc`}>
                    {omdcName}
                    <span className={headerDate}>{date}</span>
                </div>
            </div>
        );
    } else if (type === 'OMDR') {
        if (showAdminView) {
            bubbleClass = 'omd-admin-notes-bubble';
        } else {
            bubbleClass = 'omd-notes-bubble';
        }

        return (
            <div className={`omd-notes-${side.toLowerCase()}`}>
                <Row className={bubbleClass}>
                    { !showAdminView && typeof omdNotesText === "string" &&
                        <>
                        {odWantsOmdReport === false && <span style={{color: 'green'}}>*** AI Validation Review ***</span>}
                        <div
                            dangerouslySetInnerHTML={{ __html: String(omdNotesText) }}
                        />
                        </>
                    }
                    { showAdminView && Array.isArray(omdNotesText) &&
                        omdNotesText.map((note, idx) => (
                            <span
                                key={idx}
                                className={`note-str-color-${note["color"]}`}>{note['content']}
                            </span>
                        ))
                    }
                </Row>
                <div className={headerClass}>
                    {omdrName}
                    <span className={headerDate}>{date}</span>
                </div>
            </div>
        );
    } else if (type === 'ADMIN') {
        bubbleClass = 'od-notes-bubble';

        return (
            <div className={`od-notes-${side.toLowerCase()}`}>
                <Row className={bubbleClass}>
                    {adminNotesText}
                </Row>
                <div className={headerClass}>
                    In person consultation status
                    <span className={headerDate}>{date}</span>

                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default OMDODNotesBubble;
