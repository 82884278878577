import dayjs from 'dayjs';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Layout, Spin, Row, Col, Modal } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router';
import * as Constants from '../constants';
import { localizedText } from '../localizedText';

// Components
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import PDFViewer from "../components/retina/pdf-viewer";
import GptImageIdentifierDNDImages from "../components/admin/image-identifier/gpt-image-identifier-dnd-images";
import GptImageIdentifierDNDExtraImages from '../components/admin/image-identifier/gpt-image-identifier-dnd-extra-images';
import PatientValidation from '../components/admin/upload-reconciler/patient-validation';
import PatientValidationChat, { PatientValidationType, PatientDemographicsType } from '../components/admin/upload-reconciler/patient-validation-chat';
import { lengthEqual } from '../reducers/patient-exam-slice';
import PatientReconciliation from '../components/admin/upload-reconciler/patient-reconciliation';
import { IUploadReconcilerAddPatientRequest, useAddReconcilerPatientPatientMutation, 
    useLazyGetUploadReconcilerPatientMatchingListQuery, useMatchReconcilerPatientPatientsMutation, 
    IUploadReconcilerMatchPatientsRequest, IUploadReconcilerMatchPatientsRequestItem } from '../services/upload-reconciler-api';
import SelectNewExamDateModal from '../components/integrated/select-new-exam-date-modal';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Helpers
import '../../static/css/pages/admin-patient-exam-page.scss';
// TODO - This RE BCVA style sheet css should be moved to the patient-exam-bcva.scss as applicable, and this file deleted.
import '../../static/css/components/patient-exam-re-bcva.scss';
import '../../static/css/pages/upload-reconciler-page.scss';
import '../../static/css/components/admin-gpt.scss';
import '../../static/css/components/admin-gpt-image-identifier.scss';
import '../../static/css/components/_patient-exam-extra-images.scss';


// Actions
import { editExamRequest } from '../reducers/patient-exam-slice';

const { Header, Content } = Layout;

const UploadReconcilerPage = () => {

    const { MONTHS } = localizedText;

    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get('tab');

    const examId = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;
    const optometristODgrouppracticeName = useCare1AppSelector(store => store.examData.optometrist_odgrouppractice_name);
    const optometristODgrouppracticeNameRef = useRef('');

    const referralLetter = useCare1AppSelector(store => store.examData.od_referral_letter);
    const patientId = useCare1AppSelector(store => store.examData.patient);

    const [chatLoading, setChatLoading] = useState(false);

    const [getUploadReconcilerPatientMatchingListQuery, { data: patientMatchingList = [], isFetching: isPatientMatchingListFetching }] = useLazyGetUploadReconcilerPatientMatchingListQuery();

    const [addReconcilerPatientPatient, { isLoading: isAddPatientLoading }] = useAddReconcilerPatientPatientMutation();

    const [matchReconcilerPatientPatients, { isLoading: isPatientMatchLoading }] = useMatchReconcilerPatientPatientsMutation();

    const [patientMatchingOps, setPatientMatchingOps] = useState<{ [v: number]: string }>({});

    // send edit exam request when exam page first renders
    const editExam = useCallback(() => {
        if (examId !== null) {
            dispatch(editExamRequest(examId, history));
        }
    }, [params, history, dispatch])

    useEffect(() => {
        editExam();
    }, [editExam])

    useEffect(() => {
        if (optometristODgrouppracticeName !== null && optometristODgrouppracticeName !== undefined && optometristODgrouppracticeName !== '') {
            optometristODgrouppracticeNameRef.current = optometristODgrouppracticeName;
        }

    }, [optometristODgrouppracticeName])

    const onPDFLoadSuccess = (numPages: number) => {
    }

    const onPDFLoadError = () => {
    }

    const onClose = () => {
    }

    const rightFundus = useCare1AppSelector(store => store.examData.photoUrls.right_fundus_photo);
    const leftFundus = useCare1AppSelector(store => store.examData.photoUrls.left_fundus_photo);

    const rightStereoFundus = useCare1AppSelector(store => store.examData.photoUrls.right_stereo_photo);
    const leftStereoFundus = useCare1AppSelector(store => store.examData.photoUrls.left_stereo_photo);

    const rightVfPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_vf_photo);
    const leftVfPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_vf_photo);

    const rightOctRnflPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_oct_rnfl_photo);
    const leftOctRnflPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_oct_rnfl_photo);

    const rightOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.right_oct_photo);
    const leftOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.left_oct_photo);

    const extraImages = useCare1AppSelector(store => store.examData[Constants.EXTRA_IMAGES], lengthEqual);

    const [gptRightFundus, setGptRightFundus] = useState('');
    const [gptLeftFundus, setGptLeftFundus] = useState('');

    const [gptRightStereoFundus, setGptRightStereoFundus] = useState('');
    const [gptLeftStereoFundus, setGptLeftStereoFundus] = useState('');

    const [gptRightVfPhoto, setGptRightVfPhoto] = useState('');
    const [gptLeftVfPhoto, setGptLeftVfPhoto] = useState('');

    const [gptRightOctRnflPhoto, setGptRightOctRnflPhoto] = useState('');
    const [gptLeftOctRnflPhoto, setGptLeftOctRnflPhoto] = useState('');

    const [gptRightOCTMacula, setGptRightOCTMacula] = useState('');
    const [gptLeftOCTMacula, setGptLeftOCTMacula] = useState('');

    const [gptExtraImages, setGptExtraImages] = useState<string[]>([]);

    const [gptOd, setGptOd] = useState<number | null>(null);
    const [gptExamDate, setGptExamDate] = useState<dayjs.Dayjs | null>(null);
    const [gptDobDay, setGptDobDay] = useState<string | null>(null);
    const [gptDobMonth, setGptDobMonth] = useState<string | null>(null);
    const [gptDobYear, setGptDobYear] = useState<string | null>(null);
    const [gptFirstname, setGptFirstname] = useState<string | null>(null);
    const [gptLastname, setGptLastname] = useState<string | null>(null);
    const [gptPrimaryPhone, setGptPrimaryPhone] = useState<string | null>(null);
    const [gptProvince, setGptProvince] = useState<number | null>(null);
    const [gptInsurance, setGptInsurance] = useState<string | null>(null);
    const [gptInsuranceType, setGptInsuranceType] = useState<string | null>(null);

    const [odNotes, setOdNotes] = useState('');
    const [gp, setGp] = useState<number | null>(null);
    const [gpFax, setGpFax] = useState<string | null>(null);

    const setGptExtraImagesAtIndex = (base64Image: string, idx: number) => {
        if (base64Image !== null && base64Image !== undefined && base64Image !== '') {

            if (idx === -1) {
                setGptExtraImages([base64Image, ...gptExtraImages]);
            }
            else {
                const res: string[] = [...gptExtraImages];
                res[idx] = base64Image;
                setGptExtraImages(res);
            }
        }
    }

    const deleteBase64Image = (field: string) => {
        console.log(`please delete ${field} base64 image`);

        if (field.indexOf('extra-image') !== -1) {
            const regexpIdx = /[(\d+]/;
            const match = field.match(regexpIdx);

            if (match) {
                console.log(`removing extra-image[${match[0]}]`);

                const res: string[] = [...gptExtraImages];
                res.splice(Number(match[0]), 1);
                setGptExtraImages(res);
            }
        }
        else if (field === 'right-fundus') {
            setGptExtraImagesAtIndex(gptRightFundus, -1);
            setGptRightFundus('');
        }
        else if (field === 'right-stereo') {
            setGptExtraImagesAtIndex(gptRightStereoFundus, -1);
            setGptRightStereoFundus('');
        }
        else if (field === 'right-oct-macula') {
            setGptExtraImagesAtIndex(gptRightOCTMacula, -1);
            setGptRightOCTMacula('');
        }
        else if (field === 'right-oct-rnfl') {
            setGptExtraImagesAtIndex(gptRightOctRnflPhoto, -1);
            setGptRightOctRnflPhoto('');
        }
        else if (field === 'right-vf') {
            setGptExtraImagesAtIndex(gptRightVfPhoto, -1);
            setGptRightVfPhoto('');
        }
        else if (field === 'left-fundus') {
            setGptExtraImagesAtIndex(gptLeftFundus, -1);
            setGptLeftFundus('');
        }
        else if (field === 'left-stereo') {
            setGptExtraImagesAtIndex(gptLeftStereoFundus, -1);
            setGptLeftStereoFundus('');
        }
        else if (field === 'left-oct-macula') {
            setGptExtraImagesAtIndex(gptLeftOCTMacula, -1);
            setGptLeftOCTMacula('');
        }
        else if (field === 'left-oct-rnfl') {
            setGptExtraImagesAtIndex(gptLeftOctRnflPhoto, -1);
            setGptLeftOctRnflPhoto('');
        }
        else if (field === 'left-vf') {
            setGptExtraImagesAtIndex(gptLeftVfPhoto, -1);
            setGptLeftVfPhoto('');
        }
    }

    const moveBase64Images = (fromField: string, toField: string) => {
        console.log(`${fromField} should be moved to ${toField}`)

        if (toField.indexOf('extra-image') !== -1) {
            return;
        }

        const imageMap: { [k: string]: string } = {};
        imageMap['right-fundus'] = gptRightFundus;
        imageMap['right-stereo'] = gptRightStereoFundus;
        imageMap['right-oct-macula'] = gptRightOCTMacula;
        imageMap['right-oct-rnfl'] = gptRightOctRnflPhoto;
        imageMap['right-vf'] = gptRightVfPhoto;
        imageMap['left-fundus'] = gptLeftFundus;
        imageMap['left-stereo'] = gptLeftStereoFundus;
        imageMap['left-oct-macula'] = gptLeftOCTMacula;
        imageMap['left-oct-rnfl'] = gptLeftOctRnflPhoto;
        imageMap['left-vf'] = gptLeftVfPhoto;

        for (let i = 0; i < gptExtraImages.length; i++) {
            imageMap[`extra-image[${i}]`] = gptExtraImages[i];
        }

        const fromImg = imageMap[fromField];

        if (toField === 'right-fundus') {
            deleteBase64Image('right-fundus');
            setGptRightFundus(fromImg);
        }
        else if (toField === 'right-stereo') {
            deleteBase64Image('right-stereo');
            setGptRightStereoFundus(fromImg);
        }
        else if (toField === 'right-oct-macula') {
            deleteBase64Image('right-oct-macula');
            setGptRightOCTMacula(fromImg);
        }
        else if (toField === 'right-oct-rnfl') {
            deleteBase64Image('right-oct-rnfl');
            setGptRightOctRnflPhoto(fromImg);
        }
        else if (toField === 'right-vf') {
            deleteBase64Image('right-vf');
            setGptRightVfPhoto(fromImg);
        }
        else if (toField === 'left-fundus') {
            deleteBase64Image('left-fundus');
            setGptLeftFundus(fromImg);
        }
        else if (toField === 'left-stereo') {
            deleteBase64Image('left-stereo');
            setGptLeftStereoFundus(fromImg);
        }
        else if (toField === 'left-oct-macula') {
            deleteBase64Image('left-oct-macula');
            setGptLeftOCTMacula(fromImg);
        }
        else if (toField === 'left-oct-rnfl') {
            deleteBase64Image('left-oct-rnfl');
            setGptLeftOctRnflPhoto(fromImg);
        }
        else if (toField === 'left-vf') {
            deleteBase64Image('left-vf');
            setGptLeftVfPhoto(fromImg);
        }

        if (fromField === 'right-fundus') {
            setGptRightFundus('');
        }
        else if (fromField === 'right-stereo') {
            setGptRightStereoFundus('');
        }
        else if (fromField === 'right-oct-macula') {
            setGptRightOCTMacula('');
        }
        else if (fromField === 'right-oct-rnfl') {
            setGptRightOctRnflPhoto('');
        }
        else if (fromField === 'right-vf') {
            setGptRightVfPhoto('');
        }
        else if (fromField === 'left-fundus') {
            setGptLeftFundus('');
        }
        else if (fromField === 'left-stereo') {
            setGptLeftStereoFundus('');
        }
        else if (fromField === 'left-oct-macula') {
            setGptLeftOCTMacula('');
        }
        else if (fromField === 'left-oct-rnfl') {
            setGptLeftOctRnflPhoto('');
        }
        else if (fromField === 'left-vf') {
            setGptLeftVfPhoto('');
        }
    }

    const onChatStatusChanged = (loading: boolean) => {
        setChatLoading(loading);
    }

    const handleImageIdentifierChatResult = async (image_urls: string[], output: PatientValidationType) => {
        let idxBased = 1;
        if (output['right_fundus_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_fundus_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_stereo_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_stereo_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_vf_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_vf_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_oct_rnfl_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_oct_rnfl_photo'] === 0) {
            idxBased = 0;
        }
        if (output['right_oct_photo'] === 0) {
            idxBased = 0;
        }
        if (output['left_oct_photo'] === 0) {
            idxBased = 0;
        }

        try {
            if (output['right_fundus_photo'] !== null) {
                const bi: string = image_urls[output['right_fundus_photo'] - idxBased];
                setGptRightFundus(bi);
            }
        }
        catch { };

        try {
            if (output['left_fundus_photo'] !== null) {
                const bi: string = image_urls[output['left_fundus_photo'] - idxBased];
                setGptLeftFundus(bi);
            }
        }
        catch { };

        try {
            if (output['right_stereo_photo'] !== null) {
                const bi: string = image_urls[output['right_stereo_photo'] - idxBased];
                setGptRightStereoFundus(bi);
            }
        }
        catch { };

        try {
            if (output['left_stereo_photo'] !== null) {
                const bi: string = image_urls[output['left_stereo_photo'] - idxBased];
                setGptLeftStereoFundus(bi);
            }
        }
        catch { };

        try {
            if (output['right_vf_photo'] !== null) {
                const bi: string = image_urls[output['right_vf_photo'] - idxBased];
                setGptRightVfPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['left_vf_photo'] !== null) {
                const bi: string = image_urls[output['left_vf_photo'] - idxBased];
                setGptLeftVfPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['right_oct_rnfl_photo'] !== null) {
                const bi: string = image_urls[output['right_oct_rnfl_photo'] - idxBased];
                setGptRightOctRnflPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['left_oct_rnfl_photo'] !== null) {
                const bi: string = image_urls[output['left_oct_rnfl_photo'] - idxBased];
                setGptLeftOctRnflPhoto(bi);
            }
        }
        catch { };

        try {
            if (output['right_oct_photo'] !== null) {
                const bi: string = image_urls[output['right_oct_photo'] - idxBased];
                setGptRightOCTMacula(bi);
            }
        }
        catch { };

        try {
            if (output['left_oct_photo'] !== null) {
                const bi: string = image_urls[output['left_oct_photo'] - idxBased];
                setGptLeftOCTMacula(bi);
            }
        }
        catch { };

        try {
            if ('unclassified' in output) {
                const unclassified = output['unclassified'];
                if (unclassified) {
                    const res: string[] = [];
                    for (let i = 0; i < unclassified.length; i++) {
                        const bi: string = image_urls[unclassified[i] - idxBased];
                        res.push(bi);
                    }
                    setGptExtraImages(res);
                }
            }
        }
        catch { };
    }

    const handlePatientDemographicsChatResult = async (output: PatientDemographicsType) => {
        setGptExamDate(dayjs(output['exam_date']))

        const dob = dayjs(output['patient_dob'])
        setGptDobYear(`${dob.year()}`);
        setGptDobMonth(MONTHS[dob.month()]);
        setGptDobDay(`${dob.date()}`);

        setGptFirstname(output['first_name'])
        setGptLastname(output['last_name'])

        setGptPrimaryPhone(output['primary_phone'].replace(/\D/, ''))
        setGptProvince(output['province'])
        setGptInsurance(output['phn'])

        await getUploadReconcilerPatientMatchingListQuery({
            dob: dob.format('YYYY-MM-DD'),
            first_name: output['first_name'],
            last_name: output['last_name'],
            insurance: output['phn'],
            od_group_practice_name: optometristODgrouppracticeNameRef.current,
        });
    }

    const mergePatients = async () => {

        const items: IUploadReconcilerMatchPatientsRequestItem[] = [];
        for (const [key, value] of Object.entries(patientMatchingOps)) {
            items.push({
                id: Number(key),
                op: value,
            })
        }

        const patientData: IUploadReconcilerMatchPatientsRequest = {
            items: items
        }

        try {

            const response = await matchReconcilerPatientPatients(patientData).unwrap();
            if (response.success) {
                setPatientMatchingOps({});
                await reMatch();
            } else {
                throw new Error(response?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const createNewPatient = async () => {

        if (gptOd !== null && gptDobYear !== null && gptDobMonth !== null && gptDobDay !== null &&
            gptFirstname !== null && gptLastname !== null && gptInsuranceType !== null) {

            const patientData: IUploadReconcilerAddPatientRequest = {
                first_name: gptFirstname,
                last_name: gptLastname,
                province: gptProvince,
                phn: gptInsurance !== null ? gptInsurance : '',
                dob: dayjs(`${gptDobDay} ${gptDobMonth} ${gptDobYear}`, 'D MMMM YYYY').format('YYYY-MM-DD'),
                primary_phone: gptPrimaryPhone,
                insurance_type: gptInsuranceType,
                od_id: gptOd,
                gp_id: gp,
                gp_fax_number: gpFax,
            }

            try {

                const response = await addReconcilerPatientPatient(patientData).unwrap();
                if (response.success) {
                    await reMatch();
                } else {
                    throw new Error(response?.error);
                }

            } catch (error) {
                const message = (error instanceof Error) ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Error',
                });
            }
        }
    }

    const reMatch = async () => {

        if (gptDobYear !== null && gptDobMonth !== null && gptDobDay !== null &&
            gptFirstname !== null && gptLastname !== null) {

            const dob = dayjs(`${gptDobDay} ${gptDobMonth} ${gptDobYear}`, 'D MMMM YYYY');

            await getUploadReconcilerPatientMatchingListQuery({
                dob: dob.format('YYYY-MM-DD'),
                first_name: gptFirstname,
                last_name: gptLastname,
                insurance: gptInsurance === null ? '' : gptInsurance,
                od_group_practice_name: optometristODgrouppracticeNameRef.current,
            });
        }
    }

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={examOperating || patientDetailsOperating || chatLoading}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar
                        className="admin-pei-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className='admin-patient-exam-page upload-reconciler-page'>
                        <Row gutter={16}>
                            <Col
                                span={12}
                                className={'pdfViewerModal no-overflow padding-col'}>
                                <PDFViewer
                                    file={referralLetter.replace('png', 'pdf')}
                                    onPDFLoadSuccess={onPDFLoadSuccess}
                                    onPDFLoadError={onPDFLoadError}
                                    onClose={onClose}
                                    isGPTTab={true}
                                />
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <PatientValidationChat
                                            examId={examId}
                                            onChatStatusChanged={onChatStatusChanged}
                                            rightFundus={rightFundus}
                                            leftFundus={leftFundus}
                                            rightStereoFundus={rightStereoFundus}
                                            leftStereoFundus={leftStereoFundus}
                                            rightVfPhoto={rightVfPhoto}
                                            leftVfPhoto={leftVfPhoto}
                                            rightOctRnflPhoto={rightOctRnflPhoto}
                                            leftOctRnflPhoto={leftOctRnflPhoto}
                                            rightOCTMacula={rightOCTMacula}
                                            leftOCTMacula={leftOCTMacula}
                                            onImageIdentifierChatResult={handleImageIdentifierChatResult}
                                            referralLetter={referralLetter.replace('png', 'pdf')}
                                            onPatientDemographicsChatResult={handlePatientDemographicsChatResult}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <PatientValidation
                                            patientId={patientId}
                                            gptOd={gptOd}
                                            setGptOd={setGptOd}
                                            gptExamDate={gptExamDate}
                                            setGptExamDate={setGptExamDate}
                                            gptDobDay={gptDobDay}
                                            setGptDobDay={setGptDobDay}
                                            gptDobMonth={gptDobMonth}
                                            setGptDobMonth={setGptDobMonth}
                                            gptDobYear={gptDobYear}
                                            setGptDobYear={setGptDobYear}
                                            gptFirstname={gptFirstname}
                                            setGptFirstname={setGptFirstname}
                                            gptLastname={gptLastname}
                                            setGptLastname={setGptLastname}
                                            gptPrimaryPhone={gptPrimaryPhone}
                                            setGptPrimaryPhone={setGptPrimaryPhone}
                                            gptProvince={gptProvince}
                                            setGptProvince={setGptProvince}
                                            gptInsurance={gptInsurance}
                                            setGptInsurance={setGptInsurance}
                                            gptInsuranceType={gptInsuranceType}
                                            setGptInsuranceType={setGptInsuranceType}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <PatientReconciliation
                                            patientId={patientId}
                                            patientMatchingList={patientMatchingList}
                                            isFetching={isPatientMatchingListFetching || isAddPatientLoading || isPatientMatchLoading}
                                            gptProvince={gptProvince}
                                            gp={gp}
                                            setGp={setGp}
                                            gpFax={gpFax}
                                            setGpFax={setGpFax}
                                            odNotes={odNotes}
                                            setOdNotes={setOdNotes}
                                            reMatch={reMatch}
                                            createNewPatient={createNewPatient}
                                            patientMatchingOps={patientMatchingOps}
                                            setPatientMatchingOps={setPatientMatchingOps}
                                            mergePatients={mergePatients}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row><Col span={24}><div className='mt-5'></div></Col></Row>
                        <Row className="admin-gpt-image-identifier" gutter={16}>
                            <Col span={24}>
                                <DndProvider backend={HTML5Backend} context={window}>
                                    <Row>
                                        <Col span={24}>
                                            <GptImageIdentifierDNDImages
                                                rightFundus={gptRightFundus}
                                                leftFundus={gptLeftFundus}
                                                rightStereoFundus={gptRightStereoFundus}
                                                leftStereoFundus={gptLeftStereoFundus}
                                                rightVfPhoto={gptRightVfPhoto}
                                                leftVfPhoto={gptLeftVfPhoto}
                                                rightOctRnflPhoto={gptRightOctRnflPhoto}
                                                leftOctRnflPhoto={gptLeftOctRnflPhoto}
                                                rightOCTMacula={gptRightOCTMacula}
                                                leftOCTMacula={gptLeftOCTMacula}
                                                setRightFundus={setGptRightFundus}
                                                setLeftFundus={setGptLeftFundus}
                                                setRightStereoFundus={setGptRightStereoFundus}
                                                setLeftStereoFundus={setGptLeftStereoFundus}
                                                setRightVfPhoto={setGptRightVfPhoto}
                                                setLeftVfPhoto={setGptLeftVfPhoto}
                                                setRightOctRnflPhoto={setGptRightOctRnflPhoto}
                                                setLeftOctRnflPhoto={setGptLeftOctRnflPhoto}
                                                setRightOCTMacula={setGptRightOCTMacula}
                                                setLeftOCTMacula={setGptLeftOCTMacula}
                                                moveBase64Images={moveBase64Images}
                                                deleteBase64Image={deleteBase64Image}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <GptImageIdentifierDNDExtraImages
                                                extraImages={gptExtraImages}
                                                setGptExtraImagesAtIndex={setGptExtraImagesAtIndex}
                                                moveBase64Images={moveBase64Images}
                                                deleteBase64Image={deleteBase64Image}
                                            />
                                        </Col>
                                    </Row>
                                </DndProvider>
                            </Col>
                        </Row>
                        <SelectNewExamDateModal />
                    </div>
                </Content>
            </Spin>
        </Layout>
    );

}

export default UploadReconcilerPage;