import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Col, Row, } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import AdminAIInsightsScoreProgressionGraph from './admin-ai-insights-score-progression-graph';
import * as Constants from '../../constants';
import { fetchAIInsightsScoreProgressionGraph } from '../../reducers/ai-insights-historical-analysis-slice';

const AdminAIInsightsScoreProgression = () => {

    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData[Constants.EXAM_ID]);

    useEffect(() => {
        if (examId) {
            dispatch(fetchAIInsightsScoreProgressionGraph());
        }
    }, [examId, dispatch]);

    return (
        <Row>
            <Col span={12}>
                <div className="ai-title">
                    <span className='ai-heading'>{"Score Progression Graph"}</span><span className='ai-sub-heading'>{"Right Eye"}</span>
                </div>

                <AdminAIInsightsScoreProgressionGraph side="od" />
            </Col>
            <Col span={12}>
                <div className="ai-title">
                    <span className='ai-heading'>{"Score Progression Graph"}</span><span className='ai-sub-heading'>{"Left Eye"}</span>
                </div>

                <AdminAIInsightsScoreProgressionGraph side="os" />
            </Col>
        </Row>
    );
}

export default AdminAIInsightsScoreProgression;