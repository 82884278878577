import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IReferralLetterAnalysis {
    api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    deidentified_referral_letter: string,
}

const initialState: IReferralLetterAnalysis = {
    api_status: 'idle',
    deidentified_referral_letter: '',
};

export const fetchAutoGptResponse = createAsyncThunk(
    'referralLetterAnalysis/fetchAutoGptResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchReferralLetterAnalysis = createAsyncThunk(
    'referralLetterAnalysis/fetchReferralLetterAnalysis',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/deidentified_referral_letter/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const generateDeidentifiedReferralLetter = createAsyncThunk(
    'referralLetterAnalysis/generateDeidentifiedReferralLetter',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/deidentified_referral_letter/generate/`;
        const data = JSON.stringify({
            exam_id: id,
        });

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// POST: Update results of referral letter analysis
export const postReferralLetterAnalysisResults = createAsyncThunk(
    'referralLetterAnalysis/postReferralLetterAnalysisResults',
    async (formData: {value: {[x: string]: string | number | null | boolean}}, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/deidentified_referral_letter/results/`;
        const data = JSON.stringify({
            exam_id: id,
            value: formData.value,
        });

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const referralLetterAnalysisSlice = createSlice({
    name: 'referralLetterAnalysis',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReferralLetterAnalysis.pending, (state) => {
                state.api_status = 'loading';
                state.deidentified_referral_letter = '';
            })
            .addCase(fetchReferralLetterAnalysis.fulfilled, (state, action) => {
                state.api_status = 'succeeded';
                state.deidentified_referral_letter = action.payload.deidentified_referral_letter
            })
            .addCase(fetchReferralLetterAnalysis.rejected, (state) => {
                state.api_status = 'failed';
                state.deidentified_referral_letter = '';
            })
            
            .addCase(generateDeidentifiedReferralLetter.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(generateDeidentifiedReferralLetter.fulfilled, (state, action) => {
                state.api_status = 'succeeded';
            })
            .addCase(generateDeidentifiedReferralLetter.rejected, (state) => {
                state.api_status = 'failed';
            })

            .addCase(postReferralLetterAnalysisResults.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(postReferralLetterAnalysisResults.fulfilled, (state, action) => {
                state.api_status = 'succeeded';
            })
            .addCase(postReferralLetterAnalysisResults.rejected, (state) => {
                state.api_status = 'failed';
            });
    },
});

export default referralLetterAnalysisSlice.reducer;