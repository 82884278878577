import { CalendarOutlined, FileSyncOutlined, FolderOpenOutlined, PlusOutlined, SearchOutlined,
    InfoCircleOutlined } from '@ant-design/icons';
import { Row, Divider, Select, Radio, Button, Input, Tooltip, Modal, RadioChangeEvent } from 'antd';
import { localizedText } from '../../localizedText';
import { getODPatientListRequest, setPatientListFieldData } from '../../reducers/patient-list-slice';
import { openMultiTabsView, setPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/shared/patient-list-control-bar.scss';
import '../../../static/css/components/patient-list-control-bar-od.scss';
import { openAddPatientModal, openPatientSelectModal } from '../../reducers/patient-edit-slice';
import { goToExamInNewTab, goToExamInNewTabWithTarget } from '../../reducers/patient-exam-slice';


const PatientListControlBar = () => {
    const { EXAM_PERIOD_OPTIONS, NEW_PATIENT_TEXT, PATIENT_GROUPING_OPTIONS, PATIENT_LIST_SEARCH_PLACEHOLDER,
        PATIENT_LIST_TEXT, EXAM_STATUS_TYPES, EXAM_STATUS_REFERRAL_LETTER_TYPES, PATIENT_EXAM_URL, OPEN_SELECTED_VISITS, 
        OPEN_SELECTED_VISITS_TOOLTIP } = localizedText;

    const examPeriod = useCare1AppSelector(store => store.patientList.examPeriod);
    const examStatus = useCare1AppSelector(store => store.patientList.examStatus);
    const patientGrouping = useCare1AppSelector(store => store.patientList.patientGrouping);
    const examRooms = useCare1AppSelector(store => store.patientExams.rooms);
    const isRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const isOD = useCare1AppSelector(store => store.user.isOD);
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);

    const examStatusOptions = odUsesReferralLetterPei ? EXAM_STATUS_REFERRAL_LETTER_TYPES : EXAM_STATUS_TYPES;

    const dispatch = useCare1AppDispatch();

    const controlBarOpenAddPatientModal = () => {
        dispatch(openAddPatientModal());
    }

    const handleExamPeriodSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key:'examPeriod', value }));
        dispatch(getODPatientListRequest());
    }

    const handleExamStatusSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key:'examStatus', value }));
        dispatch(getODPatientListRequest());
    }

    const handlePatientGroupingSelection = (event: RadioChangeEvent) => {
        dispatch(setPatientListFieldData({ key:'patientGrouping', value: event.target.value }));
        dispatch(getODPatientListRequest());
    }

    const sendSearchPatientListRequest = (value: string) => {
        if(value){
            // Search all statuses, all time and all clinic patients
            dispatch(setPatientListFieldData({ key: 'examStatus', value: 'all_statuses' }));
            dispatch(setPatientListFieldData({ key: 'examPeriod', value: 'all_time' }));
            dispatch(setPatientListFieldData({ key: 'patientGrouping', value: 'clinic' }));

            // Set search value and send request
            dispatch(setPatientListFieldData({ key: 'searchQuery', value }));
            dispatch(getODPatientListRequest());
        } else {
            // Reset the default values
            dispatch(setPatientListFieldData({ key: 'examStatus', value: 'not_ready_od_review' }));
            dispatch(setPatientListFieldData({ key: 'examPeriod', value: '12_months' }));
            dispatch(setPatientListFieldData({ key: 'patientGrouping', value: 'doctor' }));
            dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));
            dispatch(getODPatientListRequest());
        }
    }

    const handleOpenSelectedPatients = () => {
        if(examRooms && examRooms.length){
            if (isOD) {
                for (let i=0; i<examRooms.length; i++) {
                    const examUrl = `${PATIENT_EXAM_URL}/${examRooms[i].examId}`;
                    const target = `target-${examRooms[i].examId}`;
                    goToExamInNewTabWithTarget(examUrl, target);
                }
            }
            else {
                const examUrl = `${PATIENT_EXAM_URL}/${examRooms[0].examId}`;
                dispatch(openMultiTabsView());
                dispatch(setPatientExamRoomsData({ currentExamRoom: examRooms[0].examId.toString() }));
                goToExamInNewTab(examUrl);
            }
        } else{
            Modal.info({
                className: 'info-modal',
                title: `No exam selected`,
            })
        }
    }

    return (
        <Row className={'patient-list-control-bar patient-list-control-bar-od'}>
            <Row align="middle" className="control-bar-elements">
                {/* PT-LIST-CTRL-BAR 001 */}
                <div className="patient-list-title">
                    {PATIENT_LIST_TEXT}
                </div>
                {!isRE &&
                    <Button
                        className="open-selected-patients-button"
                        icon={<FolderOpenOutlined />}
                        onClick={handleOpenSelectedPatients}
                        type='primary'
                    >
                       {OPEN_SELECTED_VISITS}
                       <Tooltip className="popup-tooltip" placement='topRight' title={OPEN_SELECTED_VISITS_TOOLTIP}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Button>
                }
                {/* PT-LIST-CTRL-BAR 002 */}
                {
                    odUsesReferralLetterPei ?
                    <Button
                        className="patient-list-new-patient-button patient-upload"
                        icon={<PlusOutlined />}
                        onClick={() => dispatch(openPatientSelectModal())}
                        type='primary'
                    >
                        Patient Upload
                    </Button>
                    :
                    <Button
                        className="patient-list-new-patient-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenAddPatientModal}
                        type='primary'
                    >
                        {NEW_PATIENT_TEXT}
                    </Button>
                }
                

                <Divider type="vertical" className="patient-list-vertical-divider" />
                {/* PT-LIST-CTRL-BAR 003 */}
                <Radio.Group
                    defaultValue="doctor"
                    className="patient-list-group-type"
                    onChange={handlePatientGroupingSelection}
                    value={patientGrouping}
                >
                    {PATIENT_GROUPING_OPTIONS && PATIENT_GROUPING_OPTIONS.map((option) => (
                        <Radio.Button
                            className="radio-group-button patient-list-group-type-button"
                            key={option.key}
                            value={option.key}
                        >
                            {option.value}
                        </Radio.Button>
                    ))}
                </Radio.Group>

                <Divider type="vertical" className="patient-list-vertical-divider" />
                {/* PT-LIST-CTRL-BAR 004, 007 */}
                <Select
                    className="dropdown-filter patient-list-exam-status-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={odUsesReferralLetterPei ? 'not_ready_od_review' : 'not_ready'}
                    onSelect={handleExamStatusSelection}
                    data-testid="exam-status-select"
                    value={examStatus}
                >
                    {examStatusOptions && examStatusOptions.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <FileSyncOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>
                {/* PT-LIST-CTRL-BAR 005, 008 */}
                <Select
                    className="dropdown-filter patient-list-exam-period-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={examPeriod}
                    onSelect={handleExamPeriodSelection}
                    data-testid="exam-period-select"
                    value={examPeriod}
                >
                    {EXAM_PERIOD_OPTIONS && EXAM_PERIOD_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <CalendarOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                {/* PT-LIST-CTRL-BAR 006 */}
                <Input.Search
                    className="patient-list-search-input"
                    allowClear
                    placeholder={PATIENT_LIST_SEARCH_PLACEHOLDER}
                    prefix={<SearchOutlined />}
                    onSearch={sendSearchPatientListRequest}
                />
            </Row>
        </Row>
    );
}

export default PatientListControlBar;
