import { createSlice } from '@reduxjs/toolkit';


export interface IDoctorList {
    info: string;
    error: string;
    operating: boolean;
    list: [];
    searchQuery: string;
    staffSearchQuery: string;
    odGroupPracticeSearchQuery: string;
    status: 'loading' | 'success' | 'failed' | '';
    showOdAddModal: boolean;
    showOdEditModal: boolean;
    currentOd: number | string;
    showOmdAddModal: boolean;
    showOmdEditModal: boolean;
    showStaffAddModal: boolean;
    showStaffEditModal: boolean;
    showPasswordChangeModal: boolean;
    currentStaffId: number | null;
    currentOmd: number | string;
    showGroupPracticeAddModal: boolean;
    showNewRegionModal: boolean;
    showIgpAddModal: boolean;
    showIgpEditModal: boolean;
    currentIgp: number | string;
    currentUsername: string;
    isSalesUser: boolean;
    isScienceUser: boolean;
    showGroupPracticeEditModal: boolean;
    showClinicLocationAddModal: boolean;
    showClinicLocationEditModal: boolean;
    currentOdGroupPractice: number | null;
    currentClinicLocation: number | null;
    currentGroupPracticeName: string;
    showTechnicianEditModal: boolean;
    showTechnicianAddModal: boolean;
    currentTechnician: number | null;
    technicianSearchQuery: string;
    showOutboundOmdEditModal: boolean;
    showOutboundOmdAddModal: boolean;
}

const initialState: IDoctorList = {
    info: '',
    error: '',
    operating: false,
    list: [],
    searchQuery: '',
    staffSearchQuery: '',
    odGroupPracticeSearchQuery: '',
    status: '',
    showOdAddModal: false,
    showOdEditModal: false,
    showStaffAddModal: false,
    showStaffEditModal: false,
    showPasswordChangeModal: false,
    currentStaffId: null,
    currentOd: '',
    showOmdAddModal: false,
    showOmdEditModal: false,
    currentOmd: '',
    showGroupPracticeAddModal: false,
    showNewRegionModal: false,
    showIgpAddModal: false,
    showIgpEditModal: false,
    currentIgp: '',
    currentUsername: '',
    isSalesUser: false,
    isScienceUser: false,
    showGroupPracticeEditModal: false,
    showClinicLocationAddModal: false,
    showClinicLocationEditModal: false,
    currentOdGroupPractice: null,
    currentClinicLocation: null,
    currentGroupPracticeName: '',
    showTechnicianEditModal: false,
    showTechnicianAddModal: false,
    currentTechnician: null,
    technicianSearchQuery: '',
    showOutboundOmdEditModal: false,
    showOutboundOmdAddModal: false,
}

export type DoctorListMenuType = 'od' | 'odgrouppractice' |'omd' | 'igp' | 'staff' | 'technician';


export const doctorSlice = createSlice({
    name: 'doctorSlice',
    initialState,
    reducers: {
        setDoctorSearchQuery: (state, action: {payload: string, type: string}) => {
            state.searchQuery = action.payload;
        },
        setShowOmdAddModal: (state, action) => {
            state.showOmdAddModal = action.payload;
        },
        setShowOmdEditModal: (state, action) => {
            state.showOmdEditModal = action.payload;
        },
        setShowStaffAddModal: (state, action) => {
            state.showStaffAddModal = action.payload;
        },
        setShowStaffEditModal: (state, action) => {
            state.showStaffEditModal = action.payload;
        },
        setCurrentOmd: (state, action) => {
            state.currentOmd = action.payload;
        },
        setShowOdAddModal: (state, action) => {
            state.showOdAddModal = action.payload;
        },
        setShowOdEditModal: (state, action) => {
            state.showOdEditModal = action.payload;
        },
        setCurrentOd: (state, action) => {
            state.currentOd = action.payload;
        },
        setShowIgpAddModal: (state, action) => {
            state.showIgpAddModal = action.payload;
        },
        setShowIgpEditModal: (state, action) => {
            state.showIgpEditModal = action.payload;
        },
        setCurrentIgp: (state, action) => {
            state.currentIgp = action.payload;
        },
        toggleOdGroupPracticeAddModal: (state) => {
            state.showGroupPracticeAddModal = !state.showGroupPracticeAddModal;
        },
        setCurrentStaffId: (state, action) => {
            state.currentStaffId = action.payload;
        },
        setShowPasswordModal: (state, action) => {
            state.showPasswordChangeModal = action.payload;
        },
        setCurrentUsername: (state, action) => {
            state.currentUsername = action.payload;
        },
        setIsSalesUser: (state, action) => {
            state.isSalesUser = action.payload;
        },
        setIsScienceUser: (state, action) => {
            state.isScienceUser = action.payload;
        },
        setStaffSearchQuery: (state, action: {payload: string, type: string}) => {
            state.staffSearchQuery = action.payload;
        },
        setShowNewRegionModal: (state, action) => {
            state.showNewRegionModal = action.payload;
        },
        toggleOdGroupPracticeEditModal: (state) => {
            state.showGroupPracticeEditModal = !state.showGroupPracticeEditModal;
        },
        setOdGroupPracticeSearchQuery: (state, action: {payload: string, type: string}) => {
            state.odGroupPracticeSearchQuery = action.payload;
        },
        toggleClinicLocationAddModal: (state) => {
            state.showClinicLocationAddModal = !state.showClinicLocationAddModal;
        },
        toggleClinicLocationEditModal: (state) => {
            state.showClinicLocationEditModal = !state.showClinicLocationEditModal;
        },
        setCurrentOdGroupPractice: (state, action) => {
            state.currentOdGroupPractice = action.payload;
        },
        setCurrentClinicLocation: (state, action) => {
            state.currentClinicLocation = action.payload;
        },
        setCurrentGroupPracticeName: (state, action) => {
            state.currentGroupPracticeName = action.payload;
        },
        toggleTechnicianAddModal: (state) => {
            state.showTechnicianAddModal = !state.showTechnicianAddModal;
        },
        toggleTechnicianEditModal: (state) => {
            state.showTechnicianEditModal = !state.showTechnicianEditModal;
        },
        setCurrentTechnician: (state, action) => {
            state.currentTechnician = action.payload;
        },
        setTechnicianSearchQuery: (state, action: {payload: string, type: string}) => {
            state.technicianSearchQuery = action.payload;
        },
        toggleOutboundOmdEditModal: (state) => {
            state.showOutboundOmdEditModal = !state.showOutboundOmdEditModal;
        },
        toggleOutboundOmdAddModal: (state) => {
            state.showOutboundOmdAddModal = !state.showOutboundOmdAddModal;
        },
    },
});

export const { setDoctorSearchQuery, setShowOmdAddModal, setShowOmdEditModal,setCurrentOmd,
    setCurrentOd, setShowOdAddModal, setShowOdEditModal, toggleOdGroupPracticeAddModal, setCurrentIgp,
    setShowIgpAddModal, setShowIgpEditModal, setShowStaffAddModal, setCurrentStaffId,
    setShowStaffEditModal, setShowPasswordModal, setCurrentUsername, setIsSalesUser, setIsScienceUser,
    setStaffSearchQuery, setShowNewRegionModal, toggleOdGroupPracticeEditModal, setOdGroupPracticeSearchQuery,
    toggleClinicLocationAddModal, toggleClinicLocationEditModal, setCurrentOdGroupPractice, setCurrentClinicLocation,
    setCurrentGroupPracticeName, setCurrentTechnician, toggleTechnicianAddModal,
    toggleTechnicianEditModal, setTechnicianSearchQuery, toggleOutboundOmdEditModal, toggleOutboundOmdAddModal
} = doctorSlice.actions;

export default doctorSlice.reducer;
