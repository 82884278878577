/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';

import { Col, Row, Button } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import '../../../static/css/components/drusen-segmentation.scss';


type AppProps = {
    fundusPhoto: string,
    useDrusenSegmentationPhoto: boolean;
    mlDrusenSegmentationBackgroundPhoto: string;
    mlDrusenSegmentationOverlayPhoto: string;
    mlDrusenSegmentationValue: string;
    mlDrusenSegmentationSucceeded: boolean;
    useMicroAneurysmsPhoto: boolean;
    mlMicroAneurysmsOverlayPhoto: string;
    mlMicroAneurysmsValue: string;
    mlMicroAneurysmsSucceeded: boolean;
    mlMicroAneurysmsModelVersion: string;
    mlMicroAneurysmsRunAt: string;
    useHardExudatesPhoto: boolean;
    mlHardExudatesOverlayPhoto: string;
    mlHardExudatesValue: string;
    mlHardExudatesSucceeded: boolean;
    title: string,
    examDate: string,
}

const AdminDrusenSegmentation = ({ fundusPhoto, 
    useDrusenSegmentationPhoto, mlDrusenSegmentationBackgroundPhoto, mlDrusenSegmentationOverlayPhoto, mlDrusenSegmentationValue, mlDrusenSegmentationSucceeded, 
    useMicroAneurysmsPhoto, mlMicroAneurysmsOverlayPhoto, mlMicroAneurysmsValue, mlMicroAneurysmsSucceeded, mlMicroAneurysmsModelVersion, mlMicroAneurysmsRunAt, 
    useHardExudatesPhoto, mlHardExudatesOverlayPhoto, mlHardExudatesValue, mlHardExudatesSucceeded, 
    title, examDate }: AppProps) => {

    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const isSCIENCEUSER = useCare1AppSelector(store => store.user.isSCIENCEUSER);
    const isValidUser = (isADMIN || isSCIENCEUSER);

    const startWithAI = () => {
        if (isValidUser) {
            if (mlDrusenSegmentationBackgroundPhoto !== null && mlDrusenSegmentationBackgroundPhoto !== '')
                return true;
            if (mlDrusenSegmentationOverlayPhoto !== null && mlDrusenSegmentationOverlayPhoto !== '')
                return true;
        }
        if (useDrusenSegmentationPhoto || useMicroAneurysmsPhoto)
            return true;

        return false;
    }

    const [useAiPhoto, setUseAiPhoto] = useState(startWithAI());
    const [scale, setScale] = useState(1);

    const handleZoomIn = () => {
        setScale(prevScale => prevScale + 0.1); // Increase scale by 0.1
    };

    const handleZoomOut = () => {
        setScale(prevScale => Math.max(0.1, prevScale - 0.1)); // Decrease scale by 0.1, ensuring minimum scale of 0.1
    };

    const tooglePhoto = () => {
        if (startWithAI())
            setUseAiPhoto(!useAiPhoto);
    }

    const [panStart, setPanStart] = useState<{x: number | null, y: number | null}>({ x: 0, y: 0 });
    const [panOffset, setPanOffset] = useState<{x: number | null, y: number | null}>({ x: 0, y: 0 });
    const [mouseDown, setMouseDown] = useState(false);

    const handleMouseDown = (e: { clientX: any; clientY: any; }) => {
        setPanStart({ x: e.clientX, y: e.clientY });
        setMouseDown(true);
    };

    const handleMouseMove = (e: { clientX: number; clientY: number; }) => {
        if (mouseDown) {
            if (panStart.x !== null && panStart.y !== null) {
                const dx = e.clientX - panStart.x;
                const dy = e.clientY - panStart.y;
                const x = panOffset.x ? panOffset.x : 0;
                const y = panOffset.y ? panOffset.y : 0;
                setPanStart({ x: e.clientX, y: e.clientY });
                setPanOffset({ x: x + dx, y: y + dy });
            }
        }
    };

    const handleMouseUp = (e: { clientX: any; clientY: any; }) => {
        setPanStart({ x: null, y: null });
        setMouseDown(false);
    };

    return (
        <Row className='drusen-segmentation'>
            <Col span={24} className='text'>
                <Row>
                    <Col span={24} className='side-name'>
                        <div>{title}</div>
                        {!mlMicroAneurysmsSucceeded && <div>Microaneurysms Model Could Not Run</div>}
                        {!mlHardExudatesSucceeded && <div>Hard Exudates Model Could Not Run</div>}
                        {`${mlMicroAneurysmsValue}`.trim() === '0' && <div>No Microaneurysms Detected</div>}
                        {`${mlHardExudatesValue}`.trim() === '0' && <div>No Hard Exudates Detected</div>}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className='exam-date-offset'>{examDate}</div>
                        <Button
                            className={!useAiPhoto ? 'original-button' : 'original-button-not-selected'}
                            onClick={() => { tooglePhoto() }}
                        >ORIGINAL</Button>
                        <Button
                            className={useAiPhoto ? 'original-button' : 'original-button-not-selected'}
                            onClick={() => { tooglePhoto() }}
                        >AI</Button>

                        {(isValidUser && useAiPhoto && mlDrusenSegmentationValue !== '' && useDrusenSegmentationPhoto) &&
                            <span className="drusen-segmentation-value">{mlDrusenSegmentationValue}</span>
                        }
                        {(useAiPhoto && mlMicroAneurysmsValue !== '' && useMicroAneurysmsPhoto) &&
                            <span className="micro-aneurysms-value">{mlMicroAneurysmsValue}</span>
                        }
                        {(useAiPhoto && mlHardExudatesValue !== '' && useHardExudatesPhoto) &&
                            <span className="hard-exudates-value">{mlHardExudatesValue}</span>
                        }

                        <Button className='zoom-in-button' shape="circle" ghost icon={<ZoomInOutlined />} onClick={handleZoomIn} />
                        <Button className='zoom-out-button' shape="circle" ghost icon={<ZoomOutOutlined />} onClick={handleZoomOut} />

                        {!useAiPhoto &&
                            <div
                                className="photo-container-border"
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                            >
                                <div className="photo-container" style={{ transform: `scale(${scale}) translate(${panOffset.x}px, ${panOffset.y}px)` }}>
                                    {fundusPhoto &&
                                        <img
                                            className='photo'
                                            src={fundusPhoto}
                                            alt='Fundus Photo'
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {useAiPhoto &&
                            <div
                                className="photo-container-border"
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                            >
                                <div className="photo-container" style={{ transform: `scale(${scale}) translate(${panOffset.x}px, ${panOffset.y}px)` }}>
                                    {fundusPhoto &&
                                        <img
                                            className='photo'
                                            src={fundusPhoto}
                                            alt='Fundus Photo'
                                        />
                                    }
                                    {(isValidUser && mlDrusenSegmentationBackgroundPhoto && useDrusenSegmentationPhoto) &&
                                        <img
                                            className='photo overlay'
                                            src={mlDrusenSegmentationBackgroundPhoto}
                                            alt='ML Drusen Segmentation Photo'
                                        />
                                    }
                                    {(isValidUser && mlDrusenSegmentationOverlayPhoto && useDrusenSegmentationPhoto) &&
                                        <img
                                            className='photo overlay'
                                            src={mlDrusenSegmentationOverlayPhoto}
                                            alt='ML Drusen Segmentation Photo'
                                        />
                                    }
                                    {(mlMicroAneurysmsOverlayPhoto && useMicroAneurysmsPhoto) &&
                                        <img
                                            className='photo overlay'
                                            src={mlMicroAneurysmsOverlayPhoto}
                                            alt='ML Micro Aneurysms Photo'
                                        />
                                    }
                                    {(mlHardExudatesOverlayPhoto && useHardExudatesPhoto) &&
                                        <img
                                            className='photo overlay'
                                            src={mlHardExudatesOverlayPhoto}
                                            alt='ML Hard Exudates Photo'
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className='info-str-container'>
                            <div className='info-str'>
                                <div>{mlMicroAneurysmsSucceeded && `MA Model (${mlMicroAneurysmsModelVersion}, ${mlMicroAneurysmsRunAt})`}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default AdminDrusenSegmentation;
