import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Checkbox, Col, Input, Select, Radio, Row, Spin, Tooltip, Form } from 'antd';

// Helpers
import { buildRequiredErrorMessage, getMaskInputFormat
    } from '../../../helpers/patient-edit-convert';

// Style
import '../../../../static/css/components/admin-gpt-patient-demographics.scss';
import { CAF_MASK_FORMAT, CAF_SERVICE_NUMBER_FIELD, CITY_FIELD, EMAIL_FIELD, FIRST_NAME_FIELD, GENDER_FEMALE_FIELD,
    GENDER_MALE_FIELD, GP_CURRENT_FIELD, GP_FAX_NUMBER_FIELD, HAS_NO_PHN_FIELD, IS_CAF_PERSONNEL_FIELD, 
    LAST_NAME_FIELD, PHN_FIELD, PHONE_FIELD, PHONE_MASK_FORMAT,
    POSTAL_CODE_FIELD, PROVINCE_FIELD, SECONDARY_PHN_FIELD,
    STREET_FIELD, IS_ON_OLD_OHIP_FIELD, 
    ZIP_CODE_MASK, GENDER_FIELD } from '../../../constants';
import { localizedText } from '../../../localizedText';
import { setPatientEditValue } from '../../../reducers/patient-edit-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FormInstance } from 'antd/es/form';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

type ComponentProps = {
    form?: FormInstance,
    successAction?: () => void,
    patientID?: number | null,
    firstName: string,
    lastName: string,
    gender: string | null,
    phone: string,
    email: string,
    province: number | null | undefined,
    PHN: string,
    secondPHN: string,
    street: string,
    city: string,
    postalCode: string,
    gp: string,
    gpFax: string,
    CAFServiceNumber: string,
    isCAF: boolean,
    hasNoPHN: boolean,
    isTestPatient: boolean,
    userIsADMIN: boolean,
    hasNoGp: boolean,
    patientDOB: string,
    examDate: string,
    isOldOHIP: boolean,
    PHNLabel: string,
    PHNSecondaryLabel: string,
    PHNSecondaryRequired: boolean,
    PHNValidationFormat: string,
    PHNSecondaryValidationFormat: string,
};

const GptPatientEditForm = ({form:parentForm, successAction, patientID, firstName, lastName, gender, phone, email, province,
                            PHN, secondPHN, street, city, postalCode, gp, gpFax, CAFServiceNumber, isCAF,
                            hasNoPHN, isTestPatient, userIsADMIN, hasNoGp, patientDOB, examDate, isOldOHIP, PHNLabel,
                            PHNSecondaryLabel, PHNSecondaryRequired, PHNValidationFormat, PHNSecondaryValidationFormat}: ComponentProps) => {
    const { ARMED_PERSONNEL_LABEL_TEXT, CITY_LABEL_TEXT, DOB_LABEL_TEXT,
        EMAIL_LABEL_TEXT, FIRST_NAME_LABEL_TEXT, FORCES_PLACEHOLDER_TEXT,
        GENDER_FEMALE_TEXT,  GENDER_LABEL_TEXT, GENDER_MALE_TEXT,
        GP_FAX_NUMBER_LABEL_TEXT,
        GP_FAX_PLACEHOLDER_TEXT, GP_LABEL_TEXT, LAST_NAME_LABEL_TEXT,
        OPERATING_TEXT, PHONE_LABEL_TEXT,
        POSTAL_CODE_LABEL_TEXT, 
        POSTAL_CODE_MASK, PROVINCE_LABEL_TEXT,
        PROVINCE_PLACEHOLDER_TEXT, STREET_LABEL_TEXT
     } = localizedText;
    const isOperating = useCare1AppSelector(store => store.patientEdit.operating);
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const userIsUsOnly = useCare1AppSelector(store => store.user.isUsOnly);
    const examIsUsOnly = useCare1AppSelector(store => store.examData.exam_is_us_only);
    const [localForm] = Form.useForm();

    const form = parentForm ?? localForm;

    const usOnly = userIsUsOnly || examIsUsOnly;

    const dispatch = useCare1AppDispatch();

    const isProvinceSelected = !!province;
    let PHNMask = getMaskInputFormat(PHNValidationFormat);
    const PHNSecondaryMask = getMaskInputFormat(PHNSecondaryValidationFormat);
    const isOntarioPatient = province === 9;
    const noInsuranceText = isOntarioPatient ? 'No INS.' : 'No Insurance';
    const oldOHIPText = 'Old OHIP';
    if (isOntarioPatient && isOldOHIP) {
        PHNMask = '9999-999-999';
    }

    const provinceLabel = usOnly ? 'State' : PROVINCE_LABEL_TEXT;
    const provincePlaceholderText = usOnly ? 'Select state' : PROVINCE_PLACEHOLDER_TEXT;
    const postalCodeLabel = usOnly ? 'Zip Code' : POSTAL_CODE_LABEL_TEXT;
    const postalCodeMask = usOnly ? ZIP_CODE_MASK : POSTAL_CODE_MASK;
    const countryCode = usOnly ? 'US' : 'CA';
    const provincesToDisplay = provinces.filter(entry => entry.country === countryCode);

    // make the column span the whole column witdh
    const fullColWidth = {span: 24};

    useEffect(()=> {
        // Get values for Ant Design fields from the store whenever values update.
        const newValidationFieldValues = {
            [FIRST_NAME_FIELD]: firstName,
            [LAST_NAME_FIELD]: lastName,
            [GENDER_FIELD]: gender,
            [PHONE_FIELD]: phone || '',
            [EMAIL_FIELD]: email,
            [PROVINCE_FIELD]: province,
            [PHN_FIELD]: PHN,
            [SECONDARY_PHN_FIELD]: secondPHN,
            [STREET_FIELD]: street,
            [CITY_FIELD]: city,
            [POSTAL_CODE_FIELD]: postalCode,
            [GP_CURRENT_FIELD]: gp,
            [GP_FAX_NUMBER_FIELD]: gpFax,
            [CAF_SERVICE_NUMBER_FIELD]: CAFServiceNumber,
            [IS_CAF_PERSONNEL_FIELD]: isCAF,
            [HAS_NO_PHN_FIELD]: hasNoPHN,
            ha_no_gp: hasNoGp,
            patient_dob: patientDOB,
            exam_date: examDate,
        };

        const formValues = userIsADMIN ? {
            ...newValidationFieldValues,
            is_test_patient: isTestPatient,
        } : newValidationFieldValues
        form.setFieldsValue(formValues);
    },[form, firstName, lastName, gender, phone, email,
        province, PHN, secondPHN, street, city, postalCode, gp, gpFax, CAFServiceNumber,
        isCAF, hasNoPHN, isTestPatient, userIsADMIN, hasNoGp, patientDOB])

    return (
        <Form
            className="gpt-patient-edit-form"
            form={form}
        > {/* Test PE 001 */}
            <Spin
                className="loading-spinner"
                spinning={isOperating}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label='Exam Date'
                            labelCol={fullColWidth}
                            colon={false}
                            name='exam_date'
                            initialValue={examDate}
                        >
                            <Input
                                className={`gpt-patient-exam-date`}
                                disabled
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={FIRST_NAME_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={FIRST_NAME_FIELD}
                            initialValue={firstName}
                        >
                            <Input
                                className={`first-name-input`}
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={LAST_NAME_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={LAST_NAME_FIELD}
                            initialValue={lastName}
                        >
                            <Input
                                className={`last-name-input`}
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={GENDER_LABEL_TEXT}
                            labelCol={fullColWidth}
                            name={GENDER_FIELD}
                            rules={[{
                                message: buildRequiredErrorMessage(GENDER_LABEL_TEXT)
                            }]}
                            initialValue={gender}
                        >
                            <RadioGroup disabled>
                                <Radio data-testid="male-gender-button" className="gender-radio-button male" value={GENDER_MALE_FIELD}>
                                    {GENDER_MALE_TEXT}
                                </Radio>
                                <Radio data-testid="female-gender-button" className="gender-radio-button female" value={GENDER_FEMALE_FIELD}>
                                    {GENDER_FEMALE_TEXT}
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={PHONE_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={PHONE_FIELD}
                            initialValue={phone || ''}
                        >
                            <InputMask
                                data-testid='phone-input'
                                className={'phone-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={EMAIL_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={EMAIL_FIELD}
                            initialValue={email}
                        >
                            <Input
                                data-testid='email-input'
                                className={"email-address-input"}
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={DOB_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={'patient_dob'}
                            initialValue={patientDOB}
                        >
                            <Input
                                className={`patient-dob`}
                                disabled
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={provinceLabel}
                            labelCol={fullColWidth}
                            colon={false}
                            name={PROVINCE_FIELD}
                            initialValue={province}
                        >
                            <Select
                                placeholder={provincePlaceholderText}
                                className="patient-province-input"
                                data-testid='province-select'
                                disabled
                            >
                                {provincesToDisplay.length > 0 && provincesToDisplay.map(province => {
                                        return (
                                            <Select.Option key={province.id} value={province.id}>
                                                {province.name}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <>
                            {
                                isOntarioPatient && (
                                    <FormItem className="ontario-numbers-only">
                                        <Tooltip placement="topLeft" title={'For old OHIP, format is 9999-999-999'}>
                                            <Checkbox
                                                data-testid='old-ohip-checkbox'
                                                checked={isOldOHIP}
                                                onChange={(e) => dispatch(setPatientEditValue({
                                                    field: IS_ON_OLD_OHIP_FIELD,
                                                    value: e.target.checked}))}
                                                disabled
                                            >
                                                { oldOHIPText }
                                            </Checkbox>
                                        </Tooltip>
                                    </FormItem>

                                )
                            }
                            <FormItem
                                className="has-no-phn"
                                name={HAS_NO_PHN_FIELD}
                                valuePropName={'checked'}
                                initialValue={hasNoPHN}
                            >
                                <Checkbox data-testid='no-insurance-checkbox' disabled>
                                    {noInsuranceText}
                                </Checkbox>
                            </FormItem>
                            <FormItem
                                className={'patient-phin-label'}
                                label={PHNLabel}
                                labelCol={fullColWidth}
                                colon={false}
                                name={PHN_FIELD}
                                initialValue={PHN}
                            >
                                <InputMask
                                    data-testid='insurance-input'
                                    mask={(PHNMask).replace('?', '')}
                                    className="patient-phin-input ant-input"
                                    disabled
                                />
                            </FormItem>
                        </>
                    </Col>
                    { usOnly ?
                        <Col span={8}>
                            <Row>
                                <FormItem
                                    className="us-has-no-phn"
                                    name={HAS_NO_PHN_FIELD}
                                    valuePropName={'checked'}
                                    initialValue={hasNoPHN}
                                >
                                    <Checkbox data-testid='no-insurance-checkbox' disabled>
                                        {noInsuranceText}
                                    </Checkbox>
                                </FormItem>
                            </Row>
                        </Col>
                        :
                    <>
                    {(PHNSecondaryRequired && isProvinceSelected && !hasNoPHN)
                        && (
                            <Col span={8}>
                                <FormItem
                                    label={PHNSecondaryLabel}
                                    labelCol={fullColWidth}
                                    colon={false}
                                    name={SECONDARY_PHN_FIELD}
                                    initialValue={secondPHN}
                                >
                                    <InputMask
                                        className="patient-mhsc-input"
                                        placeholder={`Enter ${PHNSecondaryLabel}`}
                                        mask={PHNSecondaryMask}
                                        disabled
                                    />
                                </FormItem>
                            </Col>
                        )}
                    </>}
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={STREET_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={STREET_FIELD}
                            initialValue={street}
                        >
                            <Input
                                data-testid='street-input'
                                className="patient-street-input"
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={CITY_LABEL_TEXT}
                            labelCol={fullColWidth}
                            colon={false}
                            name={CITY_FIELD}
                            initialValue={city}
                        >
                            <Input
                                data-testid='city-input'
                                className="patient-city-input"
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={postalCodeLabel}
                            labelCol={fullColWidth}
                            colon={false}
                            name={POSTAL_CODE_FIELD}
                            initialValue={postalCode}
                        >
                            <InputMask
                                data-testid='postal-input'
                                className="patient-postal-code-input ant-input"
                                mask={postalCodeMask}
                                disabled
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={
                                <div className='gp-label-row'>
                                    <span>{GP_LABEL_TEXT}</span>
                                    <div className='gp-buttons'>
                                        {
                                            !gp &&
                                            <FormItem
                                                name='has_no_gp'
                                                noStyle
                                                valuePropName='checked'
                                            >
                                                <Checkbox className='has-no-gp' disabled>
                                                    No GP
                                                </Checkbox>
                                            </FormItem>
                                            
                                        }
                                    </div>
                                </div>}
                            labelCol={fullColWidth}
                            colon={false}
                            name={GP_CURRENT_FIELD}
                            initialValue={gp}
                        >
                            <Input
                                className={`patient-gp-input`}
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={GP_FAX_NUMBER_LABEL_TEXT}
                            className='gp-fax-number-form'
                            labelCol={fullColWidth}
                            colon={false}
                            name={GP_FAX_NUMBER_FIELD}
                            initialValue={gpFax}
                        >
                            <InputMask
                                data-testid='gp-fax-input'
                                placeholder={GP_FAX_PLACEHOLDER_TEXT}
                                className="patient-gp-fax-input ant-input"
                                mask={PHONE_MASK_FORMAT}
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {!usOnly &&
                        <>
                        <FormItem
                            className="armed-personnel-confirm"
                            valuePropName='checked'
                            name={IS_CAF_PERSONNEL_FIELD}
                            initialValue={isCAF}
                        >
                            <Checkbox
                                data-testid='army-checkbox'
                                className="armed-personnel-confirm-checkbox"
                                disabled>
                                {ARMED_PERSONNEL_LABEL_TEXT}
                            </Checkbox>
                        </FormItem>
                        <FormItem
                            className="armed-personnel-service-number"
                            help=""
                            colon={false}
                            name={CAF_SERVICE_NUMBER_FIELD}
                            initialValue={CAFServiceNumber}
                        >
                            <InputMask
                                data-testid='army-input'
                                mask={CAF_MASK_FORMAT}
                                placeholder={FORCES_PLACEHOLDER_TEXT}
                                className="patient-armed-forces-input ant-input"
                                disabled
                            />
                        </FormItem>
                        </>
                        }
                    </Col>
                </Row>                
            </Spin>
        </Form>
    );

}

export default GptPatientEditForm;
